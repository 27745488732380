import { AnyAction } from 'redux';
import {
  TProfitBoostAdvanced,
  TProfitBoostData,
  TProfitBoostItem
} from 'interfaces/bet-data';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export interface IBetslipProfitBoostSliceInitialData {
  // old state
  profitBoost: TProfitBoostData | null;
  profitBoostAdvanced: TProfitBoostAdvanced | { single: []; advanced: null };

  // new state
  _profitBoost: {
    profitBoostSelectedEventId: number | string | null;
    profitBoostSelectedItemId: number | null;
    items: Array<TProfitBoostItem>;
    singleProfitBoostItems: Record<string | number, Array<TProfitBoostItem>>;
  };
}

export const betslipProfitBoostSliceInitialData: IBetslipProfitBoostSliceInitialData =
  {
    profitBoost: null,
    // @Todo separate states
    profitBoostAdvanced: { single: [], advanced: null },
    _profitBoost: {
      profitBoostSelectedEventId: null,
      profitBoostSelectedItemId: null,
      items: [],
      singleProfitBoostItems: {}
    }
  };

// @Todo need to replace advanced and basic separation on free bet reducer

export const betslipProfitBoostSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: AnyAction
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.SET_PROFIT_BOOST:
      return {
        ...state,
        profitBoost: {
          ...state.profitBoost,
          ...action.payload
        }
      };

    case BetslipActionTypes.SET_PROFIT_BOOST_ADVANCED:
      return {
        ...state,
        profitBoostAdvanced: {
          ...state.profitBoostAdvanced,
          ...action.payload
        }
      };

    case BetslipActionTypes.ADD_PROFIT_BOOST_ADVANCED_SINGLE_ITEM: {
      const singleItems = structuredClone(action.payload);

      if (state.profitBoostAdvanced.single.length) {
        state.profitBoostAdvanced.single.forEach(item => {
          if (item.eventId !== action.payload[0].eventId) {
            singleItems.push(item);
          }
        });
      }

      return {
        ...state,
        profitBoostAdvanced: {
          ...state.profitBoostAdvanced,
          single: singleItems
        }
      };
    }

    case BetslipActionTypes.ADD_PROFIT_BOOST_ADVANCED_ITEM:
      return {
        ...state,
        profitBoostAdvanced: {
          ...state.profitBoostAdvanced,
          advanced: action.payload
        }
      };

    case BetslipActionTypes.SET_MULTIPLE_PROFIT_BOOST_ITEMS:
      return {
        ...state,
        _profitBoost: {
          ...state._profitBoost,
          items: action.payload
        }
      };

    case BetslipActionTypes.SET_SELECTED_PROFIT_BOOST_ID:
      return {
        ...state,
        _profitBoost: {
          ...state._profitBoost,
          profitBoostSelectedItemId: action.payload
        }
      };

    case BetslipActionTypes.SET_SELECTED_PROFIT_BOOST_EVENT_ID:
      return {
        ...state,
        _profitBoost: {
          ...state._profitBoost,
          profitBoostSelectedEventId: action.payload
        }
      };

    case BetslipActionTypes.CLEAN_PROFIT_BOOST_STATE:
      // do not keep in cache
      if (!action.payload) {
        return {
          ...state,
          _profitBoost: structuredClone(
            betslipProfitBoostSliceInitialData._profitBoost
          )
        };
      } else {
        return {
          ...state,
          _profitBoost: {
            ...state._profitBoost,
            profitBoostSelectedEventId: null,
            profitBoostSelectedItemId: null
          }
        };
      }

    case BetslipActionTypes.ADD_SINGLE_PROFIT_BOOST_ITEM:
      return {
        ...state,
        _profitBoost: {
          ...state._profitBoost,
          singleProfitBoostItems: {
            ...state._profitBoost.singleProfitBoostItems,
            ...action.payload
          }
        }
      };

    case BetslipActionTypes.REMOVE_PROFIT_BOOST_ITEM: {
      const { id, eventId } = action.payload;

      if (id) {
        const singleItemsClone = structuredClone(
          state._profitBoost.singleProfitBoostItems
        );

        const itemsClone = structuredClone(state._profitBoost.items);

        if (eventId && singleItemsClone?.[eventId]) {
          singleItemsClone[eventId] = singleItemsClone?.[eventId]?.filter(
            profitBoost => id !== profitBoost.id
          );
        }

        return {
          ...state,
          _profitBoost: {
            ...state._profitBoost,
            singleProfitBoostItems: singleItemsClone,
            items: itemsClone?.filter(profitBoost => id !== profitBoost.id)
          }
        };
      }

      return state;
    }
  }

  return null;
};
