import { ComponentType, ReactElement, useEffect } from 'react';

type Props = {
  Child: ComponentType<{ configs: Record<string, any> }>;
  configs: Record<string, any>;
  element: Element;
};

export const ElementWrapper = function ({
  Child,
  configs,
  element
}: Props): ReactElement | null {
  useEffect(() => {
    element.setAttribute('data-loaded', 'true');
  }, [element]);

  return element.getAttribute('data-loaded') === 'true' ? null : (
    <Child configs={configs} />
  );
};
