import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { EAdvancedSelectedTabs } from 'interfaces/bet-data';
import { EBetslipBetType, EBetslipType } from 'interfaces/betslip';
import { calculateAndDispatchUpdates } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/calculateAndDispatchUpdates';
import { GENERAL_FIELD_KEY } from 'newelements/_Betslip/utils/constants/shared';
import store from 'store';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';

export const calculateUpdatesByBetType = (
  betType: EBetslipBetType,
  prevType: EBetslipBetType
) => {
  const betslipStore: IBetslipRootStateInitialData = store.getState()?.betSlip;
  const { singleEventsCalculations, general } = betslipStore.calculations;

  const multipleAndChainTypes = [
    EBetslipBetType.CHAIN,
    EBetslipBetType.MULTIPLE
  ];

  const isSingle = betType === EBetslipBetType.SINGLE;
  const betsCount = betslipStore.allLsBets.length;

  const stake =
    prevType === EBetslipBetType.SINGLE
      ? Object.values(singleEventsCalculations)[0]?.stake
      : general?.stake ?? Object.values(singleEventsCalculations)[0]?.stake;

  const defaultStake = (
    (SpringConfigs.DEFAULT_STAKE_ENABLED &&
      (betslipStore?.predefinedStakes?.default_stake ||
        SpringConfigs.DEFAULT_STAKE)) ||
    ''
  )?.toString();

  const isAdvancedBetslip = betslipStore.betslipType === EBetslipType.ADVANCED;
  const isAdvancedMultiples =
    betslipStore.advancedSelectedTab === EAdvancedSelectedTabs.MULTIPLES;

  const shouldCalculateSingleEventsForAdvancedBetslip =
    betsCount === 1 || (isAdvancedBetslip && isAdvancedMultiples && isSingle);

  const shouldCalculateSingleEventsForBasicBetslip =
    !isAdvancedBetslip && isSingle;

  // @Todo need to handle all bets update trigger to don't calculate all single events in case if added one new
  if (
    shouldCalculateSingleEventsForBasicBetslip ||
    shouldCalculateSingleEventsForAdvancedBetslip
  ) {
    betslipStore.allLsBets.forEach(bet => {
      calculateAndDispatchUpdates({
        stake: stake ?? defaultStake,
        id: bet.eventId
      });
    });
  }

  if (multipleAndChainTypes.includes(betType)) {
    const isAdded = !Object.values(betslipStore.calculations.general || {})
      .length;

    const isTypeChanged = prevType !== betType;

    calculateAndDispatchUpdates({
      id: GENERAL_FIELD_KEY,
      stake: isAdded && !isTypeChanged ? defaultStake : stake
    });
  }
};
