import { MarketType } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { MultipleCardsData } from 'interfaces/multiple-cards';
import { UserNotifications } from 'interfaces/notifications';
import { TMarketType } from 'interfaces/sportsbook';
import {
  Game,
  Region,
  SwarmData,
  TCouponState
} from 'interfaces/sportsbook-data-levels';
import { SportDataActionTypes } from 'store/action-types';
import { TCompetitionWithGame } from '../reducers/sport-data';

export type SportDataActionType = ActionType & { sportAlias?: string };

export const setCashedGame = (data: SwarmData): ActionType => {
  return {
    type: SportDataActionTypes.SET_CAHSED_GAME_DATA,
    payload: data
  };
};

export const setCashedSportData = (data: {
  alias?: string;
  data?: any; // data must be of type any for generic purpose
}): ActionType => {
  return {
    type: SportDataActionTypes.SET_CASHED_SPORT_DATA,
    payload: data
  };
};

export const setGamesByCompetitions = (data: {
  alias: string;
  data: any;
}): ActionType => {
  return {
    type: SportDataActionTypes.SET_GAMES_BY_COMPETITIONS,
    payload: data
  };
};

export const setStreamData = (data: number[]): ActionType => {
  return {
    type: SportDataActionTypes.SET_STREAM_DATA,
    payload: data
  };
};

export const setStreamChannels = (data: number[]): ActionType => {
  return {
    type: SportDataActionTypes.SET_STREAM_CHANNELS,
    payload: data
  };
};

export const setMultipleSelections = (
  payload: MultipleCardsData[] | null
): ActionType => {
  return {
    type: SportDataActionTypes.SET_MULTIPLE_SELECTIONS,
    payload
  };
};

export const setMarketTypes = (
  data: Record<string, TMarketType[] | null>
): ActionType => {
  return {
    type: SportDataActionTypes.SET_MARKET_TYPES,
    payload: data
  };
};

export const setMarketType = (
  data: Record<string, TMarketType | null>
): ActionType => {
  return {
    type: SportDataActionTypes.SET_MARKET_TYPE,
    payload: data
  };
};

export const setBoostedOdds = (
  data: Record<string, Record<string, {}>> | null
): ActionType => {
  return {
    type: SportDataActionTypes.SET_BOOSTED_ODDS,
    payload: data
  };
};

export const setBoostedOddsCalled = (): ActionType => {
  return {
    type: SportDataActionTypes.SET_BOOSTED_ODDS_CALLED
  };
};

export const setStreamDataLoading = (): ActionType => {
  return {
    type: SportDataActionTypes.SET_STREAM_DATA_LOADING
  };
};

export const setHasTopLeagueGames = (data: boolean): ActionType => {
  return {
    type: SportDataActionTypes.SET_HAS_TOP_LEAGUE_GAMES,
    payload: data
  };
};

export const setScrollToGameId = (data?: {
  id: string;
  top: number;
}): ActionType => {
  return {
    type: SportDataActionTypes.SCROLL_TO_GAME_ID,
    payload: data
  };
};

export const setScrollToCompetitionId = (data?: {
  id: string;
  top: number;
  sportId?: number;
}): ActionType => {
  return {
    type: SportDataActionTypes.SCROLL_TO_COMPETITION_ID,
    payload: data
  };
};

export const setLiveEventsData = (
  data: TCompetitionWithGame[],
  sportAlias: string
): SportDataActionType => {
  return {
    type: SportDataActionTypes.SET_LIVE_EVENTS_DATA,
    payload: data,
    sportAlias
  };
};

export const setUpcomingEventsData = (
  data: TCompetitionWithGame[],
  sportAlias: string
): SportDataActionType => {
  return {
    type: SportDataActionTypes.SET_UPCOMING_EVENTS_DATA,
    payload: data,
    sportAlias
  };
};

export const setPopularEventsData = (
  data: TCompetitionWithGame[],
  sportAlias: string
): SportDataActionType => {
  return {
    type: SportDataActionTypes.SET_POPULAR_EVENTS_DATA,
    payload: data,
    sportAlias
  };
};

export const setBoostedEventsData = (
  data: TCompetitionWithGame[],
  sportAlias: string
): SportDataActionType => {
  return {
    type: SportDataActionTypes.SET_BOOSTED_EVENTS_DATA,
    payload: data,
    sportAlias
  };
};

export const setTimeFilterData = (data: {
  option?: string;
  date: boolean;
  count?: number;
  name: string;
}): SportDataActionType => {
  return {
    type: SportDataActionTypes.SET_TIME_FILTER_DATA,
    payload: data
  };
};

export const setSportsListData = (
  data: Region[],
  sportAlias: string
): SportDataActionType => {
  return {
    type: SportDataActionTypes.SET_SPORTS_LIST_SPORT_DATA,
    payload: data,
    sportAlias
  };
};

export const setCalendarMarketTypes = (
  data: Record<string, MarketType[]>
): ActionType => {
  return {
    type: SportDataActionTypes.SET_CALENDAR_MARKET_TYPES,
    payload: data
  };
};

export const setMarketInfoType = (data: 'expanded' | 'compact'): ActionType => {
  return {
    type: SportDataActionTypes.SET_MARKET_INFO_TYPE,
    payload: data
  };
};

export const setCalendarMarketType = (data: MarketType): ActionType => {
  return {
    type: SportDataActionTypes.SET_CALENDAR_MARKET_TYPE,
    payload: data
  };
};

export const setRegionMarkets = (
  data: Record<string, Pick<Game, 'market'>>
): ActionType => {
  return {
    type: SportDataActionTypes.SET_REGION_MARKETS,
    payload: data
  };
};

export const setUserNotifications = (
  payload: UserNotifications
): ActionType => {
  return {
    type: SportDataActionTypes.SET_USER_NOTIFICATIONS,
    payload
  };
};

export const setGamesCount = (payload: Record<string, number>): ActionType => {
  return {
    type: SportDataActionTypes.SET_GAMES_COUNT,
    payload
  };
};

export const setTournamentSportIds = (
  payload: Array<string | number> | null
): ActionType => {
  return {
    type: SportDataActionTypes.SET_TOURNAMENT_SPORT_IDS,
    payload
  };
};

export const setCoupons = (payload: TCouponState): ActionType => {
  return {
    type: SportDataActionTypes.SET_COUPONS,
    payload
  };
};

export const setClickFromFavorites = (payload: boolean): ActionType => {
  return {
    type: SportDataActionTypes.SET_CLICK_FROM_FAVORITES,
    payload
  };
};
