/* eslint-disable */
export const deepMerge = (
  targetObject: any, // object must be of type any for generic purpose
  mergeObject: any, // object must be of type any for generic purpose
  options: { shouldMergeInFirst: boolean, applyEmptyArraysAndObjects: boolean}
): any => {
  /* eslint-enable */

  let customTargetObject = options.shouldMergeInFirst
    ? targetObject
    : Array.isArray(targetObject)
    ? [...targetObject]
    : { ...targetObject };

  if (!customTargetObject) {
    customTargetObject = mergeObject;
  } else {
    for (const key in mergeObject) {
      if (
        typeof mergeObject[key] !== 'object' ||
        customTargetObject[key] === undefined ||
        (options.applyEmptyArraysAndObjects &&
          typeof mergeObject[key] === 'object' &&
          !Object.keys(mergeObject[key] || {}).length)
      ) {
        customTargetObject[key] = mergeObject[key];
      } else if (mergeObject[key] === null || mergeObject[key] === undefined) {
        delete customTargetObject[key];
      } else if (
        Array.isArray(mergeObject[key]) &&
        Array.isArray(customTargetObject[key])
      ) {
        customTargetObject[key] = mergeObject[key];
      } else {
        customTargetObject[key] = deepMerge(
          customTargetObject[key],
          mergeObject[key],
          {
            shouldMergeInFirst: false,
            applyEmptyArraysAndObjects: options.applyEmptyArraysAndObjects
          }
        );
      }
    }
  }

  return customTargetObject;
};
