// prettier-ignore
export const RacingDataActionTypes = {
  SET_UPCOMING_DATA: 'SET_UPCOMING_DATA',
  SET_UPCOMING_JSON_EXIST_IDS: 'SET_UPCOMING_JSON_EXIST_IDS',
  SET_UPCOMING_JSON_SENT_IDS: 'SET_UPCOMING_JSON_SENT_IDS',
  SET_RACING_COMPETITION_DATA: 'SET_RACING_COMPETITION_DATA',
  UPDATE_RACING_COMPETITION_DATA: 'UPDATE_RACING_COMPETITION_DATA',
  SET_GAME: 'SET_GAME',
  SET_GAME_JSON_SENT_IDS: 'SET_GAME_JSON_SENT_IDS',
  SET_GAME_JSON_EXIST_IDS: 'SET_GAME_JSON_EXIST_IDS',
  SET_STORED_GAME_IDS: 'SET_STORED_GAME_IDS',
  SET_EACH_WAY_PARTNER_TERMS: 'SET_EACH_WAY_PARTNER_TERMS',
  SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB: 'SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB',
  SET_IS_COMPETITIONS_LOADING: 'SET_IS_COMPETITIONS_LOADING',
  SET_IS_GAMES_LOADING: 'SET_IS_GAMES_LOADING',
  SET_IS_UPCOMING_RACES_LOADING: 'SET_IS_UPCOMING_RACES_LOADING',
  SET_SINGLE_GAME_FROM_ACTIVE_TAB: 'SET_SINGLE_GAME_FROM_ACTIVE_TAB',
  SET_CHECK_SINGLE_GAME_START_TS: 'SET_CHECK_SINGLE_GAME_START_TS',
  SET_CHECK_COMPETITION: 'SET_CHECK_COMPETITION',
  SET_ENDED_GAME_ID: 'SET_ENDED_GAME_ID',
  SET_RESULTS_TIME_FILTER_ACTIVE_TAB: 'SET_RESULTS_TIME_FILTER_ACTIVE_TAB',
  SET_RESULTS_COMPETITION_DATA: 'SET_RESULTS_COMPETITION_DATA',
  SET_IS_RESULTS_COMPETITION_DATA_LOADING: 'SET_IS_RESULTS_COMPETITION_DATA_LOADING',
  SET_RESULTS_SINGLE_GAME_DATA: 'SET_RESULTS_SINGLE_GAME_DATA',
  SET_IS_RESULTS_GAME_DATA_LOADING: 'SET_IS_RESULTS_GAME_DATA_LOADING',
  SET_RESULTS_GAME_JSON_EXIST_IDS: 'SET_RESULTS_GAME_JSON_EXIST_IDS'
};
