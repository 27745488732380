export const COUNTER_OFFER_ACTIVE_TYPE = 3;
export const SUPER_BET_ACTIVE_TYPE = -1;
export const BET_SP_COEFICIENT = -1;
export const EVENT_SP_COEFICIENT = -1;
export const SYSTEM_BET_MIN_EVENTS_COUNT = 3;

export const PERCENTAGE_DIVISOR = 100;

export const sliceLength = {
  SLICE_START_MINUS_2: -2,
  SLICE_START_3: 3,
  SLICE_END_3: 3,
  SLICE_START_10: 10,
  SLICE_END_MINUS_1: -1,
  SLICE_END_MINUS_2: -2,
  SLICE_END_5: 5,
  SLICE_END_11: 11
};
export const spliceLength = {
  SPLICE_START_3: 3,
  SPLICE_START_4: 4
};
export const innerWidthThreshold = {
  WINDOW_WIDTH_THRESHOLD_1350: 1350,
  WINDOW_WIDTH_THRESHOLD_1520: 1520,
  WINDOW_WIDTH_THRESHOLD_1200: 1200,
  ADJUSTED_WIDTH: 20,
  WIDTH_PERCENTAGE: 0.69
};

export const VerificationStateType = {
  VERIFY_INFO_ERROR: 3,
  VERIFY_INFO_ERROR_5: 5,
  VERIFY_INFO: 7
};

export const SCORE_MULTIPLIER = 10;
export const PASSWORD_MIN_CHARACTER_COUNT = 7;
export const FILE_EXTENSION_LENGTH = -3;
export const COMPETITION_SKELETON_DEFAULT_LENGTH = 8;

export const MONTHS_IN_YEAR = 12;
export const DAYS_IN_YEAR = 365;
export const PERCENTAGE_CONVERSION = 100;

export const ActiveTabAccountWallet = {
  STAKE: 1,
  WITHDRAWALS: 2,
  REWARDS: 3
};

export const DOCUMENTS_MIN_LENGTH = 8;

export const PERIODS_VALUE_LENGTH = -1;

export const DEBOUNCE_DELAY = {
  _10: 10,
  _200: 200,
  _300: 300,
  _400: 400,
  _500: 500,
  _800: 800,
  _2500: 2500,
  _5000: 5000
};

export const MARKET_EVENT_BASE = 2.5;

export const JACKPOT_TITLE_SIZE_DIFFERENCE = 6;
