import { AnyAction } from 'redux';
import { TProfitBoostItem } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';

export const AddProfitBoostAdvancedSingleItem = (
  payload: object | null
): ActionType => {
  return {
    type: BetslipActionTypes.ADD_PROFIT_BOOST_ADVANCED_SINGLE_ITEM,
    payload
  };
};

export const AddProfitBoostAdvancedItem = (
  payload: object | null
): ActionType => {
  return {
    type: BetslipActionTypes.ADD_PROFIT_BOOST_ADVANCED_ITEM,
    payload
  };
};

export const setProfitBoost = (payload: object | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_PROFIT_BOOST,
    payload
  };
};

export const setProfitBoostAdvanced = (payload: object | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_PROFIT_BOOST_ADVANCED,
    payload
  };
};

//// new Actions

export const setMultipleProfitBoostItems = (
  payload: TProfitBoostItem[]
): AnyAction => {
  return {
    type: BetslipActionTypes.SET_MULTIPLE_PROFIT_BOOST_ITEMS,
    payload
  };
};

export const addSingleProfitBoostItem = (
  payload: Record<string | number, TProfitBoostItem[]>
): AnyAction => {
  return {
    type: BetslipActionTypes.ADD_SINGLE_PROFIT_BOOST_ITEM,
    payload
  };
};

export const setSelectedProfitBoostId = (
  payload: TProfitBoostItem['id'] | null
): AnyAction => {
  return {
    type: BetslipActionTypes.SET_SELECTED_PROFIT_BOOST_ID,
    payload
  };
};

export const cleanProfitBoostState = (keepInCache = true): AnyAction => {
  return {
    type: BetslipActionTypes.CLEAN_PROFIT_BOOST_STATE,
    payload: keepInCache
  };
};

export const removeProfitBoostItem = (payload: {
  id?: number;
  eventId?: number | string;
}): AnyAction => {
  return {
    type: BetslipActionTypes.REMOVE_PROFIT_BOOST_ITEM,
    payload
  };
};

export const setSelectedProfitBoostEventId = (
  payload: number | string | null
): AnyAction => {
  return {
    type: BetslipActionTypes.SET_SELECTED_PROFIT_BOOST_EVENT_ID,
    payload
  };
};
