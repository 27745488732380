import dayjs from 'dayjs';
import { TimesToAdd } from 'utils/constants/dateInfo/time-numbers';
import RidGenerator from 'utils/swarm/rid-generator';
import { ActionType } from 'interfaces/generic';
import { ResultsSport } from 'interfaces/results';
import { ResultsDataActionTypes } from 'store/action-types/results-data';

type TResultActiveDate = {
  from?: number;
  to?: number;
};

type State = {
  date: TResultActiveDate;
  activeDate: TResultActiveDate;
  competitionId?: number | null;
  teamId?: number | null;
  status?: 'live' | 'finished';
  currentStatus?: 'live' | 'finished';
  sportId?: number;
  data?: ResultsSport[];
  dataLoading: boolean;
  trigger: string;
};

const initialData: State = {
  date: {
    from: dayjs().add(TimesToAdd.BEFORE_2_DAYS, 'd').startOf('day').valueOf(),
    to: dayjs().endOf('day').valueOf()
  },
  activeDate: {
    from: dayjs().add(TimesToAdd.BEFORE_2_DAYS, 'd').startOf('day').valueOf(),
    to: dayjs().endOf('day').valueOf()
  },
  status: 'finished',
  currentStatus: 'finished',
  competitionId: null,
  teamId: null,
  trigger: '',
  dataLoading: false
};

export const resultsData = (state = initialData, action: ActionType): State => {
  switch (action.type) {
    case ResultsDataActionTypes.SET_DATE:
      return {
        ...state,
        date: action.payload
      };

    case ResultsDataActionTypes.SET_COMPETITION:
      return {
        ...state,
        competitionId: action.payload
      };

    case ResultsDataActionTypes.SET_TEAM:
      return {
        ...state,
        teamId: action.payload
      };

    case ResultsDataActionTypes.SET_STATUS:
      return {
        ...state,
        status: action.payload
      };

    case ResultsDataActionTypes.SET_SPORT:
      return {
        ...state,
        sportId: action.payload
      };

    case ResultsDataActionTypes.SET_DATA:
      return {
        ...state,
        data: action.payload
      };

    case ResultsDataActionTypes.SET_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.payload
      };

    case ResultsDataActionTypes.SET_TRIGGER: {
      const draft = structuredClone(state);

      if (state.date.to !== state.activeDate.to) {
        draft.data = undefined;
        draft.dataLoading = true;
      }

      if (draft.activeDate.to !== state.date.to) {
        draft.activeDate = state.date;
      }

      draft.trigger = RidGenerator.rand();

      return draft;
    }

    case ResultsDataActionTypes.SET_CURRENT_STATUS:
      return {
        ...state,
        currentStatus: action.payload
      };
    case ResultsDataActionTypes.RESET_FILTERS:
      return {
        ...initialData,
        data: state.data,
        date: state.date,
        activeDate: state.activeDate
      };

    default: {
      return state;
    }
  }
};
