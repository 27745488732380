import { TCounterOfferState, TSuperBetEventType } from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export enum ECounterOfferSelectedType {
  ODD = 'odd',
  STAKE = 'stake'
}

export interface IBetslipCounterOfferSliceInitialData {
  sentSuperBetCount: number;
  sentCounterOffersCount: number;
  superBets: TSuperBetEventType[];
  selectedOddCounterOffer: string | null;
  oddsCounterOffers: { [key: string]: string };
  counterOffers: Record<number, TCounterOfferState>;
  counterOfferSelectedType: ECounterOfferSelectedType | null;
  counterOfferUnderReviewModal: boolean;
}

export const betslipCounterOfferSliceInitialData: IBetslipCounterOfferSliceInitialData =
  {
    superBets: [],
    counterOffers: {},
    sentSuperBetCount: 0,
    oddsCounterOffers: {},
    sentCounterOffersCount: 0,
    selectedOddCounterOffer: '',
    counterOfferSelectedType: null,
    counterOfferUnderReviewModal: false
  };

export const betslipCounterOfferSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: ActionType
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.SET_ODDS_COUNTER_OFFERS:
      return {
        ...state,
        oddsCounterOffers: action.payload
      };

    case BetslipActionTypes.SET_SELECTED_ODD_COUNTER_OFFER:
      return {
        ...state,
        selectedOddCounterOffer: action.payload
      };

    case BetslipActionTypes.SET_COUNTER_OFFER_SELECTED_TYPE:
      return {
        ...state,
        counterOfferSelectedType: action.payload
      };

    case BetslipActionTypes.TOGGLE_SUPER_BET: {
      const isAlreadyContain = state.superBets.some(
        id => id === action.payload
      );

      return {
        ...state,
        superBets: isAlreadyContain
          ? state.superBets.filter(id => id !== action.payload)
          : [...state.superBets, action.payload]
      };
    }

    case BetslipActionTypes.RESET_SUPER_BETS:
      return {
        ...state,
        superBets: []
      };

    case BetslipActionTypes.SET_SENT_COUNTER_OFFERS_COUNT:
      return {
        ...state,
        sentCounterOffersCount: Math.max(0, action.payload)
      };

    case BetslipActionTypes.SET_SENT_SUPER_BET_COUNT:
      return {
        ...state,
        sentSuperBetCount: Math.max(0, action.payload)
      };

    case BetslipActionTypes.SET_COUNTER_OFFERS:
      return {
        ...state,
        counterOffers: {
          ...state.counterOffers,
          ...action.payload
        }
      };

    case BetslipActionTypes.RESET_COUNTER_OFFERS:
      return {
        ...state,
        counterOffers: {}
      };
    case BetslipActionTypes.SET_COUNTER_OFFERS_UNDER_REVIEW_MODAL:
      return {
        ...state,
        counterOfferUnderReviewModal: action.payload
      };
  }

  return null;
};
