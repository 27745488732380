import { ActionType } from 'interfaces/generic';
import { MetaDataActionTypes } from 'store/action-types/meta-data';
import { MetaData } from 'store/actions/meta-data';

const MetaDataState: MetaData = {
  sport: undefined,
  competition: undefined,
  game: undefined
};

export const metaData = (
  state = MetaDataState,
  action: ActionType
): MetaData => {
  switch (action.type) {
    case MetaDataActionTypes.SET_SPORT_DATA:
      return {
        ...state,
        sport: action.payload
      };
    case MetaDataActionTypes.SET_COMPETITION_DATA:
      return {
        ...state,
        competition: action.payload
      };
    case MetaDataActionTypes.SET_GAME_DATA:
      return {
        ...state,
        game: action.payload
      };
    case MetaDataActionTypes.SET_ALL_DATA:
      return {
        ...state,
        sport: (action.payload as MetaData)?.sport,
        competition: (action.payload as MetaData)?.competition,
        game: (action.payload as MetaData)?.game
      };

    default: {
      return state;
    }
  }
};
