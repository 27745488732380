import { ActionType } from 'interfaces/generic';
import { Game, Sport } from 'interfaces/sportsbook-data-levels';
import { ESportDataActionTypes } from 'store/action-types';
import { TAdditionalStatisticsData } from 'store/reducers/e-sport-data';

interface CustomGame extends Game {
  competitionName: string;
  sportAlias: string;
}

export const setESportListData = (
  data: Record<string, Sport> | null | undefined
): ActionType => {
  return {
    type: ESportDataActionTypes.SET_E_SPORT_LIST_DATA,
    payload: data
  };
};

export const setCashedESportPrematchGames = (data: {
  data: Record<string, CustomGame> | {};
  alias: string;
}): ActionType => {
  return {
    type: ESportDataActionTypes.SET_CASHED_E_SPORT_PREMATCH_GAMES,
    payload: data
  };
};

export const setEsportGames = (payload: {
  data: Record<string, Record<string, Game>> | null | undefined;
}): ActionType => {
  return {
    type: ESportDataActionTypes.SET_ESPORT_GAMES,
    payload
  };
};

export const setGamesSingleViewData = (payload: {
  id: string;
  data: { sport: Record<string, Sport> };
}): ActionType => {
  return {
    type: ESportDataActionTypes.SET_ESPORT_GAME_DATA,
    payload
  };
};

export const setAdditionalStatisticsData = (
  payload: TAdditionalStatisticsData
): ActionType => {
  return {
    type: ESportDataActionTypes.SET_ESPORT_ADDITIONAL_DATA,
    payload
  };
};
