import {
  Key,
  KEY_NAMES,
  Namespace,
  NAMESPACES,
  PREFIX
} from 'utils/constants/app/local-storage-keys';

export const storageKeyName = (type: Namespace, keyName: Key): string => {
  return `${PREFIX}__${NAMESPACES[type]}__${KEY_NAMES[type][keyName]}`;
};
