export enum BonusTypes {
  FREE_BONUSES = 'freeBonuses',
  CASINO_BONUSES = 'casinoBonuses'
}

export const BonusActions = {
  CANCEL_BONUS: 'cancel_bonus',
  CLAIM_BONUS: 'claim_bonus'
};

export const BonusActionsSuccessMessageKeys = {
  BONUS_CANCELED_SUCCESS: 'bonusCanceledSuccess',
  BONUS_CLAIMED_SUCCESS: 'bonusClaimedSuccess'
};

export const BonusCodeButton = {
  BONUS_CODE_CANCELED: 3,
  BONUS_CODE_EXPIRED: 4,
  BONUS_CODE_COMPLETED: 5,
  BONUS_CODE_CONVERTED: 6
};
export const BONUS_TYPE = {
  SPORT_BONUS: 1,
  WAGERING_BONUS: 2,
  MANUAL_BONUS: 3,
  CASH_BONUS: 4,
  FREE_SPIN: 5,
  FREE_BET: 6,
  BONUS_MONEY: 7,
  CLIENT_WAGERING_BONUS_RESPONSE: 8
};
