export enum ETaxAmountRangesType {
  RANGE_TAX_POSSIBLE_WIN = 2,
  RANGE_TAX_POSSIBLE_WIN_WITHOUT_STAKE = 1
}

const TAX_TYPES = {
  STAKE_TAX: 4,
  POSSIBLE_WIN_TAX: 20,
  POSSIBLE_WIN_TAX_WITHOUT_STAKE: 21
};

export enum ETaxTypes {
  STAKE_TAX = TAX_TYPES.STAKE_TAX,
  POSSIBLE_WIN_TAX = TAX_TYPES.POSSIBLE_WIN_TAX,
  POSSIBLE_WIN_TAX_WITHOUT_STAKE = TAX_TYPES.POSSIBLE_WIN_TAX_WITHOUT_STAKE
}

export type TaxRange = Partial<Record<ETaxAmountRangesType, RangeType>>;

export type TTaxesState = {
  basic?: { type: ETaxTypes; percent: number; taxableSportIds?: number[] };
  ranges?: TaxRange;
};

export type RangeType = {
  entire_amount: boolean;
  from: number;
  percent: number;
  to: number;
  type: ETaxAmountRangesType;
};
