import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import {
  EEventsTypes,
  ETimeFilterValues,
  TCompetitionWithGame,
  TSport
} from 'interfaces/events';
import { ActionType, ValueOf } from 'interfaces/generic';
import { EventsActionTypes } from 'store/action-types/events';
import { TEventsAction } from 'store/actions/events';

type TEvents = Partial<
  Record<
    string,
    Record<EEventsTypes, Partial<Record<string, TCompetitionWithGame[]>>>
  >
>;

type TInitialState = {
  events: TEvents;
  sports: Partial<Record<string, Record<EEventsTypes, TSport[]>>>;
  selectedSport: Partial<Record<string, Record<EEventsTypes, TSport>>>;
  selectedEventsType: Record<string, EEventsTypes | null>;
  timeFilter: Record<string, ValueOf<typeof ETimeFilterValues>>;
};

const activeTimeFilter: Record<
  string,
  ValueOf<typeof ETimeFilterValues>
> = JSON.parse(
  LocalStorage.getItem(storageKeyName('events', 'ACTIVE_TIME_FILTER'))
) || {};

const initialState: TInitialState = {
  events: {},
  sports: {},
  selectedSport: {},
  selectedEventsType: {},
  timeFilter: activeTimeFilter
};

export const events = (
  state = initialState,
  action: ActionType
): TInitialState => {
  switch (action.type) {
    case EventsActionTypes.SET_EVENTS: {
      const [id] = Object.keys(action.payload);
      const [payload] = Object.values(action.payload) as [TEventsAction];

      return {
        ...state,
        events: {
          ...state.events,
          ...{
            [id]: {
              ...(state.events[id] || {}),
              [payload.type]: {
                ...(state.events[id]?.[payload.type] || {}),
                [payload.sport]: payload.data
              }
            }
          }
        } as TEvents
      };
    }

    case EventsActionTypes.SET_SPORTS: {
      const [id] = Object.keys(action.payload);
      const [payload] = Object.values(action.payload as any[]);

      return {
        ...state,
        sports: {
          ...state.sports,
          ...{
            [id]: {
              ...(state.sports[id] || {}),
              ...payload
            }
          }
        }
      };
    }

    case EventsActionTypes.REMOVE_SPORT: {
      const { moduleId, type, sport } = action.payload;

      const filteredSports = (
        state.sports?.[moduleId] as Record<string, TSport[]>
      )?.[type]?.filter(s => s.alias !== sport);

      if (filteredSports) {
        return {
          ...state,
          sports: {
            ...state.sports,
            [moduleId]: {
              ...state.sports[moduleId],
              [type]: filteredSports
            }
          }
        };
      }

      return state;
    }

    case EventsActionTypes.SET_SELECTED_SPORT: {
      const [id] = Object.keys(action.payload);
      const [payload] = Object.values(action.payload as any[]);

      return {
        ...state,
        selectedSport: {
          ...state.selectedSport,
          ...{
            [id]: {
              ...(state.selectedSport[id] || {}),
              ...payload
            }
          }
        }
      };
    }

    case EventsActionTypes.SET_SELECTED_EVENTS_TYPE:
      return {
        ...state,
        selectedEventsType: {
          ...state.selectedEventsType,
          ...action.payload
        }
      };

    case EventsActionTypes.SET_TIME_FILTER:
      return {
        ...state,
        timeFilter: { ...state.timeFilter, ...action.payload }
      };

    default: {
      return state;
    }
  }
};
