// FIXME: Refactor cookies like local storage or session storage is done for avoiding runtime errors and unexpected behaviour
import {
  HOUR_PER_DAY,
  MILLISECONDS_IN_SECOND,
  SECONDS_PER_HOUR
} from 'utils/constants/dateInfo/time-numbers';

export const getCookie = (cName: string): string | Record<string, any> => {
  const name = `${cName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      const value = c.substring(name.length, c.length);

      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    }
  }

  return '';
};

export const setCookie = (
  cName: string,
  cValue: string,
  exDays = 0,
  extraAttributes: {
    SameSite?: 'Lax' | 'None' | 'Strict';
    Secure?: boolean;
    [key: string]: any;
  } = {}
): string => {
  let expires = '';
  let combinedExtraAttributes = '';

  if (exDays) {
    const d = new Date();
    d.setTime(
      d.getTime() +
        exDays * HOUR_PER_DAY * SECONDS_PER_HOUR * MILLISECONDS_IN_SECOND
    );
    expires = `expires=${d.toUTCString()};`;
  }

  if (Object.keys(extraAttributes).length) {
    combinedExtraAttributes = Object.keys(extraAttributes).reduce(
      (acc, attr) => `${acc}${attr}=${extraAttributes[attr]};`,
      ''
    );
  }

  const cookieValue = `${cName}=${cValue};${expires}${combinedExtraAttributes}path=/;secure`;
  document.cookie = cookieValue;

  return cookieValue;
};

export const deleteCookie = (cName: string): void => {
  document.cookie = `${cName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};
