import { BetTicket } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';

export const setOpenBetsData = (payload: BetTicket[]): ActionType => {
  return {
    type: BetslipActionTypes.SET_OPEN_BETS_DATA,
    payload
  };
};

export const setOpenBetsLoading = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_OPEN_BETS_LOADING,
    payload
  };
};

export const updateOpenBets = (): ActionType => {
  return {
    type: BetslipActionTypes.UPDATE_OPEN_BETS
  };
};

export const setOpenBetsCount = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_OPEN_BETS_COUNT,
    payload
  };
};

export const setOpenBetsCountAfterCashout = (
  payload: null | number
): ActionType => {
  return {
    type: BetslipActionTypes.SET_OPEN_BETS_COUNT_AFTER_CASHOUT,
    payload
  };
};
