import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getBetslipEditBetActive } from 'utils/betslip/betslip';
import {
  BetSlipEventObj,
  BetTicket,
  SystemEntity,
  TEditBet
} from 'interfaces/bet-data';
import { SportBonus } from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types/bet-slip';
import {
  betslipBetsSliceInitialData,
  betslipBetsSliceReducer,
  IBetslipBetsSliceInitialData
} from 'store/reducers/betslip/bets';
import {
  betslipCalculationsSliceInitialData,
  betslipCalculationsSliceReducer,
  IBetslipCalculationsSliceInitialData
} from 'store/reducers/betslip/calculations';
import {
  betslipConfigsSliceInitialData,
  betslipConfigsSliceReducer,
  IBetslipConfigsSliceInitialData
} from 'store/reducers/betslip/configs';
import {
  betslipCounterOfferSliceInitialData,
  betslipCounterOfferSliceReducer,
  IBetslipCounterOfferSliceInitialData
} from 'store/reducers/betslip/conterOffer';
import {
  betslipFeaturesSliceInitialData,
  betslipFeaturesSliceReducer,
  IBetslipFeaturesSliceInitialData
} from 'store/reducers/betslip/features';
import {
  betslipFreeBetSliceInitialData,
  betslipFreeBetSliceReducer,
  IBetslipFreeBetSliceInitial
} from 'store/reducers/betslip/freeBet';
import {
  betslipOddsSliceInitialData,
  betslipOddsSliceReducer,
  IBetslipOddsSliceInitialData
} from 'store/reducers/betslip/odds';
import {
  betslipOpenBetsSliceInitialData,
  betslipOpenBetsSliceReducer,
  IBetslipOpenBetsSliceInitialData
} from 'store/reducers/betslip/openBets';
import {
  betslipProfitBoostSliceInitialData,
  betslipProfitBoostSliceReducer,
  IBetslipProfitBoostSliceInitialData
} from 'store/reducers/betslip/profitBoost';
import {
  betslipStakeSliceInitialData,
  betslipStakeSliceReducer,
  IBetslipStakeSliceInitialData
} from 'store/reducers/betslip/stake';

export interface IBetslipRootStateInitialData
  extends IBetslipFreeBetSliceInitial,
    IBetslipBetsSliceInitialData,
    IBetslipOddsSliceInitialData,
    IBetslipStakeSliceInitialData,
    IBetslipConfigsSliceInitialData,
    IBetslipFeaturesSliceInitialData,
    IBetslipOpenBetsSliceInitialData,
    IBetslipProfitBoostSliceInitialData,
    IBetslipCalculationsSliceInitialData,
    IBetslipCounterOfferSliceInitialData {
  system: {
    label: string;
    value: number;
    combinationCount: number;
  } | null;
  selected: string | number;

  editBet: TEditBet;
  cashoutFrom: 'betslip' | 'betHistory' | '';
  sportBonuses: SportBonus[];
  singleReturn: number;
  isCalculating: boolean;
  calculationLoading: boolean;
  cashOutTicket: BetTicket | null;
  basicEw: {
    systemEw: boolean;
    multipleEw: boolean;
  };
  isAccumulatorBonusCalculating: boolean;

  // maybe can be moved into one of slices
  systemMinCombinationsCount: number | null;
  combinationOfSelectedSystem: BetSlipEventObj[][];
  possibleSystemCombinations: SystemEntity[];
  selectedSystem: SystemEntity | null;
}

const initialData: IBetslipRootStateInitialData = {
  ...betslipCounterOfferSliceInitialData,
  ...betslipCalculationsSliceInitialData,
  ...betslipProfitBoostSliceInitialData,
  ...betslipFeaturesSliceInitialData,
  ...betslipOpenBetsSliceInitialData,
  ...betslipConfigsSliceInitialData,
  ...betslipFreeBetSliceInitialData,
  ...betslipStakeSliceInitialData,
  ...betslipOddsSliceInitialData,
  ...betslipBetsSliceInitialData,

  selected: '',
  system: null,
  editBet: getBetslipEditBetActive(),
  cashoutFrom: '',
  sportBonuses: [],
  singleReturn: 0,
  isCalculating: false,
  calculationLoading: false,

  cashOutTicket: null,
  basicEw: {
    systemEw: false,
    multipleEw: false
  },

  isAccumulatorBonusCalculating: false,

  //
  systemMinCombinationsCount: null,
  combinationOfSelectedSystem: [],
  possibleSystemCombinations: [],
  selectedSystem: null
};

const reducers = [
  betslipBetsSliceReducer,
  betslipOddsSliceReducer,
  betslipStakeSliceReducer,
  betslipFreeBetSliceReducer,
  betslipConfigsSliceReducer,
  betslipFeaturesSliceReducer,
  betslipOpenBetsSliceReducer,
  betslipProfitBoostSliceReducer,
  betslipCalculationsSliceReducer,
  betslipCounterOfferSliceReducer
];

const betslipRootReducer = (
  state = initialData,
  action: ActionType
): IBetslipRootStateInitialData => {
  switch (action.type) {
    case BetslipActionTypes.SET_EDIT_BET_ACTIVE:
      return {
        ...state,
        editBet: action.payload
      };

    case BetslipActionTypes.SET_CASHOUT_FROM:
      return {
        ...state,
        cashoutFrom: action.payload
      };

    case BetslipActionTypes.SET_SYSTEM:
      return {
        ...state,
        system: action.payload
      };

    case BetslipActionTypes.SET_SELECTED_STAKE:
      return {
        ...state,
        selected: action.payload
      };

    case BetslipActionTypes.SET_SPORT_BONUS_RULES:
      return {
        ...state,
        sportBonuses: action.payload
      };

    case BetslipActionTypes.SET_SINGLE_RETURN:
      return {
        ...state,
        singleReturn: action.payload
      };

    case BetslipActionTypes.SET_IS_CALCULATING:
      return {
        ...state,
        isCalculating: action.payload
      };

    case BetslipActionTypes.SET_CALCULATION_LOADING:
      return {
        ...state,
        calculationLoading: action.payload
      };

    case BetslipActionTypes.SET_CASHOUT_TICKET:
      return {
        ...state,
        cashOutTicket: action.payload
      };

    case BetslipActionTypes.SET_BASIC_EW:
      return {
        ...state,
        basicEw: action.payload
      };

    // this one should be removed
    case BetslipActionTypes.SET_IS_ACCUMULATOR_BONUS_CALCULATING:
      return {
        ...state,
        isAccumulatorBonusCalculating: action.payload
      };

    case BetslipActionTypes.SET_COMBINATION_OF_SELECTED_SYSTEM:
      return {
        ...state,
        combinationOfSelectedSystem: action.payload
      };

    case BetslipActionTypes.SET_SYSTEM_POSSIBLE_COMBINATIONS:
      return {
        ...state,
        possibleSystemCombinations: action.payload
      };

    case BetslipActionTypes.SET_SYSTEM_MIN_COMBINATIONS_COUNT:
      return {
        ...state,
        systemMinCombinationsCount: action.payload
      };

    case BetslipActionTypes.SET_SELECTED_SYSTEM:
      return {
        ...state,
        selectedSystem: action.payload
      };

    default:
      return (
        reducers.reduce<IBetslipRootStateInitialData | null>(
          (_state, reducer) => _state ?? reducer(state, action),
          null
        ) ?? state
      );
  }
};

const persistConfig = {
  key: 'betSlip',
  storage: storage,
  whitelist: [
    'type',
    'editBet',
    'allLsBets',
    'calculations',
    'predefinedStakes'
  ]
};

export const betSlip = persistReducer(persistConfig, betslipRootReducer);
