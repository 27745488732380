import { ReactElement } from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import { ButtonType } from 'antd/lib/button/button';
import cc from 'classcat';
import { createTestId } from 'utils/helpers/create-test-id';
import { ButtonPropsType } from 'interfaces/button';
import './index.less';

export type TButtonProps = Omit<AntButtonProps, 'type'> & {
  fullwidth?: boolean;
  customSize?: 'small' | 'medium' | 'large' | 'x-large';
  type?: ButtonPropsType;
};

export const Button = ({
  fullwidth,
  type = 'default',
  customSize,
  ...props
}: TButtonProps): ReactElement => {
  return (
    <AntButton
      {...props}
      {...(!['green', 'cancel', 'fun'].includes(type) && {
        type: type as ButtonType
      })}
      className={cc([
        `${customSize ? `xButton__${customSize}` : ''} x-button`,
        {
          'x-button--green': type === 'green',
          'x-button--cancel': type === 'cancel',
          'x-button--fun': type === 'fun',
          'x-button--link-primary': type === 'linkPrimary',
          'x-button--fullWidth': fullwidth,
          'x-button--disabled': props.disabled
        },
        props.className
      ])}
      {...createTestId('x-button')}
    >
      {props.children}
    </AntButton>
  );
};
