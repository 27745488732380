import { deepMerge } from 'utils/collection-manipulation/deep-merge';
import { getMarketType } from 'utils/sportsbook/asian-markets-helper';
import { ActionType } from 'interfaces/generic';
import {
  Competition,
  Game,
  Market,
  Sport,
  SwarmData
} from 'interfaces/sportsbook-data-levels';
import { GameDataActionTypes } from 'store/action-types';

type CustomCompetition = Pick<Required<Competition>, 'game' | 'name' | 'id'>;
type CustomGame = Pick<Required<Game>, 'market'>;

type State = {
  gameData: {
    swarmData: SwarmData;
    other: { isDataReady?: boolean };
    competitionName: string;
    game: Record<string, Game> | Game;
    markets: Market[];
    sportAlias?: string;
    regionName?: string;
    sportName?: string;
  };
  other?: Record<string, any>;
  swarmData?: Record<string, Sport>;
};

const initialData: State = {
  gameData: {
    swarmData: {} as SwarmData,
    other: {},
    competitionName: '',
    game: {},
    markets: []
  }
};

export const createStateBasedOnSwarm = (swarmData: {
  sport: Record<string, Sport>;
}): Record<string, any> | undefined => {
  if (
    swarmData &&
    swarmData.sport &&
    Object.values(swarmData.sport).length &&
    Object.values(swarmData.sport)[0]
  ) {
    const sportId = Object.keys(swarmData.sport)[0];

    if (!swarmData.sport[sportId]?.region) {
      return;
    }

    const regionId = Object.keys(swarmData.sport[sportId].region || {})[0];
    const competition: CustomCompetition = Object.values(
      swarmData.sport[sportId].region?.[regionId].competition || {}
    )[0] as CustomCompetition;

    const gameId = Object.keys(competition.game)[0];
    const game: CustomGame = competition.game[gameId] as CustomGame;
    const markets = Object.values(game.market).map(market => {
      if (market) {
        return {
          ...market,
          type: getMarketType(market)
        };
      }

      return market;
    });

    return {
      game,
      sportName: swarmData?.sport?.[sportId]?.name,
      sportAlias: swarmData?.sport?.[sportId]?.alias,
      markets: markets.sort((a, b) => a.order - b.order),
      competitionName: competition.name,
      competitionId: competition.id,
      regionName: swarmData?.sport?.[sportId]?.region?.[regionId]?.name,
      regionAlias: swarmData?.sport?.[sportId]?.region?.[regionId]?.alias
    };
  }

  return {
    game: {},
    markets: [],
    competitionName: '',
    competitionId: '',
    sportAlias: '',
    regionName: '',
    regionAlias: '',
    sportName: ''
  };
};

export const gameData = (state = initialData, action: ActionType): State => {
  switch (action.type) {
    case GameDataActionTypes.SET_STATE: {
      const swarmData: SwarmData & {
        sport: Record<string, Sport>;
      } = action.payload.swarmData;

      return swarmData
        ? {
            ...state,
            gameData: {
              ...state.gameData,
              swarmData,
              other: {
                ...state.other,
                isDataReady: true
              },
              ...createStateBasedOnSwarm(swarmData)
            }
          }
        : initialData;
    }

    case GameDataActionTypes.UPDATE_STATE: {
      const swarmData = deepMerge(
        state.swarmData,
        action.payload.updatedSwarmData,
        { shouldMergeInFirst: false, applyEmptyArraysAndObjects: false }
      );

      return {
        ...state,
        gameData: {
          ...state.gameData,
          swarmData,
          other: {
            ...state.other,
            isDataReady: true
          },
          ...createStateBasedOnSwarm(swarmData)
        }
      };
    }

    default: {
      return state;
    }
  }
};
