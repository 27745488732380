import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { ActionType } from 'interfaces/generic';
import {
  EAddRemoveAllReducerTypes,
  EGameJsonReducerTypes,
  ERacingResultsTimeFilterTabs,
  ESetUpcomingDataReducerTypes,
  TRacingResultsRegion,
  TRacingResultsSingleGameGame
} from 'interfaces/racing';
import {
  EachWayPartnerTerms,
  RacingGame,
  RacingRegion,
  TSwarmRacingGame
} from 'interfaces/sportsbook-data-levels';
import { RacingDataActionTypes } from 'store/action-types';

export const setUpcomingData = (
  type: ESetUpcomingDataReducerTypes,
  swarmData?: Record<number, RacingGame>
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_UPCOMING_DATA,
    payload: {
      type,
      swarmData
    }
  };
};

export const setUpcomingJsonExistIds = (
  type: EGameJsonReducerTypes,
  id?: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_UPCOMING_JSON_EXIST_IDS,
    payload: { type, id }
  };
};

export const setUpcomingJsonSentIds = (
  type: EGameJsonReducerTypes,
  id?: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_UPCOMING_JSON_SENT_IDS,
    payload: { type, id }
  };
};

export const setCompetitionData = (
  type: EAddRemoveAllReducerTypes,
  data?: Record<number, RacingRegion>
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RACING_COMPETITION_DATA,
    payload: {
      type,
      data
    }
  };
};

export const setGame = (swarmData: TSwarmRacingGame | null): ActionType => {
  return {
    type: RacingDataActionTypes.SET_GAME,
    payload: {
      swarmData
    }
  };
};

export const setGameJsonSentIds = (
  type: EGameJsonReducerTypes,
  id?: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_GAME_JSON_SENT_IDS,
    payload: { type, id }
  };
};

export const setGameJsonExistIds = (
  type: EGameJsonReducerTypes,
  id?: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_GAME_JSON_EXIST_IDS,
    payload: { type, id }
  };
};

export const setEachWayPartnerTerms = (
  manualEachWayData: EachWayPartnerTerms,
  marketId: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_EACH_WAY_PARTNER_TERMS,
    payload: {
      manualEachWayData,
      marketId
    }
  };
};

export const setDateAndSportFilterActiveTab = (
  dateAndSportFilterActiveTab: string
): ActionType => {
  /* --- Please do not use this setter from another place. This local storage setter should only set the value from here. --- */

  LocalStorage.setItem(
    storageKeyName('sportsbook', 'RACING_DATE_AND_SPORT_FILTER_ACTIVE_TAB'),
    dateAndSportFilterActiveTab
  );

  return {
    type: RacingDataActionTypes.SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB,
    payload: dateAndSportFilterActiveTab
  };
};

export const setIsCompetitionsLoading = (
  competitionsLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_COMPETITIONS_LOADING,
    payload: competitionsLoading
  };
};

export const setIsGamesLoading = (isGamesLoading: boolean): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_GAMES_LOADING,
    payload: isGamesLoading
  };
};

export const setIsUpcomingRacesLoading = (
  upcomingRacesLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_UPCOMING_RACES_LOADING,
    payload: upcomingRacesLoading
  };
};

export const setSingleGameFromActiveTab = (
  singleGameFromNextOffTab: string | null
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_SINGLE_GAME_FROM_ACTIVE_TAB,
    payload: singleGameFromNextOffTab
  };
};

export const setCheckSingleGameStartTs = (
  checkSingleGameStartTs: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_CHECK_SINGLE_GAME_START_TS,
    payload: checkSingleGameStartTs
  };
};

export const setCheckCompetition = (checkCompetition: boolean): ActionType => {
  return {
    type: RacingDataActionTypes.SET_CHECK_COMPETITION,
    payload: checkCompetition
  };
};

export const setEndedGameId = (endedGameId: number | null): ActionType => {
  return {
    type: RacingDataActionTypes.SET_ENDED_GAME_ID,
    payload: endedGameId
  };
};

export const setStoredGameIds = (
  type: EAddRemoveAllReducerTypes,
  storedGameId?: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_STORED_GAME_IDS,
    payload: { type, storedGameId }
  };
};

export const setResultsTimeFilterActiveTab = (
  resultsTimeFilterActiveTab: ERacingResultsTimeFilterTabs
): ActionType => {
  /* --- Please do not use this setter from another place. This local storage setter should only set the value from here. --- */

  LocalStorage.setItem(
    storageKeyName('sportsbook', 'RACING_RESULTS_DATE_FILTER_ACTIVE_TAB'),
    resultsTimeFilterActiveTab
  );

  return {
    type: RacingDataActionTypes.SET_RESULTS_TIME_FILTER_ACTIVE_TAB,
    payload: resultsTimeFilterActiveTab
  };
};

export const setResultsCompetitionData = (
  type: EAddRemoveAllReducerTypes,
  resultsCompetitionData?: TRacingResultsRegion[],
  timeFilter?: ERacingResultsTimeFilterTabs
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RESULTS_COMPETITION_DATA,
    payload: { resultsCompetitionData, timeFilter, type }
  };
};

export const setIsResultsCompetitionDataLoading = (
  isResultsCompetitionDataLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_RESULTS_COMPETITION_DATA_LOADING,
    payload: isResultsCompetitionDataLoading
  };
};

export const setResultsSingleGameData = (
  type: EAddRemoveAllReducerTypes,
  resultsSingleGameData?: TRacingResultsSingleGameGame
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RESULTS_SINGLE_GAME_DATA,
    payload: { type, data: resultsSingleGameData }
  };
};

export const setIsResultsGameDataLoading = (
  isResultsGameDataLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_RESULTS_GAME_DATA_LOADING,
    payload: isResultsGameDataLoading
  };
};

export const setResultsGameJsonExistIds = (
  type: EGameJsonReducerTypes,
  id?: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RESULTS_GAME_JSON_EXIST_IDS,
    payload: { type, id }
  };
};
