import { ActionType } from 'interfaces/generic';
import { SwarmData } from 'interfaces/sportsbook-data-levels';
import { GameDataActionTypes } from 'store/action-types';

export const setGameInfo = (swarmData: SwarmData | null): ActionType => {
  return {
    type: GameDataActionTypes.SET_STATE,
    payload: {
      swarmData
    }
  };
};

export const updateState = (updatedSwarmData: SwarmData): ActionType => {
  return {
    type: GameDataActionTypes.UPDATE_STATE,
    payload: {
      updatedSwarmData
    }
  };
};
