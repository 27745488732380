import { Game } from './sportsbook-data-levels';

export enum EMatchStreamingViewModes {
  normal = 'normal',
  fullscreen = 'fullscreen',
  minimized = 'minimized',
  fixedOnTop = 'fixedOnTop'
}

export enum EMatchStreamingTypes {
  VOID,
  MATCH,
  VIDEO,
  TIMELINE
}

type TMatchStreamingStreamUrl = string | null;
type TStreamActiveChannel = string | null;

export type TMatchStreamingLiveStreamInfo = {
  isGuest: boolean;
  hasLiveStream?: boolean;
  activeChannel?: string;
  allowZeroBalance?: boolean;
} | null;

export type TMatchStreamingData = {
  game: Game;
  sport?: string;
  region?: string;
  competition?: string;
  competitionName?: string;
} | null;

export type TMatchStreamingSettings = {
  muted: boolean;
  fullScreen: boolean;
  applyChannels: boolean;
  pictureInPicture: boolean;
};

export type TMatchStreamingChannelInfo = {
  tvType: number;
  videoId: string;
  asset_id?: string;
} | null;

export type TMatchStreamingUrls = {
  discard: boolean;
  loading: boolean;
  hlsUrl: TMatchStreamingStreamUrl;
  iframeUrl: TMatchStreamingStreamUrl;
  streamUrlFetchError: boolean | null;
} | null;

export type TMatchStreamerContainerInfo = {
  id: string;
  shouldMount?: boolean;
} | null;

export interface IMatchStreamingState {
  containerInfo: TMatchStreamerContainerInfo;

  isLandscape: boolean;
  isTheatreMode: boolean;
  isCanFixOnTop: boolean;
  isTheatreModePrev: boolean;
  isChannelsAvailable: boolean;
  isTheatreModeAvailable: boolean;
  isHolderPositionApplied: boolean;

  isPreviewUnavailable: boolean;

  isVisible: boolean;

  data: TMatchStreamingData;

  activeChannel: TStreamActiveChannel;

  liveStreamInfo: TMatchStreamingLiveStreamInfo;

  streamingChannelInfo: TMatchStreamingChannelInfo;

  streamSettings: TMatchStreamingSettings;

  streamingType: EMatchStreamingTypes;
  availableStreamingTypes: EMatchStreamingTypes[];

  streamUrls: TMatchStreamingUrls;

  viewMode: EMatchStreamingViewModes;
  prevViewMode: EMatchStreamingViewModes;

  streamingTypeDisable: {
    previous: number;
    disable: boolean;
  };

  gamePreviewNotAvailable: boolean;
}
