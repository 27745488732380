import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSignInButtonClassNames,
  getSignUpButtonClassNames
} from 'utils/account/account-login-registre-button-classNames';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { isMobile } from 'utils/is-mobile';
import { EAccountWidgetLayoutType } from 'interfaces/account-settings';
import type { TAccountConfigs } from 'elements/Accounts/index';
import { ChangeThemeSkeleton } from 'newcomponents/Shared/account/ChangeTheme/ChangeThemeSkeleton';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import '../AuthSection/index.less';
import './index.less';

const AVATAR_SKELETON_SIZE = {
  mobile: 36,
  desktop: 40
};

export const AuthSectionSkeleton: FC<TAccountConfigs> = ({
  accountLayout,
  signInBtn,
  signUpBtn,
  favorite,
  accountSignInButtonStyle,
  accountSignUpButtonStyle
}) => {
  const { t } = useTranslation();

  if (accountLayout === EAccountWidgetLayoutType.onPage) {
    return null;
  }

  const signInButtonStyle = getSignInButtonClassNames(accountSignInButtonStyle);

  const signUpButtonStyle = getSignUpButtonClassNames(accountSignUpButtonStyle);

  return (
    <div className="authSectionsButtonsWrapper">
      {signInBtn && (
        <button className={`${signInButtonStyle} authButtonsSkeleton`}>
          <span>{t('account.signIn')}</span>
        </button>
      )}

      {signUpBtn && (
        <button className={`${signUpButtonStyle} authButtonsSkeleton`}>
          <span>{t('account.signUp')}</span>
        </button>
      )}

      {!!Number(favorite) && (
        <Skeleton.Avatar
          size={AVATAR_SKELETON_SIZE[isMobile() ? 'mobile' : 'desktop']}
          active
          className="GlobalIcon__skeleton"
          shape="circle"
        />
      )}

      {SpringConfigs.THEME_SWITCHER && !isMobile() && <ChangeThemeSkeleton />}
    </div>
  );
};
