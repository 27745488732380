import { ReactElement, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal, Switch } from 'antd';
import { subscriptionsHash } from 'utils/constants/app/general';
import { lazy } from 'utils/generic/lazy';
import { notificationSubscriptionCommand } from 'services/account-settings';
import { Button } from 'newcomponents/UI/Button';
import { updateQuery } from '../QueryRoute';
import './index.less';

const { SuccessModal } = lazy(
  () => import(/* webpackChunkName: "success-modal" */ './SuccessModal')
);

type TProps = {
  urlHash?: string;
};
const HASH_SPLIT_INDEX = 3;

export const SubscriptionModal = ({ urlHash }: TProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [userId, setUserId] = useState('');
  const [hash, setHash] = useState('');
  const [smsSwitcher, setSmsSwitcher] = useState(true);
  const [mailSwitcher, setMailSwitcher] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const closeModal = () => {
    history.push(updateQuery({}));
    setVisible(false);
  };

  const afterUnsubscribe = (data: { result: number }) => {
    setIsBtnLoading(false);

    if (data.result === 0) {
      setIsSuccess(true);
    }

    closeModal();
  };

  useEffect(() => {
    const code = location.hash.split('code=')[1];

    if (history.location.hash?.startsWith('#/verify?action=verify')) {
      history.push(updateQuery({ verify: '*', code }));
    }

    if (
      (history.location.search || history.location.hash)?.includes(
        'reset_password'
      )
    ) {
      history.push(updateQuery({ accounts: '*', 'reset-password': '*', code }));
    }

    if (urlHash?.startsWith(subscriptionsHash)) {
      const hashSplitArray = decodeURI(urlHash).split(/=|&hash/);
      setUserId(hashSplitArray[1]);
      setHash(hashSplitArray[HASH_SPLIT_INDEX]);
      setVisible(true);
    }
  }, []);

  return (
    <>
      {isSuccess && (
        <Suspense>
          <SuccessModal />
        </Suspense>
      )}
      <Modal
        visible={visible}
        width={420}
        closable={true}
        style={{ borderRadius: 8, overflow: 'hidden' }}
        centered
        onCancel={() => {
          closeModal();
        }}
        footer={[
          <Button
            key={0}
            size="large"
            type="primary"
            disabled={isBtnLoading}
            loading={isBtnLoading}
            onClick={() => {
              setIsBtnLoading(true);
              notificationSubscriptionCommand(
                hash,
                userId,
                smsSwitcher,
                mailSwitcher,
                afterUnsubscribe,
                () => {
                  setIsBtnLoading(false);
                }
              );
            }}
          >
            {t('account.unsubscribe')}
          </Button>
        ]}
      >
        <div className="notification__management-title">
          {t(`account.notificationManagement`)}
        </div>
        <div className="notification__management-info">
          {t(`account.notificationManagementInfo`)}
        </div>
        <div className="switcher__container">
          <div className="manage__sms-switcher">
            <span className="switcher__text">
              {t(`account.manageNotificationsSms`)}
            </span>
            <Switch
              checked={smsSwitcher}
              onChange={() => {
                setSmsSwitcher(prevValue => !prevValue);
              }}
            />
          </div>
          <div>
            <span className="switcher__text">
              {t(`account.manageNotificationsMail`)}
            </span>
            <Switch
              checked={mailSwitcher}
              onChange={() => {
                setMailSwitcher(prevValue => !prevValue);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
