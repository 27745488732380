import SpringConfigs from 'utils/constants/swarm/spring-configs';
import GLOBALS from 'utils/generic/global-variables';
import { isMobile } from 'utils/is-mobile';

export const isShowFloating = () => {
  const deviceType = isMobile() ? 'mobile' : 'desktop';
  const popupBody = document.querySelector('.popup-body');
  const shouldMountFloatingElement =
    ((!popupBody &&
      deviceType === 'desktop' &&
      SpringConfigs.IS_BETSLIP_FLOATING_ENABLED) ||
      (deviceType === 'mobile' &&
        SpringConfigs.IS_BETSLIP_FLOATING_ENABLED_MOBILE)) &&
    !GLOBALS.floatingBetslipMounted;

  if (shouldMountFloatingElement && !popupBody) {
    GLOBALS.floatingBetslipMounted = true;
  }

  return shouldMountFloatingElement;
};
