import { singlEachwayCalc } from 'utils/betslip/calculate-single-each-way';
import { add, divide, multiply } from 'utils/generic/calculator';
import { EBetslipBetType } from 'interfaces/betslip';
import store from 'store';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';

export const calPossibleWinByBetType = (
  stake: number,
  odd: number,
  isEachWay: boolean,
  eventId: string | number
) => {
  const betslipStore: IBetslipRootStateInitialData = store.getState()?.betSlip;
  const betType = betslipStore.type;
  const allBets = betslipStore.allLsBets;
  const combinationsOfSelectedSystem = betslipStore.combinationOfSelectedSystem;

  const isSystemType = betType === EBetslipBetType.SYSTEM;

  if (!isSystemType) {
    let eachWayPosWin = 0;

    const _manualStake = isEachWay ? divide(stake, 2) : stake;

    if (isEachWay) {
      if (betType === EBetslipBetType.SINGLE) {
        const event = allBets.find(b => b.eventId === eventId);

        const eachWayK = event?.market?.extra_info?.EachWayK || 1;
        eachWayPosWin = multiply(_manualStake, singlEachwayCalc(odd, eachWayK));
      } else {
        const multipleEachWayK = allBets.reduce((total, item) => {
          const EachWayK = item?.market?.extra_info?.EachWayK;

          if (EachWayK && item.coeficient) {
            total *= singlEachwayCalc(+item.coeficient, EachWayK);
          }

          return total;
        }, 1);

        eachWayPosWin = multiply(_manualStake, multipleEachWayK);
      }
    }

    return add(multiply(_manualStake, odd), eachWayPosWin);
  }

  // in system case the stake is already multiplied based on combinations
  const possibleWinTemp = combinationsOfSelectedSystem.reduce(
    (posWinTemp, comb) => {
      const [combOdd, combOddEw] = comb.reduce(
        ([combOdd, combOddEw], item) => {
          const EachWayK = item?.market?.extra_info?.EachWayK;

          const odd = Number(item.coeficient) ?? 0;
          const oddEw =
            EachWayK && isEachWay ? singlEachwayCalc(odd, EachWayK) : 0;

          return [multiply(odd, combOdd), multiply(oddEw, combOddEw)];
        },
        [1, 1]
      );

      return add(multiply(stake, divide(add(combOdd, combOddEw))), posWinTemp);
    },
    0
  );

  return divide(possibleWinTemp, combinationsOfSelectedSystem.length);
};
