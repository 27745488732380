export enum ESportsbookPageTypes {
  live,
  prematch,
  esport
}

export enum ESportsbookLayoutTypes {
  CLASSIC,
  AMERICAN,
  EUROPEAN,
  ADVANCED
}

export enum EMultiplesView {
  EXPANDED,
  COMPACT
}

export type TSportsbookConfigsTypes = 'live' | 'prematch' | 'all';

export type TSportsbookConfigs = {
  pageType: ESportsbookPageTypes;
  fit: 'cover' | 'contain';
  type: string;
  category: 'sport' | 'esport';
  spbLayoutType: ESportsbookLayoutTypes;
  sportType?: string;
  fixedScroll?: boolean;
  favoriteTeam?: boolean;
  showTodayEvents?: boolean;
  showTopLeagues?: boolean;
  jackpotForSportsbook?: boolean;
  jackpotFontSize?: number;
  changeFavoriteTeam?: boolean;
};

export enum ETopLeagueLayoutType {
  expanded = 0,
  compact = 1
}

export enum ETopMatchesLayoutType {
  expanded = 0,
  compact = 1
}
export enum EOlympicLayoutType {
  expanded = 0,
  compact = 1
}

export enum EBetCardView {
  COMPACT,
  EXPANDED
}
