export const insertUrlParam = (
  key: string | Record<string, string>,
  value = ''
): void => {
  const searchParams = new URLSearchParams(window.location.search);

  if (typeof key === 'string') {
    searchParams.set(key, value);
  } else {
    Object.keys(key).forEach(_key => searchParams.set(_key, key[_key]));
  }

  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const getUrlParams = (
  keys: string[] = []
): Record<string, string[] | undefined> => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsObj: Record<string, string[] | undefined> = {};

  keys.forEach(key => {
    if (searchParams.get(key)) {
      paramsObj[key] = searchParams.get(key)?.split(',');
    }
  });

  return paramsObj;
};

export const deleteUrlParam = (key: string): void => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString().replace(/\*/g, '%2A')}`;

  window.history.pushState({ path: newurl }, '', newurl);
};
