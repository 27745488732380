window.getPathname = () => decodeURI(window.location.pathname);

String.prototype.replaceAll =
  String.prototype.replaceAll ||
  function (
    this: string,
    searchValue: string | RegExp,
    replaceValue: string
  ): string {
    return this.split(searchValue).join(replaceValue);
  };
