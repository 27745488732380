export const openSlider = (tab: 'login' | 'registrationForm'): void => {
  parent.postMessage({ action: 'openSlider', tab: tab }, '*');
};

export const loginState = (
  state: 'loginRestored' | 'loggedOut' | 'loggedIn'
): void => {
  parent.postMessage({ info: state }, '*');
};

export const openParentPage = (page: string): void => {
  parent.postMessage({ action: 'openPage', page: page }, '*');
};

export const userBalanceUpdated = (balance: number): void => {
  parent.postMessage({ action: 'userBalanceUpdated', balance: balance }, '*');
};

export const fixedHeightChanged = (height: number): void => {
  parent.postMessage({ action: 'fixedHeightChanged', height: height }, '*');
};

export const betslipBetsCount = (count: number): void => {
  parent.postMessage({ betslipDataBetsLength: count }, '*');
};
