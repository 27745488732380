import LS from 'utils/bom-dom-manipulation/local-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';

export const setSelectedCompetitionsInLS = (data: string): void => {
  LS.setItem(storageKeyName('sportsbook', 'EUROPEAN_SELECTED_LEAGUES'), data);
};

export const getSelectedCompetitionsFromLS = (): Record<
  string,
  Array<number>
> => {
  return (
    JSON.parse(
      LS.getItem(storageKeyName('sportsbook', 'EUROPEAN_SELECTED_LEAGUES'))
    ) || {}
  );
};
