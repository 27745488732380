import { bankersRounding } from 'utils/betslip/bankersRounding';
import { multiply } from 'utils/generic/calculator';
import { EBetslipBetType } from 'interfaces/betslip';
import store from 'store';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';

const STAKE_X_COUNT_FOR_EACH_WAY = 2;

export const calcModifiedStake = (stake: number, isEachWay?: boolean) => {
  const betslipStore: IBetslipRootStateInitialData = store.getState()?.betSlip;
  const updatedStakeByEachWay = !isEachWay
    ? stake
    : multiply(stake, STAKE_X_COUNT_FOR_EACH_WAY);

  if (betslipStore.type === EBetslipBetType.SYSTEM) {
    return multiply(
      updatedStakeByEachWay,
      betslipStore.combinationOfSelectedSystem.length
    );
  }

  return bankersRounding(updatedStakeByEachWay);
};
