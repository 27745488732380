import { ReactElement } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import SessionStorage from 'utils/bom-dom-manipulation/session-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import type { Location, LocationState } from 'history';

type Props = {
  component?: any;
  render?: Function;
  includes?: Array<string | Array<string>>;
  excludes?: Array<string>;
  auth?: boolean;
  shouldMount?: () => boolean;
  onCatch?: () => void;
};

export const QueryRoute = ({
  shouldMount = () => true,
  onCatch,
  ...props
}: Props): ReactElement => {
  const history = useHistory();

  const auth_data_x = JSON.parse(
    LocalStorage.getItem(storageKeyName('account', 'AUTH_DATA'))
  );

  return (
    <Route
      render={routeProps => {
        const queries = qs.parse(routeProps.location.search, {
          ignoreQueryPrefix: true
        });

        let inc;
        const includes = props?.includes || [];

        if (Array.isArray(includes[0])) {
          inc = includes.some(item =>
            (item as unknown as string[]).every(subItem => subItem in queries)
          );
        } else {
          inc = includes.every(item => (item as unknown as string) in queries);
        }

        const exc = !(props?.excludes || []).some(item => item in queries);

        if (inc && exc) {
          if (!auth_data_x && props.auth) {
            saveCurrentLocation();
            const url = `${history.location.pathname}?accounts=%2A&login=%2A`;

            return <Redirect to={url} />;
          }

          if (shouldMount()) {
            if (props.render) {
              return props.render(routeProps);
            }

            if (props.component) {
              return <props.component {...routeProps} />;
            }
          } else {
            onCatch?.();
          }
        }

        return null;
      }}
    />
  );
};

export const updateQuery = (
  params: ParsedQs,
  appendPathName = '',
  state: LocationState = undefined,
  scrollToTop = false
): Location => {
  const nextHistory = {
    search: qs.stringify(params, {
      addQueryPrefix: true
    }),
    state
  } as Location;

  if (appendPathName.length > 0 && process.env.REACT_APP_ENV !== 'widgets') {
    nextHistory.pathname = appendPathName;
  }

  if (scrollToTop) {
    window.scrollTo(0, 0);
  }

  return nextHistory;
};

export const addQuery = (
  params: ParsedQs,
  appendPathName = '',
  state: LocationState = undefined
): Location => {
  const currentParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  const nextHistory = {
    search: qs.stringify(
      { ...currentParams, ...params },
      { addQueryPrefix: true }
    ),
    state
  } as Location;

  if (appendPathName.length > 0 && process.env.REACT_APP_ENV !== 'widgets') {
    nextHistory.pathname = appendPathName;
  }

  return nextHistory;
};

export const addQueryInString = (
  locationString: string,
  params: ParsedQs
): string => {
  const [path, search] = locationString.split('?');

  let compiledSearch = '';

  if (search) {
    const currentParams = qs.parse(`?${search}`, {
      ignoreQueryPrefix: true
    });

    compiledSearch = qs.stringify(
      { ...currentParams, ...params },
      { addQueryPrefix: true }
    );
  }

  return path + compiledSearch;
};

export const saveCurrentLocation = (
  key: 'casinoSingleGame' | 'login' | 'mobileSportsbookGames' = 'login',
  externalRoute?: string,
  skipStoring = false
): string | undefined => {
  const { game, category, type } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  if (game && category && type && key === 'casinoSingleGame') {
    return;
  }

  const route =
    externalRoute || `${window.location.pathname}${window.location.search}`;

  if (skipStoring) {
    return route;
  }

  const prevRoutes =
    JSON.parse(SessionStorage.getItem(storageKeyName('app', 'PREVIOUS_URL'))) ||
    {};

  if (key === 'casinoSingleGame' && !prevRoutes.casinoSingleGame) {
    prevRoutes['casinoStartingRoute'] = route;
  }

  prevRoutes[key] = route;

  SessionStorage.setItem(
    storageKeyName('app', 'PREVIOUS_URL'),
    JSON.stringify(prevRoutes)
  );

  return route;
};

export function getSavedLocation(
  key:
    | 'casinoSingleGame'
    | 'login'
    | 'casinoStartingRoute'
    | 'mobileSportsbookGames' = 'login',
  returnCurrentPathname = true,
  removeSavedLocation = true,
  queryParam = false
): string {
  const routes =
    JSON.parse(SessionStorage.getItem(storageKeyName('app', 'PREVIOUS_URL'))) ||
    {};

  const lastRoute = routes[key];

  if (removeSavedLocation) {
    delete routes[key];

    SessionStorage.setItem(
      storageKeyName('app', 'PREVIOUS_URL'),
      JSON.stringify(routes)
    );
  }

  return (
    lastRoute ||
    (returnCurrentPathname
      ? queryParam
        ? `${window.location.pathname}${window.location.search}`
        : window.location.pathname
      : '')
  );
}
