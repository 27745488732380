import { ActionType } from 'interfaces/generic';
import { PartnerConfig } from 'interfaces/partner-config';
import { SocketActionTypes } from 'store/action-types/socket';

type InitialSocketData = {
  isConnected: boolean;
  partnerConfigs?: PartnerConfig;
  jackpots?: {
    casino: number[];
    bet: number[];
  };
};

const initialSocketData: InitialSocketData = {
  isConnected: false
};

export const socket = (
  state = initialSocketData,
  action: ActionType
): InitialSocketData => {
  switch (action.type) {
    case SocketActionTypes.SET_IS_CONNECTED:
      return {
        ...state,
        isConnected: action.payload
      };
    case SocketActionTypes.SET_PARTNER_CONFIGS:
      return {
        ...state,
        partnerConfigs: action.payload
      };
    case SocketActionTypes.SET_JACKPOTS:
      return {
        ...state,
        jackpots: action.payload
      };

    default: {
      return state;
    }
  }
};
