import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { ODD_TYPES } from 'utils/constants/betslip/bet-types';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { storageKeyName } from 'utils/generic/storage-key-name';

export const getOddsFormat = (): number => {
  const format: string = JSON.parse(
    LocalStorage.getItem(storageKeyName('betslip', 'ODDS_FORMAT'))
  );

  const defaultOddType = SpringConfigs.DEFAULT_ODD_TYPE?.toUpperCase();

  return Number(
    (format && ODD_TYPES[format.toUpperCase()]) ?? ODD_TYPES[defaultOddType]
  );
};

export const setOddsFormat = (format: string): void => {
  LocalStorage.setItem(
    storageKeyName('betslip', 'ODDS_FORMAT'),
    JSON.stringify(format) || 'null'
  );
};
