export const appendCustomCode = (
  customCode: string,
  data?: Record<string, any>
): void => {
  if (data) {
    for (const key in data) {
      if (customCode.includes(`@@${key}`)) {
        customCode = customCode.replace(new RegExp(`@@${key}`, 'g'), data[key]);
      }
    }
  }

  const p = new DOMParser();

  try {
    const d = p.parseFromString(customCode, 'text/html');
    const script = d.querySelectorAll('script');

    for (let i = 0; i < script.length; i++) {
      const appendScript = document.createElement('script');

      if (script[i].src) {
        appendScript.src = script[i].src;
      }

      appendScript.async = script[i].async;
      appendScript.defer = script[i].defer;
      appendScript.innerText = `try{${script[i].innerText}}catch (e) {console.info(e)}`;
      document.body.append(appendScript);
    }
  } catch (e) {
    /* eslint-enable */
    console.info(e);
  }
};
