import i18n from 'i18next';
import { NewCasinoProvider } from 'interfaces/new-casino';

export const CUSTOM_PROVIDERS: Record<'all', NewCasinoProvider> = {
  all: {
    name: 'all',
    title: i18n.t('casino.allProviders'),
    order: '0',
    badge: null,
    blocked_countries: []
  }
};
