import { Dispatch } from 'redux';
import LS from 'utils/bom-dom-manipulation/local-storage';
import { BonusTypes } from 'utils/constants/account/bonus-types';
import { storageKeyName } from 'utils/generic/storage-key-name';
import {
  GeoDataType,
  LolikStakeBalance,
  OptInInfo,
  PaymentPromoData,
  PaymentResponse,
  TSalaryOccupation
} from 'interfaces/account-settings';
import {
  CashbackNotificationsTypes,
  CasinoBonusDetails,
  DepositLimits,
  ProfileData,
  SuperBet
} from 'interfaces/authentication';
import { ActionType } from 'interfaces/generic';
import { getBonusData, getFreeSpinBonuses } from 'services/account-settings';
import { LoyaltyInfo } from 'elements/Accounts/views/LoyaltyPoints/loyaltyTypes';
import {
  TLoyaltyBenefitCardInfo,
  TPartnerLoyaltyBenefit,
  TPartnerLoyaltyGroup,
  TUserLoyaltyBenefit
} from 'elements/Accounts/views/LoyaltyPointsNew/types';
import { UserDataActionTypes } from 'store/action-types/user-data';

export type UserData = ProfileData;

export const setUserData = (data: UserData): ActionType => {
  return {
    type: UserDataActionTypes.SET_USER_DATA,
    payload: data
  };
};

export const updateUserData = (data: UserData): ActionType => {
  return {
    type: UserDataActionTypes.UPDATE_USER_DATA,
    payload: data
  };
};

export const removeUserData = (): ActionType => {
  return {
    type: UserDataActionTypes.REMOVE_USER_DATA
  };
};

export const setUserLoggedIn = (isLoggedIn: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_USER_LOGGED_IN,
    payload: isLoggedIn
  };
};

export const setLoginLimit = (loginLimit: number): ActionType => {
  return {
    type: UserDataActionTypes.SET_USER_LOGIN_LIMIT,
    payload: loginLimit
  };
};

export const setUnreadMessagesCount = (
  unreadMessagesCount: number
): ActionType => {
  return {
    type: UserDataActionTypes.SET_INBOX_MESSAGES_COUNT,
    payload: unreadMessagesCount
  };
};

export const deleteMessageAction = (id: number): ActionType => {
  return {
    type: UserDataActionTypes.DELETE_MESSAGE,
    payload: id
  };
};

export const setUserBonuses =
  (
    isFreeBonuses: boolean,
    callback?: Function,
    freeSpin?: boolean,
    acceptianceType?: number
  ) =>
  (dispatch: Dispatch): void => {
    if (freeSpin) {
      getFreeSpinBonuses(
        { max_rows: 30, acceptance_type: acceptianceType || 0 },
        // @Todo FIX_MY_TYPE @todo-account
        (data: { details: Record<string, any> }) => {
          dispatch({
            type: UserDataActionTypes.SET_USER_BONUSES,
            payload: {
              ['freeSpinBonuses']: Array.isArray(data.details)
                ? data.details
                : []
            }
          });
          callback?.();
        }
      );
    } else {
      getBonusData(isFreeBonuses, data => {
        const bonusType = isFreeBonuses
          ? BonusTypes.FREE_BONUSES
          : BonusTypes.CASINO_BONUSES;

        dispatch({
          type: UserDataActionTypes.SET_USER_BONUSES,
          payload: {
            [bonusType]: Array.isArray(data.bonuses) ? data.bonuses : []
          }
        });
        callback?.();
      });
    }
  };

export const setPendingSuperBets = (
  superBets: Record<number, SuperBet>
): ActionType => {
  return {
    type: UserDataActionTypes.SET_PENDING_SUPER_BETS,
    payload: superBets
  };
};

export const removePendingSuperBet = (superBetId: number): ActionType => {
  return {
    type: UserDataActionTypes.REMOVE_PENDING_SUPER_BET,
    payload: superBetId
  };
};

export const setVerificationModal = (state: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_VERIFICATION_MODAL,
    payload: state
  };
};

export const setFastRegData = (
  state: Record<string, any> | null
): ActionType => {
  return {
    type: UserDataActionTypes.SET_FAST_REG_DATA,
    payload: state
  };
};

export const setWithdrawalCapability = (state: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_WITHDRAWAL_CAPABILITY,
    payload: state
  };
};

export const setCasinoBonusDetails = (data: CasinoBonusDetails): ActionType => {
  return {
    type: UserDataActionTypes.SET_CASINO_BONUS_DETAILS,
    payload: data
  };
};

export const setShowAutoidentVerificationModal = (
  state: boolean | undefined
): ActionType => {
  return {
    type: UserDataActionTypes.SET_SHOW_AUTOIDENT_VERIFICATION_MODAL,
    payload: state
  };
};

export const setShowCancelRegisterPopup = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_SHOW_CANCEL_REGISTER_POPUP,
    payload
  };
};

export const setUserOptIns = (payload: OptInInfo[] | null): ActionType => {
  return {
    type: UserDataActionTypes.SET_USER_OPT_INS,
    payload
  };
};

export const setShouldGetUserOptIns = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SHOULD_GET_USER_OPTINS,
    payload
  };
};

export const setSendAsPromoCode = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SEND_AS_PROMO_CODE,
    payload
  };
};

export const setHasFormError = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.HAS_FORM_ERROR,
    payload
  };
};

export const setGpsTrackingParams = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.GPS_TRACKING,
    payload
  };
};

export const updateGpsTrackingParams = (payload: GeoDataType): ActionType => {
  return {
    type: UserDataActionTypes.UPDATE_GPS_TRACKING,
    payload
  };
};

export const resetGpsTrackingParams = (): ActionType => {
  return {
    type: UserDataActionTypes.RESET_GPS_TRACKING
  };
};

export const setUserDepositLimits = (data: DepositLimits): ActionType => {
  return {
    type: UserDataActionTypes.USER_DEPOSIT_LIMITS,
    payload: data
  };
};

export const setRememberMe = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_REMEMBER_ME,
    payload
  };
};

export const setPaymentsData = (payload: PaymentResponse): ActionType => {
  return {
    type: UserDataActionTypes.SET_PAYMENTS,
    payload
  };
};

export const setClientInfo = (
  payload?: TSalaryOccupation | null
): ActionType => {
  return {
    type: UserDataActionTypes.SET_CLIENT_INFO,
    payload
  };
};

export const setSumSubModalOpen = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SUM_SUB_MODAL_OPEN,
    payload
  };
};

export const setPaymentDepositPromo = (
  payload: PaymentPromoData[] | null
): ActionType => {
  return {
    type: UserDataActionTypes.SET_PAYMENT_DEPOSIT_PROMO,
    payload
  };
};

export const setStakeBalance = (payload: LolikStakeBalance): ActionType => {
  return {
    type: UserDataActionTypes.STAKE_BALANCE,
    payload
  };
};

export const setWithdrawableBalance = (payload: number): ActionType => {
  return {
    type: UserDataActionTypes.WITHDRAWABLE_BALANCE,
    payload
  };
};

export const setRegIsFinished = (regIsFinished: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_REG_IS_FINISHED,
    payload: regIsFinished
  };
};

export const setDisplayCryptoInFiat = (payload: boolean): ActionType => {
  LS.setItem(
    storageKeyName('account', 'DISPLAY_CRYPTO_IN_FIAT'),
    String(payload)
  );

  return {
    type: UserDataActionTypes.SET_DISPLAY_CRYPTO_IN_FIAT,
    payload
  };
};

export const setSelectedCurrencyForDisplay = (payload: string): ActionType => {
  LS.setItem(
    storageKeyName('account', 'SELECTED_CURRENCY_FOR_DISPLAY'),
    payload
  );

  return {
    type: UserDataActionTypes.SET_SELECTED_CURRENCY_FOR_DISPLAY,
    payload
  };
};

export const resetSelectedCurrencyForDisplay = (): ActionType => {
  LS.removeItem(storageKeyName('account', 'SELECTED_CURRENCY_FOR_DISPLAY'));

  return {
    type: UserDataActionTypes.SET_SELECTED_CURRENCY_FOR_DISPLAY
  };
};

export const setCashbackNotifications = (
  payload: Array<CashbackNotificationsTypes>
) => {
  return {
    type: UserDataActionTypes.CASHBACK_NOTIFICATIONS,
    payload
  };
};

export const setPersonalisedDataLoaded = (payload: boolean): ActionType => {
  return {
    type: UserDataActionTypes.SET_PERSONALISED_DATA_LOADED,
    payload
  };
};

export const setPartnerLoyaltyGroups = (
  payload: TPartnerLoyaltyGroup[]
): ActionType => {
  return {
    type: UserDataActionTypes.SET_PARTNER_LOYALTY_GROUPS,
    payload
  };
};

export const setPartnerLoyaltyBenefits = (
  payload: TPartnerLoyaltyBenefit[]
): ActionType => {
  return {
    type: UserDataActionTypes.SET_PARTNER_LOYALTY_BENEFITS,
    payload
  };
};

export const setUserLoyaltyInfo = (payload: LoyaltyInfo) => {
  return {
    type: UserDataActionTypes.SET_USER_LOYALTY_INFO,
    payload
  };
};

export const setUserLoyaltyBenefits = (payload: TUserLoyaltyBenefit[]) => {
  return {
    type: UserDataActionTypes.SET_USER_LOYALTY_BENEFITS,
    payload
  };
};

export const setActiveClaimableBenefit = (
  payload: TLoyaltyBenefitCardInfo | null
) => {
  return {
    type: UserDataActionTypes.SET_ACTIVE_CLAIMABLE_BENEFIT,
    payload
  };
};
