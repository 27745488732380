import { EBetslipPage } from 'interfaces/bet-data';
import { EBetslipBetType, TabObject } from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';

export const setBetslipOpen = (payload?: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_BETSLIP_OPEN,
    payload
  };
};

export const setMustOpen = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_MUST_OPEN,
    payload
  };
};

export const setMustClose = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_MUST_CLOSE,
    payload
  };
};

export const setType = (payload: EBetslipBetType): ActionType => {
  return {
    type: BetslipActionTypes.SET_TYPE,
    payload
  };
};

export const setBetUkType = (payload: object): ActionType => {
  return {
    type: BetslipActionTypes.UPDATE_BETSLIP_UK_TYPE,
    payload
  };
};

export const setSelectedBetType = (payload: string | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_SELECTED_BET_TYPE,
    payload
  };
};

export const updateBetSlipType = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.UPDATE_BETSLIP_TYPE,
    payload
  };
};

export const setBetslipTabs = (payload: TabObject[]): ActionType => {
  return {
    type: BetslipActionTypes.SET_TABS,
    payload
  };
};

export const setAdvancedSelectedTab = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_ADVANCED_SELECTED_TAB,
    payload
  };
};

export const setActiveTabPage = (payload: EBetslipPage): ActionType => {
  return {
    type: BetslipActionTypes.SET_ACTIVE_TAB_PAGE,
    payload
  };
};

export const setQuickSingleBetslipOpen = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_SINGLE_QUICK_BETSLIP_OPEN,
    payload
  };
};

export const setFreezeTabChange = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.FREEZE_TAB_CHANGE,
    payload
  };
};

export const ladderIsLoaded = (): ActionType => {
  return {
    type: BetslipActionTypes.LADDER_LOADED
  };
};
