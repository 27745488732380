import i18n from 'i18next';
import {
  backendErrorCodes,
  skipedErrorCodes
} from 'utils/constants/swarm/backend-error-codes';
import RidConstants from 'utils/constants/swarm/rid-prefix-constants';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import { showToastError } from 'utils/generic/show-toast-error';
import { dataspotFunnelAction, EDataspotAction } from '../analytics/dataspot';
/* eslint-disable no-magic-numbers */
export type TSwarmDataError = {
  HasError: boolean;
  StatusCode: string;
  Data: {
    Key: string;
    Message: string;
    ErrorData: Record<string, any>;
  };
};

type TSwarmData =
  | {
      status?: number;
      result?: number | string;
      details?: string;
    }
  | TSwarmDataError
  | string;

interface SwarmMessage {
  code: number;
  data: TSwarmData;
  msg?: string;
  rid: string;
  RequestId?: string;
}

const UNMATCHED_ELEMENT_INDEX = -1;

const checkIsDataInstantsOfError = (data: TSwarmData) => {
  return !!(
    data &&
    typeof data === 'object' &&
    'HasError' in data &&
    data.HasError
  );
};

const skipErrorMsg = (
  commandObj: { command: string },
  code: number,
  result: string
): boolean => {
  if (commandObj.command === CommandNames.RESTORE_LOGIN) {
    return true;
  }

  if (
    commandObj.command === CommandNames.GET_CLIENT_NONCE &&
    result === '-1001' &&
    code === 0
  ) {
    return true;
  }

  if (result && code === 0 && skipedErrorCodes[result]) {
    skipedErrorCodes[result]?.notify &&
      showToastError(i18n.t(`${skipedErrorCodes[result].msg}`));

    return true;
  }

  return false;
};

const verifyErrorExists = (msg: SwarmMessage): boolean => {
  const { data, code } = msg;
  const message = msg.msg;
  let errorExists = code !== 0 || checkIsDataInstantsOfError(data);

  if (data) {
    // if has status code return for notification
    if (errorExists) {
      if (Object.prototype.hasOwnProperty.call(data, 'status')) {
        errorExists = true;
      }
    }

    if (typeof data === 'string') {
      if (data.indexOf('error') !== -1 && message !== undefined) {
        errorExists = true;
      }
    } else if (typeof data === 'object') {
      // there are different errors' structure from swarm
      if (
        ('result' in data &&
          Object.prototype.hasOwnProperty.call(data, 'result') &&
          data.result !== undefined &&
          +data.result !== 0 &&
          typeof data.result === 'string' &&
          !isNaN(parseInt(data.result))) ||
        ('result' in data && data.result && data.result in backendErrorCodes)
      ) {
        errorExists = true;

        //capture error promo case
        if ([26, 2469, 2471, '-2469', '-2471'].includes(data.result)) {
          dataspotFunnelAction(EDataspotAction.PromocodeFailed);
        }
      }
    }
  }

  switch (code) {
    case 20:
    case 21:
      errorExists = true;
      break;
    case 25:
      errorExists = true;
      break;
    case 27:
      errorExists = true;
      break;

    default: {
      break;
    }
  }

  return errorExists;
};

const verifyRequestSession = (msg: SwarmMessage): boolean => {
  if (
    (msg.rid && msg.rid.indexOf(RidConstants.REQUEST_SESSION) !== -1) ||
    (msg.RequestId &&
      msg.RequestId.indexOf(RidConstants.REQUEST_SESSION) !== -1)
  ) {
    return true;
  }

  return false;
};

const verifyCommand = (msg: SwarmMessage): boolean => {
  if (
    (msg.rid && msg.rid.indexOf(RidConstants.COMMAND) !== -1) ||
    (msg.RequestId && msg.RequestId.indexOf(RidConstants.COMMAND) !== -1)
  ) {
    return true;
  }

  return false;
};

const verifyGeoLocation = (msg: SwarmMessage): boolean => {
  return !!(
    msg &&
    msg.rid &&
    +msg.rid == 0 &&
    msg.data &&
    // @ts-ignore
    msg.data[9999999999]
  );
};

const verifySubscribe = (msg: SwarmMessage): boolean => {
  if (
    msg.rid?.indexOf(RidConstants.SUBSCRIBE) !== UNMATCHED_ELEMENT_INDEX ||
    msg.RequestId?.indexOf(RidConstants.SUBSCRIBE) !== UNMATCHED_ELEMENT_INDEX
  ) {
    return true;
  }

  return false;
};

const verifyUnsubscribe = (msg: SwarmMessage): boolean => {
  return msg.rid.indexOf(RidConstants.UNSUBSCRIBE) !== UNMATCHED_ELEMENT_INDEX;
};

const verifySubscriptionUpdate = (msg: SwarmMessage): boolean => {
  return msg.rid === '0';
};

export default {
  verifyErrorExists,
  verifyRequestSession,
  verifyCommand,
  verifySubscribe,
  verifyUnsubscribe,
  verifySubscriptionUpdate,
  verifyGeoLocation,
  skipErrorMsg
};
