import { bankersRounding } from 'utils/betslip/bankersRounding';
import {
  getSelectedEventProfitBoostItemsUtil,
  getSelectedProfitBoostItemUtil
} from 'utils/betslip/profitBoostHelpers';
import { PERCENTAGE_DIVISOR } from 'utils/constants/app/magic-numbers-app';
import { add, divide, multiply } from 'utils/generic/calculator';
import type { TCalculationParams } from 'newelements/_Betslip/utils/calculator/index';
import store from 'store';
import type { IBetslipRootStateInitialData } from 'store/reducers/betslip';
import type {
  IFinalCalculatedFields,
  IProfitBoostCalculationResult
} from 'store/reducers/betslip/calculations';

type TCalculateProfitBoost = (params: {
  eventId: TCalculationParams['eventId'];
  fields: Omit<IFinalCalculatedFields, 'finalStake'>;
}) => IProfitBoostCalculationResult;

export const calculateProfitBoost: TCalculateProfitBoost = ({
  eventId,
  fields
}) => {
  const profitBoost: IProfitBoostCalculationResult['profitBoost'] = {
    boostAmount: 0,
    possibleWinWithoutProfitBoostAmount: fields.possibleWin
  };

  const betslipStore: IBetslipRootStateInitialData = store.getState().betSlip;

  const profitBoostStore = betslipStore._profitBoost;

  const selectedEventId = profitBoostStore.profitBoostSelectedEventId;

  if (selectedEventId && eventId === selectedEventId) {
    const selectedProfitBoostItems =
      getSelectedEventProfitBoostItemsUtil(profitBoostStore);

    const selectedItem = getSelectedProfitBoostItemUtil(
      profitBoostStore,
      selectedProfitBoostItems
    );

    if (selectedItem) {
      profitBoost.boostAmount = bankersRounding(
        divide(
          bankersRounding(
            multiply(
              fields.possibleWinWithoutStake,
              selectedItem.amount_percent
            )
          ),
          PERCENTAGE_DIVISOR
        )
      );
    }
  }

  return {
    profitBoost,
    result: {
      ...fields,
      possibleWin: bankersRounding(
        add(profitBoost.boostAmount, fields.possibleWin)
      ),
      possibleWinWithoutStake: bankersRounding(
        add(profitBoost.boostAmount, fields.possibleWinWithoutStake)
      )
    }
  };
};
