import { message } from 'antd';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { configureMessage } from 'configure/configure-message';

export const showToastSuccess = (
  content: string,
  context: HTMLElement | undefined | null = null,
  key?: string
): void => {
  configureMessage(context, SpringConfigs.ERROR_MSG_DURATION, key || content);
  message.success({
    content,
    key: key || content
  });
};
