import { BetSlipEventObj } from 'interfaces/bet-data';
import store from 'store';
import {
  removeGeneralCalculation,
  removeSingleEventCalculation
} from 'store/actions';

export const filterCalculatedItems = (allBets: BetSlipEventObj[]) => {
  const calculations = store.getState()?.betSlip.calculations;

  const singleEventIds = Object.keys(calculations.singleEventsCalculations);
  // @Todo need to handle general calculation result removing case to
  singleEventIds.forEach(eventId => {
    if (!allBets.some(bet => bet.eventId.toString() === eventId.toString())) {
      store.dispatch(removeSingleEventCalculation(eventId.toString()));
    }
  });

  if (allBets.length === 0) {
    store.dispatch(removeGeneralCalculation());
  }
};
