import { ActionType } from 'interfaces/generic';
import {
  EMatchStreamingTypes,
  EMatchStreamingViewModes,
  TMatchStreamerContainerInfo,
  TMatchStreamingChannelInfo,
  TMatchStreamingData,
  TMatchStreamingLiveStreamInfo,
  TMatchStreamingSettings,
  TMatchStreamingUrls
} from 'interfaces/match-streaming';
import { MatchStreamingActionTypes } from 'store/action-types/match-streaming';

export const setMatchStreamingGameData = (
  payload: TMatchStreamingData
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_GAME_DATA
  };
};

export const setMatchStreamingViewMode = (
  payload: EMatchStreamingViewModes
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_MODE
  };
};

export const setIsCanFixMatchStreamingOnTop = (
  payload: boolean
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_IS_CAN_FIX_MS_ON_TOP
  };
};

export const resetMatchStreamingState = (
  ignoreMinimizedState?: boolean,
  gameId?: number | null
): ActionType => {
  return {
    payload: { ignoreMinimizedState, gameId },
    type: MatchStreamingActionTypes.RESET_MATCH_STREAMING_STATE
  };
};

export const setLiveStreamInfo = (
  payload: TMatchStreamingLiveStreamInfo
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_LIVE_STREAM_INFO
  };
};

export const setStreamingChannelsInfo = (
  payload: TMatchStreamingChannelInfo
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_CHANNELS_INFO
  };
};

export const setMatchStreamingStreamUrl = (
  payload: Partial<NonNullable<TMatchStreamingUrls>>
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_STREAM_URL
  };
};

export const setGamePreviewNotAvailable = (payload: boolean): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_GAME_PREVIEW_NOT_AVAILABLE
  };
};

export const setIsStreamingLandscape = (payload: boolean): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_IS_STREAMER_LANDSCAPE
  };
};

export const setMatchStreamingStreamType = (
  payload: EMatchStreamingTypes
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_STREAM_TYPE
  };
};

export const setDisableMatchStreamingStreamType = (payload: {
  previous: number;
  disable: boolean;
}): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_DISABLE_MATCH_STREAMING_STREAM_TYPE
  };
};

export const toggleMatchStreamingSetting = (
  payload: keyof TMatchStreamingSettings
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.TOGGLE_MATCH_STREAMING_SETTINGS
  };
};

export const setIsMatchStreamingChannelsAvailable = (
  payload: boolean
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_CHANNELS_AVAILABLE
  };
};

export const setIsMatchStreamingTheatreModeAvailable = (
  payload: boolean
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_THEATRE_MODE_AVAILABLE
  };
};

export const setIsMatchStreamingVisible = (payload: boolean): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_HIDDEN
  };
};

export const setMatchStreamingTheatreMode = (payload: boolean): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_THEATRE_MODE
  };
};

export const setMatchStreamingContainerInfo = (
  payload: TMatchStreamerContainerInfo
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_CONTAINER_INFO
  };
};

export const setMatchStreamingMountingInfo = (payload: boolean): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_MATCH_STREAMING_MOUNTING_INFO
  };
};

export const setIsMatchStreamingPreviewUnavailable = (
  payload: boolean
): ActionType => {
  return {
    payload,
    type: MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_PREVIEW_UNAVAILABLE
  };
};
