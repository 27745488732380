const setItem = (name: string, data: string): boolean => {
  if (window.localStorageAvailable) {
    window.localStorage.setItem(name, data);

    return true;
  } else {
    return false;
  }
};

const getItem = (name: string): string => {
  return window.localStorageAvailable
    ? window.localStorage.getItem(name) || 'null'
    : 'null';
};

const removeItem = (name: string): boolean => {
  if (window.localStorageAvailable) {
    window.localStorage.removeItem(name);

    return true;
  } else {
    return false;
  }
};

export default {
  setItem,
  getItem,
  removeItem
};
