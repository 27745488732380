import { innerWidthThreshold } from 'utils/constants/app/magic-numbers-app';

export const TABLET_BY_DEFAULT = 3;

export const isMobile = (): boolean => {
  return typeof window.isMobile !== 'undefined'
    ? +window.isMobile === 1 ||
        (+window.isTablet === 1 &&
          window?.partnerConfigs?.tabletByDefault === TABLET_BY_DEFAULT)
    : window.innerWidth < innerWidthThreshold.WINDOW_WIDTH_THRESHOLD_1200;
};
