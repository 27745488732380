import { getCookie } from 'utils/bom-dom-manipulation/cookies';
import { gamblingLogoURL } from 'utils/constants/casino/gambling-logo-url';
import {
  MATCHTRACKER_LANG,
  STATISTICS_LANG
} from 'utils/constants/languages/language-format';
import { languagePrefixes } from 'utils/constants/swarm/swarm-lang-prefix-constants';
import SpringConfigsDev from 'utils/dev/spring-configs-dev';
import { isMobile } from 'utils/is-mobile';
import { SpringConfigsType } from 'interfaces/generic';
import {
  EBetCardView,
  EMultiplesView,
  EOlympicLayoutType,
  ETopLeagueLayoutType,
  ETopMatchesLayoutType
} from 'interfaces/sportsbook-configs';

const lang = document.documentElement.lang;
const dir = document.documentElement.dir || 'ltr';
export const androidWrapperApp = getCookie('isAndroidWrapperApp');
export const iOSWrapperApp = getCookie('isIOSWrapperApp');

/* eslint-disable */
/* prettier-ignore */
const SpringConfigs: SpringConfigsType = process.env.NODE_ENV === 'development' ? SpringConfigsDev : {
  PARTNER_ID:                                   window?.partnerConfigs?.springConfig?.partnerId || 4,
  SOURCE:                                       androidWrapperApp ? 16 : iOSWrapperApp ? 17 : isMobile() ? 4 : 42,
  WRAPPER_APP:                                  !!androidWrapperApp || !!iOSWrapperApp,
  SWARM_URL:                                    window?.partnerConfigs?.swarmUrl || 'wss://eu-swarm-springre.betconstruct.com/',
  CASINO_URL:                                   window?.partnerConfigs?.casinoUrl ||  'https://go.cmsbetconstruct.com/casino',
  DECENTRALIZED_CASINO_URL:                     window?.partnerConfigs?.decentralizedCasinoUrl ||  '',
  DECENTRALIZED_CASINO_PARTNER_ID:              window?.partnerConfigs?.decentralizedCasinoPartnerId || 0,
  CMS_URL:                                      window?.partnerConfigs?.cmsUrl ||  'https://go.cmsbetconstruct.com/',
  JACKPOT_URL:                                  'wss://rgs-wss.betconstructapps.com/jackpot',
  GEO_LINK_URL:                                 window?.partnerConfigs?.geoLinkUrl || 'https://geoapi.bcapps.net',
  A_TO_Z_MENU_DATA_PATH:                        window?.partnerConfigs?.aToZJsonPath || 'https://static.springbuilder.site/fs/userFiles-v2/testvah/data/springXConfigs/aToZNavigationJsonPath.json?v=1632748042',
  ACCOUNT_REGISTRATION_JSON_PATH:               window?.partnerConfigs?.accountRegistrationJsonPath || '',
  ACCOUNT_PARAMS_COUNTRY_EXCEPTIONS:            window?.partnerConfigs?.accountParamsCountryExceptions || '',
  SECTIONS_JSON:                                window?.partnerConfigs?.accountSectionsJsonPath || '',
  ACCOUNT_PARAMS:                               window?.partnerConfigs?.accountParams || '',
  CUSTOM_PAYMET_URL:                            window?.partnerConfigs?.customPaymentUrl || '',
  USER_FILES_URL:                               window?.partnerConfigs?.userFilesUrl || false,
  TERMS_LINK:                                   window?.partnerConfigs?.termsLink || 'https://www.betconstruct.com',
  TERMS_POPUP_LINK:                             window?.partnerConfigs?.termsPopupLink || 'https://www.betconstruct.com',
  PRIVACY_POLICY_LINK:                          window?.partnerConfigs?.privacyPolicyLink || 'https://www.betconstruct.com',
  TERMS_LINK_TARGET:                            window?.partnerConfigs?.termsLinkTarget || '1',
  PRIVACY_POLICY_TARGET:                        window?.partnerConfigs?.privacyPolicyTarget || '1',
  STORAGE_URL :                                 'https://static.springbuilder.site/',
  LANGUAGE_PREFIX:                              languagePrefixes[lang] || languagePrefixes[lang.slice(0, 2)],
  MATCHTRACKER_LANG_PREFIX:                     MATCHTRACKER_LANG[lang] || MATCHTRACKER_LANG[lang.slice(0, 2)],
  STATISTICS_LANG_PREFIX:                        STATISTICS_LANG[lang] || STATISTICS_LANG[lang.slice(0, 2)],
  CURRENT_LANGUAGE:                             lang || 'en',
  CURRENT_LANGUAGE_PREFIX:                      window.currentLanguagePrefix || 'en',
  DEFAULT_LANGUAGE_PREFIX:                      window.getDefaultLanguagePrefix ? window.getDefaultLanguagePrefix() : window.currentLanguagePrefix || 'en',
  IS_DEFAULT_LANGUAGE:                          window.currentLanguageObject?.default,
  ODD_ROUNDING:                                 3,
  PRICE_ROUNDING:                               2,
  PRICE_SEPARATOR:                              window?.partnerConfigs?.priceSeparator ? ',' : ' ',
  HIDE_TRAILING_ZEROES:                         true,
  VERSION:                                      +(window?.partnerConfigs?.version || 123456),
  IS_TOP_NAVIGATION_BUTTON_ENABLED:             window?.partnerConfigs?.isTopNavigationButtonEnabled || false,
  TOP_NAVIGATION_BUTTON_POSITION:               window?.partnerConfigs?.topNavigationButtonPosition || 'right',
  TOP_NAVIGATION_BUTTON_ALIGNMENT:              window?.partnerConfigs?.topNavigationButtonAlignment || 'bottom',
  DIRECTION:                                    dir,
  SPORTS_CONFIGS:                               window?.partnerConfigs?.sportsConfigs || '',
  ERROR_MSG_DURATION:                           window?.partnerConfigs?.errorMessageDuration || 3,
  IOVATION_MODE:                                window?.partnerConfigs?.iovationMode || '0',
  SCREENSHOT_TIMEOUT:                           window?.partnerConfigs?.screenshotTimeout || 2000,
  LOGO_URL:                                     `${window?.partnerConfigs?.userFilesUrl}/images/logo.png?v=${window?.partnerConfigs?.translationsVersion || ''}`,
  INCLUDE_SPORT_DATA_IN_META:                   window?.partnerConfigs?.includeSportDataInMetas,
  SESSION_LIFE_TIME:                            window?.partnerConfigs?.sessionTime,
  BOTTOM_NAVIGATION:                            window?.partnerConfigs?.enableBottomNavigation || window?.partnerConfigs?.bottomNavigation,
  SITE_LOGO_URL:                                window?.partnerConfigs?.siteLogoUrl || '',
  USER_PERSONALIZATION_ENABLED:                 window?.partnerConfigs?.userPersonalizationEnabled || false,


  /**  CASINO  **/
  CASINO_GAME_SWITCHING_DURING_GAME_SESSION:    true,
  CASINO_GAMES_FETCH_COUNT:                     16,
  CASINO_GAMES_FETCH_MAX_COUNT:                 30,
  CASINO_CACHED_GAMES_MAX_COUNT:                15,
  CASINO_MOBILE_IFRAME:                         Boolean(+(window?.partnerConfigs?.springConfig?.casinoIFrameMobileOnOff || '0')),
  CASINO_SPORTSBOOK_SWITCHER:                   +(window?.partnerConfigs?.accountType || 0),
  CASINO_GAMBLING_LOGO_URL:                     window.partnerConfigs?.casinoGamblingLogoUrl || gamblingLogoURL,
  CASINO_HIGH_QUALITY_PICTURES:                 window.partnerConfigs?.casinoHighQualityPictures || false,
  CASINO_GAME_URL_PREFIX:                       'games',
  CASINO_MOUNT_PATH:                            'slots',
  CASINO_FUN_MODE:                              window.partnerConfigs?.casinoFunMode || false,
  CASINO_GAME_SIDEBAR_ENABLED:                  window.partnerConfigs?.casinoGameSideBar || false,
  CASINO_PROVIDER_ELEMENT_EXIST:                window.partnerConfigs?.casinoProviderElementExist || false,
  CASINO_CATEGORY_ELEMENT_EXIST:                window.partnerConfigs?.casinoCategoryElementExist || false,
  CASINO_IFRAME_FROZEN_BALANCE:                 window.partnerConfigs?.casinoIframeFrozenBalance || false,
  CASINO_POP_UP_IN_MOBILE:                      window.partnerConfigs?.casinoPopUpInMobile || false,
  CASINO_LAUNCH_TYPE:                           window.partnerConfigs?.casinoLaunchType || 0,
  CASINO_MULTIVIEW_ENABLED:                     window.partnerConfigs?.casinoMultiView || 0,
  /**  SPORTSBOOK  **/
  SPORTSBOOK_MOUNT_PATH:              window?.mockedData ? '' : 'match',
  SPORT_MENU_DEFAULT_SELECTED:        window?.partnerConfigs?.initiallyOpen || 'sportsTypes',
  SPORT_MARKET_IS_COMPACT:            window?.partnerConfigs?.isCompact || 0,
  SPORT_COMPETITION_IS_COMPACT:       window?.partnerConfigs?.isCompetitionCompact || 0,
  IS_BOOK_BET_AVAILABLE:              (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookBetAvailable)) || false,
  IS_BOOK_BET_SHARING_AVAILABLE:      (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookBetSharingAvailable)) || false,
  IS_BOOK_COPY_LINK_AVAILABLE:        (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookCopyLinkAvailable)) || false,
  IS_BOOK_FACEBOOK_SHARING_AVAILABLE: (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookFBSharingAvailable)) || false,
  IS_BOOK_TELEGRAM_SHARING_AVAILABLE: (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookTelegramSharingAvailable)) || false,
  IS_BOOK_TWITTER_SHARING_AVAILABLE:  (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookTwitterSharingAvailable)) || false,
  IS_BOOK_WHATSAPP_SHARING_AVAILABLE:      (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookingWhatsappAvailable)) || false,
  IS_BOOKING_DOWNLOAD_AVAILABLE:      (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.bookingDownloadingAvailable)) || false,
  IS_BET_SHARING_AVAILABLE:           (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betSharingAvailable)) || false,
  IS_BET_FACEBOOK_SHARING_AVAILABLE:  (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betFBSharingAvailable)) || false,
  IS_BET_TELEGRAM_SHARING_AVAILABLE:  (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betTelegramSharingAvailable)) || false,
  IS_BET_TWITTER_SHARING_AVAILABLE:   (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betTwitterSharingAvailable)) || false,
  IS_BET_WHATSAPP_SHARING_AVAILABLE:   (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betWhatsappSharingAvailable)) || false,
  IS_BET_DOWNLOAD_AVAILABLE:          (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betDownloadingAvailable)) || false,
  IS_BET_COPY_LINK_AVAILABLE:          (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.betCopyLinkAvailable)) || false,
  GENERATE_SHARING_IMAGE_URL:         `${window.location.origin}/papi/default/ModuleSbAccount/generateSharingImage`,
  RENDER_COMPETITIONS_IN_DOM:         window?.partnerConfigs?.renderCompetitionsInDom || false,
  MARKETS_TOOLTIPS:                    window?.partnerConfigs?.marketsTooltips || false,

  IS_QUICK_BET:                       (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.quickBet)) || false,
  GAME_NUMBER:                        window?.partnerConfigs?.gameNumber || 0,
  IS_BOOSTED_ODDS_ENABLED:            window.partnerConfigs?.boostedOddsEnabled || false,
  IS_MULTIPLES_ENABLED:               window.partnerConfigs?.multiplesEnabled || false,
  MULTIPLES_VIEW:                     window.partnerConfigs?.expressOfTheDayMobileLayout  || EMultiplesView.EXPANDED,
  IS_EDIT_BET_AVAILABLE:              (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.editBetAvailable && +window?.partnerConfigs?.betslipType !== 1)) ?? true,
  BETSLIP_ICON:                      window.partnerConfigs?.betslipIcon || '',
  IS_BETSLIP_FLOATING_ENABLED:        window.partnerConfigs?.betslipFloatingMode,
  BET_CARD_DEFAULT_VIEW:              window.partnerConfigs?.betCardDefaultView || EBetCardView.COMPACT,
  IS_BETSLIP_FLOATING_ENABLED_MOBILE: window.partnerConfigs?.betslipFloatingModeMobile,
  BETSLIP_POSITION:                   window.partnerConfigs?.betslipPosition || false,
  TEAM_LOGOS:                         Boolean(+(window?.partnerConfigs?.teamLogos || '1')),
  PUSH_NOTIFICATIONS_ENABLED:         window?.partnerConfigs?.pushNotifications || false,
  BETS_WITH_SAME_GROUP:               !!window?.partnerConfigs?.betsWithSameGroup,
  BETSLIP_MAX_BTN:                    window?.partnerConfigs?.betslipMaxBtn || false,
  BETSLIP_MAX_BTN_TYPE:               +(window?.partnerConfigs?.betslipMaxBtnType || '0'),
  OPEN_BETS_BY_DEFAULT:               window?.partnerConfigs?.openBetsByDefault || 0,
  BETSLIP_TYPE:                       window?.partnerConfigs?.betslipType || 0,
  BET_WITH_ONLY_BONUS_OR_DEPOSIT:     window?.partnerConfigs?.betWithBonusOrDeposit || false,
  SPORTSBOOK_ICON_STYLE:              window?.partnerConfigs?.sportsbookIconStyle || 'colored',
  FLAG_STYLE:                         window?.partnerConfigs?.flagStyle || 'circled',
  MATCH_TRACKER_URL:                  'https://visualization.ml.bcua.io',
  TOP_LEAGUES:                        Boolean(window?.partnerConfigs?.topLeagues) || false,
  TOP_LEAGUES_LAYOUT:                 window?.partnerConfigs?.topLeaguesLayout || ETopLeagueLayoutType.expanded,
  TOP_LEAGUES_DEFAULT_OPEN:           window?.partnerConfigs?.topLeaguesDefaultOpen || false,
  TOP_MATCHES:                        Boolean(window?.partnerConfigs?.topMatches) || false,
  TOP_MATCHES_LAYOUT:                 window?.partnerConfigs?.topMatchesLayout || ETopMatchesLayoutType.expanded,
  OLYMPIC_COMPETITION:                 Boolean(window?.partnerConfigs?.olympicCompetition) || false,
  OLYMPIC_COMPETITION_LAYOUT:          window?.partnerConfigs?.olympicCompetitionLayout || EOlympicLayoutType.expanded,
  TODAY_EVENTS:                       Boolean(window?.partnerConfigs?.todayEvents) || false,
  SPORTS_GROUPING:                    window?.partnerConfigs?.sportsGrouping || 0,
  PERSONALIZED_SPORTS:                window?.partnerConfigs?.personalizedSports || false,
  LIVE_PREMATCH_SWITCHER:             window?.partnerConfigs?.includeLivePrematchFilter || false,
  CHAIN_TYPE:                         window.partnerConfigs?.chainType || false,
  CALCULATE_POSSIBLE_WIN_WORKER_URL:  window.location.origin + '/widgets-x/workers/calculate-possible-win-worker.js',
  /**  ACCOUNT  **/
  GIFT_BET:                                     window?.partnerConfigs?.giftBet === '1',
  REBET_AVAILABLE:                              (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.rebetAvailable === 1)),
  IS_PRINT_AVAILABLE:                           (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.printBetAvailable) && !isMobile()) || false,
  REMOVE_SUSPENDED_SELECTION:                   (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.removeSuspendedSelection)) || false,
  METAMASK_LOGIN:                               (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.metamaskLogin)) || false,
  SEON_INTEGRATION:                             (window?.partnerConfigs && window.partnerConfigs.seonIntegration) || false,
  SEON_VALIDATION_ACTION:                       (window?.partnerConfigs && window.partnerConfigs.seonValidationAction) || 'all',
  THEME_SWITCHER:                               Boolean(window?.partnerConfigs?.themeMode),
  RESPONSIBLE_GAMING_URL:                       'https://www.opbets.com/responsible-gaming',
  GAMBLING_MESSAGE_URL:                          'http://www.begambleaware.org',
  ACCOUNT_VERIFIED:                             window?.partnerConfigs?.accountVerification || 0,
  DEPOSIT_PROMO:                                window?.partnerConfigs?.depositPromo || false,
  ACCOUNT_ELEMENT_EXIST:                        window.partnerConfigs?.accountElementExist || false,
  /**  MISC  **/
  MOCKED_DATA:                                  window?.mockedData || false,
  JURISDICTION:                                 window?.partnerConfigs?.jurisdiction || '0',
  IS_WEBP_SUPPORTED:                            null,
  DT:                                           {
                                                  shortDate:  'DD MMM',
                                                  longDate:   'DD MMM YYYY',
                                                  time:       'HH:mm',
                                                  longTime:   'HH:mm:ss'
                                                },

  PAGE_URLS:                                    window?.partnerConfigs?.sbPageUrls || {
                                                  live:
                                                            !isMobile()
                                                              ? `${window.location.origin}/desktop-sportsbook-live`
                                                              : `${window.location.origin}/live`,
                                                  prematch:
                                                            !isMobile()
                                                              ? `${window.location.origin}/desktop-sportsbook-prematch`
                                                              : `${window.location.origin}/prematch`,
                                                  casino:       `${window.location.origin}/casino`,
                                                  racing:       `${window.location.origin}/racing`,
                                                  tournaments:  `${window.location.origin}/tournament`,
                                                  esport:       `${window.location.origin}/esports`,
                                                  results:       `${window.location.origin}/results`,
                                                  'live-casino': `${window.location.origin}/live-casino`
                                                },
  FIREBASE_ENABLED:                             Boolean(+window.partnerConfigs?.springConfig?.enableFirebase),
  ONE_SIGNAL_APP_ID:                            window.partnerConfigs?.springConfig?.oneSignalAppId || '',
  FAST_TRACK_BRAND:                             window.partnerConfigs?.springConfig?.fastTrackBrand || '',

  GAMBLING_AREAS :                              window?.partnerConfigs?.gamblingAreas,

  NEM_ID_INTEGRATION:                           Number(window?.partnerConfigs?.nemidIntegration || '0'),
  NEM_ID_INTEGRATION_URL:                       window?.partnerConfigs?.nemidIntegrationURL || '',


  BET_BUILDER_URL:                              window?.partnerConfigs?.betBuilderIframeUrl || '',
  COMMUNITY_CHAT_ENABLED:                       window?.partnerConfigs?.communityChatEnabled || false,
  COMMUNITY_CHAT_USER_VERIFICATION_DISABLED:    window?.partnerConfigs?.communityChatUserVerificationDisabled || false,
  IS_ACTIVE_STREAM_TAB:                         (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.isStreamActiveTabCompact)) ?? true,
  OUTRIGHT_FILTER:                              +(window?.partnerConfigs?.outrightFilter || 0),
  CAROUSEL_ARROWS:                              isMobile() ? true : (window?.partnerConfigs?.carouselArrows || false),
  BET_BUILDER_SWITCHER:                         window?.partnerConfigs?.betBuilder || false,
  CURRENCIES_WITH_SYMBOLS:                      window?.partnerConfigs?.currenciesWithSymbols || false,
  CURRENCY_PLACEMENT:                           window?.partnerConfigs?.currencyPlacement || "default",
  REALITY_CHECK_SWITCHER:                       window?.partnerConfigs?.realityCheckSwitcher || false,
  DEFAULT_ODD_ACCEPT:                           window?.partnerConfigs?.defaultOddAccept?.toString() || "2",
  CURRENT_COUNTRY_CODE:                         (window?.partnerConfigs?.postGridApiKey && Number(window?.partnerConfigs?.jurisdiction) === 8) ? "CA" : (getCookie('country') as string || 'AM'),
  ODD_TYPE:                                     window?.partnerConfigs?.defaultOddTypes || ["decimal","fractional","american","hongkong","malay","indo"],
  DEFAULT_ODD_TYPE:                             window?.partnerConfigs?.defaultSelectedOddType || "decimal",
  IS_BET_STATUS_CHECKER_AVAILABLE :             window?.partnerConfigs?.betStatusCheck || false,
  STATISTICS_SWITCHER:                          Boolean(+(window?.partnerConfigs?.statisticEnabled || 0)),
  STATISTICS_URL:                               window?.partnerConfigs?.statisticsLink  || 'https://statistics.betcoapps.com',
  PREDEFINE_STAKES_ENABLE:                      Boolean(+(window.partnerConfigs?.betslipPredefinedStakes || '0')),

  PREDEFINE_STAKES_ADDING:                      !!window.partnerConfigs?.betslipAddingPredefinedStakes || false,

  PREDEFINE_STAKE_1:                            Number(window.partnerConfigs?.stake_1 || ''),
  PREDEFINE_STAKE_2:                            Number(window.partnerConfigs?.stake_2 || ''),
  PREDEFINE_STAKE_3:                            Number(window.partnerConfigs?.stake_3 || ''),
  DEFAULT_STAKE_ENABLED:                        Boolean(+(window.partnerConfigs?.betslipDefaultStake || '0')),
  DEFAULT_STAKE:                                Number(window.partnerConfigs?.betslipDefaultStakeValue || ''),
  IS_PARTIAL_CASHOUT_AVAILABLE :                window?.partnerConfigs?.partial_cashout_off_onn || false,
  IS_ALLOW_BONUS_BALANCE_SUPERBET :             window?.partnerConfigs?.allow_bonus_balance_for_superbet || false,
  SPORT_TOURNAMENTS:                            window?.partnerConfigs?.sportTournaments || false,
  ROCKET_BET:                                   window?.partnerConfigs?.rocketBet || false,
  LVISION:                                      window?.partnerConfigs?.enableLvision || false,
  LVISION_API_KEY:                              window?.partnerConfigs?.lvisionAPiKey || '',
  SUPER_BET_DEFAULT_ON:                         window?.partnerConfigs?.superBetDefaultOn || false,
  PICTURE_IN_PICTURE_STREAMING:                 window?.partnerConfigs?.pictureInPictureStreaming ?? true,
    AFTER_SUCCESSFUL_BET:                       Number(window?.partnerConfigs?.afterSuccessfulBet || 0),
    SHOW_MULTIPLE_BET_ORIGINAL_TOTAL_ODDS:      false,


  /** GPS Tracking **/
  GPS_TRACKING_URL :                            window.geolocationUrl || 'https://geolocation.bcapps.net',
  GPS_TRACKING_ENABLED :                        window?.partnerConfigs?.GPSTrackingEnabled || false,
  GPS_TRACKING_CHECKING_INTERVAL :              window?.partnerConfigs?.GPSTrackingCheckingInterval || 5000,

  IFRAME_SPORTSBOOK:                            window?.partnerConfigs?.iframe_sportsbook || false,
  TERMINAL_MODE:                                window?.partnerConfigs?.terminalMode || false,

  HOMEWORK_ENABLED:                             Boolean(+(window?.partnerConfigs?.homeworkEnabled || 0)),
  SHOW_SITE_NAME_IN_TITLE:                      Number(window?.partnerConfigs?.showSiteNameInTitles || 3),
  EXTERNAL_PAYMENT_URL:                         "https://dark-staging-18747819.springbuilder.site",

  /** PostGrid **/
  POSTGRID_API_KEY:                             window?.partnerConfigs?.postGridApiKey || null,
  POSTGRID_API_URL:                             'https://api.postgrid.com/v1/addver',
  POSTGRID_API_INTERNATIONAL_URL:               'https://api.postgrid.com/v1/intl_addver',

  LOCATE_GBG_API_KEY:                           window?.partnerConfigs?.locateGBGApiKey || null,

  LEFT_SIDE_BAR:                                window.partnerConfigs?.leftSideBar || false,
  LEFT_SIDE_BAR_SPORTS:                         window.partnerConfigs?.leftSideSports || false,
  LEFT_SIDE_BAR_CASINO:                         window.partnerConfigs?.leftSideCasino || false,

  IS_RTL:                                       dir === 'rtl',

  COUPON_BUILDER_SWITCHER:                      Boolean(+(window?.partnerConfigs?.couponBuilder || 0)),
  IOVATION_ENABLED:                             Boolean(+(window?.partnerConfigs?.iovationEnabledX || 0)),
  HOORY_ENABLED:                                (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.hooryEnabled)) || false,
  HOORY_ENABLED_SPORTSBOOK_BETSLIP:     (window?.partnerConfigs && Boolean(+window?.partnerConfigs?.hooryForElementsEnabled)) || false
};
/* eslint-enable */

export default SpringConfigs;
