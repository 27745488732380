import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import {
  EEventsTypes,
  ETimeFilterValues,
  TCompetitionWithGame,
  TSport
} from 'interfaces/events';
import { ActionType, ValueOf } from 'interfaces/generic';
import { EventsActionTypes } from 'store/action-types/events';

const timeFilter: Record<
  string,
  ValueOf<typeof ETimeFilterValues>
> = JSON.parse(
  LocalStorage.getItem(storageKeyName('events', 'ACTIVE_TIME_FILTER'))
) || {};

export type TEventsAction = {
  type: EEventsTypes;
  sport: string;
  data: TCompetitionWithGame[];
};

export const setEvents = (
  payload: Record<string, TEventsAction>
): ActionType => {
  return {
    type: EventsActionTypes.SET_EVENTS,
    payload
  };
};

export const setSports = (
  payload: Partial<Record<string, Record<string, TSport[] | undefined>>>
): ActionType => {
  return {
    type: EventsActionTypes.SET_SPORTS,
    payload
  };
};

export const removeSport = (payload: Record<string, string>): ActionType => {
  return {
    type: EventsActionTypes.REMOVE_SPORT,
    payload
  };
};

export const setSelectedSport = (
  payload: Partial<Record<string, Record<string, TSport>>>
): ActionType => {
  return {
    type: EventsActionTypes.SET_SELECTED_SPORT,
    payload
  };
};

export const setSelectedEventsType = (
  payload: Record<string, EEventsTypes>
): ActionType => {
  return {
    type: EventsActionTypes.SET_SELECTED_EVENTS_TYPE,
    payload
  };
};

export const setTimeFilter = (
  payload: Record<string, ValueOf<typeof ETimeFilterValues>>
): ActionType => {
  LocalStorage.setItem(
    storageKeyName('events', 'ACTIVE_TIME_FILTER'),
    JSON.stringify({ ...timeFilter, ...payload })
  );

  return {
    type: EventsActionTypes.SET_TIME_FILTER,
    payload
  };
};
