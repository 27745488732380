import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export interface IBetslipStakeSliceInitialData {
  totalStake: number;
  totalBetStake: number;
  totalSingleWin: number;
  maxStakeLoading: boolean;
  // @Todo add type or write with Record
  stakes: { [key: string]: string };
  currentStake: string | number;
  predefinedStakes: { [key: string]: string | number | undefined } | null;
}

export const betslipStakeSliceInitialData: IBetslipStakeSliceInitialData = {
  stakes: {},
  predefinedStakes: null,
  totalBetStake: 0,
  totalSingleWin: 0,
  currentStake: '',
  maxStakeLoading: false,
  totalStake: 0
};

export const betslipStakeSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: ActionType
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.SET_MAX_STAKE_LOADING:
      return {
        ...state,
        maxStakeLoading: action.payload
      };

    case BetslipActionTypes.SET_STAKES:
      return {
        ...state,
        stakes: action.payload
      };

    case BetslipActionTypes.SET_RS_PREDEFINED_STAKES:
      return {
        ...state,
        predefinedStakes: action.payload
      };

    case BetslipActionTypes.SET_TOTAL_BET_STAKE:
      return {
        ...state,
        totalBetStake: action.payload
      };

    case BetslipActionTypes.SET_CURRENT_STAKE:
      return {
        ...state,
        currentStake: action.payload
      };

    case BetslipActionTypes.SET_TOTAL_SINGLE_WIN:
      return {
        ...state,
        totalSingleWin: action.payload
      };

    case BetslipActionTypes.SET_TOTAL_STAKE:
      return {
        ...state,
        totalStake: action.payload
      };
  }

  return null;
};
