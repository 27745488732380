import { combineReducers } from 'redux';
import { appData } from './app-data';
import { betSlip } from './betslip';
import { casinoData } from './casino-data';
import { eSportData } from './e-sport-data';
import { events } from './events';
import { favData } from './fav-data';
import { gameData } from './game-data';
import { jackpot } from './jackpot';
import { loadings } from './loadings';
import { matchStreaming } from './match-streaming';
import { metaData } from './meta-data';
import { newCasinoStore } from './new-casino';
import { newSportsbook } from './new-sportsbook';
import { paymentsReducer } from './payments';
import { popularBets } from './popular-bets';
import { raceData } from './racing-data';
import { resultsData } from './results-data';
import { socket } from './socket';
import { specElems } from './spec-elems';
import { sportData } from './sport-data';
import { userData } from './user-data';

const rootReducer = combineReducers({
  favData,
  userData,
  socket,
  betSlip,
  appData,
  sportData,
  eSportData,
  casinoData,
  specElems,
  gameData,
  raceData,
  resultsData,
  loadings,
  metaData,
  newCasinoStore,
  newSportsbook,
  popularBets,
  events,
  matchStreaming,
  jackpot,
  payments: paymentsReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export * from './batch';

export default rootReducer;
