export const LANGUAGE_LOCALES: Record<string, string> = {
  en: 'en',
  ru: 'ru',
  gr: 'el',
  el: 'el',
  de: 'de',
  tr: 'tr',
  fr: 'fr',
  pt: 'pt',
  pl: 'pl',
  ko: 'ko',
  es: 'es',
  ar: 'ar',
  hi: 'hi',
  ur: 'hi',
  gu: 'hi',
  mr: 'hi',
  pa: 'hi',
  bn: 'hi',
  no: 'nn'
};

export const LOCALE_LANG_EXCEPTIONS: Record<string, string> = {
  gr: 'el_GR',
  en: 'en_US',
  uz: 'uz',
  gu: 'hi_IN',
  ur: 'hi_IN',
  pa: 'hi_IN',
  mr: 'hi_IN',
  bn: 'hi_IN',
  ar: 'ar_EG',
  es: 'es_ES',
  no: 'nb_NO'
};

export const MATCHTRACKER_LANG: Record<string, string> = {
  ar: 'arb',
  en: 'eng',
  fa: 'fas',
  ka: 'geo',
  de: 'ger',
  zh: 'zhh',
  ko: 'kor',
  pl: 'pol',
  pt: 'por',
  ru: 'rus',
  es: 'spa',
  tr: 'tur',
  uk: 'ukr',
  gr: 'gre',
  fr: 'fra',
  'zh-hant-hk': 'zho',
  'pt-br': 'pt-br'
};

export const CASINO_LANG_EXCEPTIONS: Record<string, string> = {
  fr: 'fre',
  tr: 'tr',
  bn: 'ben',
  'pt-br': 'pt-br'
};

export const BET_BUILDER_LANGUAGE_MAPPING: Record<string, string> = {
  en: 'en-GB',
  es: 'es-ES',
  fr: 'fr-FR',
  pt: 'pt-PT',
  hy: 'hy-AM',
  de: 'de-DE',
  it: 'it-IT',
  tr: 'tr-TR',
  fa: ' fa-IR',
  ar: 'ar-001',
  ru: 'ru-RU',
  gr: 'el-GR',
  pl: 'pl-PL',
  hi: 'hi-IN',
  'en-us': 'en-US',
  'ko-kr': 'ko-KR'
};

export const STATISTICS_LANG: Record<string, string> = {
  ar: 'ar',
  az: 'az',
  'da-dk': 'da',
  de: 'de',
  el: 'el',
  en: 'en',
  'en-us': 'us',
  'en-ca': 'cn',
  es: 'es',
  fa: 'fa',
  fi: 'fi',
  fr: 'fr',
  gr: 'gr',
  he: 'he',
  hy: 'hy',
  id: 'id',
  it: 'it',
  ja: 'ja',
  ka: 'ka',
  'ka-ge': 'ka',
  kk: 'kk',
  ko: 'ko',
  'ko-kr': 'ko',
  ku: 'ku',
  lt: 'lt',
  mk: 'mk',
  mn: 'mn',
  ms: 'ms',
  nn: 'nor',
  no: 'no',
  'pl-pl': 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sv: 'sv',
  sv_se: 'sv',
  th: 'th',
  tr: 'tr',
  uk: 'uk',
  uz: 'uz',
  vi: 'vi',
  zh: 'zh',
  zs: 'zh',
  hi: 'hi',
  nl: 'nl',
  'bg-bg': 'bg',
  'km-kh': 'km',
  gu: 'gu',
  zh_CN: 'zh',
  ur: 'ur',
  pa: 'pa',
  mr: 'mr',
  'mr-in': 'mr',
  sw: 'sw',
  sr: 'sr',
  'sq-al': 'sq',
  'sl-si': 'sl',
  'sk-sk': 'sk',
  'rw-rw': 'rw',
  'pt-br': 'pb',
  mg: 'mg',
  'ma-in': 'ma',
  'lv-lv': 'lv',
  'lt-lt': 'lt',
  hr: 'hr',
  'et-ee': 'et',
  'cs-cz': 'cs',
  bn: 'bn',
  'am-et': 'am'
};
