import { CALCULATE_POWER_OF_10 } from 'utils/constants/app/general';
import store from 'store';

type TRoundMultipleValue = (number: number, isOdd?: boolean) => number;

// Backend decimals rounding type
export enum EDecimalRoundTypes {
  Cut = 0,
  RoundUp = 1,
  Round = 2
  // AwayFromZero = 3
}

export const roundMultipleValue: TRoundMultipleValue = (
  number,
  isOdd = false
) => {
  const partnerConfig = store.getState().socket.partnerConfigs;
  const roundKey = isOdd
    ? 'price_round_method'
    : 'multiple_possiblewin_round_method';

  const decimalsKey = isOdd ? 'price_decimals' : 'multiple_price_decimals';

  const roundMethod = partnerConfig?.[roundKey] as EDecimalRoundTypes;
  const priceDecimals = +(partnerConfig?.[decimalsKey] || 0);

  if (!number) {
    return number;
  }

  const afterDot = number.toString().split('.')[1];

  if (priceDecimals && afterDot?.length > priceDecimals) {
    let updatedNumber = number * Math.pow(CALCULATE_POWER_OF_10, priceDecimals);

    switch (roundMethod) {
      case EDecimalRoundTypes.Cut:
        updatedNumber = Math.floor(updatedNumber);

        break;
      case EDecimalRoundTypes.RoundUp:
        if (afterDot[0] !== '0') {
          updatedNumber = Math.floor(updatedNumber) + 1;
        }

        break;
      case EDecimalRoundTypes.Round:
        updatedNumber = Math.round(updatedNumber);
        break;
    }

    return updatedNumber / Math.pow(CALCULATE_POWER_OF_10, priceDecimals);
  }

  return number;
};
