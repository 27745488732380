import { getOddsFormat } from 'utils/betslip/odds-format';
import { BetSlipEventObj } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export type IBetslipOddsSliceInitialData = {
  oddFormat: number;
  betsTotalOdds: boolean;
  totalOddsReturn: number;
  activeMarketOdd: BetSlipEventObj | null;
  eventsPrevOdds: Record<number, number | string>;
};

export const betslipOddsSliceInitialData: IBetslipOddsSliceInitialData = {
  oddFormat: getOddsFormat(),
  totalOddsReturn: 0,
  activeMarketOdd: null,
  eventsPrevOdds: {},
  betsTotalOdds: false
};

export const betslipOddsSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: ActionType
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.UPDATE_ODD_FORMAT:
      return {
        ...state,
        oddFormat: action.payload
      };

    case BetslipActionTypes.SET_TOTAL_ODD_RETURN:
      return {
        ...state,
        totalOddsReturn: action.payload
      };

    case BetslipActionTypes.SET_ACTIVE_MARKET_ODD:
      return {
        ...state,
        activeMarketOdd: action.payload
      };

    case BetslipActionTypes.SET_EVENTS_PREV_ODDS:
      return {
        ...state,
        eventsPrevOdds: {
          ...state.eventsPrevOdds,
          ...action.payload
        }
      };

    case BetslipActionTypes.SET_BETS_TOTAL_ODDS:
      return {
        ...state,
        betsTotalOdds: action.payload
      };
  }

  return null;
};
