import { FC, ReactElement } from 'react';
import cc from 'classcat';
import { SkeletonElementProps } from '..';
import './index.less';

export interface SkeletonInputProps
  extends Omit<SkeletonElementProps, 'size' | 'shape'> {
  size?: 'large' | 'small' | 'default';
  block?: boolean;
}

const INPUT_SKELETON_SIZE = {
  small: 'customSkeleton-input-sm',
  large: 'customSkeleton-input-lg',
  default: ''
};

export const SkeletonInput: FC<SkeletonInputProps> = ({
  style,
  block,
  active,
  className,
  size = 'default'
}): ReactElement => {
  return (
    <div
      className={cc([
        'customSkeleton-element',
        {
          'customSkeleton-active': active,
          'customSkeleton-block': block
        },
        className
      ])}
    >
      <span
        className={`customSkeleton-input ${INPUT_SKELETON_SIZE[size]} ${
          className || ''
        }`}
        style={style}
      />
    </div>
  );
};
