import type { TProfitBoostItem } from 'interfaces/bet-data';
import { GENERAL_FIELD_KEY } from 'newelements/_Betslip/utils/constants/shared';
import type { IBetslipProfitBoostSliceInitialData } from 'store/reducers/betslip/profitBoost';

type TGetSelectedEventProfitBoostItems = (
  profitBoostStore: IBetslipProfitBoostSliceInitialData['_profitBoost']
) => TProfitBoostItem[] | undefined;

export const getSelectedEventProfitBoostItemsUtil: TGetSelectedEventProfitBoostItems =
  profitBoostStore => {
    if (
      profitBoostStore.profitBoostSelectedEventId !== GENERAL_FIELD_KEY &&
      profitBoostStore.profitBoostSelectedEventId
    ) {
      return profitBoostStore.singleProfitBoostItems[
        profitBoostStore.profitBoostSelectedEventId
      ];
    }

    return profitBoostStore.items;
  };

type TGetSelectedProfitBoostItemUtil = (
  profitBoostStore: IBetslipProfitBoostSliceInitialData['_profitBoost'],
  profitBoostItems?: TProfitBoostItem[]
) => TProfitBoostItem | null;

export const getSelectedProfitBoostItemUtil: TGetSelectedProfitBoostItemUtil = (
  profitBoostStore,
  profitBoostItems
) => {
  return (
    profitBoostItems?.find(
      ({ id }) => id === profitBoostStore.profitBoostSelectedItemId
    ) || null
  );
};
