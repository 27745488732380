import { RangeType, TTaxesState } from 'interfaces/betslip';
import { PartnerConfig } from 'interfaces/partner-config';
import store from 'store';
import { setTaxAmountRanges } from 'store/actions';

export const modifyAndSetTaxDataOnRedux = (partnerConfig: PartnerConfig) => {
  const taxes: TTaxesState = {};

  (partnerConfig?.tax_amount_ranges || []).forEach((range: RangeType) => {
    if (!taxes.ranges) {
      taxes.ranges = {};
    }

    if (!taxes.ranges[range.type]) {
      taxes.ranges[range.type] = range;
    }
  });

  if (partnerConfig.tax_type && partnerConfig.tax_percent) {
    taxes.basic = {
      type: partnerConfig.tax_type,
      percent: partnerConfig.tax_percent,
      taxableSportIds: partnerConfig.tax_sportIds
    };
  }

  if (Object.keys(taxes).length) {
    store.dispatch(setTaxAmountRanges(taxes));
  }
};
