import { getType } from 'utils/betslip/betslip';
import { EBetslipBetType, EBetslipType } from 'interfaces/betslip';
import { calculateUpdatesByBetType } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/calculateUpdatesByBetType';
import store from 'store';
import { setType } from 'store/actions';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';

/**
 * this function handles some cases related with bet type change
 *
 *  1. prevent dispatching type store if types are equal
 *  2. run calculations for all type changing from single to multiple or change
 *
 * */

export const dispatchBetslipBetType = (type: EBetslipBetType) => {
  const betslipStore = store.getState()
    ?.betSlip as IBetslipRootStateInitialData;

  // @Todo need to consider counterOfferCase
  const currentType =
    betslipStore.betslipType === EBetslipType.BASIC
      ? getType(betslipStore.type)
      : EBetslipBetType.SINGLE;

  if (currentType === type) {
    return;
  }

  store.dispatch(setType(type));
  calculateUpdatesByBetType(type, currentType);

  // @Todo need to consider system case here to
};
