import * as Sentry from '@sentry/browser';

export const initSentry = () => {
  Sentry.init({
    beforeSend(event, hint) {
      const error = hint.originalException as {
        message: string;
      };

      if (
        error &&
        error.message &&
        (error.message.match('Invalid or unexpected token') ||
          error.message.match('Invalid character: @'))
      ) {
        return null;
      }

      return event;
    },
    dsn: 'https://529b48b3f37545fcb865205e9ce21c2b@o328684.ingest.sentry.io/6245954',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    ignoreErrors: ['ChunkLoadError'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    allowUrls: [/https.*widgets-x\/static\/js/]
  });
};
