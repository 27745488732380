import { Dispatch, ReactElement, SetStateAction } from 'react';
import { EAfterSuccessfulBet } from 'interfaces/betslip';
import { ERacingSportSideBarTabs } from 'interfaces/racing';
import {
  EBetCardView,
  EMultiplesView,
  EOlympicLayoutType,
  ETopLeagueLayoutType,
  ETopMatchesLayoutType
} from 'interfaces/sportsbook-configs';

export type ValueOf<T> = T[keyof T];
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
export type OptionalNullable<T> = {
  [K in keyof T as T[K] extends NonNullable<unknown> ? K : never]: T[K];
} & {
  [K in keyof T as T[K] extends NonNullable<unknown> ? never : K]?: T[K];
};

export type ActionType = {
  type: string;
  payload?: any;
};

export type DataConfig = {
  category: string;
  type: string;
  categoryIds?: string;
};

export enum ERecaptchaActions {
  getMaxBet = 'get_max_bet',
  doBet = 'do_bet',
  // this action for testing and it makes recaptcha visible,
  test_fallback = 'test_fallback'
}

export type NavSectionItem = {
  id: number;
  title: string;
  pageType?: PageType;
  iconInfo: {
    lib: IconLibName;
    name: string;
    theme?: string;
  };
  countGetter?: (countSetter: CountSetter) => string | null;
  queryStringObject?: {
    type?: string;
    sport?: string;
  };
};

export type IconLibName = 'sports' | 'flags' | 'casino' | 'generic' | 'account';

export type PageType =
  | 'all'
  | 'blank'
  | 'live'
  | 'prematch'
  | 'casinoGames'
  | 'casinoTournaments'
  | 'eLive'
  | 'ePrematch'
  | 'virtualSports'
  | 'racing'
  | 'home';

export type CountSetter = Dispatch<SetStateAction<number | null>>;

export type ResponseGeneric = {
  result: number | string;
  result_text: null | string;
  details: Record<string, any>;
};

type TCurrencyPlacement = 'default' | 'left' | 'right';

export type RouteMatchType = {
  url: string;
  path: string;
  isExact: boolean;
  params: Record<string, string>;
};

export type tAlignment = 'top' | 'bottom';
export type tPosition = 'right' | 'left' | 'center';

export type SpringConfigsType = {
  PARTNER_ID: number;
  SOURCE: number | null;
  WRAPPER_APP: boolean;
  SWARM_URL: string;
  CASINO_URL: string;
  DECENTRALIZED_CASINO_URL: string;
  DECENTRALIZED_CASINO_PARTNER_ID: number;
  JACKPOT_URL: string;
  GEO_LINK_URL: string;
  A_TO_Z_MENU_DATA_PATH: string;
  ACCOUNT_REGISTRATION_JSON_PATH: string;
  ACCOUNT_PARAMS_COUNTRY_EXCEPTIONS: string;
  SECTIONS_JSON: string;
  ACCOUNT_PARAMS: string;
  USER_FILES_URL: string | boolean;
  TERMS_LINK: string;
  TERMS_POPUP_LINK: string;
  PRIVACY_POLICY_LINK: string;
  TERMS_LINK_TARGET: string;
  STORAGE_URL: string;
  PRIVACY_POLICY_TARGET: string;
  LANGUAGE_PREFIX: string;
  STATISTICS_LANG_PREFIX: string;
  MATCHTRACKER_LANG_PREFIX: string;
  CURRENT_LANGUAGE: string;
  CURRENT_LANGUAGE_PREFIX: string;
  DEFAULT_LANGUAGE_PREFIX: string;
  IS_DEFAULT_LANGUAGE?: number;
  ODD_ROUNDING: number;
  PRICE_ROUNDING: number;
  PRICE_SEPARATOR: string;
  HIDE_TRAILING_ZEROES: boolean;
  BOTTOM_NAV_VALIDATION?: boolean;
  CASINO_DISABLE_FUN_MODE_FOR_NOT_AUTHORIZED_USERS?: boolean;
  VERSION: number;
  IS_TOP_NAVIGATION_BUTTON_ENABLED: boolean;
  TOP_NAVIGATION_BUTTON_POSITION: tPosition;
  TOP_NAVIGATION_BUTTON_ALIGNMENT: tAlignment;
  DIRECTION: string;
  SPORTS_CONFIGS: string | boolean;
  IOVATION_ENABLED: boolean;
  IOVATION_MODE: string;
  SCREENSHOT_TIMEOUT: number;
  LOGO_URL: string;
  INCLUDE_SPORT_DATA_IN_META?: boolean;
  SESSION_LIFE_TIME?: number;
  BOTTOM_NAVIGATION?: boolean;
  ERROR_MSG_DURATION?: number;
  IS_RTL: boolean;
  CMS_URL: string;
  SITE_LOGO_URL: string;
  CUSTOM_PAYMET_URL: string;
  USER_PERSONALIZATION_ENABLED: boolean;

  /**  CASINO  **/
  CASINO_GAMES_FETCH_COUNT: number;
  CASINO_GAMES_FETCH_MAX_COUNT: number;
  CASINO_CACHED_GAMES_MAX_COUNT: number;
  CASINO_MOBILE_IFRAME: boolean;
  CASINO_GAME_URL_PREFIX: string;
  CASINO_SPORTSBOOK_SWITCHER: number;
  CASINO_GAMBLING_LOGO_URL: string;
  CASINO_HIGH_QUALITY_PICTURES: boolean;
  CASINO_MOUNT_PATH: string;
  CASINO_GAME_SWITCHING_DURING_GAME_SESSION: boolean;
  CASINO_FUN_MODE: boolean;
  CASINO_GAME_SIDEBAR_ENABLED: boolean;
  CASINO_PROVIDER_ELEMENT_EXIST: boolean;
  CASINO_CATEGORY_ELEMENT_EXIST: boolean;
  CASINO_IFRAME_FROZEN_BALANCE: boolean;
  CASINO_POP_UP_IN_MOBILE: boolean;
  CASINO_LAUNCH_TYPE: number;
  CASINO_MULTIVIEW_ENABLED: number;
  /**/
  CASINO_TIMER_NET_WIN?: boolean;
  GAMBLING_COMMISSION?: boolean;
  PANIC_BUTTON?: boolean;
  /** CASINO CONFIGS FROM JURISDICTIONS **/
  SHOW_PLAYER_INFORMATION_IN_CASINO_SINGLE_VIEW?: boolean;
  SHOW_CASINO_TIMER_NET_WIN?: boolean;
  IS_HEADER_VISIBLE_IN_CASINO?: boolean;
  SHOW_GAMBLING_WITH_LOGO?: boolean;
  IS_CASINO_FUN_MODE_UNAVAILABLE?: boolean;

  /**  SPORTSBOOK  **/
  RENDER_COMPETITIONS_IN_DOM: boolean;
  SPORTSBOOK_MOUNT_PATH: string;
  SPORT_MENU_DEFAULT_SELECTED:
    | 'sportsTypes'
    | 'topLeagues'
    | 'todayEvents'
    | 'topMatches'
    | 'betBuilder'
    | 'multiples'
    | 'outright'
    | 'boostedOdds';
  SPORT_MARKET_IS_COMPACT: string | number;
  SPORT_COMPETITION_IS_COMPACT: number;
  IS_BOOK_BET_AVAILABLE: boolean;
  IS_BOOK_BET_SHARING_AVAILABLE: boolean;
  IS_BOOK_COPY_LINK_AVAILABLE: boolean;
  IS_BOOK_FACEBOOK_SHARING_AVAILABLE: boolean;
  IS_BOOK_TELEGRAM_SHARING_AVAILABLE: boolean;
  IS_BOOK_TWITTER_SHARING_AVAILABLE: boolean;
  IS_BOOK_WHATSAPP_SHARING_AVAILABLE: boolean;
  IS_BOOKING_DOWNLOAD_AVAILABLE: boolean;
  IS_BET_SHARING_AVAILABLE: boolean;
  IS_BET_FACEBOOK_SHARING_AVAILABLE: boolean;
  IS_BET_TELEGRAM_SHARING_AVAILABLE: boolean;
  IS_BET_TWITTER_SHARING_AVAILABLE: boolean;
  IS_BET_WHATSAPP_SHARING_AVAILABLE: boolean;
  IS_BET_DOWNLOAD_AVAILABLE: boolean;
  IS_BET_COPY_LINK_AVAILABLE: boolean;
  GENERATE_SHARING_IMAGE_URL: string;
  IS_BET_STATUS_CHECKER_AVAILABLE: boolean;
  IS_QUICK_BET: boolean;
  GAME_NUMBER: boolean | number;
  IS_EDIT_BET_AVAILABLE: boolean;
  IS_BOOSTED_ODDS_ENABLED: boolean;
  IS_MULTIPLES_ENABLED: boolean;
  MULTIPLES_VIEW: EMultiplesView;
  IS_BETSLIP_FLOATING_ENABLED?: boolean;
  IS_BETSLIP_FLOATING_ENABLED_MOBILE?: boolean;
  BETSLIP_POSITION: boolean;
  BET_CARD_DEFAULT_VIEW: EBetCardView;
  TEAM_LOGOS: boolean;
  PUSH_NOTIFICATIONS_ENABLED: boolean;
  BETS_WITH_SAME_GROUP: boolean;
  BETSLIP_MAX_BTN: boolean | number;
  BETSLIP_MAX_BTN_TYPE: number;
  SHOW_MULTIPLE_BET_ORIGINAL_TOTAL_ODDS: boolean;
  SPORTSBOOK_ICON_STYLE: 'colored' | 'default';
  FLAG_STYLE: 'circled' | 'default';
  BETSLIP_TYPE: number;
  BET_WITH_ONLY_BONUS_OR_DEPOSIT: boolean;
  STATISTICS_URL: string;
  STATISTICS_SWITCHER: boolean;
  MATCH_TRACKER_URL: string;
  TOP_LEAGUES: boolean;
  TOP_LEAGUES_LAYOUT: ETopLeagueLayoutType;
  TOP_LEAGUES_DEFAULT_OPEN: boolean;
  TOP_MATCHES: boolean;
  TOP_MATCHES_LAYOUT: ETopMatchesLayoutType;
  OLYMPIC_COMPETITION: boolean;
  OLYMPIC_COMPETITION_LAYOUT: EOlympicLayoutType;
  TODAY_EVENTS: boolean;
  MARKETS_TOOLTIPS: boolean;
  CALCULATE_POSSIBLE_WIN_WORKER_URL: string;
  BETSLIP_ICON?: string;

  /** SPORTSBOOK CONFIGS FROM JURISDICTIONS **/

  IS_AMERICAN_LAYOUT?: boolean;
  IS_SGB_TYPE_FOR_BB?: boolean;
  GET_AMERICAN_MARKET_TYPES?: boolean;
  SORT_UPCOMING_EVENTS_FIRST?: boolean;

  /** ACCOUNT **/
  USER_AUTOLOGIN?: boolean;
  WALLET_VIEW?: 'grid' | 'list';
  GIFT_BET: boolean;
  REBET_AVAILABLE?: boolean;
  IS_PRINT_AVAILABLE: boolean;
  REMOVE_SUSPENDED_SELECTION: boolean;
  METAMASK_LOGIN: boolean;
  THEME_SWITCHER: boolean;
  RESPONSIBLE_GAMING_URL: string;
  GAMBLING_MESSAGE_URL: string;
  POSTGRID_API_KEY: string | null;
  POSTGRID_API_URL: string;
  POSTGRID_API_INTERNATIONAL_URL: string;
  ACCOUNT_VERIFIED: boolean | number;
  DEPOSIT_PROMO: boolean;
  LOCATE_GBG_API_KEY: string | null;
  /**/
  LOGIN_FREEZE?: boolean;
  ADD_INFO_AFTER_LOGIN?: boolean;
  ACCOUNT_CLOSURE?: boolean;
  REALITY_CHECK?: boolean;
  SESSION_LIMIT?: boolean;
  SELF_EXCLUSION_REASON?: boolean;
  SESSION_INFORMATION_AVAILABLE?: boolean;
  SEON_INTEGRATION?: boolean;
  SEON_VALIDATION_ACTION: 'all' | 'login' | 'register';
  GPS_TRACKING_URL: string;
  GPS_TRACKING_ENABLED: boolean;
  GPS_TRACKING_CHECKING_INTERVAL: number;
  ACCOUNT_ELEMENT_EXIST: boolean;
  /** ACCOUNT CONFIGS FROM JURISDICTIONS **/

  SHOW_DEPOSIT_LIMITS?: boolean;
  SHOW_DOCUMENTS_WITH_CARDS?: boolean;
  VALIDATE_DE_ADDRESS_FIELD?: boolean;
  SELFIE_WITH_DOCUMENTS?: boolean;
  SHOULD_HIDE_LIMITS_INITIAL_VALUE?: boolean;
  SHOW_USER_UNVERIFIED_MESSAGE?: boolean;
  SHOW_LIMITS_CONFIRMATION?: boolean;
  SHOW_NICK_NAME_IN_REG_FORM?: boolean;
  SHOW_DOC_ISSUE_WITH_SUGGESTIONS?: boolean;
  SHOW_ACCOUNT_STATEMENT?: boolean;
  SHOW_ACCOUNT_SESSION?: boolean;
  SHOW_ACCOUNT_GAME_INFORMATION_MODAL?: boolean;
  SHOW_PRODUCT_ID_IN_BALANCE_HISTORY?: boolean;
  SHOULD_INIT_IDIN_SERVICE?: boolean;
  IS_MULTI_ACCOUNT_DISABLED?: boolean;
  SHOW_VERIFY_ACCOUNT_MODAL?: boolean;
  SHOW_ACCOUNT_ADDITIONAL_INFO_MODAL?: boolean;
  SHOW_ADM_TRANSACTION_ID_IN_BALANCE_HISTORY?: boolean;
  SHOW_USERNAME_IN_REG_FORM?: boolean;
  SHOW_DEPOSIT_LIMIT_CREATION_DATE?: boolean;
  SHOW_INFO_POPUP_BEFORE_REASONS_IN_ACCOUNT_CLOSE?: boolean;
  SHOW_WITHDRAWAL_CHECKER_MODAL?: boolean;
  SHOW_ACCOUNT_VERIFICATION_SECTION?: boolean;
  HIDE_WAGERING_FACTOR?: boolean;
  IS_PREDEFINED_PAYMENT_METHODS_AVAILABLE?: boolean;
  SHOW_BANK_NAME_IN_PAYMENT?: boolean;
  SHOW_TIMEOUT_INFO?: boolean;
  IS_FAST_REGISTRATION_ENABLED?: boolean;
  SHOULD_IGNORE_USER_FROZEN_BALANCE?: boolean;
  HAS_BONUS_ELIGIBLE?: boolean;
  IS_BONUS_BALANCE_VIEW_EXPANDED_BY_DEFAULT?: boolean;
  SHOW_DEPOSIT_CONFIRMATION_MESSAGE?: boolean;
  IS_PAYMENT_INFO_TEXT_LONGER?: boolean;
  CANADIAN_ACCOUNT_TYPE?: boolean;
  CYPRUS_ACCOUNT_TYPE?: boolean;
  MGA_ACCOUNT_TYPE?: boolean;
  CASINO_BALANCE_IN_USER_BALANCE?: boolean;
  IS_ACCUMULATED_LOSSES_AVAILABLE?: boolean;
  SHOULD_HIDE_ACCUMULATED_PROFITS?: boolean;
  DEPOSIT_RESTRICTION?: boolean;
  AGE_REQUIREMENTS_NOTICE?: boolean;
  DE_PROVINCE_ALPHA2?: boolean;
  GAMBLING_MESSAGE_ENABLED?: boolean;
  AUTOFILL_DOC_ISSUE_CODE?: boolean;
  UPLOAD_DOCS_MIN_COUNT?: boolean | number;
  LOGIN_LIMIT_POPUP?: boolean;
  SHOW_CONFIRMATION_POPUP_FOR_LIMITS?: boolean;
  MIN_DEPOSIT_LIMIT?: number;
  HIDE_WITHDRAWAL_ALLOWED_DATE?: boolean;
  SHOW_ONLY_SINGLE_AND_MULTIPLE_ODDS_IN_BET_HISTORY?: boolean;
  OLYMPIC_ICON_OTHER?: boolean;

  /**  MISC  **/
  MOCKED_DATA: boolean;
  JURISDICTION: string;
  IS_WEBP_SUPPORTED: boolean | null;
  ICONS_MOUNT_PATH: string;
  ICON_EXTENSION: string;
  DT: {
    shortDate: string;
    longDate: string;
    time: string;
    longTime: string;
  };
  /**/
  PAGE_URLS: Record<string, string> & {
    homepageKey?: string;
    live: string;
    prematch: string;
    casino: string;
    tournaments: string;
    esport: string;
    results: string;
    'live-casino': string;
  };
  FIREBASE_ENABLED: boolean;
  ONE_SIGNAL_APP_ID: string;
  FAST_TRACK_BRAND: string;
  GAMBLING_AREAS?: Record<string, string[]>;
  COMMUNITY_CHAT_ENABLED: boolean;
  COMMUNITY_CHAT_USER_VERIFICATION_DISABLED: boolean;
  BET_BUILDER_URL: string;
  IS_ACTIVE_STREAM_TAB: boolean;
  OUTRIGHT_FILTER: number;
  CAROUSEL_ARROWS: boolean;
  BET_BUILDER_SWITCHER: boolean;
  CURRENCIES_WITH_SYMBOLS: boolean;
  CURRENCY_PLACEMENT: TCurrencyPlacement;
  REALITY_CHECK_SWITCHER: boolean;
  DEFAULT_ODD_ACCEPT: string;
  CURRENT_COUNTRY_CODE: string;
  ODD_TYPE: Array<string>;
  DEFAULT_ODD_TYPE: string;
  OPEN_BETS_BY_DEFAULT: number;
  PREDEFINE_STAKES_ENABLE: boolean;
  PREDEFINE_STAKES_ADDING: boolean;
  PREDEFINE_STAKE_1: number;
  PREDEFINE_STAKE_2: number;
  PREDEFINE_STAKE_3: number;
  IS_PARTIAL_CASHOUT_AVAILABLE: boolean;
  IS_ALLOW_BONUS_BALANCE_SUPERBET: boolean;
  IFRAME_SPORTSBOOK: boolean;
  HOMEWORK_ENABLED: boolean;
  SHOW_SITE_NAME_IN_TITLE: number;
  SPORT_TOURNAMENTS: boolean;
  EXTERNAL_PAYMENT_URL: string;
  DEFAULT_STAKE_ENABLED: boolean;
  DEFAULT_STAKE: number;
  ROCKET_BET: boolean;
  LVISION: boolean;
  LVISION_API_KEY: string;
  PERSONALIZED_SPORTS: boolean;
  SPORTS_GROUPING: number;
  NEM_ID_INTEGRATION: number;
  NEM_ID_INTEGRATION_URL: string;
  LEFT_SIDE_BAR: boolean;
  LEFT_SIDE_BAR_SPORTS: boolean;
  LEFT_SIDE_BAR_CASINO: boolean;
  SUPER_BET_DEFAULT_ON: boolean;
  COUPON_BUILDER_SWITCHER: boolean;
  PICTURE_IN_PICTURE_STREAMING: boolean;
  AFTER_SUCCESSFUL_BET: EAfterSuccessfulBet;
  DOC_UPLOAD_TRU_NARRATIVE?: boolean;
  LIVE_PREMATCH_SWITCHER?: boolean;
  TERMINAL_MODE: boolean;
  CHAIN_TYPE: boolean;

  /** BETSLIP CONFIGS FROM JURISDICTIONS **/

  CALCULATE_BETS_WITHOUT_TAXES?: boolean;
  SHOULD_HIDE_BET_TYPE_CHECKER?: boolean;
  HIDE_BET_BUILDER?: boolean;
  DISABLE_ONLY_CYPRUS_MATCHES_BET?: boolean;

  /** GENERIC CONFIGS FROM JURISDICTIONS **/

  SHOW_REALITY_CHECK?: boolean;
  SHOW_GAMBLING_AREAS?: boolean;
  IS_TWO_WALLET_ACTIVE?: boolean;
  IS_NEM_ID_ENABLED?: boolean;
  SHOW_WITHDRAWAL_INFORMATION?: boolean;
  IS_BANK_ID_INTEGRATION_ENABLED?: boolean;
  USE_AMERICAN_DATE_FORMATTING?: boolean;
  USE_CA_USA_DATE_FORMATTING?: boolean;
  HOORY_ENABLED: boolean;
  HOORY_ENABLED_SPORTSBOOK_BETSLIP: boolean;
};

export type TAtoZMenuConfigs = {
  fit: string;
  aToZRacingSportsList: ERacingSportSideBarTabs[];
  languages: {
    prefix: string;
    title: string;
    region: string;
    language: string;
    urlPrefix: string;
  }[];
  showLangSwitcher: boolean;
  selectCurrentPage: boolean;
  categoryIds: string;
  moduleId: string;
  type: string;
  category: string;
  limit: number;
  casinoGamesLimit: number;
  showCategoryName: number;
  outRightEnabledAToZ: boolean;
  todayEventsEnabledAToZ: boolean;
  boostedOddsEnabledAToZ: boolean;
  multiplesEnabledAToZ: boolean;
  sliderArrowsAToZ: boolean;
  couponBuilderAToZ: boolean;
  BBAToZ: boolean;
  topMatchesEnabledAToZ?: boolean;
  olympicCompetitionEnabledAtoZ?: boolean;
};

export type AToZData = {
  category?: string;
  iconUrl?: string | null;
  id: number | string;
  order?: number;
  orderBottomNav?: number;
  path: { [key: string]: string } | string;
  showBottomNav?: boolean;
  showOnAtoZ: boolean;
  title: { [key: string]: string } | string;
  type?: string;
  icon?: null | string;
  showEvery?: boolean;
  onlyBottomNav?: boolean;
  badge?: boolean;
  inCasinoMenu?: boolean;
  inSportMenu?: boolean;
  menuCasinoOrdering?: number;
  menuSportsOrdering?: number;
  target?: string;
  customPageCodeEnable?: string;
  customPageCode?: string;
  customPageTitle?: string;
  customPageLogo?: string;
  categoryBottom?: string;
};

export type MenuItem = {
  id: number | string;
  alias: string;
  name: string;
  gameCount?: number;
  game?: number;
  showInLive?: boolean;
  order?: number;
  afterIconIsRendered?: boolean;
};

export type TShowNoItemWrapper = {
  game?: boolean;
  competitions?: boolean;
  regions?: boolean;
};

export type TCurrentOdd = {
  sport: string;
  region: string;
  competition: string | number;
  game: number;
  category?: string;
};

export type THomeworkData = {
  answers: number;
  finalText: string;
  id: number;
  infoKey: string;
  infoUrl: string;
  language: string;
  openPopUp: boolean;
  tasks: number;
  type: number;
};

export type TSortingTypes = 'asc' | 'desc';

export interface ICustomMenuItem {
  key: number | string;
  value: string;
  icon?: ReactElement;
  count?: number;
  skeleton?: boolean;
}

export enum EFavoriteTabsTypes {
  ALL = 'all',
  SPORTSBOOK = '1',
  CASINO = '2'
}

export type FTNScan = {
  eventName?: string;
  blockNumber?: number;
  timeStamp: number;
  transactionHash: string;
  address?: string;
  name: string;
  amount: number;
}[];

export enum AppendCustomCodes {
  APPEND_CUSTOM_CODE_ON_REG_POPUP = 'appendCustomCodeRegistrationPopupOpen',
  CUSTOM_CODE_ON_REG_POPUP = 'customCodeRegistrationPopupOpen',
  APPEND_CUSTOM_CODE_LOGIN = 'appendCustomCodeLogin',
  CUSTOM_CODE_LOGIN = 'customCodeLogin',
  APPEND_CUSTOM_CODE_LOGOUT = 'appendCustomCodeLogout',
  CUSTOM_CODE_LOGOUT = 'customCodeLogout',
  APPEND_CUSTOM_CODE_REG = 'appendCustomCodeRegistration',
  CUSTOM_CODE_REG = 'customCodeRegistration',
  APPEND_CUSTOM_CODE_DEPOSIT = 'appendCustomCodeDeposit',
  CUSTOM_CODE_DEPOSIT = 'customCodeDeposit',
  APPEND_CUSTOM_CODE_BETSLIP = 'betslipAppendCustomCodeActivity',
  CUSTOM_CODE_BETSLIP = 'betslipCustomCode',
  APPEND_CUSTOM_CODE_FIRST_DEPOSIT = 'appendCustomCodeFirstDeposit',
  CUSTOM_CODE_FIRST_DEPOSIT = 'customCodeFirstDeposit',
  APPEND_CUSTOM_CODE_DEPOSIT_FAIL = 'appendCustomFailedDeposit',
  CUSTOM_CODE_DEPOSIT_FAIL = 'customFailedDeposit',
  APPEND_CUSTOM_CODE_LOGIN_FAIL = 'appendCustomFailedLogin',
  CUSTOM_CODE_LOGIN_FAIL = 'customFailedLogin',
  APPEND_CUSTOM_CODE_REGISTER_FAIL = 'appendCustomCodeRegistrationWithErrors',
  CUSTOM_CODE_REGISTER_FAIL = 'customCodeRegistrationWithErrors',
  APPEND_CUSTOM_CODE_RESTORE_LOGIN = 'appendCustomCodeRestoreLogin',
  CUSTOM_CODE_RESTORE_LOGIN = 'customCodeRestoreLogin',
  APPEND_CUSTOM_CODE_WITHDRAW = 'appendCustomCodeWithdraw',
  CUSTOM_CODE_WITHDRAW = 'customCodeWithdraw',
  APPEND_CUSTOM_PROFILE_UPDATE = 'appendCustomCodeProfileUpdate',
  CUSTOM_CODE_PROFILE_UPDATE = 'customCodeProfileUpdate',
  APPEND_CUSTOM_EMAIL_CONFIRM = 'appendCustomCodeAfterEmailConfirmation',
  CUSTOM_CODE_EMAIL_CONFIRM = 'customCodeAfterEmailConfirmation',
  APPEND_CUSTOM_TIME_OUT = 'appendCustomCodeAfterSuccessfulTimeOut',
  CUSTOM_CODE_TIME_OUT = 'customCodeAfterSuccessfulTimeOut',
  APPEND_CUSTOM_SELF_EXC = 'appendCustomCodeSelfExclusion',
  CUSTOM_CODE_SELF_EXC = 'customCodeSelfExclusion',
  APPEND_CUSTOM_ON_DEPOSIT_PAGE = 'appendCustomCodeOnDepositPage',
  CUSTOM_CODE_ON_DEPOSIT_PAGE = 'customCodeOnDepositPage'
}

export type AccountParamsType = Record<string, AppendCustomCodes> | undefined;

export type JweResponse = Omit<ResponseGeneric, 'details'> & {
  details: {
    jwe: string;
  };
};
