import { addPropertyToObjectWithLimit } from 'utils/helpers';
import { isMobile } from 'utils/is-mobile';
import {
  getSelectedCompetitionsFromLS,
  setSelectedCompetitionsInLS
} from 'utils/sportsbook/set-selected-competitions-in-LS';
import RidGenerator from 'utils/swarm/rid-generator';
import { ActionType, TShowNoItemWrapper } from 'interfaces/generic';
import { TMatchStatistic } from 'interfaces/match-statistic';
import {
  TAdvancedSelectedMarkets,
  TAdvancedSelectedRangeFilter,
  TGameMainInfoType,
  TGameMarketsInfoType,
  TSortingOption
} from 'interfaces/sportsbook';
import {
  EUcompetitionsFiltersType,
  Game,
  IMenuGroupWithSports,
  Market,
  Region,
  SelectedSport,
  Sport,
  SportOnly,
  TEUOddFilter,
  TUserSportOrder
} from 'interfaces/sportsbook-data-levels';
import { NewSportsbookActionTypes } from 'store/action-types/new-sportsbook';
import { createStateBasedOnSwarm } from 'store/reducers/game-data';

export type TGameDataWithMoreInfo = {
  competitionId: number;
  competitionName: string;
  game: Game;
  markets: Market[];
  regionName: string;
  regionAlias: string;
  sportAlias: string;
  sportName: string;
};

type TInitialData = {
  sportsData: SportOnly[] | null;
  sportsGroupingData: {
    prematch: IMenuGroupWithSports[] | null;
    live: IMenuGroupWithSports[] | null;
  };
  sportRegionCompData: Record<string, Sport> | null;
  selectedCompetitions: Record<string, Array<number>>;
  gamesByCompetitions: Sport | null;
  euCompetitionsFilters: EUcompetitionsFiltersType;
  euOddFilter: TEUOddFilter;
  swarmResponse: Record<string, Record<string, Sport>> | null;
  regionCompData: Record<string, Record<string, Region>> | null;
  gamesSingleViewData: Record<string, TGameDataWithMoreInfo> | null;
  games: Record<string, Record<string, Sport>>;
  marketsCount: Record<string, Record<string, number>>;
  myBetsOpen: boolean;
  hasSportStreamingGame: boolean;
  showStreamingGamesOnly: boolean;
  userSportOrder: null | Record<string, TUserSportOrder>;
  gamesSortingOption: TSortingOption;
  matchStatistics: Record<string, TMatchStatistic>;
  showNoItemWrapper: TShowNoItemWrapper;
  selectedSport: SelectedSport | null;
  navigationWrapperHeightChangedTrigger: string;
  advancedTempCompetitionIds: string[];
  advancedSportsForMarketsTypes: string[];
  advancedEventsLayoutGameIds: Record<string, Record<string, number[]>>;
  advancedGamesMainInfo: Record<
    string,
    Record<string, TGameMainInfoType>
  > | null;
  advancedGamesMarketsInfo: Record<
    string,
    Record<string, { market: TGameMarketsInfoType }>
  > | null;
  advancedSelectedMarkets: Record<string, TAdvancedSelectedMarkets> | null;
  advancedEventsLayoutSports: Record<string, Sport[]> | null;
  advancedSelectedRangeFilter: TAdvancedSelectedRangeFilter | null;
  advancedLiveSports: Record<string, Sport> | null;
  advancedLiveGames: Record<string, Record<string, Region>> | null;
};
const REGION_COMP_DATA_LIMIT = 5;
const SWARM_RESPONSE_LIMIT = 5;

export const filtersDefaultValues: EUcompetitionsFiltersType = {
  marketType: 'main',
  dateRange: {
    startDate: 0,
    endDate: 0
  },
  sortBy: 'time'
};

export const advancedSelectedMarketsInitialState: TAdvancedSelectedMarkets = {
  first: '',
  second: '',
  third: ''
};

const initialData: TInitialData = {
  sportsData: null,
  sportsGroupingData: {
    prematch: null,
    live: null
  },
  sportRegionCompData: null,
  selectedCompetitions: getSelectedCompetitionsFromLS(),
  gamesByCompetitions: null,
  euCompetitionsFilters: filtersDefaultValues,
  euOddFilter: 'all',
  swarmResponse: null,
  regionCompData: null,
  gamesSingleViewData: null,
  games: {},
  marketsCount: {},
  myBetsOpen: false,
  hasSportStreamingGame: false,
  showStreamingGamesOnly: false,
  userSportOrder: null,
  gamesSortingOption: { name: 'default', key: '', type: 'asc' },
  matchStatistics: {},
  showNoItemWrapper: {
    game: false,
    competitions: false,
    regions: false
  },
  selectedSport: null,
  navigationWrapperHeightChangedTrigger: '',
  advancedTempCompetitionIds: [],
  advancedSportsForMarketsTypes: [],
  advancedEventsLayoutGameIds: {},
  advancedGamesMainInfo: null,
  advancedGamesMarketsInfo: null,
  advancedSelectedMarkets: null,
  advancedEventsLayoutSports: null,
  advancedSelectedRangeFilter: null,
  advancedLiveSports: null,
  advancedLiveGames: null
};

export const newSportsbook = (
  state = initialData,
  action: ActionType
): TInitialData => {
  switch (action.type) {
    case NewSportsbookActionTypes.SET_SPORTS_DATA:
      return {
        ...state,
        sportsData: action.payload
      };

    case NewSportsbookActionTypes.SET_SPORTS_GROUPING_DATA:
      if (
        state.sportsGroupingData[
          Object.keys(
            action.payload
          )[0] as keyof typeof state.sportsGroupingData
        ]
      ) {
        const key = Object.keys(action.payload)[0];

        return {
          ...state,
          sportsGroupingData: {
            ...state.sportsGroupingData,
            [key]: action.payload[key]?.map((group: IMenuGroupWithSports) => ({
              ...group,
              isOpened: !!state.sportsGroupingData[
                key as keyof typeof state.sportsGroupingData
              ]?.find(prevGroup => prevGroup.id === group.id)?.isOpened
            }))
          }
        };
      } else {
        const key = Object.keys(action.payload)[0];

        return {
          ...state,
          sportsGroupingData: {
            ...state.sportsGroupingData,
            [key as keyof typeof state.sportsGroupingData]: action.payload[key]
          }
        };
      }

    case NewSportsbookActionTypes.TOGGLE_GROUP_ITEM:
      return {
        ...state,
        sportsGroupingData: {
          ...state.sportsGroupingData,
          [action.payload.type as keyof typeof state.sportsGroupingData]:
            state.sportsGroupingData[
              action.payload.type as keyof typeof state.sportsGroupingData
            ]?.map(g =>
              g.id === action.payload.id ? { ...g, isOpened: !g.isOpened } : g
            ) || null
        }
      };

    case NewSportsbookActionTypes.SET_SPORT_REGION_COMP_DATA:
      return {
        ...state,
        sportRegionCompData: action.payload
          ? action.payload.replaceData
            ? action.payload.data
            : addPropertyToObjectWithLimit(
                { ...state.sportRegionCompData } || ({} as Sport),
                action.payload.data,
                REGION_COMP_DATA_LIMIT
              )
          : null
      };

    case NewSportsbookActionTypes.SET_REGION_COMP_DATA:
      return {
        ...state,
        regionCompData: action.payload
          ? action.payload.replaceData
            ? action.payload.data
            : addPropertyToObjectWithLimit(
                { ...state.regionCompData } || ({} as Region),
                action.payload.data,
                REGION_COMP_DATA_LIMIT
              )
          : null
      };

    case NewSportsbookActionTypes.SET_SELECTED_COMPETITIONS: {
      const selectedCompetitions = {
        ...state.selectedCompetitions,
        [action.payload.key]: action.payload.data
      };

      setSelectedCompetitionsInLS(JSON.stringify(selectedCompetitions));

      return {
        ...state,
        selectedCompetitions: selectedCompetitions
      };
    }

    case NewSportsbookActionTypes.SET_GAMES_BY_COMPETITIONS_DATA:
      return {
        ...state,
        gamesByCompetitions: action.payload
      };

    case NewSportsbookActionTypes.SET_EU_COMPETITIONS_FILTERS:
      return {
        ...state,
        euCompetitionsFilters: {
          ...state.euCompetitionsFilters,
          ...action.payload
        }
      };

    case NewSportsbookActionTypes.SET_EU_ODD_FILTER:
      return {
        ...state,
        euOddFilter: action.payload
      };

    case NewSportsbookActionTypes.SET_USER_SPORT_ORDER:
      return {
        ...state,
        userSportOrder: action.payload
      };

    case NewSportsbookActionTypes.SET_SWARM_RESPONSE:
      return {
        ...state,
        swarmResponse: addPropertyToObjectWithLimit(
          { ...state.swarmResponse } || ({} as Sport),
          action.payload,
          SWARM_RESPONSE_LIMIT
        )
      };

    case NewSportsbookActionTypes.SET_SINGLE_GAME_DATA:
      return {
        ...state,
        gamesSingleViewData: {
          ...state.gamesSingleViewData,
          [action.payload.id]: createStateBasedOnSwarm(action.payload.data)
        }
      };

    case NewSportsbookActionTypes.SET_GAMES:
      return {
        ...state,
        games: action.payload ? { ...state.games, ...action.payload } : {}
      };

    case NewSportsbookActionTypes.SET_MARKETS_COUNT:
      return {
        ...state,
        marketsCount: { ...state.marketsCount, ...action.payload }
      };

    case NewSportsbookActionTypes.SET_MATCH_STATISTICS:
      return {
        ...state,
        matchStatistics: { ...state.matchStatistics, ...action.payload }
      };

    case NewSportsbookActionTypes.SET_MY_BETS_OPEN:
      return {
        ...state,
        myBetsOpen: action.payload
      };

    case NewSportsbookActionTypes.SET_HAS_SPORT_STREAMING_GAME:
      return {
        ...state,
        hasSportStreamingGame: action.payload
      };

    case NewSportsbookActionTypes.SET_SHOW_STREAMING_GAMES_ONLY:
      return {
        ...state,
        showStreamingGamesOnly: action.payload
      };

    case NewSportsbookActionTypes.SET_GAMES_SORTING_OPTION:
      return {
        ...state,
        gamesSortingOption: action.payload
      };

    case NewSportsbookActionTypes.SET_SHOW_NO_ITEM_WRAPPER:
      return {
        ...state,
        showNoItemWrapper: action.payload
      };

    case NewSportsbookActionTypes.SET_SPORTSBOOK_SELECTED_SPORT:
      return {
        ...state,
        selectedSport: action.payload
      };

    case NewSportsbookActionTypes.SET_ADVANCED_TEMP_COMPETITION_IDS:
      return {
        ...state,
        advancedTempCompetitionIds: action.payload
      };

    case NewSportsbookActionTypes.SET_ADVANCED_SPORTS_FOR_MARKETS_TYPES: {
      const sportsSet = new Set([
        ...state.advancedSportsForMarketsTypes,
        action.payload
      ]);

      return {
        ...state,
        advancedSportsForMarketsTypes: Array.from(sportsSet)
      };
    }

    case NewSportsbookActionTypes.SET_ADVANCED_EVENTS_LAYOUT_GAME_IDS: {
      const { data, key } = action.payload;

      const stateByKey = state.advancedEventsLayoutGameIds[key];

      return {
        ...state,
        advancedEventsLayoutGameIds: {
          ...state.advancedEventsLayoutGameIds,
          [key]: {
            ...stateByKey,
            ...data
          }
        }
      };
    }

    case NewSportsbookActionTypes.SET_ADVANCED_GAMES_MAIN_INFO: {
      const { data, key } = action.payload;
      const stateByKey = state.advancedGamesMainInfo?.[key] || {};

      return {
        ...state,
        advancedGamesMainInfo: {
          ...state.advancedGamesMainInfo,
          [key]: {
            ...stateByKey,
            ...data
          }
        }
      };
    }

    case NewSportsbookActionTypes.SET_ADVANCED_GAMES_MARKETS_INFO: {
      const { data, key } = action.payload;
      const stateByKey = state.advancedGamesMarketsInfo?.[key] || {};

      return {
        ...state,
        advancedGamesMarketsInfo: {
          ...state.advancedGamesMarketsInfo,
          [key]: {
            ...stateByKey,
            ...data
          }
        }
      };
    }

    // selected markets logic is based on reference equality of initial state, when changing anything here please check comparisons with advancedSelectedMarketsInitialState in project
    case NewSportsbookActionTypes.SET_ADVANCED_SELECTED_MARKETS: {
      const { data, key } = action.payload;
      const stateByKey = state.advancedSelectedMarkets?.[key] || {};

      return {
        ...state,
        advancedSelectedMarkets: {
          ...state.advancedSelectedMarkets,
          [key]:
            data === advancedSelectedMarketsInitialState
              ? data
              : {
                  ...stateByKey,
                  ...(isMobile() ? { first: data.first } : data)
                }
        }
      };
    }

    case NewSportsbookActionTypes.SET_ADVANCED_EVENTS_LAYOUT_SPORTS: {
      const { data, key } = action.payload;

      return {
        ...state,
        advancedEventsLayoutSports: {
          ...state.advancedEventsLayoutSports,
          [key]: data
        }
      };
    }

    case NewSportsbookActionTypes.SET_ADVANCED_SELECTED_RANGE_FILTER:
      return {
        ...state,
        advancedSelectedRangeFilter: action.payload
      };

    case NewSportsbookActionTypes.SET_ADVANCED_LIVE_SPORTS:
      return {
        ...state,
        advancedLiveSports: action.payload
      };

    case NewSportsbookActionTypes.SET_ADVANCED_LIVE_GAMES:
      return {
        ...state,
        advancedLiveGames: {
          ...state.advancedLiveGames,
          ...action.payload
        }
      };
    case NewSportsbookActionTypes.SET_NAVIGATION_WRAPPER_HEIGHT_CHANGED:
      return {
        ...state,
        navigationWrapperHeightChangedTrigger: RidGenerator.rand()
      };

    default: {
      return state;
    }
  }
};
