import { ActionType } from 'interfaces/generic';
import { SpecElemsActionTypes } from 'store/action-types/spec-elems';

type InitialData = {
  betslip: boolean;
};

const initialData: InitialData = {
  betslip: false
};

export const specElems = (
  state = initialData,
  action: ActionType
): InitialData => {
  switch (action.type) {
    case SpecElemsActionTypes.SET_ELEMENT_PRESENT:
      return {
        ...state,
        [action.payload as keyof InitialData]: true
      };

    case SpecElemsActionTypes.SET_ELEMENT_ABSENT:
      if (state[action.payload as keyof InitialData]) {
        return {
          ...state,
          [action.payload as keyof InitialData]: false
        };
      }

      return state;

    case SpecElemsActionTypes.RESET_ALL_ELEMENTS:
      return initialData;

    default: {
      return state;
    }
  }
};
