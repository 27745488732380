export const SMS_VERIFICATION_TYPE = 1;
export const EMAIL_VERIFICATION_TYPE = 2;

export const VERIFIED_OBJECT: Record<string, Record<string, string>> = {
  1: {
    name: 'sms',
    title: 'account.bySms',
    value: 'SMS'
  },
  2: {
    name: 'email',
    title: 'account.byEmail',
    value: 'EMAIL'
  }
};

export type TResponse = {
  code: number;
  data: { status: number };
  details?: {};
};
