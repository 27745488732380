export enum EDataspotAction {
  BurgerMenu = 'burger-menu',
  ChangeAnyOfSettings = 'change-any-settings',
  ClickedLiveChatButton = 'clicked-livechat',
  ClickedBanner = 'clicked-banner',
  ClickedMenuItem = 'clicked-menu-item',
  Login = 'login',
  LoginSubmit = 'login-submit',
  LoginFailed = 'login-failed',
  Registration = 'register',
  RegistrationSubmit = ' register-submit',
  RegisterSuccess = 'register-success',
  RegisterFail = 'register-failed',
  ForgotPassword = 'clicked-forgot-password',
  SportsStatistics = 'sport-statistics',
  AddingEventToBetslip = 'add-to-betslip',
  PlaceBetButton = 'bet-now',
  Results = 'results',
  PlacedExpressOfTheDayBet = 'placed-express-day-bet',
  VisibleRecommendation = 'visible-recommendation',
  CasinoGameSelection = 'select-casino-game',
  LiveCasinoGameSelection = 'select-live-casino-game',
  ClickedFeaturedGame = 'clicked-featured-game',
  ClickedCasinoRecommendedGame = 'clicked-casino-recommended-game',
  BonusClaim = 'claim-bonus',
  SportBonus = 'sport-bonus',
  CasinoBonus = 'casino-bonus',
  BonusHistory = 'bonus-history',
  PromocodeApply = 'promo-code',
  PromocodeSuccess = 'promo-code-success',
  PromocodeFailed = 'promo-code-failed',
  CasinoFreeSpins = 'casino-free-spins',
  LoyaltyPoints = 'loyalty-points',
  ExchangeShop = 'exchange-shop',
  ExchangeShopHistory = 'exchange-shop-history',
  UploadDocument = 'upload-document'
}

export const dataspotFunnelAction = (action: EDataspotAction) => {
  window.__dataspotPromise__?.then(() => {
    window.__dataspot__?.funnelAction?.({ action });
  });
};
