import { getType } from 'utils/betslip/betslip';
import { BetSlipEventObj } from 'interfaces/bet-data';
import { EBetslipBetType, EBetslipType } from 'interfaces/betslip';
import { calculateUpdatesByBetType } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/calculateUpdatesByBetType';
import { dispatchBetslipBetType } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/dispatchBetslipBetType';
import { filterCalculatedItems } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/utils/filterCalculatedItems';
import store from 'store';
import { setAllLsBets } from 'store/actions';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';

const MIN_BETS_COUNT_FOR_SYSTEM_BET_TYPE = 3;

/**
 *
 * this function handles some cases based on bets count change
 *
 *
 * Attention!! don't change the functions calling order
 * */
export const dispatchBetslipAllBets = (bets: BetSlipEventObj[]) => {
  const betslipStore = store.getState()
    ?.betSlip as IBetslipRootStateInitialData;

  store.dispatch(setAllLsBets(bets));

  //@Todo  handleCounter offer case here to
  const prevType =
    betslipStore.betslipType === EBetslipType.BASIC
      ? getType(betslipStore.type)
      : EBetslipBetType.SINGLE;

  const isBetAdded =
    betslipStore.allLsBets.length === 1 &&
    betslipStore.allLsBets.length < bets.length;

  let updatedType = prevType;

  const isSystemTypeIsNoptActual =
    bets.length < MIN_BETS_COUNT_FOR_SYSTEM_BET_TYPE &&
    prevType === EBetslipBetType.SYSTEM;

  const isShouldBeSingleType =
    bets.length <= 1 && prevType !== EBetslipBetType.SINGLE;

  if (isShouldBeSingleType) {
    updatedType = EBetslipBetType.SINGLE;
  } else if (isSystemTypeIsNoptActual || isBetAdded) {
    updatedType = EBetslipBetType.MULTIPLE;
  }

  if (prevType !== updatedType) {
    dispatchBetslipBetType(updatedType);
  } else {
    calculateUpdatesByBetType(updatedType, prevType);
  }

  filterCalculatedItems(bets);
};
