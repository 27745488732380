import { divide, multiply } from 'utils/generic/calculator';

type TCreatePercentCalculator = (percent: number) => TPercentCalculator;

type TPercentCalculator = (amount: number) => ReturnType<TCalcPercentOf>;

type TCalcPercentOf = (amount: number, percent: number) => number;

export const PERCENTAGE_TO_DIVIDE_TO_CALC_TAX = 100;

export const calcPercentOf: TCalcPercentOf = (amount = 0, percent = 0) =>
  divide(multiply(amount, percent), PERCENTAGE_TO_DIVIDE_TO_CALC_TAX);

export const createPercentCalculator: TCreatePercentCalculator =
  (percent = 0) =>
  (amount = 0) =>
    calcPercentOf(amount, percent);
