export const ExclusionTypes = {
  TIMEOUT: 1,
  SELF_EXCLUSION: 2,
  SPORTSBOOK_TEMPORARY_BLOCK: 3,
  SPORTSBOOK_BLOCK_FOR_ACCOUNT_CLOSURE: 4,
  ACCOUNT_CLOSURE: 5,
  BETTING_BLOCK: 6
};

export const ExclusionTypesIT = {
  SELF_EXCLUSION: 2,
  ACCOUNT_CLOSURE: 5
};
