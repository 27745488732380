import {
  getAndRemoveMustOpenBetslipFromLS,
  getBetslipType
} from 'utils/betslip/betslip';
import { EAdvancedSelectedTabs, EBetslipPage } from 'interfaces/bet-data';
import {
  EBetslipBetType,
  EBetslipType,
  TabObject,
  TTaxesState
} from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export interface IBetslipConfigsSliceInitialData {
  tabs: TabObject[];
  mustOpen: boolean;
  mustClose: boolean;
  taxes: TTaxesState;
  betslipOpen: boolean;
  ladderLoaded: boolean;
  betslipType: EBetslipType;
  freezeTabChange?: boolean;
  type: EBetslipBetType;
  activeTabPage?: EBetslipPage;
  singleQuickBetslipOpen: boolean;
  advancedSelectedTab: EAdvancedSelectedTabs;
}

export const betslipConfigsSliceInitialData: IBetslipConfigsSliceInitialData = {
  tabs: [],
  taxes: {},
  mustClose: false,
  betslipOpen: false,
  ladderLoaded: false,
  betslipType: getBetslipType(),
  singleQuickBetslipOpen: false,
  type: EBetslipBetType.SINGLE, //getBetslipTypeLs() as EBetslipBetType,
  mustOpen: getAndRemoveMustOpenBetslipFromLS(),
  advancedSelectedTab: EAdvancedSelectedTabs.QUICKBET
};

export const betslipConfigsSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: ActionType
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.LADDER_LOADED:
      return {
        ...state,
        ladderLoaded: true
      };

    case BetslipActionTypes.SET_TYPE:
      return {
        ...state,
        type: action.payload
      };

    case BetslipActionTypes.SET_MUST_OPEN:
      return {
        ...state,
        mustOpen: action.payload
      };

    case BetslipActionTypes.SET_MUST_CLOSE:
      return {
        ...state,
        mustClose: action.payload
      };

    case BetslipActionTypes.UPDATE_BETSLIP_TYPE:
      return {
        ...state,
        betslipType: action.payload
      };

    case BetslipActionTypes.SET_ACTIVE_TAB_PAGE:
      return {
        ...state,
        activeTabPage: action.payload
      };

    case BetslipActionTypes.FREEZE_TAB_CHANGE:
      return {
        ...state,
        freezeTabChange: action.payload
      };

    case BetslipActionTypes.SET_BETSLIP_OPEN:
      return {
        ...state,
        betslipOpen: action.payload ?? !state.betslipOpen
      };

    case BetslipActionTypes.SET_SINGLE_QUICK_BETSLIP_OPEN:
      return {
        ...state,
        singleQuickBetslipOpen: action.payload
      };

    case BetslipActionTypes.SET_TABS:
      return {
        ...state,
        tabs: action.payload
      };

    case BetslipActionTypes.SET_TAXES_DATA:
      return {
        ...state,
        taxes: action.payload
      };

    case BetslipActionTypes.SET_ADVANCED_SELECTED_TAB:
      return {
        ...state,
        advancedSelectedTab: action.payload
      };
  }

  return null;
};
