import {
  CALCULATE_POW_OF_10,
  MAX_DECIMALS_TO_APPLY_ROUNDING,
  POINT_TO_APPLY_ROUNDING
} from 'newelements/_Betslip/utils/constants/calculations';
import Store from 'store';

/*
 * source: https://stackoverflow.com/questions/3108986
 *
 * Rules
 *
 * 1. If the fractional part of the number is less than 0.5, the number is rounded down.
 * 2. If the fractional part of the number is greater than 0.5, the number is rounded up.
 * 3. If the fractional part of the number is exactly 0.5, the number is rounded to the nearest even whole number.
 *
 * @constructor
 * @param {number} amount - the amount witch need to round
 * @param {number} decimals - symbols count to skip before apply rounding
 *
 * */

export const bankersRounding = (
  amount: number,
  _decimals: number | null = null
) => {
  const decimals = _decimals ?? Store.getState().appData.rounding;
  const m = CALCULATE_POW_OF_10 ** decimals;
  const n = +(decimals ? amount * m : amount).toFixed(
    MAX_DECIMALS_TO_APPLY_ROUNDING
  );

  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8;
  const r =
    f > POINT_TO_APPLY_ROUNDING - e && f < POINT_TO_APPLY_ROUNDING + e
      ? i % 2 === 0
        ? i
        : i + 1
      : Math.round(n);

  return decimals ? r / m : r;
};
