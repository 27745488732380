import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { saveGameUrlOnLocalStorage } from 'utils/sportsbook/match-streaming';
import { ActionType } from 'interfaces/generic';
import {
  EMatchStreamingTypes,
  EMatchStreamingViewModes,
  IMatchStreamingState,
  TMatchStreamingSettings
} from 'interfaces/match-streaming';
import { MatchStreamingActionTypes } from 'store/action-types/match-streaming';

const initialData: IMatchStreamingState = {
  containerInfo: null,

  streamUrls: {
    hlsUrl: null,
    discard: false,
    loading: false,
    iframeUrl: null,
    streamUrlFetchError: null
  },

  isVisible: false,

  isCanFixOnTop: true,
  activeChannel: null,
  liveStreamInfo: null,

  streamSettings: {
    muted: true,
    fullScreen: false,
    applyChannels: false,
    pictureInPicture: false
  },

  streamingChannelInfo: null,

  // 0, 1, 2, 3
  streamingType: EMatchStreamingTypes.MATCH,
  availableStreamingTypes: [],

  streamingTypeDisable: {
    previous: 0,
    disable: false
  },

  gamePreviewNotAvailable: false,
  isChannelsAvailable: false,
  isTheatreModeAvailable: false,
  isTheatreMode: false,
  isTheatreModePrev: false,
  isLandscape: false,
  isHolderPositionApplied: false,

  viewMode: EMatchStreamingViewModes.normal,
  prevViewMode: EMatchStreamingViewModes.normal,

  data: null,

  isPreviewUnavailable: false
};

export const matchStreaming = (
  state = initialData,
  action: ActionType
): IMatchStreamingState => {
  switch (action.type) {
    case MatchStreamingActionTypes.SET_MATCH_STREAMING_GAME_DATA: {
      const draft = structuredClone(state);
      draft.isVisible = true;

      if (
        (state.data?.game.id &&
          action.payload?.game.id &&
          state.data?.game.id !== action.payload?.game.id) ||
        !action.payload?.game.id
      ) {
        draft.isCanFixOnTop = true;
        draft.streamUrls = null;
        draft.liveStreamInfo = initialData.liveStreamInfo;
        draft.streamingChannelInfo = initialData.streamingChannelInfo;
      }

      saveGameUrlOnLocalStorage();
      draft.data = { ...state.data, ...action.payload };
      draft.prevViewMode = state.isTheatreMode
        ? EMatchStreamingViewModes.normal
        : state.viewMode;
      draft.viewMode = EMatchStreamingViewModes.normal;

      return draft;
    }

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_MODE:
      return {
        ...state,
        prevViewMode: state.viewMode,
        viewMode: action.payload
      };

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_CONTAINER_INFO:
      return {
        ...state,
        containerInfo: action.payload
      };

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_MOUNTING_INFO:
      return {
        ...state,
        isHolderPositionApplied: action.payload
      };

    case MatchStreamingActionTypes.SET_IS_CAN_FIX_MS_ON_TOP:
      return {
        ...state,
        isCanFixOnTop: action.payload
      };

    case MatchStreamingActionTypes.SET_IS_STREAMER_LANDSCAPE:
      return {
        ...state,
        isLandscape: action.payload
      };

    case MatchStreamingActionTypes.RESET_MATCH_STREAMING_STATE: {
      const draft = structuredClone(state);

      if (
        !state.isChannelsAvailable &&
        (action.payload.ignoreMinimizedState ||
          state.viewMode !== EMatchStreamingViewModes.minimized)
      ) {
        if (
          !action.payload.gameId ||
          action.payload.gameId !== state.data?.game.id
        ) {
          draft.data = null;
          draft.isVisible = false;
          draft.isCanFixOnTop = true;
          draft.isTheatreMode = false;
          draft.isTheatreModePrev = false;
          draft.prevViewMode = state.viewMode;
          draft.streamUrls = initialData.streamUrls;
          draft.liveStreamInfo = initialData.liveStreamInfo;
          draft.streamingChannelInfo = initialData.streamingChannelInfo;
        }

        draft.viewMode = EMatchStreamingViewModes.normal;
      }

      return draft;
    }

    case MatchStreamingActionTypes.SET_LIVE_STREAM_INFO: {
      const draft = structuredClone(state);

      if (!action.payload) {
        draft.streamUrls = initialData.streamUrls;
      }

      draft.liveStreamInfo = action.payload;

      return draft;
    }

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_CHANNELS_INFO: {
      const draft = structuredClone(state);

      if (
        JSON.stringify(state.streamingChannelInfo) !==
          JSON.stringify(action.payload) ||
        !action.payload
      ) {
        draft.streamUrls = state.streamUrls
          ? { ...(state.streamUrls || {}), loading: true }
          : null;
        draft.streamingChannelInfo = action.payload;
      }

      return draft;
    }

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_STREAM_URL: {
      const draft = structuredClone(state);

      if (!state.streamUrls?.discard) {
        draft.streamUrls = action.payload
          ? { ...(draft.streamUrls || {}), ...action.payload }
          : null;
      } else {
        draft.streamUrls = initialData.streamUrls;
      }

      return draft;
    }

    case MatchStreamingActionTypes.TOGGLE_MATCH_STREAMING_SETTINGS:
      return {
        ...state,
        streamSettings: {
          ...state.streamSettings,
          [action.payload as keyof TMatchStreamingSettings]:
            !state.streamSettings[
              action.payload as keyof TMatchStreamingSettings
            ]
        }
      };

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_STREAM_TYPE:
      LocalStorage.setItem(
        storageKeyName('sportsbook', 'STREAM_ACTIVE_TAB_MODE'),
        `${action.payload}`
      );

      return {
        ...state,
        streamingType: action.payload
      };

    case MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_CHANNELS_AVAILABLE:
      return {
        ...state,
        isChannelsAvailable: action.payload
      };

    case MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_THEATRE_MODE_AVAILABLE:
      return {
        ...state,
        isTheatreModeAvailable: action.payload
      };

    case MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_HIDDEN:
      return {
        ...state,
        isVisible: action.payload
      };

    case MatchStreamingActionTypes.SET_IS_MATCH_STREAMING_PREVIEW_UNAVAILABLE:
      return {
        ...state,
        isPreviewUnavailable: action.payload
      };

    case MatchStreamingActionTypes.SET_DISABLE_MATCH_STREAMING_STREAM_TYPE:
      return {
        ...state,
        streamingTypeDisable: action.payload
      };

    case MatchStreamingActionTypes.SET_MATCH_STREAMING_THEATRE_MODE: {
      const draft = structuredClone(state);

      if (state.isTheatreMode !== action.payload) {
        draft.isTheatreModePrev = state.isTheatreMode;
      }

      draft.isTheatreMode = action.payload;

      return draft;
    }

    case MatchStreamingActionTypes.SET_GAME_PREVIEW_NOT_AVAILABLE: {
      return {
        ...state,
        gamePreviewNotAvailable: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
