// prettier-ignore
export const ResultsDataActionTypes = {
  SET_DATE: 'SET_DATE',
  SET_COMPETITION: 'SET_COMPETITION',
  SET_TEAM: 'SET_TEAM',
  SET_STATUS: 'SET_STATUS',
  SET_CURRENT_STATUS: 'SET_CURRENT_STATUS',
  SET_SPORT: 'SET_SPORT',
  SET_DATA: 'SET_DATA',
  SET_TRIGGER: 'SET_TRIGGER',
  SET_DATA_LOADING: 'SET_DATA_LOADING',
  RESET_FILTERS: 'RESET_FILTERS'
};
