import {
  ELEMENT_HEIGHT,
  ELEMENT_NAMES_FOR_CONTAINER_HEIGHT,
  ELEMENTS_WITH_SKELETON_FALLBACK
} from 'utils/constants/app/element-names-for-container-height';
import { FallbackContainer } from 'components/UI/Containers/FallbackContainer';

export const getWidgetFallback = (
  elementName: string,
  configs: Record<string, any> = {}
) => {
  if (elementName in ELEMENTS_WITH_SKELETON_FALLBACK) {
    const FallbackSkeleton = ELEMENTS_WITH_SKELETON_FALLBACK[elementName];

    return <FallbackSkeleton {...configs} />;
  }

  if (ELEMENT_NAMES_FOR_CONTAINER_HEIGHT.includes(elementName)) {
    return <FallbackContainer />;
  }

  if (elementName in ELEMENT_HEIGHT) {
    return <div style={{ minHeight: ELEMENT_HEIGHT[elementName] }}></div>;
  }

  return null;
};
