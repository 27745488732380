/* eslint-disable */
import { ValueOf } from 'interfaces/generic';

export function arrayFindBy<ArrayItemType extends Record<string, any>, IsTuple extends boolean>(
  arr: Array<ArrayItemType>, // array item must be of type any for generic purpose
  property: keyof ArrayItemType,
  value: ValueOf<ArrayItemType>, // value must be of type any for generic purpose
  tuple: IsTuple
): IsTuple extends false ? ArrayItemType : [ArrayItemType, number];

export function arrayFindBy(
  arr: any[], // array item must be of type any for generic purpose
  property: any,
  value: any, // value must be of type any for generic purpose
  tuple = false
): any {
  /* eslint-enable */
  const index = arr.findIndex(elem => elem[property] === value);

  if (tuple) {
    return [arr[index], index];
  } else {
    return arr[index];
  }
}
