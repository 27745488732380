import type { TBetslipCalculations } from 'store/reducers/betslip/calculations';

export const BETSLIP_AREA_ID = 'betslip-area';
export const BETSLIP_FOOTER_CONTAINER_ID = 'betslip-footer-container';

export const GENERAL_FIELD_KEY: keyof TBetslipCalculations = 'general';

// maybe need to change place
export const SELECT_OPTIONS_HEIGHT = 300;

export const SYSTEM_COMBINATIONS_SELECTOR_PORTAL_ID =
  'systemCombinationsPortal';

export const FIXED_FOOTER_ID_PORTAL_ID = 'fixed-footer';

export const FREE_BET_AMOUNT_SELECTOR_PORTAL_ID = 'freeBetAmountSelectorPortal';

export const SINGLE_CALCULATION_FIELDS_PORTAL_ID =
  'singleCalculationFieldsPortalId';
