import { ComponentType, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { THEME_PREFIX } from 'utils/constants/app/theme';
import {
  CASINO_ELEMENT_NAMES,
  NEED_MOUNTED_ONE_ELEMENTS
} from 'utils/constants/casino/casino-element-names';
import { timeoutDuration } from 'utils/constants/dateInfo/time-numbers';
import { getWidgetFallback } from 'utils/generic/calculate-widget-fallback';
import GLOBALS from 'utils/generic/global-variables';
import { lazy as lazyImport } from 'utils/generic/lazy';
import { configure } from './configure';
import { LocaleCalendar } from './configure/dayjs';
import { browserHistory } from './configure/history';
import { initI18nManual } from './configure/i18n';
import { reassignTranslationsForStore } from './configure/reassign-translations-for-store';
import { initSentry } from './configure/sentry';
import { isShowFloating } from './configure/utils';
import './global';
import { ThemeProvider } from './providers/ThemeProvider';
import store, { persistor } from './store';
import SpringConfigs from './utils/constants/swarm/spring-configs';
import './utils/globals';
import { Betslip } from 'newelements/_Betslip';
import { Portal } from 'components/Shared/Portal';
import { SubscriptionModal } from 'components/SubscriptionModal';
import { ElementWrapper } from 'newcomponents/ElementWrapper';
import { setDataConfigs } from 'store/actions/app-data';
import { queryClient } from 'stores/query/query-client';
import './style.less';

/* eslint-disable */
/* prettier-ignore */
const {MessageListener} =               lazyImport(() => import(/* webpackChunkName: "message-listener" */                  'components/MessageListener'));
const { Generic } = lazyImport(() => import(/* webpackChunkName: "generic" */ 'components/Generic'));
const { HomeworkIframe } = lazyImport(() => import(/* webpackChunkName: "homework-iframe" */ 'newelements/Generics/homework/iframe'));
const { BetslipFloating } = lazyImport(() => import(/* webpackChunkName: "betslip-floating-mode" */ 'elements/BetslipFloating'));
const { TopNavigationButton } = lazyImport(() => import(/* webpackChunkName: "top-navigation-button" */ 'newelements/TopNavigationButton'));
const { CasinoGameSingleViewRenderer } = lazyImport(() => import(/* webpackChunkName: "casino-game-single-view-renderer" */ 'newelements/Casino/GameSingleViewRenderer'));
const { Pages } = lazyImport(() => import(/* webpackChunkName: "pages" */ 'pages'));
const { Accounts } = lazyImport(
  () => import(/* webpackChunkName: "accounts" */ 'elements/Accounts')
);
const { AtoZMenu } = lazyImport(
  () => import(/* webpackChunkName: "a-to-z-menu" */ 'elements/AtoZMenu')
);

const { BottomNavigation } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "bottom-navigation" */ 'elements/BottomNavigation'
    )
);
const { CasinoCategories } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "casino-categories" */ 'newelements/Casino/Categories'
    )
);
const { CasinoGames } = lazyImport(
  () =>
    import(/* webpackChunkName: "casino-games" */ 'newelements/Casino/Games')
);
const { CasinoTournaments } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "casino-tournaments" */ 'elements/CasinoTournaments'
    )
);
const { Sportsbook: DesktopSportsbook } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "desktop-sportsbook" */ 'newelements/Sportsbook'
    )
);
const { Sportsbook } = lazyImport(
  () => import(/* webpackChunkName: "e-sports" */ 'newelements/Sportsbook')
);
const { Sportsbook: MobileSportsbook } = lazyImport(
  () =>
    import(/* webpackChunkName: "mobile-sportsbook" */ 'newelements/Sportsbook')
);
const { RacingSport } = lazyImport(
  () => import(/* webpackChunkName: "racing-sport" */ 'newelements/RacingSport')
);
const { Results } = lazyImport(
  () => import(/* webpackChunkName: "sports-results" */ 'elements/Results')
);
const { SelectedSport } = lazyImport(
  () =>
    import(/* webpackChunkName: "selected-sport" */ 'elements/SelectedSport')
);
const { SportsCalendar } = lazyImport(
  () =>
    import(/* webpackChunkName: "sports-calendar" */ 'elements/SportsCalendar')
);
const { SportsList } = lazyImport(
  () => import(/* webpackChunkName: "sports-list" */ 'elements/SportsList')
);
const { TvGames } = lazyImport(
  () => import(/* webpackChunkName: "tv-game" */ 'elements/TvGames')
);
const { VirtualSport } = lazyImport(
  () => import(/* webpackChunkName: "virtual-sport" */ 'elements/VirtualSport')
);
const { OptIn } = lazyImport(
  () => import(/* webpackChunkName: "opt-in" */ 'elements/OptIn')
);
const { SkillGames } = lazyImport(
  () => import(/* webpackChunkName: "skill-games" */ 'elements/SkillGames')
);
const { BetOnLeague } = lazyImport(
  () => import(/* webpackChunkName: "euro-2024" */ 'newelements/BetOnLeague')
);
const { CasinoSingleGame } = lazyImport(
    () => import(/* webpackChunkName: "casino-single-game" */ 'newelements/CasinoSingleGame')
);
const { Statscore } = lazyImport(
  () => import(/* webpackChunkName: "statscore" */ 'elements/Statscore')
);
const { Clock } = lazyImport(
  () => import(/* webpackChunkName: "clock" */ 'elements/Clock')
);
const { PinnacleSports } = lazyImport(
  () =>
    import(/* webpackChunkName: "pinnacle-sports" */ 'elements/PinnacleSports')
);
const { VaixRecommendations } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "vaix-recommendations" */ 'newelements/VaixRecommendations'
    )
);
const { SportsbookIframe } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "sportsbookIframe" */ 'newelements/SportsbookIframe'
    )
);
const { PascalGaming } = lazyImport(
  () =>
    import(/* webpackChunkName: "pascal-gaming" */ 'newelements/PascalGaming')
);
const { TournamentSingleView } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "tournament-single-view" */ 'elements/CasinoTournaments/views/TournamentSingleView'
    )
);
const { CryptoSideBar } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "crypto-side-bar" */ 'newelements/CryptoSideBar'
    )
);

const { Jackpot } = lazyImport(
  () => import(/* webpackChunkName: "jackpot" */ 'newelements/Jackpot')
);
const { JackpotWinners } = lazyImport(
  () => import(/* webpackChunkName: "jackpot-winners" */ 'newelements/JackpotWinners')
);
const { PopularBets } = lazyImport(
  () => import(/* webpackChunkName: "popular-bets" */ 'newelements/PopularBets')
);
const { TopWinners } = lazyImport(
  () =>
    import(/* webpackChunkName: "top-winners" */ 'newelements/TopCasinoWinners')
);
const { SuggestedBets } = lazyImport(
  () =>
    import(/* webpackChunkName: "suggested-bets" */ 'newelements/SuggestedBets')
);
const { FTNRate } = lazyImport(
  () => import(/* webpackChunkName: "ftn-rate" */ 'newelements/FTNRate')
);
const { Events } = lazyImport(
  () => import(/* webpackChunkName: "events" */ 'newelements/Events')
);
const { MultipleOfTheDay } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "multiple-of-the-day-widget" */ 'newelements/MultipleOfTheDay'
    )
);
const { CasinoProviders } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "casino-providers" */ 'newelements/Casino/Providers'
    )
);
const { SportsbookLeftSideBar } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "sportsbook-left-sidebar" */ 'elements/SportsbookLeftSideBar'
    )
);

const { OddFormatView } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "odd-format-view" */ 'newelements/OddFormatView'
    )
);
const { PromoTournaments } = lazyImport(
  () =>
    import(
      /* webpackChunkName: "tournaments-cards" */ 'newelements/PromoTournaments'
    )
);
const { CasinoSearch } = lazyImport(
  () =>
    import(/* webpackChunkName: "casino-search" */ 'newelements/Casino/Search')
);
const { SearchElement } = lazyImport(
  () =>
    import(/* webpackChunkName: "search-element" */ 'newelements/SearchElement')
);
const { Ftnscan } = lazyImport(
  () => import(/* webpackChunkName: "ftn-scan" */ 'newelements/Ftnscan')
);
const {TopSportWinners} = lazyImport(() => import(/* webpackChunkName: "top-sport-winners" */ 'newelements/TopSportWinners'))

const {SportsbookGamesListMarketsWidget} = lazyImport(() => import(/* webpackChunkName: "game-list-markets-widget" */           'newelements/Sportsbook/Widgets/SportsbookGamesListMarketsWidget'));
const {SportsbookTabsSwitcherWidget} =     lazyImport(() => import(/* webpackChunkName: "tab-switcher-widget" */                'newelements/Sportsbook/Widgets/SportsbookTabsSwitcherWidget'));
const {SportsbookSportsMenuWidget} =       lazyImport(() => import(/* webpackChunkName: "sports-menu-widget" */                 'newelements/Sportsbook/Widgets/SportsbookSportsMenuWidget'));
const {SportsbookLeftSideBarWidget} =      lazyImport(() => import(/* webpackChunkName: "left-sidebar-widget" */                'newelements/Sportsbook/Widgets/SportsbookLeftSideBarWidget'));
const {EuropeanPrematchMiddleWidget} =     lazyImport(() => import(/* webpackChunkName: "european-middle-widget" */             'newelements/Sportsbook/Widgets/EuropeanPrematchMiddleWidget'));
const {EuropeanPrematchLeftWidget} =       lazyImport(() => import(/* webpackChunkName: "european-left-widget" */               'newelements/Sportsbook/Widgets/EuropeanPrematchLeftWidget'));
const {EuropeanLiveContentWidget} =        lazyImport(() => import(/* webpackChunkName: "european-live-content-widget" */       'newelements/Sportsbook/Widgets/EuropeanLiveContentWidget'));
const {CalendarWidget} =                   lazyImport(() => import(/* webpackChunkName: "calendar-widget" */                    'newelements/SeparatedWidgets/CalendarWidget'));
const {TournamentsWidget} =                lazyImport(() => import(/* webpackChunkName: "tournaments-widget" */                 'newelements/SeparatedWidgets/TournamentsWidget'));
const {ResultsWidget} =                    lazyImport(() => import(/* webpackChunkName: "results-widget" */                     'newelements/SeparatedWidgets/ResultsWidget'));
const {EsportLeftSideBarWidget} =          lazyImport(() => import(/* webpackChunkName: "esport-left-sidebar-widget" */         'newelements/Sportsbook/Widgets/EsportLeftSideBarWidget'));
const {EsportMiddleWidget} =               lazyImport(() => import(/* webpackChunkName: "esport-middle-widget" */               'newelements/Sportsbook/Widgets/EsportMiddleWidget'));
const {EsportSportsMenuWidget} =           lazyImport(() => import(/* webpackChunkName: "esport-sports-menu-widget" */          'newelements/Sportsbook/Widgets/EsportSportsMenuWidget'));
const {BetslipWidget} =                    lazyImport(() => import(/* webpackChunkName: "Betslip-widget" */                     'newelements/Sportsbook/Widgets/BetslipWidget'));
const {RacingCategoryWidget} =             lazyImport(() => import(/* webpackChunkName: "racing-category-widget" */             'newelements/SeparatedWidgets/Racing/RacingCategoryWidget'));
const {RacingLeftSideWidget} =             lazyImport(() => import(/* webpackChunkName: "racing-left-side-widget" */            'newelements/SeparatedWidgets/Racing/RacingLeftSideWidget'));
const {UpcomingRacesWidget} =              lazyImport(() => import(/* webpackChunkName: "upcoming-races-widget" */              'newelements/SeparatedWidgets/Racing/UpcomingRacesWidget'));
const {OverViewWidget} =              lazyImport(() => import(/* webpackChunkName: "overview-widget" */                         'newelements/Sportsbook/Widgets/OverViewWidget'));
const {MultiViewWidget} =              lazyImport(() => import(/* webpackChunkName: "multiview-widget" */              'newelements/Sportsbook/Widgets/MultiViewWidget'));



/* eslint-enable */
if (window.partnerConfigs?.chunksPublicPath) {
  __webpack_public_path__ = window.partnerConfigs.chunksPublicPath;
}

configure();

//should be any because props are dynamic
const renderWidget = (Element: ComponentType<any>, name: string) => {
  return new Promise(resolve => {
    const widgetContainers = document.querySelectorAll(
      `[data-widget="${name}"][data-loaded="false"]`
    );

    if (!widgetContainers.length) {
      resolve(true);

      return;
    }

    const showFloating = isShowFloating();

    const shouldMountSubscriptionModal = !GLOBALS.subscriptionModalMounted;

    if (shouldMountSubscriptionModal) {
      GLOBALS.subscriptionModalMounted = true;
    }

    const shouldMountCasinoGameSingleView =
      !GLOBALS.casino.singleGameViewMounted;

    if (shouldMountCasinoGameSingleView) {
      GLOBALS.casino.singleGameViewMounted = true;
    }

    const shouldMountTopNavigationButton =
      !GLOBALS.isTopNavigationButtonMounted;

    if (shouldMountTopNavigationButton) {
      GLOBALS.isTopNavigationButtonMounted = true;
    }

    widgetContainers.forEach((element, elementNumber) => {
      const rootWidget = createRoot(element);
      let configs: Record<string, any> = {};

      try {
        configs = JSON.parse(element.getAttribute('data-configs') || '{}');
      } catch (e) {
        console.error(e);
      }

      if (configs.moduleId) {
        store.dispatch(setDataConfigs(configs.moduleId, configs));
      }

      if (CASINO_ELEMENT_NAMES.includes(name)) {
        if (GLOBALS.casino.casinoElementsCount === 0) {
          configs = { ...configs, isFirstCasinoElement: true };
        }

        GLOBALS.casino.casinoElementsCount += 1;
      }

      if (NEED_MOUNTED_ONE_ELEMENTS.includes(name)) {
        const elemetName = name.toLowerCase();

        if (!GLOBALS.mounted[elemetName]) {
          configs = { ...configs, mounted: true };
        }

        GLOBALS.mounted[elemetName] = true;
      }

      const shouldMountMessageListener = !GLOBALS.app.messageListenerMounted;

      if (shouldMountMessageListener) {
        GLOBALS.app.messageListenerMounted = true;
      }

      const shouldMountGeneric = !GLOBALS.app.genericMounted;

      if (shouldMountGeneric) {
        GLOBALS.app.genericMounted = true;
      }

      const shouldMountHomeworkIframe =
        !GLOBALS.homeworkIframe &&
        !SpringConfigs.MOCKED_DATA &&
        SpringConfigs.HOMEWORK_ENABLED;

      if (shouldMountHomeworkIframe) {
        GLOBALS.homeworkIframe = true;
      }

      rootWidget.render(
        <ConfigProvider
          prefixCls={THEME_PREFIX}
          locale={LocaleCalendar.datePickerLocale}
        >
          <Suspense fallback={getWidgetFallback(name, configs)}>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <Router history={browserHistory}>
                    <Suspense>
                      {!SpringConfigs.MOCKED_DATA && (
                        <>
                          {!elementNumber && showFloating ? (
                            <Portal>
                              <BetslipFloating />
                            </Portal>
                          ) : null}
                          {shouldMountTopNavigationButton ? (
                            <Portal>
                              <TopNavigationButton />
                            </Portal>
                          ) : null}
                          {shouldMountSubscriptionModal ? (
                            <Portal>
                              <SubscriptionModal urlHash={location.hash} />
                            </Portal>
                          ) : null}
                          {shouldMountMessageListener && <MessageListener />}
                          {shouldMountGeneric && <Generic />}
                          {shouldMountHomeworkIframe && <HomeworkIframe />}
                          {!elementNumber &&
                            shouldMountCasinoGameSingleView && (
                              <>
                                <CasinoGameSingleViewRenderer />
                                <TournamentSingleView fromIndex />
                              </>
                            )}
                        </>
                      )}
                    </Suspense>
                    {SpringConfigs.MOCKED_DATA ? (
                      <Element configs={configs} />
                    ) : (
                      <ElementWrapper
                        Child={Element}
                        configs={configs}
                        element={element}
                      />
                    )}
                  </Router>
                </PersistGate>
              </Provider>
            </QueryClientProvider>
          </Suspense>
        </ConfigProvider>
      );

      if (SpringConfigs.MOCKED_DATA) {
        const widgetContainers = document.querySelectorAll(
          `[data-widget="${name}"]`
        );

        if (
          widgetContainers?.length &&
          widgetContainers[0].getAttribute('data-loaded') === 'true'
        ) {
          return true;
        }

        setTimeout(() => {
          if (
            widgetContainers?.length &&
            widgetContainers[0].getAttribute('data-loaded') !== 'true'
          ) {
            widgetContainers[0].setAttribute('data-loaded', 'true');
          }
        }, timeoutDuration.DURATION_200);

        if (
          widgetContainers?.length &&
          widgetContainers[0].getAttribute('data-loaded') !== 'true'
        ) {
          widgetContainers[0].setAttribute('data-loaded', 'true');
        }
      }
    });
    resolve(true);
  });
};

window.initWidgets = async () => {
  if (!SpringConfigs.MOCKED_DATA) {
    initSentry();
  }

  if (!GLOBALS.initProces) {
    GLOBALS.initProces = true;
    const initi18n = await initI18nManual();

    if (initi18n) {
      reassignTranslationsForStore();

      Promise.all([
        renderWidget(Accounts, 'Accounts'),
        renderWidget(Betslip, 'Betslip'),
        renderWidget(Jackpot, 'Jackpot'),
        renderWidget(JackpotWinners, 'JackpotWinners'),
        renderWidget(CasinoCategories, 'CasinoCategories'),
        renderWidget(CasinoProviders, 'CasinoProviders'),
        renderWidget(CasinoGames, 'CasinoGames'),
        renderWidget(CasinoSearch, 'CasinoSearch'),
        renderWidget(CasinoTournaments, 'CasinoTournaments'),
        renderWidget(MobileSportsbook, 'MobileSportsbook'),
        renderWidget(VirtualSport, 'VirtualSport'),
        renderWidget(DesktopSportsbook, 'DesktopSportsbook'),
        renderWidget(SportsbookLeftSideBar, 'SportsbookLeftSideBar'),
        renderWidget(Sportsbook, 'Esports'),
        renderWidget(TvGames, 'TvGames'),
        renderWidget(RacingSport, 'RacingSport'),
        renderWidget(AtoZMenu, 'AtoZMenu'),
        renderWidget(BottomNavigation, 'BottomNavigation'),
        renderWidget(Events, 'Events'),
        renderWidget(MultipleOfTheDay, 'MultipleOfTheDay'),
        renderWidget(SelectedSport, 'SelectedSport'),
        renderWidget(SearchElement, 'Search'),
        renderWidget(SportsCalendar, 'Calendar'),
        renderWidget(SportsList, 'SportsList'),
        renderWidget(Results, 'Results'),
        renderWidget(OptIn, 'OptIn'),
        renderWidget(SkillGames, 'SkillGames'),
        renderWidget(Statscore, 'Statscore'),
        renderWidget(PinnacleSports, 'PinnacleSports'),
        renderWidget(Clock, 'Clock'),
        renderWidget(VaixRecommendations, 'VaixRecommendations'),
        renderWidget(SportsbookIframe, 'SportsbookIframe'),
        renderWidget(PascalGaming, 'PascalGaming'),
        renderWidget(TopWinners, 'TopWinners'),
        renderWidget(PopularBets, 'PopularBets'),
        renderWidget(CryptoSideBar, 'CryptoSideBar'),
        renderWidget(SuggestedBets, 'SuggestedBets'),
        renderWidget(SportsbookGamesListMarketsWidget, 'GamesListMarkets'),
        renderWidget(SportsbookTabsSwitcherWidget, 'SportsbookTabSwitcher'),
        renderWidget(SportsbookLeftSideBarWidget, 'SportsbookLeftSidebar'),
        renderWidget(SportsbookSportsMenuWidget, 'SportsMenu'),
        renderWidget(EuropeanPrematchMiddleWidget, 'EuropeanPrematchMiddle'),
        renderWidget(EuropeanPrematchLeftWidget, 'EuropeanPrematchLeft'),
        renderWidget(EuropeanLiveContentWidget, 'EuropeanLiveContent'),
        renderWidget(TournamentsWidget, 'TournamentsWidget'),
        renderWidget(CalendarWidget, 'CalendarWidget'),
        renderWidget(ResultsWidget, 'ResultsWidget'),
        renderWidget(EsportLeftSideBarWidget, 'EsportLeftSideBar'),
        renderWidget(EsportMiddleWidget, 'EsportGamesList'),
        renderWidget(EsportSportsMenuWidget, 'EsportSportsMenu'),
        renderWidget(BetslipWidget, 'BetslipWidget'),
        renderWidget(RacingCategoryWidget, 'RacingCategoryWidget'),
        renderWidget(RacingLeftSideWidget, 'RacingLeftSideWidget'),
        renderWidget(UpcomingRacesWidget, 'UpcomingRacesWidget'),
        renderWidget(OverViewWidget, 'OverViewWidget'),
        renderWidget(MultiViewWidget, 'MultiViewWidget'),
        renderWidget(FTNRate, 'FTNRate'),
        renderWidget(OddFormatView, 'OddFormatView'),
        renderWidget(PromoTournaments, 'PromoTournaments'),
        renderWidget(Ftnscan, 'Ftnscan'),
        renderWidget(BetOnLeague, 'BetOnLeague'),
        renderWidget(BetOnLeague, 'Euro2024'),
        renderWidget(CasinoSingleGame, 'CasinoSingleGame'),
        renderWidget(TopSportWinners, 'SportsbookWinners')
      ]).then(() => (GLOBALS.initProces = false));
    }
  }
};

(async () => {
  if (
    process.env.REACT_APP_ENV !== 'widgets' &&
    document.getElementById('root')
  ) {
    // @ts-ignore
    const root = createRoot(document.getElementById('root'));
    const initi18n = await initI18nManual();

    if (initi18n) {
      reassignTranslationsForStore();
      const showFloating = isShowFloating();

      const shouldMountSubscriptionModal = !GLOBALS.subscriptionModalMounted;

      if (shouldMountSubscriptionModal) {
        GLOBALS.subscriptionModalMounted = true;
      }

      const shouldMountTopNavigationButton =
        !GLOBALS.isTopNavigationButtonMounted;

      if (shouldMountTopNavigationButton) {
        GLOBALS.isTopNavigationButtonMounted = true;
      }

      const shouldMountCasinoGameSingleView =
        !GLOBALS.casino.singleGameViewMounted;

      if (shouldMountCasinoGameSingleView) {
        GLOBALS.casino.singleGameViewMounted = true;
      }

      const shouldMountGeneric = !GLOBALS.app.genericMounted;

      if (shouldMountGeneric) {
        GLOBALS.app.genericMounted = true;
      }

      root.render(
        <Suspense fallback={null}>
          <ConfigProvider
            prefixCls={THEME_PREFIX}
            locale={LocaleCalendar.datePickerLocale}
          >
            <Provider store={store}>
              <ThemeProvider>
                <QueryClientProvider client={queryClient}>
                  <PersistGate loading={null} persistor={persistor}>
                    <Router history={browserHistory}>
                      {showFloating ? (
                        <Portal>
                          <BetslipFloating />
                        </Portal>
                      ) : null}
                      {shouldMountTopNavigationButton ? (
                        <Portal>
                          <TopNavigationButton />
                        </Portal>
                      ) : null}
                      {shouldMountSubscriptionModal ? (
                        <Portal>
                          <SubscriptionModal urlHash={location.hash} />
                        </Portal>
                      ) : null}
                      {shouldMountCasinoGameSingleView && (
                        <>
                          <CasinoGameSingleViewRenderer />
                          <TournamentSingleView fromIndex />
                        </>
                      )}
                      {shouldMountGeneric && <Generic />}
                      <Pages />
                    </Router>
                  </PersistGate>
                </QueryClientProvider>
              </ThemeProvider>
            </Provider>
          </ConfigProvider>
        </Suspense>
      );
    }
  }
})();
