import { addPropertyToObjectWithLimit } from 'utils/helpers';
import { ActionType } from 'interfaces/generic';
import {
  Game,
  GameInfoAdditionalDataMap,
  Sport
} from 'interfaces/sportsbook-data-levels';
import { ESportDataActionTypes } from 'store/action-types';

export type TAdditionalStatisticsData = {
  selected: number;
  map: GameInfoAdditionalDataMap | undefined;
  maps: Array<GameInfoAdditionalDataMap> | undefined;
};

type InitialData = {
  sport: Record<string, Sport> | null;
  cashedSport: { [key: string]: Sport<Game[]> };
  games: Record<string, Record<string, Game>> | null;
  gamesSingleViewData: Record<string, { sport: Record<string, Sport> }>;
  additionalStatisticsData: TAdditionalStatisticsData;
};

const initialData: InitialData = {
  sport: null,
  cashedSport: {},
  games: null,
  gamesSingleViewData: {},
  additionalStatisticsData: {
    selected: -1,
    map: undefined,
    maps: undefined
  }
};

const ESPORT_GAMES_LIMIT = 10;

export const eSportData = (
  state = initialData,
  action: ActionType
): InitialData => {
  switch (action.type) {
    case ESportDataActionTypes.SET_E_SPORT_LIST_DATA:
      return {
        ...state,
        sport: action.payload
      };
    case ESportDataActionTypes.SET_CASHED_E_SPORT_PREMATCH_GAMES:
      return {
        ...state,
        cashedSport: {
          ...state.cashedSport,
          [action.payload.alias]: action.payload.data
        }
      };

    case ESportDataActionTypes.SET_ESPORT_GAMES:
      return {
        ...state,
        games: action.payload.data
          ? addPropertyToObjectWithLimit(
              state.games || {},
              action.payload.data,
              ESPORT_GAMES_LIMIT
            )
          : null
      };

    case ESportDataActionTypes.SET_ESPORT_GAME_DATA:
      return {
        ...state,
        gamesSingleViewData: {
          ...state.gamesSingleViewData,
          [action.payload.id]: action.payload.data
        }
      };

    case ESportDataActionTypes.SET_ESPORT_ADDITIONAL_DATA:
      return {
        ...state,
        additionalStatisticsData: action.payload
      };

    default:
      return state;
  }
};
