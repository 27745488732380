import { ActionType } from 'interfaces/generic';
import { PartnerConfig } from 'interfaces/partner-config';
import { SocketActionTypes } from 'store/action-types/socket';

export const setIsConnected = (isConnected: boolean): ActionType => {
  return {
    type: SocketActionTypes.SET_IS_CONNECTED,
    payload: isConnected
  };
};

export const setPartnerConfigs = (data: PartnerConfig): ActionType => {
  return {
    type: SocketActionTypes.SET_PARTNER_CONFIGS,
    payload: data
  };
};

export const setJackpots = (data: Object): ActionType => {
  return {
    type: SocketActionTypes.SET_JACKPOTS,
    payload: data
  };
};
