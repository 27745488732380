import { Dispatch, ReactNode, SetStateAction } from 'react';
import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { ESmsRestrictionsAction } from 'utils/constants/account/e-sms-restrictions-action';
import { TWO_WALLET_TYPES } from 'utils/constants/account/payment';
import { ProfileData, TField } from './authentication';

type AbstractDataResponse = {
  result: number | string | null;
  result_text: null | string;
};

export type ChangePassword = {
  password: string;
  new_password: string;
  confirmation_code?: string;
};

export type Bonus = {
  acceptance_type: number;
  amount: number;
  amount_percent: null | number;
  bonus_type: number;
  can_accept: boolean;
  client_bonus_expiration_date: number;
  deposit_count: number;
  deposit_wagering_factor: number;
  description: string;
  end_date: number;
  expiration_date: number;
  expiration_days: number;
  external_id: null | number;
  id: number;
  is_for_verified_players_only: boolean;
  money_requirenments: Record<
    string,
    { currency_id: string; min_amount: number; max_amount: number }
  > | null;
  name: string;
  partner_bonus_id: number;
  result_type: number;
  source: number;
  start_date: number;
  trigger_type: number;
  wagering_factor: number;
  acceptance_date: number;
  AcceptanceType: number;
  Amount: number;
  BonusType: number;
  CanAccept: boolean;
  ClientBonusExpirationDate: number;
  Description: string;
  EndDate: number;
  ExpirationDate: number;
  ExternalId: null | number;
  Id: number;
  IsForVerifiedPlayersOnly: boolean;
  Name: string;
  PartnerBonusId: number;
  ResultType: number;
  Source: number;
  StartDate: number;
  WageringFactor: number;
  ExpirationDays: number;
  free_bet_percentge?: number;
};

export type UpdateNotificationParams = {
  password: string;
  subscribe_to_email: boolean;
  subscribe_to_internal_message: boolean;
  subscribe_to_phone_call: boolean;
  subscribe_to_push_notification: boolean;
  subscribe_to_sms: boolean;
};

export type UpdateProfileParams = {
  address?: string;
  birth_date?: null | string;
  city?: string;
  country_code?: string;
  email?: string;
  first_name?: string;
  gender?: string;
  last_name?: string;
  password: string;
  zip_code?: string;
  is_two_factor_authentication_enabled?: boolean;
  confirmation_code?: string;
  personal_id?: string;
  doc_number?: string;
};
export type TUpdateClientInfo = {
  occupation?: string;
  position?: String;
  experience_level?: String;
  salary_max?: number;
  salary_min?: number;
};
export type DateParams = {
  from_date?: number;
  to_date?: number;
  product?: string;
  outcome?: number;
  only_counts?: boolean;
  bet_id?: number | string;
  all_bet_types?: boolean;
};

export type CountryCode =
  | 'AC'
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TA'
  | 'TC'
  | 'TD'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW';

export type PickerPeriod = {
  value: number | string;
  label: string;
  disabled: boolean;
};

export type TPeriodValue = { period: number; reason?: string };

export interface IAccountModalCustomContent {
  title?: string;
  content?: string;
  withoutPassword?: boolean;
}

export type Period = {
  value: number;
  label: string;
  type: string;
  amount: number;
  show: boolean;
  name: string;
  reason?: string;
  user?: ProfileData;
};

/***  ---  PAYMENTS INTERFACES ---  ***/

export type TPaymentWallet = {
  $id: string;
  ClientId: number;
  ExpirationDate: number | null;
  Id: number;
  InfoMismatch: null;
  IsDeleted: false;
  IsInvalid: boolean | null;
  IsVerified: false;
  Modified: string;
  ModifiedByUsername: string;
  PartnerId: number;
  PayWay: number;
  PaymentSystemIcon: ReactNode | null;
  PaymentSystemId: number;
  WalletId: string;
  WalletInfo: string;
  WalletWithdrawalInfo: string;
};

export type PaymentResponse = {
  deposit: Record<string, Payment>;
  withdraw: Record<string, Payment>;
};

export type PaymentWalletResponse = {
  details: TPaymentWallet[];
};

export type PaymentDeleteWalletResponse = {
  details: TPaymentWallet;
};

export type PaymentV2Response =
  | PaymentV2ResponseSuccess
  | PaymentV2ResponseError;

type PaymentV2ResponseSuccess = {
  deposit: number[];
  withdraw: number[];
};

export type PaymentV2ResponseError = {
  message: string;
  status: string;
};

export type Payment = {
  walletCurrency: string;
  FormType: string;
  PaymentSystemIconUrl: string;
  PaymentSystem_id: string;
  countryAllow: string;
  countryRestrict: Record<string, string>;
  customAmounts: boolean | Record<number | string, number>;
  info_notes: Record<string, PaymentInfoNote>;
  infotext: string;
  isSavable: boolean;
  isScanable: boolean;
  method: PaymentMethod;
  displayName: string;
  systemId: Record<string, any>;
  paymentGroup: number | {};
  crytoCurrency: string;
  order?: number;
  status?: number;
  icon?: string;
};

export type PaymentWithName = Payment & {
  paymentName: string;
  stayInSameTabOnDeposit?: boolean;
  cryptoPayment?: boolean | string;
  hideDepositAmmount?: boolean;
  depositPrefilledAmount?: string;
  key?: string;
};

type PaymentInfoNote = {
  min: string;
  max: string;
};

export type PaymentPromoData = {
  id: number;
  min?: string;
  max?: string;
  value: string;
};

export type LolikStakeBalance = {
  balance: number;
  rate?: number;
  nextReward?: number;
};

export type PaymentMethod = {
  action: string;
  enctype: string;
  fields: Record<string, PaymentField>;
  method: PaymentMethodType;
  target: string;
  infoData?: PaymentPromoData[];
};

export type PaymentField = {
  lang: string;
  langID: string;
  required?: boolean;
  scan?: string;
  hidden?: boolean;
  disabled?: boolean;
  trans_key: string;
  type: string;
  value:
    | string
    | boolean
    | number
    | {
        minLenght: number;
      };
  opts?: Record<string, PaymentFieldPickerOption>;
  name: string;
  prefillFromProfile?: string;
  minLenght?: number;
  maxLenght?: number;
  mask?: string;
  oldType?: string;
  related?: string;
  placeholder?: string;
  child?: string;
};

type PaymentFieldPickerOption = {
  text: string;
  value: string;
  key: string;
  title: string;
  withdrawFieldTypes?: Record<string, string>;
  betshops?: Record<string, PaymentFieldPickerOption>;
  address?: string;
  name?: string;
  id?: string;
};

type PaymentMethodType = 'POST' | 'GET' | 'iframe' | 'formDraw';

export type PaymentImageProps = {
  data: PaymentWithName;
  containerHeight?: number;
};

export type DepositParams = {
  amount: number;
  payer?:
    | {
        status_urls: PaymentStatusUrls;
        getWallets: number;
        eamount?: null | number;
      }
    | Record<string, string | number | null>;
  forProduct?: TWO_WALLET_TYPES;
  service: string | number;
};

export type WithdrawParams = {
  amount: number;
  payee: Record<string, string | number>;
  forProduct?: TWO_WALLET_TYPES;
  service: string;
};

export type PaymentStatusUrls = {
  cancel?: string;
  fail?: string;
  pendingUrl?: string;
  success?: string;
};

export type ClientClaimableDepositBonuses = {
  result: number;
  result_text: string | null;
  details?: DepositClaimableBonus[] | null;
};

export interface DepositClaimableBonus {
  Id: number;
  PartnerBonusId: number;
  Source: number;
  Name: string;
  Description: string;
  StartDate: number;
  EndDate: number;
  ClientBonusExpirationDate: null;
  ExpirationDate: null;
  ExpirationDays: number;
  WageringFactor: number;
  DepositWageringFactor: number;
  MoneyRequirenments: null;
  CanAccept: boolean;
  IsForVerifiedPlayersOnly: boolean;
  BonusType: number;
  Amount: number;
  AcceptanceType: number;
  ResultType: number;
  ExternalId: number;
  AmountPercent: number;
  TriggerType: number;
}

export type DepositResponse = {
  details: DepositResponseDetails;
  result: string | number | null;
};

type Fields =
  | Record<string, DepositResponseField>
  | Record<string, PaymentField>;

export type DepositResponseDetails = {
  action: string;
  fields?: Fields;
  method: 'iframe' | 'get' | 'post' | 'formdraw' | 'IFRAME';
  error_code: number | string;
  error: string;
  message: string;
};

export type DepositResponseField = {
  name: string;
  value: string | number | null;
};

export type FormDrawField = {
  value: {
    type: string;
    label: string;
    disabled: boolean;
    hidden: boolean;
    prefillFromProfile: boolean;
    setValue: string;
    options: Record<string, PaymentFieldPickerOption>;
    minLenght?: number;
    maxLenght?: number;
  };
  name: string;
};

export type WalletType = 'deposit' | 'withdraw';

export type WithdrawResponse = WithdrawResponseSuccess | WithdrawResponseError;

export type WithdrawResponseSuccess = {
  Result: string | number;
  result: string | number;
  details: PaymentDetails;
};

export type PaymentResponseError = {
  result: number | string;
  result_text: null | string;
  details: PaymentDetails;
};
type PaymentDetails = {
  code: number | string;
  error: string;
  error_code: string;
  message: string;
  action: string;
  method: string;
  fields: Fields;
};

export type WithdrawResponseError = PaymentResponseError;
export type DepositResponseError = PaymentResponseError;

export type SwarmResponse = {
  code: number;
  data: any;
  rid: string;
};

export type PaymentStatusResponse = {
  result_text: string | null;
  result_status: string | null;
  deposits_requests: {
    request: PaymentStatusRecord[];
  };
  withdrawal_requests: {
    request: PaymentStatusRecord[];
  };
};

export type PaymentStatusRecord = {
  id: number | string;
  amount: number | string;
  name: string;
  payment_system_name: string;
  status: number | string;
  date: string;
  allow_time: string;
  is_cancelable: boolean;
  paid_time: number;
};

export type TRequestRejectReason = {
  result: number;
  result_text: string | null;
  details: {
    RejectReason: string | null;
  };
};
/***  ---  DOCUMENTS INTERFACES ---  ***/

export type DocumentsResponse = {
  code: number;
  text_code: string;
  data: DocumentItem[];
};

export type DocumentItem = {
  CreatedDate: string;
  Id: number;
  ImageData: string;
  ImageType: string;
  IsChecked: boolean;
  IsDeleted: boolean;
  Name: string;
  Status: number;
  StatusName: string;
  TypeId: number;
};

export type LegigameDocuments = Record<
  'AddressDoc' | 'BankDoc' | 'IdDoc',
  LegigameDocument
>;

export type LegigameDocument = {
  CreatedDate: string | null;
  Id: number;
  ImageData: string | null;
  ImageType: string | null;
  IsChecked: boolean;
  IsDeleted: boolean;
  Name: string;
  ProcessState: number | null;
  Status: number;
  StatusName: string | null;
  TypeId: number;
  VerificationState: number;
};

export type CardData = {
  ImageType: number;
  legigame: boolean;
  multiple: boolean;
  name?: string;
  document?: LegigameDocument | null;
  documentTypeSelector: boolean;
  header?: {
    description: string;
    dynamicDescription: string;
    status: number;
  };
  disabled?: boolean;
};

export type TDepositLimitChangeDetails = {
  result: 0;
  result_text: null;
  details: {
    MaxSingleDeposit: number | null;
    MaxDailyDeposit: number | null;
    MaxWeeklyDeposit: number | null;
    MaxMonthlyDeposit: number | null;
    MaxYearlyDeposit: number | null;
    Id: number;
    ChangeTime: number | null;
    Created: number | null;
  };
};

export type DepositLimits = {
  result: number;
  result_text: null | string;
  details: TDepositLimitDetails;
};

export type TDepositLimitDetails = {
  max_year_deposit: null | number;
  max_single_deposit: null | number;
  max_remaining_yearly_deposit: null | number;
  max_remaining_single_deposit: null | number;
} & {
  [k in EDepositLimitsKeys | EDepositRemainingKeys]: number;
};

export enum EDepositLimitsKeys {
  max_day_deposit = 'max_day_deposit',
  max_week_deposit = 'max_week_deposit',
  max_month_deposit = 'max_month_deposit'
}

export enum EDepositRemainingKeys {
  max_remaining_daily_deposit = 'max_remaining_daily_deposit',
  max_remaining_weekly_deposit = 'max_remaining_weekly_deposit',
  max_remaining_monthly_deposit = 'max_remaining_monthly_deposit'
}

export type LimitsResponse = AbstractDataResponse;

export type TLimitsError = AbstractDataResponse & { details: string };

export type SportsbookLimits = {
  code: number;
  rid: number;
  details: {
    SportMaxDailyBet: number;
    SportMaxMonthlyBet: number;
    SportMaxRemainingDailyBet: number;
    SportMaxRemainingMonthlyBet: number;
    SportMaxRemainingSingleBet: number;
    SportMaxRemainingWeeklyBet: number;
    SportMaxSingleBet: number;
    SportMaxWeeklyBet: number;
  };
};

export type LossLimits = {
  code: number;
  rid: number;
  details: LossDetailsSport | (LossDetailsCasino & Record<string, number>);
};

export type LossDetailsSport = Record<string, number> & {
  DailyLoss: number;
  WeeklyLoss: number;
  MonthlyLoss: number;
  RemainingDailyLoss: number;
  RemainingWeeklyLoss: number;
  RemainingMonthlyLoss: number;
};

export type LossDetailsCasino = Record<string, number> & {
  casinoDailyLoss: number;
  casinoWeeklyLoss: number;
  casinoMonthlyLoss: number;
  casinoRemainingDailyLoss: number;
  casinoRemainingWeeklyLoss: number;
  casinoRemainingMonthlyLoss: number;
};

export type CasinoLimits = AbstractDataResponse & {
  details: {
    CasinoMaxDailyBet: number;
    CasinoMaxSingleBet: number;
    CasinoWeek: number;
    CasinoMonth: number;
    CasinoMaxRemainingSingleBet: number;
    CasinoMaxRemainingDailyBet: number;
  };
};

export type GetCurrencyConfigsResponse = {
  data: {
    currency: {
      [key: string]: {
        id: number;
        name: string;
        rate: number;
        rounding: number;
      };
    };
  };
};

export type Message = {
  body: string;
  checked: number;
  thread_id?: null;
  date: number;
  id: number;
  subject: string;
  is_deletable: boolean;
};

export type SportActivityResponse = AbstractDataResponse & {
  details: SportActivityData;
};

export type SessionInfoResponse = AbstractDataResponse & {
  details: SessionInfoData;
};

export type SportActivityData = Record<string, number> & {
  TotalSportStake: number;
  TotalSportWin: number;
  TotalSportReturnAmount: number;
  TotalCasinoStake: number;
  TotalCasinoWin: number;
  Profit: number;
};

export type SessionInfoData = Record<string, number> & {
  StartBalance: number;
  TotalStake: number;
  Profit: number;
};

export type LegigameResponse = AbstractDataResponse & {
  details: LegigameDocuments;
};

export type OptInInfo = {
  ClientId: number;
  Code: string;
  Created: null;
  ExpirationDate: null;
  Id: number;
  PartnerId: number;
};

export type UserOptInsResponse = AbstractDataResponse & {
  details: OptInInfo[];
};

export type PreCalculationsResponse = AbstractDataResponse & {
  details: {
    CasinoLastBetDate: number;
    DepositAmount: number;
    DepositCount: number;
    FirstDepositDate: number | null;
    LastDepositDate: number | null;
    SportLastBetDate: number;
    WithdrawalAmount: number;
    WithdrawalCount: number;
  };
};

export type PartnerBonusesForRequestResponse = AbstractDataResponse & {
  details: TPartnerBonus[];
};

export type TPartnerBonus = {
  Id: number;
  Name: string;
  Description: string;
  Type: number;
};

export type TClientBonusRequestResponse = AbstractDataResponse & {
  details: {};
};

export type TClientBonusesResponse = AbstractDataResponse & {
  details: TClientRequestedBonus[];
};

export type TClientRequestedBonus = {
  Id: number;
  Type: number;
  State: 0 | 1 | 2;
  Created: number;
  ClientNote?: string;
  Modified: number;
  UserNote?: string;
  PartnerBonusId?: number;
};

type PartnerDocumentsType = {
  Usage: number;
  DocumentTypeId: number;
  DocumentTypeName: string;
  FilterNameId: number;
};

export type PartnerDocumentsTypeResponse = {
  details: PartnerDocumentsType[];
};

export type PartnerBalanceTypes = {
  label: string;
  value: 'all' | number;
};

export type TLimitsHistoryResponse = AbstractDataResponse & {
  details: TLimitsHistoryItem[];
};

export type TLimitsHistoryItem = {
  Id: number;
  ClientId: number;
  PeriodType: number;
  Period: number;
  Limit: number;
  TypeId: number;
  ProductCategoryId: number;
  Operation: null | number;
  Created: number;
  TypeName: string;
  PeriodTypeName: string;
  ProductCategoryName: string;
  OperationName: null | string;
  Name: null | string;
};

export type GeoDataType = {
  action?: string;
  checkInterval?: NodeJS.Timeout | number;
  sendGeoToken?: boolean;
  popupType?: string;
  errorPopupTitle?: string;
  errorPopupMsg?: string;
  trackingLink?: string;
  trackingDuration?: number;
  loadingValidateGeoRestriction?: boolean;
  loadingGetGeoToken?: boolean;
  validateGeoRestrictionCancelCallback?: () => void;
  validateGeoRestrictionSuccessCallback?: () => void;
};

export type PostGridHiddenParamsType = {
  addressSelected?: boolean;
  manualSelected?: boolean;
  hiddenFields?: TField[];
  hiddenFieldValues?: Record<string, any>;
};

export const PostGridHiddenInputTypesMapping = {
  country: 'country_code',
  city: 'city',
  pc: 'zip_code',
  postalOrZip: 'zip_code',
  prov: 'province',
  provinceOrState: 'province'
};

export type PostGridAddressType = {
  text: string;
  description: string;
  address: { address: string; city: string; pc: string };
};

export type PostGridAddressDetailsType = {
  country?: string;
  countryCode?: string;
  city?: string;
  pc?: string;
  postalOrZip?: string;
  prov?: string;
  provinceOrState?: string;

  address?: {
    city?: string;
    pc?: string;
    prov?: string;
  };
};

export type paymentFee = {
  currency: string;
  amountFTN: number;
  amount: number;
};

export type TSalaryOccupation = {
  ExperienceLevel: string | null;
  Occupation: string | null;
  PaymentSystemId: string | null;
  Position: string | null;
  SalaryMax: number | null;
  SalaryMin: number | null;
};

export enum EPaymentViewType {
  GRID = 'Grid',
  CLASSIC = 'Classic'
}

export type TClientSelfSchedules = {
  code: number;
  rid: number;
  details: {
    Schedules: any;
  };
};

export type TDataScheduleItem = {
  ScheduleType: number | undefined;
  TimeFrom: string | undefined;
  TimeTo: string | undefined;
  WeekDayFrom: number | undefined;
  WeekDayTo: number | undefined;
  Day: string;
  key: number;
  main?: {
    TimeFrom: string | undefined;
    TimeTo: string | undefined;
  };
};

export type TStatements = {
  BetId: number;
  ResultDate: string;
  ProviderName: string;
  GameName: string;
  WinAmount: number;
  Currency: string;
  Playerid: number;
  BetAmount: string;
};

export type TSDocumentForm = {
  form: FormInstance;
  autoidentVerification?: boolean;
  loadingAutoident: boolean;
  setImagePreviewData: Dispatch<SetStateAction<string>>;
};

export type TIdinData = {
  address?: string;
  city: string;
  country_code: string;
  zip_code: string;
  email: string;
  last_name: string;
  first_name: string;
  birth_date: string;
  gender?: string;
} | null;

export type TLocateData = {
  address: string;
  city: string;
  zip_code: string;
  country_code: string;
} | null;

export type MessageFilterDateFileds = 'from_date' | 'to_date';
export type MessageFilterDates = {
  from_date: dayjs.Dayjs;
  to_date: dayjs.Dayjs;
};

export enum EAccountWidgetLayoutType {
  popup = 'popup',
  onPage = 'onPage'
}

export type TCashbackConvertDataProps = {
  result: number | string;
  result_text: null | string;
  details: {
    code: number | string;
    error: string;
    error_code: string;
  };
};
export type TSmsEmailVerificationModalData = {
  actionType: ESmsRestrictionsAction;
  username?: string;
  showPasswordField?: boolean;
  phoneNumber?: string;
  email?: string;
};
