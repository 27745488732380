import { TMonths } from './types';

const MONTHS_COUNT = 32;
const LAST_YEAR_COUNT = 1920;

export const days = (): Array<number> => {
  const dayValues = [];

  for (let i = 1; i < MONTHS_COUNT; i++) {
    dayValues.push(i);
  }

  return dayValues;
};

export const months = (februaryDays: number): TMonths => {
  return {
    january: {
      countDays: 31,
      number: 1
    },
    february: {
      countDays: februaryDays,
      number: 2
    },
    march: {
      countDays: 31,
      number: 3
    },
    april: {
      countDays: 30,
      number: 4
    },
    may: {
      countDays: 31,
      number: 5
    },
    june: {
      countDays: 30,
      number: 6
    },
    july: {
      countDays: 31,
      number: 7
    },
    august: {
      countDays: 31,
      number: 8
    },
    september: {
      countDays: 30,
      number: 9
    },
    october: {
      countDays: 31,
      number: 10
    },
    november: {
      countDays: 30,
      number: 11
    },
    december: {
      countDays: 31,
      number: 12
    }
  };
};

const lastYear = (ageLimit: number): number => {
  const currentYear = new Date();

  return currentYear.getFullYear() - ageLimit;
};

export const yearDivisible = {
  DIVISIBLE_BY_4: 4,
  DIVISIBLE_BY_100: 100,
  DIVISIBLE_BY_400: 400
};

export function leapYear(year: number): boolean {
  return (
    (year % yearDivisible.DIVISIBLE_BY_4 === 0 &&
      year % yearDivisible.DIVISIBLE_BY_100 != 0) ||
    year % yearDivisible.DIVISIBLE_BY_400 === 0
  );
}

export const years = (ageLimit: number): Array<number> => {
  const yearValues = [];

  for (let j = lastYear(ageLimit); j > LAST_YEAR_COUNT; j--) {
    yearValues.push(j);
  }

  return yearValues;
};

export const CookieExpiration = {
  DAYS_15: 15,
  DAYS_30: 30
};
