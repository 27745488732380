import { memo, ReactPortal, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  rootId?: string;
  hide?: boolean;
} & PropsWithChildren;

export const Portal = memo(
  ({ rootId, children, hide }: Props): ReactPortal | null => {
    const [container, setContainer] = useState<HTMLElement | null>();

    useLayoutEffect(() => {
      const root = rootId ? document.getElementById(rootId) : null;

      if (root) {
        setContainer(root);
      }
    }, [rootId]);

    useEffect(() => {
      setContainer(rootId ? document.getElementById(rootId) : document.body);
    }, [rootId]);

    return container && !hide ? createPortal(children, container) : null;
  }
);
