import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';

export const checkTypeIsNotSportbook = (
  command: string,
  sportsbook?: boolean
): boolean => {
  const sportType = SpringConfigs.CASINO_SPORTSBOOK_SWITCHER;
  const NotAllowedCommands = [
    CommandNames.GET_CLIENT_CASINO_BET_LIMIT,
    CommandNames.GET_CASINO_LOSS_LIMIT,
    CommandNames.GET_BONUS_DETAILS
  ];

  if (sportsbook === true && command === CommandNames.GET_BONUS_DETAILS) {
    return true;
  }

  return !(sportType === 1 && NotAllowedCommands.includes(command));
};
