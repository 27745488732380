type TResult = {
  'data-testid'?: string;
};

export const createTestId = (id: string): TResult => {
  if (process.env.NODE_ENV !== 'test') {
    return {};
  } else {
    return {
      'data-testid': `${id}-test-id`
    };
  }
};

export const getTestId = (id: string): string => {
  if (process.env.NODE_ENV !== 'test') {
    return '';
  }

  return `${id}-test-id`;
};
