import { TJackpotMain } from './jackpot';
import { NewCasinoGame, NewCasinoGameBackend } from './new-casino';

export type CasinoInitialData = CasinoOptions & {
  casinoOptionsUID: string;
  casinoGameTournaments: Array<CasinoTournamentsType> | null;
  cachedGames: {
    activeCategories: string[];
    activeProviders: string[];
  };
  cachedTournaments: {
    options: SelectedOptions;
    tournamentsData: Record<number, CasinoTournamentsType[]>;
  };
  JackpotData: Record<string, TJackpotMain>;
  SportsbookJackpotData: Record<string, TJackpotMain>;
  casinoGameJackpot: Record<string, TJackpotMain>;
  selectedCasinoGame: NewCasinoGameBackend | null;
  removedFavoriteCasinoGame?: number;
  cachedCasinoFavoriteGames?: NewCasinoGameBackend[];
  joinedTournaments: number[];
  selectedCasinoCategoryId: string;
  isLoaded: boolean;
  tournamentActiveProductTypeId: ETournamentProductTypeIds | null;
};

export type SelectedOptions = {
  status: number[];
  registration: boolean | null;
  entryType: number | null;
};

export type CasinoOptions = {
  categories: CasinoCategory[];
  providers: CasinoProvider[];
};

type CasinoCategory = {
  id: string;
  name: string;
  title: string;
  games_count: string;
};

export type CasinoProvider = {
  name: string;
  order: string;
  title: string;
  blocked_countries: string[];
  badge: null | { title: string; class: string };
};

export type CasinoFetchingParams = {
  partner_id: number;
  is_mobile: number;
  lang: string;
};

export type CasinoGamesFetchingParams = CasinoFetchingParams & {
  by_key: string;
  offset: number;
  limit: number;
  category?: string;
  provider?: string;
  use_webp?: number;
};

export type CasinoTournamentGamesFetchingParams = CasinoFetchingParams & {
  with: string;
};

type CasinoGame = {
  active: string;
  background?: string;
  backgrounds: string[];
  blocked_countries: string[];
  blocked_currencies: string[];
  categories: string[];
  cats: CasinoGameCategory[];
  description: string | null;
  extearnal_game_id: string;
  feats: [];
  features: [];
  front_game_id: string;
  game_options: null | string;
  game_skin_id: string;
  has_age_restriction: number;
  icon_1: string;
  icon_2: string;
  icon_3: string;
  icons: string[];
  id: string;
  name: string;
  provider: string;
  provider_title: string;
  ratio: string;
  rtp: null | string;
  server_game_id: string;
  show_as_provider: string;
  status: string;
  themes: [];
  thms: [];
  title: string | null;
  types: CasinoGameTypes;
  paytable?: string;
  badge?: { title: string; class: string };
};

type TCasinoResponseWithPaytable = {
  count: string;
  games: NewCasinoGameBackend[];
  paytable?: string;
};

export type CasinoResponse =
  | [string, TCasinoResponseWithPaytable | NewCasinoGameBackend][]
  | null;

type CasinoGameTypes = {
  is_mobile?: number;
  realMode: number;
  is_target?: number;
  viewMode: number;
  funMode: number;
};

type CasinoGameCategory = {
  id: string;
  title: string;
  type: string;
};

export type CasinoCategoryTuple = [number, CasinoGame[]];

type LobbyBannerImages = Array<{
  LangId: string;
  Images: Array<{
    ImageUrl: string;
    ImagePath: string;
    Name: string;
    ImageEncoded: string;
  }>;
}>;

export interface CasinoTournamentsType {
  Id: number;
  Name: string;
  ProductTypeId: number;
  LobbyBannerImages: LobbyBannerImages;
  DetailsBannerImages: LobbyBannerImages;
  PrizeFund: number;
  RegistrationAmount: number;
  Stage: number;
  RegistrationStartDate: Date;
  RegistrationEndDate: Date;
  StartDate: Date;
  EndDate: Date;
  JoinDate: Date | null | boolean;
  PrizeStructure: PrizeStructureType;
  CurrencyId: string;
  JoinedPlayersCount: number;
  MinBet: number;
  MaxBet: number | null;
  MinRounds: number;
  MaxRounds: number | null;
  MinStartingNumberOfPlayers: number;
  GameList: Array<{
    CategoryId: number;
    Id: number;
    ProviderId: number;
    ProviderName: string;
  }>;
  Description: string;
  TopPlayerList: TournamentPlayerList;
  IsParticipated: boolean;
  autoJoin: boolean;
  CurrentPlayerStats: TournamentPlayer;
  games: NewCasinoGame[];
  gamesTotalCount: number;
}

export type PrizeStructureType = Array<{
  PlaceNumber: number;
  PrizePercent: number;
}>;

export type TournamentPlayerList = {
  TopPlayerList: TournamentPlayer[];
  CurrentPlayer: TournamentPlayer | null;
};

export type TournamentPlayer = {
  TourPlayerId: number;
  PlayerId: number;
  ExternalId: number;
  Amount: number;
  Place: number;
  WinnedAmount: number;
  BetCount: number;
  RemainingRounds: number | null;
  ExternalIdMasked: string;
};

export type TCasinoCategoryItems = Record<string, { icon?: string }>;

export enum CasinoQuickDepositTriggerView {
  ICON,
  ICON_WITH_TEXT
}

export enum EEntryTypesOptions {
  BUY_IN = 0,
  FREE_ENTRY = 1
}
/*eslint no-magic-numbers:*/
export enum EStatusOptionsTypes {
  ALL = 0,
  CANCELED = -1,
  FINISHED = 1,
  LIVE = 2,
  UPCOMING = 3
}

export enum EAdditionalInformationTypes {
  CANCELED = -1,
  FINISHED = 1,
  ENDS_IN = 2,
  STARTS_IN = 3
}

export enum ETournamentProductTypeIds {
  ALL,
  CASINO,
  SPORT
}

export enum ETournamentStatuses {
  finished = 1,
  canceled = -1,
  running = 2,
  future = 3
}

export enum ECasinoGameMode {
  REAL = 'real',
  FUN = 'fun'
}

export enum ECasinoGameView {
  SINGLE,
  MULTI
}

export enum ECasinoGameViewOrder {
  UNASSIGNED,
  FIRST,
  SECOND
}

export interface ICasinoCachedGame {
  active: boolean;
  storeId: number;
  order: ECasinoGameViewOrder;
  data: NewCasinoGame;
  mode: ECasinoGameMode;
}

export type ICasinoCachedGames = Record<string, ICasinoCachedGame>;
