import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { importDayJsLang } from './dayjs';

export const initI18nManual = () => {
  const lang = document.documentElement.lang.slice(0, 2);
  importDayJsLang();

  const getTranslations = async () => {
    const version = window.partnerConfigs?.translationsVersion
      ? `?v=${window.partnerConfigs.translationsVersion}`
      : '';

    const url = `${SpringConfigs.USER_FILES_URL}/data/translations/${SpringConfigs.CURRENT_LANGUAGE}_override.json${version}`;
    let params: any = [];
    await fetch(url)
      .then(data => data?.json())
      .then(resp => {
        params = resp;
      });

    return params;
  };

  const multiLangPromise = new Promise(resolve => {
    import(
      /* webpackChunkName: "translations-[index]" */ `../locales/${lang}/translations.json`
    )
      .then(async langFile => {
        if (!SpringConfigs.USER_FILES_URL) {
          loadI18n(langFile);
          resolve(true);

          return;
        }

        getTranslations()
          .then(data => {
            resolve(true);

            for (const key in data) {
              if (langFile[key]) {
                Object.assign(langFile[key], data[key]);
              } else {
                langFile[key] = data[key];
              }
            }

            loadI18n(langFile);
          })
          .catch(() => {
            loadI18n(langFile);
            resolve(true);
          });
      })
      .catch(async () => {
        const en: any = await import('../locales/en/translations.json');

        if (!SpringConfigs.USER_FILES_URL) {
          loadI18n(en);
          resolve(true);

          return;
        }

        getTranslations()
          .then(data => {
            resolve(true);

            for (const key in data) {
              en[key] && Object.assign(en[key], data[key]);
            }

            loadI18n(en);
          })
          .catch(() => {
            loadI18n(en);
            resolve(true);
          });
      });

    const loadI18n = (data: Record<string, any>) => {
      i18n
        .use(initReactI18next)
        .init({
          fallbackLng: 'en',
          lng: lang,
          resources: {
            en: {
              translations: data
            },
            ...(lang !== 'en' && {
              [lang]: {
                translation: data
              }
            })
          },
          ns: ['translations'],
          defaultNS: 'translations'
        })
        .then(() => {
          resolve(true);
        });
    };
  });

  return multiLangPromise;
};
