import { AnyAction } from 'redux';
import type { PaymentResponse } from 'interfaces/account-settings';
import { PaymentsActionType } from 'store/action-types';

export type TPaymentData = {
  data: PaymentResponse;
  loading: boolean;
  loaded: boolean;
  quickDeposit: {
    open: boolean;
  };
};

const INITIAL_DATA: TPaymentData = {
  data: {
    withdraw: {},
    deposit: {}
  },
  loaded: false,
  loading: false,
  quickDeposit: {
    open: false
  }
};

export const paymentsReducer = (
  state = INITIAL_DATA,
  action: AnyAction
): TPaymentData => {
  switch (action.type) {
    case PaymentsActionType.SET_PAYMENTS:
      return {
        ...state,
        data: JSON.parse(JSON.stringify(action.payload)),
        loading: false,
        loaded: true
      };
    case PaymentsActionType.SET_PAYMENTS_LOADING:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case PaymentsActionType.RESET_PAYMENTS:
      return INITIAL_DATA;
    case PaymentsActionType.SET_QUICK_DEPOSIT_OPEN:
      return {
        ...state,
        quickDeposit: {
          ...state.quickDeposit,
          open: action.payload
        }
      };

    default: {
      return state;
    }
  }
};
