import { AnyAction } from 'redux';
import { FreeBetAmounts } from 'interfaces/bet-data';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export interface IBetslipFreeBetSliceInitial {
  freeBets: {
    selectedFreeBetId: number | null;
    selectedFreeBetEventId: number | string | null;
    singleEventFreeBets: Record<string, FreeBetAmounts>;
    multipleFreeBets: FreeBetAmounts;
    advancedFreeBets: FreeBetAmounts;
    totalReturn: string | undefined;
  };
  isFrenchFreeBetEnabled: boolean;
}

export const betslipFreeBetSliceInitialData: IBetslipFreeBetSliceInitial = {
  freeBets: {
    selectedFreeBetId: null,
    selectedFreeBetEventId: null,
    singleEventFreeBets: {},
    multipleFreeBets: [],
    advancedFreeBets: [],
    // @Todo need to remove
    totalReturn: undefined
  },
  isFrenchFreeBetEnabled: false
};

export const betslipFreeBetSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: AnyAction
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.UPDATE_EVENT_AND_GAME_IDS:
      return {
        ...state,
        freeBets: {
          ...betslipFreeBetSliceInitialData.freeBets,
          advancedFreeBets: state.freeBets.advancedFreeBets,
          multipleFreeBets: state.freeBets.multipleFreeBets,
          singleEventFreeBets: state.freeBets.singleEventFreeBets
        }
      };

    case BetslipActionTypes.UPDATE_FREE_BET_PARTIAL:
      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          ...action.payload
        }
      };

    case BetslipActionTypes.SET_FREE_BET_SELECTED_EVENT_ID:
      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          selectedFreeBetEventId: action.payload
        }
      };

    case BetslipActionTypes.SET_SELECTED_FREE_BET_AMOUNT_ID:
      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          selectedFreeBetId: action.payload
        }
      };

    case BetslipActionTypes.SET_ADVANCED_FREE_BET_DATA:
      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          advancedFreeBets: action.payload
        }
      };

    case BetslipActionTypes.SET_FREE_BETS_DATA_FIELDS:
      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          ...action.payload
        }
      };

    case BetslipActionTypes.UPDATE_FREE_BET_SINGLE_EVENTS:
      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          singleEventFreeBets: {
            ...state.freeBets.singleEventFreeBets,
            ...action.payload
          }
        }
      };

    case BetslipActionTypes.REMOVE_FREE_BET_ITEM: {
      const singleEventFreeBets = structuredClone(
        state.freeBets?.singleEventFreeBets || {}
      );

      for (const k in singleEventFreeBets) {
        if (
          singleEventFreeBets[k].some(({ value }) => value === action.payload)
        ) {
          singleEventFreeBets[k] = singleEventFreeBets[k].filter(
            ({ value }) => value !== action.payload
          );
        }
      }

      const advancedFreeBets = state.freeBets.advancedFreeBets.filter(
        ({ value }) => value !== action.payload
      );

      const multipleFreeBets = state.freeBets.multipleFreeBets.filter(
        ({ value }) => value !== action.payload
      );

      return {
        ...state,
        freeBets: {
          ...state.freeBets,
          advancedFreeBets,
          singleEventFreeBets,
          multipleFreeBets
        }
      };
    }

    case BetslipActionTypes.RESET_FREE_BETS_DATA_FIELDS:
      return {
        ...state,
        freeBets: {
          ...structuredClone(betslipFreeBetSliceInitialData.freeBets),
          ...(action.payload && {
            advancedFreeBets: state.freeBets.advancedFreeBets,
            multipleFreeBets: state.freeBets.multipleFreeBets,
            singleEventFreeBets: state.freeBets.singleEventFreeBets
          })
        }
      };

    case BetslipActionTypes.SET_IS_FRENCH_FREE_BET_ENABLED:
      return {
        ...state,
        isFrenchFreeBetEnabled: action.payload
      };
  }

  return null;
};
