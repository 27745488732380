import { FC, ReactElement } from 'react';
import { useContentHeight } from 'hooks/useContentHeight';

type Props = {
  considerTabs?: boolean;
};

export const FallbackContainer: FC<Props> = ({
  considerTabs = false
}): ReactElement => {
  const contentHeight = useContentHeight(considerTabs);

  return <div style={{ height: contentHeight }} />;
};
