import { applyDeviceTypeAttributeToDocument } from './applyDeviceTypeAttributeToDocument';
import { configureAsyncValidator } from './configure-async-validator';
import { configureMessage } from './configure-message';
import { configureSpring } from './configure-spring';
import { configureDayjs } from './dayjs';

export const configure = () => {
  configureSpring();
  configureMessage();
  configureAsyncValidator();
  applyDeviceTypeAttributeToDocument();
  configureDayjs();
};
