import { ReactElement } from 'react';
import { EBetslipBetType, TSuspendReason } from 'interfaces/betslip';
import { ResponseGeneric } from 'interfaces/generic';
import {
  Game,
  GameInfo,
  Market,
  RacingGame
} from 'interfaces/sportsbook-data-levels';

export type HistoryItem = {
  DocumentTypeName: string;
  PaymentSystemName: string | null;
  TransactionId: number;
  Created: number;
  Game: string;
  Amount: number;
  Balance: number;
  FrozenBalance: number;
  DocumentId: number;
  ThirdPartyId: number;
  ProductId?: number;
  HasChildren: boolean | null;
};

export type Event = {
  game_info: GameInfo;
  game_status: string;
  team1: string;
  team2: string;
  game_name: string;
  game_id: string;
  region_name: string;
  sport_index: string;
  sport_name: string;
  sport_alias: string;
  competition_name: string;
  current_game_state: string;
  current_game_time: string;
  isLive: boolean;
  selection_id: number;
  event_name: string;
  coeficient: number;
  market_name: string;
  game_start_date: number;
  outcome: number;
  isSuspended?: boolean;
  eventBase?: number;
  sp?: boolean;
  ewAllowed?: boolean;
  extra_info?: string;
  each_way?: boolean | null;
  type_1?: string;
};

export type betDetailsType = {
  sport_name: string;
  description: Array<string>;
  translations: Array<any>;
  fixture: string;
  league_name?: string;
  identifier?: string;
  events?: Array<string>;
};

export type BetTicket = {
  bet_details: betDetailsType;
  id: number;
  type: number;
  odd_type: number;
  amount: number;
  k: number;
  currency: string;
  outcome: number;
  number: null | number;
  client_id: number;
  betshop_id: null | number;
  is_live: boolean;
  payout: number;
  possible_win: number;
  accept_type_id: number;
  cash_out: number | null;
  system_min_count: null | number;
  client_login: string;
  barcode: number;
  calc_date: null | number;
  date_time: number;
  events: Array<BetTicketEvent>;
  client_bonus_id: null | number;
  bonus_bet_amount: number;
  bonus: number;
  is_super_bet: boolean;
  is_bonus_money: boolean;
  is_free_bet_money?: boolean;
  source: number;
  draw_number: null | number;
  tags: null | number;
  old_bet_id: number;
  auto_cash_out_amount: null | number;
  additional_amount: null | number;
  each_way: null | boolean;
  total_partial_cashout_amount: null | number;
  remaining_stake: number;
  tax_amount: number;
  has_recalculation_reason: boolean;
  info_cashdesk_id: null | number;
  stake_tax_amount: null | number;
  is_gift: null | boolean;
  predefined_multiple_id: number;
  system_combinations: null | SystemCombinations[];
  system_with_linked_matches: null | boolean;
  cashouts_history?: Array<{
    cashout_amount: number;
    date: number;
    stake: number;
  }>;
  // custom prop
  show: boolean;
  possible_tax_amount: null | number;
  profit?: number;
  adm_info_model?: {
    is_freebet_awarded?: boolean;
  };
  game_id?: number;
};

export type BetTicketEvent = {
  bet_id: number;
  selection_id: number;
  coeficient: number;
  outcome: number;
  outcome_name: string;
  game_info: string;
  event_name: string;
  game_start_date: number;
  team1: string;
  team2: string;
  game_id: number;
  is_live: boolean;
  sport_id: number;
  sport_index: string;
  sport_name: string;
  competition_name: string;
  current_game_state: string;
  current_game_time: string;
  isLive: boolean;
  region_name?: string;
  region_alias?: string;
  market_name: string | ReactElement[];
  match_display_id: number;
  game_name: string;
  basis: number;
  match_info: null | string;
  info: null | string;
  home_score: null | number | string;
  away_score: null | number | string;
  cash_out_price: null | number;
  selection_price: number;
  order: number;
  is_banker: null | boolean;
  game_end_time: number;
  sport_alias?: string;
  gameStartTime?: string;
  gameStartDate?: string;
  extra_info?: string;
  each_way?: boolean | null;
  is_calculated?: boolean;
};

type SystemCombinations = {
  BetAmount: number;
  SystemMinCount: number;
};

export type BetTicketMin = {
  id: number;
  amount: number | null;
};

export type BetSlipEventObj = {
  id?: string | number;
  sport_index?: string;
  sport_name?: string;
  sport_alias?: string;
  sport_id?: number;
  sport_type?: number;
  competition_name: string;
  current_game_state: string;
  current_game_time: string;
  isLive: boolean;
  region_name: string;
  region_alias?: string;
  game_start_date: number;
  market_name: string;
  marketType: string;
  market?: Market<Game | RacingGame>;
  gameId: number;
  eventId: number;
  event_name: string;
  coeficient: number | string | undefined;
  eventBase?: number | undefined;
  team1: string;
  team2?: string;
  isSuspended?: boolean;
  suspendReasons?: TSuspendReason[] | null;
  game_name?: string;
  game_state?: string;
  game_time?: string;
  game_status?: string;
  type?: number;
  game_info?: GameInfo;
  sp?: boolean;
  express_id?: number;
  isConflict?: boolean;
  ewAllowed?: boolean;
  each_way?: boolean;
  stake?: string | number;
  baseRid?: string;
  tag?: number;
  competitionId?: number;
  type_1?: string;
  isUsa?: boolean;
  booking_id?: number;
};

export interface SharingEvent extends BetSlipEventObj {
  gameStartDate: string;
  gameStartTime: string;
  selection_id: number;
  outcome: number;
  fixed_game_start_date?: string;
  fixed_outcome?: string;
  fixed_game_start_time?: string;
}

interface SharingDataParams {
  booking_id?: number;
  bet_id?: number;
  fixed_type?: string;
  fixed_outcome?: string;
  fixed_date_time?: string;
  fixed_amount?: string;
  fixed_possible_win?: string;
  bet_type?: string;
  odd_type: number | string;
  currency: string;
}

export type SharingData = {
  booking_id?: number;
  bet_id?: number;
  locale?: string;
  params: SharingDataParams &
    Partial<
      Pick<
        BetTicket,
        | 'events'
        | 'each_way'
        | 'type'
        | 'id'
        | 'date_time'
        | 'outcome'
        | 'amount'
        | 'possible_win'
        | 'k'
        | 'bonus_bet_amount'
        | 'is_free_bet_money'
        | 'client_bonus_id'
        | 'bonus'
        | 'is_bonus_money'
      >
    >;
};

export type CashoutDetalisRule = {
  BetId: number;
  MinAmount: number;
  PartialAmount: number;
  Token: string;
};

export type CashoutDetailsPriceChange = {
  code: number | string;
  new_price: number;
  text_code: string | null;
};

export type CashoutDetailsManual = {
  price: number;
};

export type MaxBetResponse = ResponseAbstract & MaxBetDetails;

export type MaxBetDetails = {
  details: {
    amount: number;
    unit_stake?: number;
  };
};

type ResponseAbstract = {
  result: number | string;
  result_text: null | string;
};

export type CashoutResponse = ResponseAbstract & {
  details:
    | {}
    | CashoutDetailsManual
    | CashoutDetailsPriceChange
    | CashoutDetalisRule;
};

export type PermissibleOdds = ResponseAbstract & {
  details: Ladder[];
};

export type DoBetResponseType = ResponseAbstract & {
  StatusCode?: string;
  Data?: {
    Key: string;
    Message: string;
    ErrorData: {
      SelectionId: string | number;
      OldPrice: string | number;
      ActualPrice: string | number;
    };
  };
  details:
    | {
        bet_id: number;
      }
    | Array<{
        event_id: number;
        status: string;
        price: number;
        old_price: number;
        new_price: number;
        new_event_id: number;
        new_basis: number;
      }>;
};

export type UkBets = {
  name: string;
  type: number;
  count: number;
  combCount: number;
  strongCount: boolean;
  addSingleOdds: boolean;
  posWin: number;
  amount: number;
  stake: string;
  sys_bet: number | boolean | string;
  each_way: boolean;
  active: boolean;
  folds: boolean;
  showPosWin: boolean;
  ewPosWin: number;
};

export type SystemEntity = {
  label: string;
  value: number;
  enabled?: boolean;
  combinationCount?: number;
};

export type Ladder = {
  Price: number;
  Numerator: number;
  Denominator: number;
};

export type MarketTypeSwarm = {
  Kind: string;
  BasaltKind: string;
  SelectionCount: number;
  SportId: number;
  Order: number;
  MarketTypeName: string;
  SportAlias: string;
  SelectionTypesNames?: string[];
};

export type MarketType = {
  label: string;
  value: string;
};

export type MarketTypeResponce = Omit<ResponseGeneric, 'details'> & {
  details: MarketTypeSwarm[];
};

export type BookBetParamsType = {
  BetType: number;
  Amount: number;
  SystemMinCount?: number;
  Selections?: {
    SelectionId: number;
    Price: number;
  }[];
  BetSlipUid?: string;
}[];

export type TGetFreeBetAmountEvent = {
  SelectionId: number;
  Coeficient: number;
};

export type TV3FreebetParams = {
  Events: Array<TGetFreeBetAmountEvent>;
  IsLive: boolean;
  Type?: number;
};

export type TV3FreebetResponse = ResponseAbstract & {
  status?: number;
  details: TAdvancedFreebet[];
};

export type TAdvancedFreebet = {
  id: number;
  amount?: number;
  amount_percent?: number;
  expiration_date: number;
  freebet_max_payouts: FreebetMaxPayoutsEntity[];
  bet_types: number[];
  boost_max_bet_amounts?: TBoostMaxBetAmount[] | null;
};

export interface FreebetMaxPayoutsEntity {
  Currency: string;
  MaxAmount: number;
  MaxPercent: null | number;
}

export type TProfitBoostAdvanced = {
  single: TProfitBoostData[] | [];
  advanced: TProfitBoostData | null;
};

export type TProfitBoostData = {
  type?: EBetslipBetType;
  eventId: number | string | null | undefined;
  selected: boolean;
  items: Array<TProfitBoostItem>;
};

export type TProfitBoostItem = {
  id: number;
  // @Todo remove "selected" field
  selected?: boolean;
  amount_percent: number;
  expiration_date: number;
  boost_max_bet_amounts?: TBoostMaxBetAmount | null;
};

export type TBoostMaxBetAmount = {
  Currency: string;
  MaxBetAmount: number;
};

type TCreateBetsParams = {
  bets: Array<{
    Source: number | string | undefined;
    EachWay: boolean | undefined;
    Amount: number;
    Events: Array<{
      SelectionId: number;
      Coeficient: number;
    }>;
    OddType: number;
    Type: number;
    AdditionalAmount?: number;
    OldBetId?: number;
  }>;
};

export type TCreateBets = {
  command: string;
  params: TCreateBetsParams;
  rid: string;
};

export type TEditBet = {
  active: boolean;
  amount: number | string | null;
  betId: number | null;
  eventIds: number[];
} | null;

export type BookingIdObject = {
  booking_id: number;
  selection_id?: number;
};

export type TFreeBetAmount = {
  label: number;
  value: number;
  maxPayouts: FreebetMaxPayoutsEntity[];
};

export type FreeBetAmounts = Array<TFreeBetAmount>;

export type FreeBetData = {
  [key: string]: FreeBetAmounts;
};

export type BookingIds = Array<BookingIdObject>;

export interface IBalanceHistoryDetails {
  State: number;
  Amount: number;
  Created: number;
  ExternalId: number;
}

export enum EBetslipPage {
  BETSLIP,
  OPEN_BETS
}

export enum EAdvancedSelectedTabs {
  QUICKBET,
  MULTIPLES
}
