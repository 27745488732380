import { CALCULATE_POWER_OF_10 } from 'utils/constants/app/general';
import RidConstants from 'utils/constants/swarm/rid-prefix-constants';

export const CALCULATE_POWER_OF_15 = 15;

const rand = (): string => {
  return Math.floor(
    Math.random() * Math.pow(CALCULATE_POWER_OF_10, CALCULATE_POWER_OF_15)
  ).toString();
};

const gForCommand = (key?: string): string => {
  return `${key ?? ''}${RidConstants.COMMAND}${rand()}`;
};

const gForSubscribe = (key?: string): string => {
  return `${key ?? ''}${RidConstants.SUBSCRIBE}${rand()}`;
};

const gForUnsubscribe = (): string => {
  return `${RidConstants.UNSUBSCRIBE}${rand()}`;
};

const gForRequestSession = (): string => {
  return `${RidConstants.REQUEST_SESSION}${rand()}`;
};

const gCustom = (str = ''): string => {
  return `${str}${rand()}`;
};

const RidGenerator = {
  gForCommand,
  gForSubscribe,
  gForUnsubscribe,
  gForRequestSession,
  gCustom,
  rand
};

export default RidGenerator;
