import LS from 'utils/bom-dom-manipulation/local-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { ActionType } from 'interfaces/generic';
import { TPopularBet, TSelectedLeagues, TSport } from 'interfaces/popular-bets';
import { PopularBetsActionTypes } from 'store/action-types/popular-bets';

const getSelectedLeagues = (): TSelectedLeagues => {
  return (
    JSON.parse(LS.getItem(storageKeyName('popularBets', 'SELECTED_LEAGUES'))) ||
    {}
  );
};

type TState = {
  bets: TPopularBet[] | null;
  selectedLeagues: TSelectedLeagues;
  selectedSport: TSport | null;
};

const initialData: TState = {
  bets: null,
  selectedLeagues: getSelectedLeagues(),
  selectedSport: null
};

export const popularBets = (
  state = initialData,
  action: ActionType
): TState => {
  switch (action.type) {
    case PopularBetsActionTypes.SET_BETS:
      return {
        ...state,
        bets: action.payload
      };
    case PopularBetsActionTypes.SET_SELECTED_LEAGUES:
      LS.setItem(
        storageKeyName('popularBets', 'SELECTED_LEAGUES'),
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        selectedLeagues: action.payload
      };
    case PopularBetsActionTypes.SET_SELECTED_SPORT:
      return {
        ...state,
        selectedSport: action.payload
      };

    default: {
      return state;
    }
  }
};
