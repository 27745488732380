import { ActionType } from 'interfaces/generic';
import { SpecElemsActionTypes } from 'store/action-types/spec-elems';

export const setElementPresent = (payload: string): ActionType => {
  return {
    type: SpecElemsActionTypes.SET_ELEMENT_PRESENT,
    payload
  };
};

export const setElementAbsent = (payload: string): ActionType => {
  return {
    type: SpecElemsActionTypes.SET_ELEMENT_ABSENT,
    payload
  };
};

export const resetAllElements = (): ActionType => {
  return {
    type: SpecElemsActionTypes.SET_ELEMENT_ABSENT
  };
};
