import { BetSlipEventObj, BetTicket, SystemEntity } from 'interfaces/bet-data';
import {
  BetslipEvents,
  SportBonus,
  TSuperBetEventType
} from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import {
  BetslipActionTypes,
  betslipBetsSliceActions
} from 'store/action-types/bet-slip';

export const triggerBetted = (): ActionType => {
  return {
    type: BetslipActionTypes.BETTED_TRIGGER
  };
};

export const setCashoutFrom = (
  payload?: 'betslip' | 'betHistory' | ''
): ActionType => {
  return {
    type: BetslipActionTypes.SET_CASHOUT_FROM,
    payload
  };
};

export const setSystem = (
  payload: SystemEntity | null | undefined
): ActionType => {
  return {
    type: BetslipActionTypes.SET_SYSTEM,
    payload
  };
};

export const toggleSuperBet = (payload: TSuperBetEventType): ActionType => {
  return {
    type: BetslipActionTypes.TOGGLE_SUPER_BET,
    payload
  };
};

export const resetSuperBets = (): ActionType => {
  return {
    type: BetslipActionTypes.RESET_SUPER_BETS
  };
};

export const setSentCounterOffersCount = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_SENT_COUNTER_OFFERS_COUNT,
    payload
  };
};

export const setSentSuperBetCount = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_SENT_SUPER_BET_COUNT,
    payload
  };
};

export const setEditBetActive = (payload: object): ActionType => {
  return {
    type: BetslipActionTypes.SET_EDIT_BET_ACTIVE,
    payload
  };
};

export const setConflictingGameIds = (payload: Set<number>): ActionType => {
  return {
    type: BetslipActionTypes.SET_CONFLICTING_GAME_IDS,
    payload
  };
};

export const setSportBonusRules = (payload: SportBonus[]): ActionType => {
  return {
    type: BetslipActionTypes.SET_SPORT_BONUS_RULES,
    payload
  };
};

export const setSingleReturn = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_SINGLE_RETURN,
    payload
  };
};

export const setIsCalculating = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_IS_CALCULATING,
    payload
  };
};

export const setCalculationLoading = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_CALCULATION_LOADING,
    payload
  };
};

export const setSubscribedEvents = (payload: BetslipEvents): ActionType => {
  return {
    type: BetslipActionTypes.SET_SUBSCRIBED_EVENTS,
    payload
  };
};

export const removeSubscribedEvent = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.REMOVE_SUBSCRIBED_EVENT,
    payload
  };
};

export const mergeSubscribedEvents = (payload: BetslipEvents): ActionType => {
  return {
    type: betslipBetsSliceActions.MERGE_SUBSCRIBED_EVENTS,
    payload
  };
};

export const setCashoutTicket = (payload: BetTicket | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_CASHOUT_TICKET,
    payload
  };
};

export const setTaxAmountRanges = (payload: object | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_TAXES_DATA,
    payload
  };
};

export const setBasicEw = (payload: {
  systemEw: boolean;
  multipleEw: boolean;
}): ActionType => {
  return {
    type: BetslipActionTypes.SET_BASIC_EW,
    payload
  };
};

export const setTotalSingleWin = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_TOTAL_SINGLE_WIN,
    payload
  };
};

export const setIsAccumulatorBonusCalculating = (
  payload: boolean
): ActionType => {
  return {
    type: BetslipActionTypes.SET_IS_ACCUMULATOR_BONUS_CALCULATING,
    payload
  };
};

export const setCombinationOfSelectedSystem = (
  payload: BetSlipEventObj[][]
): ActionType => {
  return {
    type: BetslipActionTypes.SET_COMBINATION_OF_SELECTED_SYSTEM,
    payload
  };
};

export const setPossibleSystemCombinations = (
  payload: SystemEntity[]
): ActionType => {
  return {
    type: BetslipActionTypes.SET_SYSTEM_POSSIBLE_COMBINATIONS,
    payload
  };
};

export const setSystemMinCombinationsCount = (
  payload: number | null
): ActionType => {
  return {
    type: BetslipActionTypes.SET_SYSTEM_MIN_COMBINATIONS_COUNT,
    payload
  };
};

export const setSelectedSystem = (payload: SystemEntity | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_SELECTED_SYSTEM,
    payload
  };
};

export * from './bets';
export * from './odds';
export * from './stake';
export * from './configs';
export * from './freeBet';
export * from './openBets';
export * from './features';
export * from './calculator';
export * from './profitBoost';
export * from './counterOffer';
