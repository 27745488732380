import LS from 'utils/bom-dom-manipulation/local-storage';
import { arrayFindBy } from 'utils/collection-manipulation/array-find-by';
import { VAIX_IFRAME_ID } from 'utils/constants/app/iframe-ids';
import {
  BET_TYPES_CONF,
  indicatorBookingId
} from 'utils/constants/betslip/bet-types';
import { RACING_SPORT_IDS } from 'utils/constants/sportsbook/racing-sport';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { isMobile } from 'utils/is-mobile';
import { serializeSwarmData } from 'utils/swarm/serialize-swarm-data';
import { BetSlipEventObj, EBetslipPage, TEditBet } from 'interfaces/bet-data';
import { EBetslipBetType, TBetslipEvent } from 'interfaces/betslip';
import {
  Event as EventType,
  SwarmDataBetslipEvents
} from 'interfaces/sportsbook-data-levels';
import { dispatchBetslipAllBets } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/dispatchBetslipAllBets';
import store from 'store';
import {
  setActiveMarketOdd,
  setActiveTabPage,
  setBetUkType,
  setBookBetId,
  setConflictingGameIds,
  setEventsPrevOdds,
  setFreeBetField,
  setFreezeTabChange,
  setQuickSingleBetslipOpen,
  setStakes,
  updateEventAndGameIds
} from 'store/actions/betslip';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';
import { dataspotFunnelAction, EDataspotAction } from '../analytics/dataspot';
import { sendPostMessage } from '../bom-dom-manipulation/send-post-message-to-iframe';

type BetSlip = {
  bets: BetSlipEventObj[];
};

const UNMATCHED_ELEMENT_INDEX = -1;

export const betslipIsEmpty = (): boolean => {
  return !getBetSlipFromLs().bets.length;
};

export const betSlipInitRedux = (): void => {
  const betSlip: BetSlip = getBetSlipFromLs();
  const bets: BetSlipEventObj[] = betSlip.bets || [];
  storeSubscriptionsIdsToRedux(bets);
};

export const checkConflicts = (bets: BetSlipEventObj[]): void => {
  const conflictingGameIds: Set<number> = new Set();

  bets.reduce((gameIds, bet) => {
    let checkForMultipleEventsFromSingleEventsConflict = false;

    bets.forEach(item => {
      if (
        bet.gameId === item.gameId &&
        bet.eventId !== item.eventId &&
        bet.marketType === bet.marketType &&
        bet.express_id === item.express_id
      ) {
        gameIds.add(item.eventId);
        checkForMultipleEventsFromSingleEventsConflict = true;
      }
    });

    let checkForExpressIdConflict = false;

    !bet.express_id &&
      bets.forEach(item => {
        if (bet.gameId === item.gameId && item.express_id) {
          gameIds.add(item.eventId);

          checkForExpressIdConflict = true;
        }
      });

    if (
      checkForExpressIdConflict ||
      checkForMultipleEventsFromSingleEventsConflict
    ) {
      gameIds.add(bet.eventId);
    }

    return gameIds;
  }, conflictingGameIds);

  store.dispatch(setConflictingGameIds(conflictingGameIds));
};

export const addBetSlipEvent = (
  betSlipEventObjNew: BetSlipEventObj,
  addedFromInlineCard: boolean,
  isEvent?: boolean,
  correctIndex?: number
): void => {
  // @Todo consider EachWay case

  // @Todo move all this logics onto middleware
  store.dispatch(setFreeBetField({ selectedFreeBetEventId: null }));
  setBookBetIdInLs(null);
  // why we dio it here??
  const betSlipEventObj = JSON.parse(JSON.stringify(betSlipEventObjNew));

  store.dispatch(setActiveMarketOdd(betSlipEventObj));
  const bets: BetSlipEventObj[] = structuredClone(
    store.getState()?.betSlip?.allLsBets || []
  );

  const eventIndex = bets.findIndex(
    i =>
      i.eventId === betSlipEventObj.eventId ||
      (i.market_name === betSlipEventObj.market_name &&
        i.gameId === betSlipEventObj.gameId) ||
      (i.express_id === betSlipEventObj.express_id &&
        i.gameId === betSlipEventObj.gameId)
  );

  const shouldAddEventWithConflict = SpringConfigs.BETS_WITH_SAME_GROUP;

  if (!shouldAddEventWithConflict && eventIndex > UNMATCHED_ELEMENT_INDEX) {
    bets.splice(eventIndex, 1, betSlipEventObj);
  } else {
    const spReplaceEventIndex = bets.findIndex(
      bet =>
        bet.eventId === betSlipEventObj.eventId &&
        (betSlipEventObj.sp || bet.sp)
    );

    if (spReplaceEventIndex > UNMATCHED_ELEMENT_INDEX) {
      bets.splice(spReplaceEventIndex, 1, betSlipEventObj);
    } else {
      bets.splice(correctIndex || bets.length, 0, betSlipEventObj);
    }
  }

  dataspotFunnelAction(EDataspotAction.AddingEventToBetslip);

  sendBetsToVaix(bets);
  checkConflicts(bets);
  dispatchBetslipAllBets(bets);
  // store.dispatch(setAllLsBets(bets));
  storeSubscriptionsIdsToRedux(bets);
  store.dispatch(setActiveTabPage(EBetslipPage.BETSLIP));

  if (isMobile() && bets.length === 1) {
    store.dispatch(setQuickSingleBetslipOpen(true));
  } else {
    store.dispatch(setQuickSingleBetslipOpen(false));
  }

  return;
};

export const updateBetSlipEvent = (
  event: Record<string, TBetslipEvent>
): void => {
  const betSlip: BetSlip = getBetSlipFromLs();
  const bets: BetSlipEventObj[] = [];

  betSlip.bets.forEach((bet: BetSlipEventObj) => {
    const newEvent = event[bet.eventId];

    if (newEvent) {
      bet.team1 = newEvent.team1 || bet.team1;
      bet.team2 = newEvent.team2 || bet.team1;
      bet.sport_name = newEvent.sport_name || bet.sport_name;
      bet.region_name = newEvent.region_name || bet.region_name;
      bet.competition_name = newEvent.competition_name || bet.competition_name;
      bet.market_name = newEvent.market_name || bet.market_name;
      bet.suspendReasons = newEvent.suspendReasons;
      bet.isSuspended = !!newEvent.suspendReasons;
      bet.sport_id = newEvent.sport_id;
      bet.express_id = newEvent.express_id;
      bet.event_name = newEvent.event_name;

      if (typeof bet.market !== 'number' && bet.market) {
        bet.market.name = newEvent.market_name || bet.market?.name;
      }

      const isRacing = RACING_SPORT_IDS.includes(newEvent.sport_id);

      if (!isRacing) {
        bet.game_name = newEvent.game_name || bet.game_name;
      }
    }

    bets.push(bet);
  });

  // if (bets.length) {
  //   LS.setItem(
  //     storageKeyName('betslip', 'BETSLIP_DATA'),
  //     JSON.stringify({ bets })
  //   );
  // }
  // store.dispatch(setAllLsBets(bets));
  dispatchBetslipAllBets(bets);

  return;
};

export const removeBetSlipEvent = (
  eventId: string | number,
  freezeTabChange?: boolean
): [BetSlipEventObj, number] => {
  setBookBetIdInLs(null);
  // @Todo need to freeBet clean action
  store.dispatch(setFreeBetField({ selectedFreeBetEventId: null }));
  const betSlip: BetSlip = getBetSlipFromLs();
  const bets: BetSlipEventObj[] = betSlip.bets || [];
  const eventIndex = bets.findIndex(i => Number(i.eventId) === Number(eventId));
  let deletedEvent = {} as BetSlipEventObj;

  if (eventIndex > UNMATCHED_ELEMENT_INDEX) {
    [deletedEvent] = bets.splice(eventIndex, 1);
    checkConflicts(bets);
    dispatchBetslipAllBets(bets);
    // store.dispatch(setAllLsBets(bets));
    store.dispatch(setEventsPrevOdds({ [deletedEvent.eventId]: null }));
    // LS.setItem(
    //   storageKeyName('betslip', 'BETSLIP_DATA'),
    //   JSON.stringify(betSlip)
    // );
    storeSubscriptionsIdsToRedux(bets);
    freezeTabChange && store.dispatch(setFreezeTabChange(true));
    sendBetsToVaix(bets);

    store.dispatch(
      setStakes(
        Object.fromEntries(
          Object.entries(store.getState().betSlip.stakes || {}).filter(
            ([id]) => id !== eventId.toString()
          )
        )
      )
    );

    const { eventIds } = getBetslipEditBetActive() || {};

    if (eventIds?.includes(deletedEvent.eventId) && eventIds.length === 1) {
      LS.removeItem(storageKeyName('betslip', 'EDIT_BET_ACTIVE'));
    }
  }

  return [deletedEvent, eventIndex];
};

export const addBetSlipMultipleEvents = (
  betSlipEvents: BetSlipEventObj[],
  shouldReplaceBetslip = false,
  addToExistingBets = false,
  suggestedByHoory = false
): void => {
  setBookBetIdInLs(null);

  if (shouldReplaceBetslip) {
    replaceAllBetSlipEvent(betSlipEvents);
  } else {
    if (!addToExistingBets) {
      removeAllBetSlipEvent();
    }

    const betslipStore: IBetslipRootStateInitialData =
      store.getState()?.betSlip;

    const bets = structuredClone(betslipStore?.allLsBets || []);

    if (suggestedByHoory) {
      betSlipEvents.forEach(addedBetslipEvents => {
        const existingEventInBetslip = bets.find(
          betEvent => betEvent.eventId === addedBetslipEvents.eventId
        );

        if (existingEventInBetslip) {
          existingEventInBetslip.booking_id = addedBetslipEvents.booking_id;
        } else {
          bets.push(addedBetslipEvents);
        }
      });
    } else {
      bets.push(...betSlipEvents);
    }

    dispatchBetslipAllBets(bets);
    storeSubscriptionsIdsToRedux(bets);
  }

  return;
};

const replaceAllBetSlipEvent = (bets: BetSlipEventObj[]): void => {
  dispatchBetslipAllBets(bets);
  sendBetsToVaix(bets);
  storeSubscriptionsIdsToRedux(bets);
};

export const removeAllBetSlipEvent = (freezeTabChange?: boolean): void => {
  setBookBetIdInLs(null);
  dispatchBetslipAllBets([]);
  store.dispatch(setBetUkType(BET_TYPES_CONF));
  freezeTabChange && store.dispatch(setFreezeTabChange(true));
  storeSubscriptionsIdsToRedux([]);
  sendBetsToVaix([]);
};

const storeSubscriptionsIdsToRedux = (bets: BetSlipEventObj[]) => {
  store.dispatch(
    updateEventAndGameIds(
      bets.map(bet => ({
        eventId: bet.eventId,
        gameId: bet.gameId,
        marketId: bet.market?.id,
        ...(bet.baseRid && { baseRid: bet.baseRid })
      }))
    )
  );
};

export const getBetSlipFromLs = (): BetSlip => {
  let betSlip: BetSlip;

  try {
    //TODO need for clear wrong betslip data in local storage, remove after 1 month @todo-betslip
    // const savedBetSlip: { bets: BetSlipEventObj[] } | null = JSON.parse(
    //   LS.getItem(storageKeyName('betslip', 'BETSLIP_DATA'))
    // );

    // @Todo need to remove this logc from all places
    const bets: BetSlipEventObj[] = JSON.parse(
      JSON.stringify(store.getState().betSlip.allLsBets)
    );

    const savedBetSlip = { bets };

    if (savedBetSlip && !SpringConfigs.MOCKED_DATA) {
      //TODO need for clear wrong betslip data in local storage, remove after 1 month @todo-betslip
      if (savedBetSlip.bets) {
        savedBetSlip.bets = savedBetSlip.bets.filter(bet => !!bet);
      }

      betSlip = (savedBetSlip || { bets: [] }) as BetSlip;
    } else {
      betSlip = { bets: [] };
    }
  } catch (e) {
    betSlip = { bets: [] };
  }

  return betSlip;
};

export const normalizeStake = (
  result: string,
  value: string,
  maxLength: number,
  allowZero?: boolean
): string => {
  if (!value) {
    return result;
  }

  if (result?.length > maxLength) {
    return result;
  }

  if (result.includes('.') && value === '.') {
    return result;
  }

  if (!allowZero) {
    return result
      .concat(value)
      .replace(/^0+(?=[0-9])/, '')
      .replace(/^\./, '0.');
  } else {
    return result.concat(value);
  }
};

export const getType = (
  type: EBetslipBetType | '',
  forceSingle?: boolean
): EBetslipBetType => {
  return forceSingle
    ? EBetslipBetType.SINGLE
    : type ||
        (getBetSlipFromLs()?.bets?.length > 1
          ? EBetslipBetType.MULTIPLE
          : EBetslipBetType.SINGLE);
};

export const getBetslipType = (): number => {
  const type = LS.getItem(storageKeyName('betslip', 'BETSLIP_TYPE'));

  const isAdvancedBetslip = SpringConfigs.JURISDICTION === '1';

  return isAdvancedBetslip ? 1 : Number(type) || SpringConfigs.BETSLIP_TYPE;
};

export const setBetslipType = (type: number): void => {
  LS.setItem(storageKeyName('betslip', 'BETSLIP_TYPE'), `${type}`);
};

export const createBetslipEventsFromSwarm = (
  betslipEventsData: SwarmDataBetslipEvents,
  suggestedByHoory?: boolean
): BetSlipEventObj[] => {
  const serializedData = serializeSwarmData(betslipEventsData);

  return serializedData.events.map(event => {
    const market = arrayFindBy(
      serializedData.markets,
      'id',
      event.market_id,
      false
    );

    const game = arrayFindBy(serializedData.games, 'id', market.game_id, false);
    const competition = arrayFindBy(
      serializedData.competitions,
      'id',
      game.competition_id,
      false
    );

    const region = arrayFindBy(
      serializedData.regions,
      'id',
      competition.region_id,
      false
    );

    const sport = arrayFindBy(
      serializedData.sports,
      'id',
      region.sport_id,
      false
    );

    return {
      gameId: Number(market.game_id),
      marketType: market.type || '',
      market: market,
      eventId: Number(event.id),
      eventBase: event.base,
      team1: game.team1_name,
      team2: game.team2_name || '',
      coeficient: event.price,
      event_name: event.name || '',
      ewAllowed: event.ew_allowed || false,
      game_start_date: game.start_ts,
      sport_index: sport.alias,
      sport_alias: sport.alias,
      sport_name: sport.name || '',
      sport_type: Number(sport.type),
      competition_name: competition.name || '',
      competitionId: Number(competition.id),
      current_game_state: game.info.current_game_state || '',
      current_game_time: game.info.current_game_time || '',
      isLive: !!((game.type || 0) % 2),
      region_name: region.name,
      region_alias: region.alias || region.name,
      market_name: market.name || '',
      game_name: `${game.team1_name} ${
        game.team2_name ? ` - ${game.team2_name}` : ''
      }`,
      game_info: game.info,
      express_id: market.express_id,
      type: game.type,
      sport_id: Number(sport.id),
      type_1: event.type_1,
      ...(suggestedByHoory && {
        booking_id: indicatorBookingId.BY_HOORY // suggested by hoory indicator
      })
    };
  });
};

export const getBetslipEditBetActive = (): TEditBet => {
  const editBetData = JSON.parse(
    LS.getItem(storageKeyName('betslip', 'EDIT_BET_ACTIVE'))
  );

  if (editBetData !== 'null') {
    return editBetData;
  }

  return {
    active: false,
    amount: '',
    betId: null,
    eventIds: []
  };
};

export const updateBaseEventsData = (
  data: { event: Record<string, EventType> },
  betSlipEventObj: BetSlipEventObj,
  addedFromInlineCard: boolean,
  isEvent: boolean | undefined
): number | void => {
  if (!Object.values(data.event)[0]) {
    return;
  }

  const stakes = store.getState().betSlip.stakes;

  const oldStake = stakes[betSlipEventObj.eventId];

  const [deletedEvent] = removeBetSlipEvent(betSlipEventObj.eventId, true);

  const correctEventMain = Object.values(data.event)[0];

  if (correctEventMain && Object.keys(deletedEvent).length) {
    deletedEvent.eventId = correctEventMain.id;
    deletedEvent.eventBase = correctEventMain.base;
    deletedEvent.coeficient = `${correctEventMain.price}`;
    addBetSlipEvent(deletedEvent, addedFromInlineCard, isEvent);

    if (oldStake) {
      stakes[correctEventMain.id] = oldStake;
      store.dispatch(setStakes(stakes));
    }

    return correctEventMain.id;
  }
};

export const setBetslipTypeLS = (type: string): void => {
  LS.setItem(storageKeyName('betslip', 'TYPE'), type);
};

export const getBetslipTypeLs = (): string => {
  const type = LS.getItem(storageKeyName('betslip', 'TYPE'));

  return type === 'null' ? '' : type;
};

export const getAndRemoveMustOpenBetslipFromLS = () => {
  const betslipMustOpenStorage = storageKeyName('betslip', 'MUST_OPEN');
  const mustOpen = !!JSON.parse(LS.getItem(betslipMustOpenStorage));
  LS.removeItem(betslipMustOpenStorage);

  return mustOpen;
};

export const setBookBetIdInLs = (id: string | null): void => {
  if (id) {
    LS.setItem(storageKeyName('betslip', 'BOOK_BET_ID'), id);
  } else {
    LS.removeItem(storageKeyName('betslip', 'BOOK_BET_ID'));
  }

  store.dispatch(setBookBetId(id));
};

export const getBookBetIdFromLs = (): string => {
  return JSON.parse(LS.getItem(storageKeyName('betslip', 'BOOK_BET_ID')));
};

const sendBetsToVaix = (bets: BetSlipEventObj[]) => {
  if (document.getElementById('V3VaixRecommendationsWidget')) {
    const eventsToPost = createEventsObject(bets);
    sendPostMessage(
      { type: 'betSlipEventsChange', value: eventsToPost },
      VAIX_IFRAME_ID
    );
  }
};

export const createEventsObject = (
  allLsBets: BetSlipEventObj[]
): Record<string, { oddType: string; selected: boolean }> => {
  const eventsToPost: Record<string, { oddType: string; selected: boolean }> =
    {};

  allLsBets.forEach(bet => {
    eventsToPost[bet.eventId] = {
      oddType: bet.marketType,
      selected: true
    };
  });

  return eventsToPost;
};

export const getOutcomeStatus = (
  outcome: number
): 'success' | 'error' | 'processing' | 'default' => {
  switch (+outcome) {
    case 0:
      return 'processing';
    case 1:
      return 'error';
    default:
      return 'success';
  }
};

/*eslint no-magic-numbers:*/
// TODO check after betslip refactor
export const isNotReturnedOrWon = [0, 1, 5];
export const isNotCashedOut = [0, 1, 3];
