const DOM_EXCEPTION_CODE_INVALID_ACCESS = 22;
const DOM_EXCEPTION_CODE_TYPE_MISMATCH = 1014;

export const storageAvailable = (
  type: 'localStorage' | 'sessionStorage'
): boolean => {
  let storage;

  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (e) {
    return Boolean(
      e instanceof DOMException &&
        // everything except Firefox
        (e.code === DOM_EXCEPTION_CODE_INVALID_ACCESS ||
          // Firefox
          e.code === DOM_EXCEPTION_CODE_TYPE_MISMATCH ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
    );
  }
};
