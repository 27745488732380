import { EDecimalRoundTypes } from 'utils/string-manipulation/round-multiple-value';
import { CALCULATE_POW_OF_10 } from 'newelements/_Betslip/utils/constants/calculations';
import Store from 'store';

// @Todo need to change this variable into dynamic value after doing separator task
const DECIMAL_SEPARATOR = '.';

//
// /**
//  * @param {String} stake
//  *
//  * this function should apply on all amounts except odds
//  *  stake
//  *  tax amount
//  *  total stake
//  *  accumulator bonus
//  *  total return (except multiple total return, it has own rounding)
//  *  ...
//  * */
export const roundAmount = (_amount: string | number = ''): string => {
  const rounding: number = Store.getState().appData.rounding;

  const amount = typeof _amount === 'string' ? _amount : _amount.toString();
  const isRoundingExist = Number(rounding) === Number(rounding);

  const isStakeIncompleteForRounding =
    amount.endsWith(DECIMAL_SEPARATOR) && rounding !== 0;

  if (amount === '' || !isRoundingExist || isStakeIncompleteForRounding) {
    return amount;
  }

  // @Todo need to test all cases
  return round(amount, rounding, EDecimalRoundTypes.Cut).toString();
};

export const roundMultipleAmount = (_amount: string | number = '') => {
  const amount = typeof _amount === 'string' ? _amount : _amount.toString();
  const partnerConfig = Store.getState().socket.partnerConfigs;

  const roundMethod =
    partnerConfig?.multiple_possiblewin_round_method as EDecimalRoundTypes;

  const decimals = +(partnerConfig?.multiple_price_decimals || 0);

  return round(+amount, decimals, roundMethod);
};

export const roundOdd = (
  odd: number | string,
  extraRoundMethod: EDecimalRoundTypes | null = null
) => {
  const partnerConfig = Store.getState().socket.partnerConfigs;

  const roundMethod =
    extraRoundMethod ??
    (partnerConfig?.price_round_method as EDecimalRoundTypes);

  const decimals = +(partnerConfig?.price_decimals || 0);

  const isOddIncompleteForRounding =
    odd === '' || odd.toString().endsWith(DECIMAL_SEPARATOR);

  if (isOddIncompleteForRounding) {
    return odd;
  }

  return round(+odd, decimals, roundMethod);
};

export const round = (
  amount: number | string,
  decimals: number,
  roundMethod: EDecimalRoundTypes = EDecimalRoundTypes.Cut
) => {
  if (!amount) {
    return amount;
  }

  const afterDot = amount.toString().split(DECIMAL_SEPARATOR)[1];

  if (decimals && afterDot?.length > decimals) {
    // in case if user writes like  '1.000'
    if (afterDot.split('').every(s => s === '0')) {
      return `${
        amount.toString().split(DECIMAL_SEPARATOR)[0]
      }${DECIMAL_SEPARATOR}${afterDot.slice(0, decimals)}`;
    }

    let updatedNumber =
      Number(amount) * Math.pow(CALCULATE_POW_OF_10, decimals);

    switch (roundMethod) {
      case EDecimalRoundTypes.Cut:
        updatedNumber = Math.floor(updatedNumber);

        break;
      case EDecimalRoundTypes.RoundUp:
        if (afterDot[0] !== '0') {
          updatedNumber = Math.floor(updatedNumber) + 1;
        }

        break;
      case EDecimalRoundTypes.Round:
        updatedNumber = Math.round(updatedNumber);
        break;
    }

    return updatedNumber / Math.pow(CALCULATE_POW_OF_10, decimals);
  }

  return amount;
};
