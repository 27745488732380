import qs from 'qs';
import { setCookie } from 'utils/bom-dom-manipulation/cookies';
import { isSafari } from 'utils/bom-dom-manipulation/is-safari';
import { JURISDICTIONS } from 'utils/constants/app/jurisdictions';
import { CookieExpiration } from 'utils/constants/dateInfo/dateInfo';
import SpringConfigs from 'utils/constants/swarm/spring-configs';

export const configureSpring = (): void => {
  /**  JURISDICTIONS SECTION  **/
  if (SpringConfigs.JURISDICTION !== '0') {
    const jurisdictionFile = JURISDICTIONS[SpringConfigs.JURISDICTION];
    import(
      /* webpackChunkName: "jurisdiction-[request]" */ `utils/constants/jurisdictions/${jurisdictionFile}`
    ).then(jurisdiction => {
      Object.assign(SpringConfigs, jurisdiction.default);
    });
  }

  /**  PAGE_URLS SECTION  **/
  for (const pageKey in SpringConfigs.PAGE_URLS) {
    if (SpringConfigs.PAGE_URLS[pageKey] === window.location.origin) {
      Object.assign(SpringConfigs.PAGE_URLS, { homepageKey: pageKey });
      break;
    }
  }

  /**  WEBP SUPPORT SECTION  **/
  if (isSafari()) {
    new Promise<boolean>(resolve => {
      const image = new Image();

      image.onerror = function () {
        resolve(false);
      };

      image.onload = function () {
        resolve(image.width === 1);
      };

      image.src =
        'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
    })
      .then(value => {
        SpringConfigs.IS_WEBP_SUPPORTED = value;
      })
      .catch(() => {
        SpringConfigs.IS_WEBP_SUPPORTED = false;
      });
  } else {
    SpringConfigs.IS_WEBP_SUPPORTED = true;
  }

  /**  BTAG, LOYALTY CODE AND REFERENCE CODE SECTION  **/
  const searchParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  let btag = '';

  if (searchParams.btag) {
    btag = String(searchParams.btag);
  } else if (window.location.hash.includes('btag')) {
    btag = window.location.hash.split('btag=')?.[1]?.split('&')?.[0];
  }

  if (btag) {
    setCookie('btag', String(btag), CookieExpiration.DAYS_30, {
      SameSite: 'None',
      Secure: true
    });
  }

  if (searchParams.loyalty_code) {
    setCookie(
      'loyalty_code',
      String(searchParams.loyalty_code),
      CookieExpiration.DAYS_30,
      {
        SameSite: 'None',
        Secure: true
      }
    );
  }

  let agentId = '';

  if (searchParams.agentId) {
    agentId = String(searchParams.agentId);
  } else if (window.location.hash.includes('agentId')) {
    agentId = window.location.hash.split('agentId=')?.[1]?.split('&')?.[0];
  }

  if (agentId) {
    setCookie('agentId', String(agentId), CookieExpiration.DAYS_30, {
      SameSite: 'None',
      Secure: true
    });
  }

  if (searchParams.reference_code) {
    setCookie(
      'reference_code',
      String(searchParams.reference_code),
      CookieExpiration.DAYS_30,
      {
        SameSite: 'None',
        Secure: true
      }
    );
  }
};
