import dayjs from 'dayjs';
import i18n from 'i18next';
import {
  dataspotFunnelAction,
  EDataspotAction
} from 'utils/analytics/dataspot';
import {
  deleteCookie,
  getCookie,
  setCookie
} from 'utils/bom-dom-manipulation/cookies';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { setCookieLoginCredentials } from 'utils/bom-dom-manipulation/login-credentials';
import { sendPostMessage } from 'utils/bom-dom-manipulation/send-post-message-to-iframe';
import SessionStorage from 'utils/bom-dom-manipulation/session-storage';
import {
  EMAIL_VERIFICATION_TYPE,
  SMS_VERIFICATION_TYPE,
  TResponse
} from 'utils/constants/account/verificationTypes';
import { VAIX_IFRAME_ID } from 'utils/constants/app/iframe-ids';
import { timeoutDuration } from 'utils/constants/dateInfo/time-numbers';
import { EAccount } from 'utils/constants/store/query-keys';
import SpringConfigs, {
  androidWrapperApp,
  iOSWrapperApp
} from 'utils/constants/swarm/spring-configs';
import {
  CommandNames,
  CommandSource
} from 'utils/constants/swarm/swarm-command-names';
import { showToastError } from 'utils/generic/show-toast-error';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { isMobile } from 'utils/is-mobile';
import { appendCustomCode } from 'utils/other/customCode';
import { loginState } from 'utils/sportsbook/api-post-message-sender';
import RidGenerator from 'utils/swarm/rid-generator';
import {
  ApplyTwoFactorAuthenticationParams,
  ApplyTwoFactorAuthenticationResponse,
  AuthData,
  AuthGenericResponse,
  ChildRegionsResponse,
  CPFCheckResponse,
  EnableTwoFactorAuthenticationResponse,
  LoginCredentials,
  LoginError,
  LoginResponse,
  MinRegFields,
  ProfileData,
  SeonActionValidateResponse
} from 'interfaces/authentication';
import { AccountParamsType, AppendCustomCodes } from 'interfaces/generic';
import { WebsocketEnhanced } from 'interfaces/spring-websocket-interfaces';
import { connectingWS } from 'hooks/useSpringConnect';
import Store from 'store';
import { queryClient } from 'stores/query/query-client';

type AuthService = {
  setAfterSignIn: (x: Function | null) => null;
  getAfterSignIn: (inPopup: boolean) => Function | null;
  executeAfterSignInCompleted: (x: Function | null) => Function | null;
};

/**
 * When afterSignIn callback is returning true, afterSignInCompleted will not be executed.
 * **/
export const Authentication = function (): AuthService {
  let afterSignIn: Function | null = null;
  let afterSignInCompleted: Function | null = null;
  const authService: AuthService = {
    setAfterSignIn: (callbackOrNull: Function | null) =>
      _setAfterSignIn(callbackOrNull),
    getAfterSignIn: (inPopup: boolean): Function | null =>
      _getAfterSignIn(inPopup),
    executeAfterSignInCompleted: (callbackOrNull: Function | null) =>
      _executeAfterSignInCompleted(callbackOrNull)
  };

  const _setAfterSignIn = (callbackOrNull: Function | null = null): null => {
    afterSignIn = callbackOrNull;

    return null;
  };

  const _executeAfterSignInCompleted = (
    callbackOrNull: Function | null = null
  ): null => {
    afterSignInCompleted = callbackOrNull;

    return null;
  };

  const _getAfterSignIn = (inPopup: boolean): Function | null => {
    if (
      afterSignIn ||
      (inPopup && afterSignInCompleted) ||
      window.refreshWhenLoggedIn
    ) {
      return () => {
        const preventAfterSignInCompleted = afterSignIn?.();
        afterSignIn = null;

        if (preventAfterSignInCompleted) {
          return true;
        }

        afterSignInCompleted?.();
        afterSignInCompleted = null;
      };
    }

    return afterSignIn;
  };

  return authService;
};

/* eslint-disable */
export const authCommand = async (
  commandObj: any, //  command object must be of type any for generic purpose
  callback: Function | null,
  callbackUpdate?: Function | null,
  callbackError?: Function | null
): Promise<void> => {
  const ws: WebsocketEnhanced = (await connectingWS) as WebsocketEnhanced;

  ws.sendCommand(commandObj, '', callback, callbackUpdate, callbackError);
};

export const unsubscribeCommand = (rid: string): void => {
  connectingWS.then((socket: WebsocketEnhanced) => socket.unsubscribe(rid));
};

export const login = (
  credentials: LoginCredentials,
  callback: Function = () => {},
  callbackError: Function = () => {}
): void => {
  sendPostMessage({
    type: 'loginInProgress'
  }, VAIX_IFRAME_ID);

  const {
    username,
    password,
    recaptchaToken,
    smsVerificationCode,
    rememberMe
  } = credentials;

  const command: {
    command: string;
    params: LoginCredentials;
    rid: string;
  } = {
    command: CommandNames.LOGIN,
    params: {
      username: username,
      password: password,
      g_recaptcha_response: recaptchaToken,
      confirmation_code: smsVerificationCode,
      encrypted_token: rememberMe
    },
    rid: RidGenerator.gForCommand()
  };

  if (SpringConfigs.IOVATION_ENABLED) {
    const iovationModeKey =
      +SpringConfigs.IOVATION_MODE === 1 ? 'finger_print' : 'io_black_box';

    if (
      iovationModeKey === 'io_black_box' &&
      window.IGLOO &&
      window.IGLOO.getBlackbox
    ) {
      const iovationObject = window.IGLOO.getBlackbox();

      if (iovationObject?.finished) {
        command.params[iovationModeKey] = iovationObject.blackbox;
      }
    }
  }
  dataspotFunnelAction(EDataspotAction.Login);
  authCommand(
    command,
    (data: LoginResponse) => {
      setCookie('userGroupsIds', '1', 30, { SameSite: 'None', Secure: true });
      callback(data);
      dataspotFunnelAction(EDataspotAction.LoginSubmit);
    },
    null,
    (data: LoginError) => {
      if (data?.data?.details?.Key === 'LoginFreezed') {
        showToastError(
          `${i18n.t('swarm.LoginFreezed')} ${dayjs()
            .add(+(data.data.details.ErrorData?.TimeToWait || 0), 'minutes')
            .format(SpringConfigs.DT.time)}`,
          null,
          data.data.details.Key
        );
      }
      dataspotFunnelAction(EDataspotAction.LoginFailed);
      deleteCookie('userGroupsIds');
      callbackError(data);
      const accountParams: AccountParamsType = queryClient.getQueryData([
        EAccount['AccountParams']
      ]);

      if (
        accountParams &&
        +accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_LOGIN_FAIL]
      ) {
        appendCustomCode(
          accountParams[AppendCustomCodes.CUSTOM_CODE_LOGIN_FAIL],
          {
            reason: data?.data?.details?.Message,
            username
          }
        );
      }
    }
  );
};

export const forgetPassword = (
  valueTuple: {
    type: 'sms' | 'email';
    value?: string;
  },
  callback: Function = () => {},
  callbackError: Function = () => {}
): void => {
  const command: Record<string, any> = {
    command:
      valueTuple.type === 'email'
        ? CommandNames.FORGOT_PASSWORD
        : CommandNames.RESET_PASSWORD_VIA_SMS,
    rid: RidGenerator.gForCommand()
  };

  command.params = {
    [valueTuple.type === 'email' ? 'email' : 'key']: valueTuple.value
  };
  dataspotFunnelAction(EDataspotAction.ForgotPassword);
  authCommand(command, callback, null, callbackError);
};

export const forgetUsername = (
  valueTuple: {
    value?: string;
    password?: string;
  },
  callback: Function = () => {},
  callbackError: Function = () => {}
): void => {
  const command: Record<string, any> = {
    command: CommandNames.SEND_USERNAME_BY_SMS,
    rid: RidGenerator.gForCommand()
  };

  command.params = {
    phone: valueTuple.value,
    password: valueTuple.password
  };

  authCommand(command, callback, null, callbackError);
};

export const resetPassword = (
  newPassword: string,
  resetCode: string,
  callback: Function = () => {},
  callbackError: Function = () => {}
): void => {
  const command = {
    command: CommandNames.RESET_PASSWORD,
    params: {
      new_password: newPassword,
      reset_code: resetCode
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getUserActivity = (
  callback: Function = () => {},
  callbackError: Function = () => {}
): void => {
  const currentTime = new Date();
  const oneMonthBefore = new Date();
  oneMonthBefore.setDate(oneMonthBefore.getDate() - 10);

  const command = {
    command: CommandNames.USER_ACTIVITY,
    params: {
      from_date: +oneMonthBefore / 1000,
      to_date: +currentTime / 1000
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

const setUserNameInSS = (user: ProfileData) => {
  const auth_data_x = JSON.parse(
    LocalStorage.getItem(storageKeyName('account', 'AUTH_DATA'))
  );

  if (user?.first_name && auth_data_x) {
    auth_data_x.first_name = user.first_name;
  }

  if (user?.last_name && auth_data_x) {
    auth_data_x.last_name = user.last_name;
  }

  LocalStorage.setItem(
    storageKeyName('account', 'AUTH_DATA'),
    JSON.stringify(auth_data_x)
  );
};

export const getUser = (
  callback: Function,
  callbackUpdate: Function,
  type?: 'login' | 'register' | 'restore',
  isAutologin?: boolean,
  overridePersonalId?: boolean
): void => {
  const commandObj = {
    command: CommandNames.GET,
    params: {
      source: CommandSource.USER,
      what: {
        profile: []
      },
      subscribe: true
    },
    rid: RidGenerator.gForSubscribe()
  };

  authCommand(
    commandObj,
    (data: { profile: Record<string, ProfileData> }) => {
      if (!data?.profile) {
        return;
      }

      const user = Object.values(data?.profile)[0];

      user.user_id = user.id;

      if (overridePersonalId) {
        user.personal_id = user.doc_number;
      }

      if (type) {
        const auth_data_x = JSON.parse(
          LocalStorage.getItem(storageKeyName('account', 'AUTH_DATA'))
        );

        if (auth_data_x?.auth_token) {
          user.auth_token = auth_data_x.auth_token;
        }
        const accountParams: AccountParamsType = queryClient.getQueryData([
          EAccount['AccountParams']
        ]);

        if (type === 'restore' || type === CommandNames.LOGIN) {
          window.__dataspot__?.loginUser?.({
            id: user.id,
            birth_date: user.birth_date,
            gender: user.gender
          });
        }

        if (type === CommandNames.LOGIN && accountParams) {
          if (+accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_LOGIN]) {
            if (!window.refreshWhenLoggedIn) {
              appendCustomCode(
                accountParams[AppendCustomCodes.CUSTOM_CODE_LOGIN],
                user
              );
            } else {
              LocalStorage.setItem(
                storageKeyName('account', 'CUSTOM_CODE_LOGIN_AFTER_REFRESH'),
                accountParams[AppendCustomCodes.CUSTOM_CODE_LOGIN]
              );
            }
          }
        }

        if (
          type === 'restore' &&
          accountParams &&
          +accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_RESTORE_LOGIN]
        ) {
          appendCustomCode(
            accountParams[AppendCustomCodes.CUSTOM_CODE_RESTORE_LOGIN],
            user
          );
        }

        if (type === 'register' && accountParams) {
          if (+accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_REG]) {
            if (!window.refreshWhenLoggedIn) {
              appendCustomCode(
                accountParams[AppendCustomCodes.CUSTOM_CODE_REG],
                user
              );
            } else {
              LocalStorage.setItem(
                storageKeyName('account', 'CUSTOM_CODE_REG_AFTER_REFRESH'),
                accountParams[AppendCustomCodes.CUSTOM_CODE_REG]
              );
            }
          }
          +accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_LOGIN] &&
            appendCustomCode(
              accountParams[AppendCustomCodes.CUSTOM_CODE_LOGIN],
              user
            );
        }
      }

      setUserNameInSS(user);

      if (
        type !== 'restore' &&
        SpringConfigs.SEON_INTEGRATION &&
        ['all', 'login'].includes(SpringConfigs.SEON_VALIDATION_ACTION)
      ) {
        let timeoutId: NodeJS.Timeout;

        const getUserCallback = () => {
          clearTimeout(timeoutId);

          if (!Store.getState().appData.seonSessionData) {
            timeoutId = setTimeout(
              getUserCallback,
              timeoutDuration.DURATION_300
            );
          } else {
            validateSeonOnAction(
              'login',
              (data: SeonActionValidateResponse) => {
                if (data && !data.success || Object.keys(data.error).length > 0) {
                  console.error(data);
                }
              }
            );

            if (isAutologin) {
              validateSeonOnAction(
                'register',
                (data: SeonActionValidateResponse) => {
                  if (!data.success || Object.keys(data.error).length > 0) {
                    console.error(data);
                  }
                }
              );
            }

            callback(user);
          }
        };

        getUserCallback();
      } else {
        callback(user);
      }
    },
    (data: { profile: Record<string, ProfileData> }) => {
      const updatedFields = Object.values(data.profile || {})[0];

      setUserNameInSS(updatedFields);
      callbackUpdate(updatedFields);
    }
  );
};

export const removeUserFromStorage = (): void => {
  [
    storageKeyName('account', 'AUTH_DATA'),
    storageKeyName('account', 'JWT'),
    storageKeyName('account', 'FREEZE_START_TIME'),
    storageKeyName('account', 'REALITY_CHECK_START_TIME'),
    storageKeyName('account', 'FIRST_DEPOSIT')
  ].forEach(item => {
    LocalStorage.removeItem(item);
  });

  SessionStorage.removeItem(storageKeyName('account', 'ACTIVE_TIME_IN_CASINO'));

  deleteCookie('sbuser');
  deleteCookie('userGroupsIds');
};

export const logout = (
  callback: Function = () => {},
  params?: { source?: number; fcm_token?: string; subscription_id?: string }
): void => {
  sendPostMessage({
    type: 'userId',
    value: '0'
  }, VAIX_IFRAME_ID);

  const command = {
    command: CommandNames.LOGOUT,
    params,
    rid: RidGenerator.gForCommand()
  };

  const doAfterLogout = () => {
    window.__dataspot__?.logoutUser?.();
    removeUserFromStorage();
    const accountParams: AccountParamsType = queryClient.getQueryData([
      EAccount['AccountParams']
    ]);
    if (
      accountParams &&
      +accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_LOGOUT]
    ) {
      appendCustomCode(accountParams[AppendCustomCodes.CUSTOM_CODE_LOGOUT]);
    }
    callback();
  };

  authCommand(command, doAfterLogout, null, doAfterLogout);
};

export const restoreLogin = (
  auth_data_x: AuthData,
  callback: Function = () => {},
  callbackUpdate: Function = () => {},
  callbackError: Function = () => {},
  isOverridePersonalId: boolean,
  loginWithCryptoWallet = false
): void => {
  const IS_LOGOUT = LocalStorage.getItem(
    storageKeyName('account', 'IS_LOGOUT')
  );
  if (IS_LOGOUT === '1') {
    return;
  }
  sendPostMessage({
    type: 'loginInProgress'
  }, VAIX_IFRAME_ID);

  const commandName = auth_data_x.jwe_token
    ? CommandNames.LOGIN_ENCRYPTED
    : CommandNames.RESTORE_LOGIN;

  const params =
    commandName === CommandNames.LOGIN_ENCRYPTED
      ? { jwe_token: auth_data_x.jwe_token, auth_token: auth_data_x.auth_token }
      : { ...auth_data_x };

  const commandObj = {
    command: commandName,
    params: params,
    rid: RidGenerator.gForCommand()
  };

  const qrCodeOriginSecret = auth_data_x.qr_code_origin;

  authCommand(
    commandObj,
    (data: AuthGenericResponse) => {
      const { auth_token, user_id } = data;

      if (auth_token && user_id) {
        const auth_data_x: AuthData = {
          auth_token,
          user_id,
          qr_code_origin: qrCodeOriginSecret
        };

        LocalStorage.setItem(
          storageKeyName('account', 'AUTH_DATA'),
          JSON.stringify(auth_data_x)
        );
        setCookie('userGroupsIds', '1', 30, { SameSite: 'None', Secure: true });
      }

      if (!getCookie('sbuser')) {
        setCookieLoginCredentials(
          'sbuser',
          {
            rememberMe: !!auth_data_x?.jwe_token,
            userGroups: ''
          },
          15
        );
      }

      getUser(callback, callbackUpdate, 'restore', false, isOverridePersonalId);

      if (SpringConfigs.IFRAME_SPORTSBOOK) {
        loginState('loginRestored');
      }

      if (loginWithCryptoWallet && window.refreshWhenLoggedIn) {
        window.mustRefreshForSessionVisibility = true;
        window.location.reload();
      }
    },
    null,
    callbackError
  );
};

export const register = (
  fields: MinRegFields,
  loginAfter: boolean,
  loginCallback: Function = () => {},
  callbackError: Function = () => {},
  registerCallback?: Function,
  phoneAsUserName?: string
): void => {
  const userNameField = fields.username
    ? fields.username
    : fields.email
    ? fields.email
    : (phoneAsUserName as string);

  const command: {
    command: string;
    params: {
      user_info: MinRegFields;
    };
    rid: string;
  } = {
    command: CommandNames.REGISTER_USER,
    params: {
      user_info: {
        ...fields,
        lang_code: SpringConfigs.LANGUAGE_PREFIX,
        siteId: SpringConfigs.PARTNER_ID,
        username: userNameField
      }
    },
    rid: RidGenerator.gForCommand()
  };

  if (SpringConfigs.IOVATION_ENABLED) {
    const iovationModeKey: 'finger_print' | 'io_black_box' =
      +SpringConfigs.IOVATION_MODE === 1 ? 'finger_print' : 'io_black_box';
    if (window.IGLOO && window.IGLOO.getBlackbox) {
      const iovationObject = window.IGLOO.getBlackbox();

      if (iovationObject?.finished) {
        command.params.user_info[iovationModeKey] = iovationObject.blackbox;
      }
    }
  }

  dataspotFunnelAction(EDataspotAction.RegistrationSubmit);
  dataspotFunnelAction(EDataspotAction.PromocodeApply);

  authCommand(
    command,
    (data: any) => {
      if (data.result === 'OK') {
        dataspotFunnelAction(EDataspotAction.RegisterSuccess);
        dataspotFunnelAction(EDataspotAction.PromocodeSuccess);
        const { password } = fields;
        const username = userNameField;

        registerCallback?.();

        if (
          SpringConfigs.SEON_INTEGRATION &&
          ['all', 'register'].includes(SpringConfigs.SEON_VALIDATION_ACTION) &&
          Store.getState().appData.seonSessionData &&
          !loginAfter
        ) {
          validateSeonOnAction(
            'register',
            (data: SeonActionValidateResponse) => {
              if (!data.success || Object.keys(data.error).length > 0) {
                console.error(data);
              }
            }
          );
        }

        if (loginAfter) {
          login(
            { username, password },
            (resp: LoginResponse) => loginCallback(resp, true),
            () => {
              const accountParams: AccountParamsType = queryClient.getQueryData(
                [EAccount['AccountParams']]
              );
              if (
                accountParams &&
                +accountParams[AppendCustomCodes.APPEND_CUSTOM_CODE_LOGIN_FAIL]
              ) {
                appendCustomCode(
                  accountParams[AppendCustomCodes.CUSTOM_CODE_LOGIN_FAIL]
                );
              }
            }
          );
        }
      }
    },
    null,
    (data: any) => {
      callbackError(data);
      dataspotFunnelAction(EDataspotAction.RegisterFail);
    }
  );
};

export const subscribeToUserNotifications = (
  callback: Function = () => {},
  callbackUpdate: Function = () => {},
  rid: string
): void => {
  const command = {
    command: CommandNames.GET,
    params: {
      source: CommandSource.NOTIFICATIONS,
      what: {
        user: []
      },
      subscribe: true
    },
    rid: rid
  };

  authCommand(command, callback, callbackUpdate);
};

export const enableTwoFactorAuthentication = (
  callback: (res: EnableTwoFactorAuthenticationResponse) => void
): void => {
  const commandObj = {
    command: CommandNames.ENABLE_TWO_FACTOR_AUTHENTICATION,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback, null);
  });
};

export const applyTwoFactorAuthentication = (
  {
    code,
    fingerprint,
    is_device_trusted,
    trust_period
  }: ApplyTwoFactorAuthenticationParams,
  callback: (res: ApplyTwoFactorAuthenticationResponse) => void
): void => {
  const commandObj = {
    command: CommandNames.APPLY_TWO_FACTOR_AUTHENTICATION_CODE,
    params: {
      code,
      is_device_trusted,
      device_fingerprint: fingerprint,
      trust_period
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback, null);
  });
};

export const sendSmsToPhoneNumber = (
  phoneNumber: string,
  actionType: number,
  channel_type: number,
  email: string,
  callback: (response: TResponse) => void,
  callbackError: Function
): void => {
  const commandObj = {
    command: CommandNames.SEND_SMS_TO_PHONE_NUMBER,
    params: {
      ...(channel_type === SMS_VERIFICATION_TYPE && {
        phone_number: phoneNumber?.replace('+', '')
      }),
      action_type: actionType,
      channel_type,
      ...(channel_type === EMAIL_VERIFICATION_TYPE && {
        email,
        phone_number: ''
      })
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback, null, callbackError);
  });
};

export const sendSmsToPhoneNumberWithUsername = (
  login: string,
  actionType: number,
  channel_type: number,
  callback: () => void
): void => {
  const commandObj = {
    command: CommandNames.SEND_SMS_WITH_USERNAME,
    params: {
      login,
      action_type: actionType,
      channel_type
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback);
  });
};

export const verifyEmail = (
  code: string,
  callback: (data: TResponse) => void,
  callbackError?: () => void
): void => {
  const commandObj = {
    command: CommandNames.VERIFY_USER,
    params: {
      verification_code: code
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback, null, callbackError);
  });
};

export const storeFcmToken = (token: string, callback = () => {}): void => {

  const commandObj = {
    command: CommandNames.STORE_FCM_TOKEN,
    params: {
      fcm_token: token,
      source: !isMobile ? ' builder' : androidWrapperApp ? "android" : iOSWrapperApp ?  'ios' : 'builder',
      app_type: 'all'
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback);
  });
};

export const getChildRegions = (
  countryCode: string,
  callback: (data: ChildRegionsResponse) => void
): void => {
  const commandObj = {
    command: CommandNames.GET_CHILD_REGIONS,
    params: {
      alpha2_code: countryCode
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback);
  });
};

const validateSeonOnAction = (
  action: 'login' | 'register',
  callback: (data: SeonActionValidateResponse) => void
): void => {
  const seonSessionData = Store.getState().appData.seonSessionData;
  const commandObj = {
    command: CommandNames.VALIDATE_SEON_ON_ACTION,
    params: {
      action: action,
      additional_data: {
        encrypted_payload: seonSessionData
      }
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback);
  });
};

export const checkCPFNumber = (
  cpf: string,
  byCountry: boolean,
  byPayBroker: boolean,
  callback: (data: CPFCheckResponse) => void,
  callbackError: () => void
): void => {
  const commandObj = {
    ...(byCountry
      ? {
          command: CommandNames.CHECK_CPFS,
          params: { cpfs: cpf }
        }
      : byPayBroker
      ? {
          command: CommandNames.GET_CLIENT_INFORMATION_BY_PAY_BROKER_CPF,
          params: { cpf }
        }
      : {
          command: CommandNames.CHECK_CPF_NUMBER,
          params: { cpf }
        }),
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback, null, callbackError);
  });
};

export const verifyPhone = (
  code: string,
  callback: (data: TResponse) => void,
  callbackError: () => void
): void => {
  const commandObj = {
    command: CommandNames.VERIFY_PHONE_NUMBER,
    params: {
      verification_code: code
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(commandObj, null, callback, null, callbackError);
  });
};

export const sendRestoreLoginPostMsg = (data: {
  auth_token: string;
  user_id: number;
}): void => {
  window.parent.postMessage(
    {
      action: CommandNames.LOGIN,
      credentials: data
    },
    '*'
  );
};
