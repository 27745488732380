import { TCounterOfferState } from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { ECounterOfferSelectedType } from 'store/reducers/betslip/conterOffer';

export const setCounterOffers = (
  payload: Record<number, TCounterOfferState>
): ActionType => {
  return {
    type: BetslipActionTypes.SET_COUNTER_OFFERS,
    payload
  };
};

export const resetCounterOffers = (): ActionType => {
  return {
    type: BetslipActionTypes.RESET_COUNTER_OFFERS
  };
};

export const setCounterOfferSelectedType = (
  payload: ECounterOfferSelectedType | null
): ActionType => {
  return {
    type: BetslipActionTypes.SET_COUNTER_OFFER_SELECTED_TYPE,
    payload
  };
};

export const setSelectedOddCounterOffer = (
  payload: string | null
): ActionType => {
  return {
    type: BetslipActionTypes.SET_SELECTED_ODD_COUNTER_OFFER,
    payload
  };
};

export const setOddsCounterOffers = (payload: Object): ActionType => {
  return {
    type: BetslipActionTypes.SET_ODDS_COUNTER_OFFERS,
    payload
  };
};

export const setCounterOffersUnderReviewModal = (
  payload: boolean
): ActionType => {
  return {
    type: BetslipActionTypes.SET_COUNTER_OFFERS_UNDER_REVIEW_MODAL,
    payload
  };
};
