// prettier-ignore
export const MatchStreamingActionTypes = {
  SET_LIVE_STREAM_INFO: 'SET_LIVE_STREAM_INFO',
  SET_IS_CAN_FIX_MS_ON_TOP: 'SET_IS_CAN_FIX_MS_ON_TOP',
  SET_MATCH_STREAMING_MODE: 'SET_MATCH_STREAMING_MODE',
  SET_IS_STREAMER_LANDSCAPE: 'SET_IS_STREAMER_LANDSCAPE',
  SET_MATCH_STREAMER_POSITION: 'SET_MATCH_STREAMER_POSITION',
  RESET_MATCH_STREAMING_STATE: 'RESET_MATCH_STREAMING_STATE',
  SET_IS_MATCH_STREAMING_HIDDEN: 'SET_IS_MATCH_STREAMING_HIDDEN',
  SET_MATCH_STREAMING_GAME_DATA: 'SET_MATCH_STREAMING_GAME_DATA',
  SET_MATCH_STREAMING_STREAM_URL: 'SET_MATCH_STREAMING_STREAM_URL',
  SET_MATCH_STREAMING_STREAM_TYPE: 'SET_MATCH_STREAMING_STREAM_TYPE',
  TOGGLE_MATCH_STREAMING_SETTINGS: 'TOGGLE_MATCH_STREAMING_SETTINGS',
  SET_MATCH_STREAMING_THEATRE_MODE: 'SET_MATCH_STREAMING_THEATRE_MODE',
  SET_MATCH_STREAMING_MOUNTING_INFO: 'SET_MATCH_STREAMING_MOUNTING_INFO',
  SET_MATCH_STREAMING_CONTAINER_INFO: 'SET_MATCH_STREAMING_CONTAINER_INFO',
  SET_MATCH_STREAMING_CHANNELS_INFO: 'SET_MATCH_STREAMING_CHANNELS_INFO',
  SET_IS_MATCH_STREAMING_CHANNELS_AVAILABLE: 'SET_IS_MATCH_STREAMING_CHANNELS_AVAILABLE',
  SET_IS_MATCH_STREAMING_PREVIEW_UNAVAILABLE: 'SET_IS_MATCH_STREAMING_PREVIEW_UNAVAILABLE',
  SET_IS_MATCH_STREAMING_THEATRE_MODE_AVAILABLE: 'SET_IS_MATCH_STREAMING_THEATRE_MODE_AVAILABLE',
  SET_DISABLE_MATCH_STREAMING_STREAM_TYPE: 'SET_DISABLE_MATCH_STREAMING_STREAM_TYPE',
  SET_GAME_PREVIEW_NOT_AVAILABLE: 'SET_GAME_PREVIEW_NOT_AVAILABLE'
};
