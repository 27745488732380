/* eslint-disable */
const isUsa = +(window?.partnerConfigs?.jurisdiction || '0') === 7 || +(window?.partnerConfigs?.jurisdiction || '0') === 8;

export const languagePrefixes: Record<string, string> = {
  'ar': 'arb',
  'az': 'aze',
  'da-dk': 'den',
  'de': 'ger',
  'el-gr': 'gre',
  'en': 'eng',
  'en-us': isUsa ? 'usa' : 'eng',
  'en-ca': 'cn',
  'es': 'spa',
  'fa': 'fas',
  'fi': 'fin',
  'fr': 'fra',
  'gr': 'gre',
  'he': 'heb',
  'hy': 'arm',
  'id': 'ind',
  'it': 'ita',
  'ja': 'jpn',
  'ka': 'geo',
  'kk': 'kaz',
  'ko': 'kor',
  'ku': 'kur',
  'lt': 'lit',
  'mk': 'mkd',
  'mn': 'mon',
  'ms': 'mys',
  'nb-no': 'nor',
  'nn': 'nor',
  'no': 'nor',
  'pl': 'pol',
  'pt': 'por',
  'pt-br': 'pt-br',
  'ro': 'ron',
  'ru': 'rus',
  'sv': 'swe',
  'th': 'tha',
  'tr': 'tur',
  'uk': 'ukr',
  'uz': 'uzb',
  'vi': 'vie',
  'zh': 'zhh',
  'zh-hant-hk': 'zho',
  'hi-in': 'hin',
  'nl-nl': 'nld',
  'bg-bg': 'bgr',
  'km-kh': 'khm',
  'gu-hi': 'guj',
  'gu-in': 'guj',
  'ur': 'urd',
  'pa': 'pan',
  'mr': 'mar'
};
/* eslint-enable */
