import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { ActionType } from 'interfaces/generic';
import { LoadingActionTypes } from 'store/action-types/loadings';

type LoadingsData = {
  regionDataLoading: boolean;
  mobileLoading: boolean;
};

const initialLoadingsData: LoadingsData = {
  regionDataLoading: false,
  mobileLoading: !SpringConfigs.MOCKED_DATA
};

export const loadings = (
  state = initialLoadingsData,
  action: ActionType
): LoadingsData => {
  switch (action.type) {
    case LoadingActionTypes.SET_REGION_DATA_LOADING:
      return {
        ...state,
        regionDataLoading: action.payload
      };

    default: {
      return state;
    }
  }
};
