import { FC, ReactElement } from 'react';
import cc from 'classcat';
import { SkeletonElementProps } from '..';
import './index.less';

export interface SkeletonButtonProps
  extends Omit<SkeletonElementProps, 'size'> {
  size?: 'large' | 'small' | 'default';
  block?: boolean;
  applyElementStyle?: boolean;
}

const BUTTON_SKELETON_SIZE = {
  small: 'customSkeleton-button-sm',
  large: 'customSkeleton-button-lg',
  default: ''
};

export const SkeletonButton: FC<SkeletonButtonProps> = ({
  style = {},
  active,
  className,
  size = 'default',
  applyElementStyle = true
}): ReactElement => {
  return (
    <div
      className={cc([
        'customSkeleton',
        {
          'customSkeleton-element': applyElementStyle,
          'customSkeleton-active': active
        },
        className
      ])}
    >
      <span
        className={cc([
          'customSkeleton-button ',
          BUTTON_SKELETON_SIZE[size],
          className
        ])}
        style={style}
      ></span>
    </div>
  );
};
