import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { isMobile } from 'utils/is-mobile';

export type TBottomNavigation = {
  id: number;
  category: string;
  type: string;
  title: string;
  status?: number;
  icon: string;
  path?: string;
  onlyBottomNav?: boolean;
  showEvery?: boolean;
};

export const defaultData: Array<TBottomNavigation> = [
  {
    id: 0,
    category: 'sport',
    type: 'live',
    title: 'Live',
    status: 1,
    icon: 'sports.colored.live',
    path: '/live',
    showEvery: true
  },
  {
    id: 1,
    category: 'sport',
    type: 'prematch',
    title: 'Prematch',
    status: 0,
    icon: 'generic.colored.upcoming',
    path: '/prematch'
  },
  {
    id: 2,
    category: 'casino',
    type: 'casino',
    title: 'Casino',
    status: 0,
    icon: 'casino.colored.placeholder',
    path: '/casino',
    showEvery: true
  },
  {
    id: 3,
    category: 'other',
    type: 'other',
    title: 'Other',
    icon: 'casino.colored.othergames',
    onlyBottomNav: true,
    showEvery: true
  }
];

export const QUICK_BET_ELEMENT_DEFAULT_HEIGHT_MOBILE = 84;
export const EDIT_BET_ELEMENT_SWITCHER_HEIGHT_MOBILE = 80;
export const BOTTOM_NAVIGATION_ELEMENT_DEFAULT_HEIGHT_MOBILE = 70;
export const BETS_TOTAL_ODDS_HEIGHT_MOBILE = 30;
export const TOP_DISTANCE_HEIGHT = 24;
export const BOTTOM_DISTANCE_ADD_TOTAL_ODDS_HEIGHT = 32;
export const BOTTOM_NAVIGATION_ELEMENT_ADD_TOTAL_ODDS_HEIGHT = 16;

export const EDIT_BET_ELEMENT_SWITCHER_HEIGHT = isMobile()
  ? EDIT_BET_ELEMENT_SWITCHER_HEIGHT_MOBILE
  : 0;

export const QUICK_BET_ELEMENT_DEFAULT_HEIGHT = isMobile()
  ? QUICK_BET_ELEMENT_DEFAULT_HEIGHT_MOBILE
  : 0;

export const BOTTOM_NAVIGATION_ELEMENT_DEFAULT_HEIGHT =
  SpringConfigs.BOTTOM_NAVIGATION && isMobile()
    ? BOTTOM_NAVIGATION_ELEMENT_DEFAULT_HEIGHT_MOBILE
    : 0;
export const BETS_TOTAL_ODDS_HEIGHT = isMobile()
  ? BETS_TOTAL_ODDS_HEIGHT_MOBILE
  : 0;
