import i18n from 'i18next';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { showToastSuccess } from 'utils/swarm/swarm-success-handler';

export const copyToClipboard = (
  text: string,
  notShowMessage?: boolean
): void => {
  navigator.clipboard.writeText(text);

  if (!notShowMessage) {
    showToastSuccess(i18n.t('account.copied'));
  }
};

export function addPropertyToObjectWithLimit<T>(
  obj: Record<string, T>,
  newProp: Record<string, T>,
  limit: number
): Record<string, T> {
  if (
    Object.keys(obj || {}).length + 1 > limit &&
    !obj[Object.keys(newProp)[0]] &&
    Array.isArray(obj[Object.keys(obj)[0]])
  ) {
    delete obj[Object.keys(obj)[0]];
  }

  return { ...obj, ...newProp };
}

export const routePathWithMockedDataCheck = (path: string): string => {
  return SpringConfigs.MOCKED_DATA ? '' : path;
};
