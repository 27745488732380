import { ImmortalDB } from 'immortal-db';
import { nanoid } from 'nanoid';
import platform from 'platform';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { isMobile } from '../is-mobile';

const CollectorObject: Record<string, any> = {
  deviceType: 'string',
  model: 'object',
  os: 'string',
  architecture: 'number',
  browser: 'string',
  browserBuildNumber: 'string',
  languages: 'string',
  time: 'string',
  coreNumbers: 'number',
  ramMemory: 'number',
  userAgent: 'string',
  devicePixelRatio: 'number',
  colorDepth: 'number'
};

const GeoDataObject: Record<string, string> = {
  Region: 'string',
  countryCode: 'string',
  countryName: 'string',
  ipAddress: 'string',
  latitude: 'number',
  longitude: 'number',
  proxyOrVPNStatus: 'number',
  statusCode: 'string',
  statusMessage: 'string',
  zipCode: 'string'
};

export const USER_HASH_LENGTH = 36;

const getCollectedInfo = () => {
  const Navigator = window.navigator as any;

  const collectedInfo = {
    deviceType: isMobile() ? 'mobile' : 'desktop',
    model: platform.product,
    os: platform?.os?.toString(),
    architecture: platform?.os?.architecture,
    browser: platform.name,
    browserBuildNumber: platform.version,
    languages: Navigator.languages.toString(),
    time: new Date().toString(),
    coreNumbers: Navigator.hardwareConcurrency,
    ramMemory: Navigator.deviceMemory,
    userAgent: platform.ua,
    devicePixelRatio: window.devicePixelRatio,
    colorDepth: window.screen.colorDepth
  };

  let response = true;
  Object.entries(collectedInfo).forEach(([key, value]) => {
    if (typeof value !== CollectorObject[key]) {
      response = false;
    }
  });

  return response
    ? collectedInfo
    : { deviceType: isMobile() ? 'mobile' : 'desktop' };
};

const getUserGeoData = async (geoIpLink: string) => {
  let geoDataResponse: null | Response = null;
  await fetch(`${geoIpLink}?type=json`)
    .then(response => {
      geoDataResponse = response;
    })
    .catch(err => {
      throw new Error(err);
    });

  if (!geoDataResponse) {
    return {};
  }

  const geoData = await (geoDataResponse as Response).json();

  const getUserGeoDataResponse = {
    ...geoData,
    proxyOrVPNStatus: 0
  };

  let response = true;
  Object.entries(getUserGeoDataResponse).forEach(([key, value]) => {
    if (typeof value !== GeoDataObject[key]) {
      response = false;
    }
  });

  return response ? getUserGeoDataResponse : {};
};

const generateUniqueIdentification = async (geoIpLink: string) => {
  const collectedInfo = getCollectedInfo();

  let userGeoData = 'not-detected';

  try {
    userGeoData = await getUserGeoData(geoIpLink);
  } catch (e) {
    console.info(e);
  }

  const userHash = await nanoid(USER_HASH_LENGTH);
  await ImmortalDB.set('user-hash', userHash);

  return {
    userGeoData,
    userIdentifier: userHash,
    collectedInfo
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserUniqueIdentification = async (
  geoIpLink = SpringConfigs.GEO_LINK_URL
) => {
  if (!geoIpLink) {
    throw new Error('GeoIp url and Swarm link are required');
  }

  if (typeof geoIpLink !== 'string') {
    throw new Error('GeoIp url and Swarm link type invalid');
  }

  const uniqueIdentifier = await ImmortalDB.get('unique-fingerprint', '');
  const userHash = await ImmortalDB.get('user-hash', '');

  if (uniqueIdentifier) {
    await ImmortalDB.remove('unique-fingerprint');
  }

  const collectedInfo = getCollectedInfo();

  if (userHash) {
    try {
      const userGeoData = await getUserGeoData(geoIpLink);

      return {
        userIdentifier: userHash,
        userGeoData,
        collectedInfo
      };
    } catch (e) {
      console.info(e);
    }
  } else {
    return await generateUniqueIdentification(geoIpLink);
  }
};

const RTCPeerConnection: any =
  window.RTCPeerConnection ||
  window.mozRTCPeerConnection ||
  window.webkitRTCPeerConnection; //compatibility for Firefox and chrome

interface RTCPeerConnectionLocal extends RTCPeerConnection {
  localIp?: string | null;
}

export const userPcInfo: RTCPeerConnectionLocal =
    process.env.NODE_ENV !== 'test' && RTCPeerConnection
      ? new RTCPeerConnection({
          iceServers: []
        })
      : {},
  noop = () => {};

if (process.env.NODE_ENV !== 'test') {
  userPcInfo.createDataChannel?.(''); //create a bogus data channel
  userPcInfo.createOffer?.(
    userPcInfo.setLocalDescription?.bind?.(userPcInfo),
    noop
  ); // create offer and set local description

  userPcInfo.onicecandidate = ice => {
    if (ice && ice.candidate && ice.candidate.candidate) {
      userPcInfo.localIp = ice.candidate.address;
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUniqueIdentification = async () => {
  const userHash = (await ImmortalDB.get('user-hashX', '')) as any;

  if (!userHash) {
    const userHashX = await nanoid(USER_HASH_LENGTH);
    ImmortalDB.set('user-hashX', userHashX);

    return userHashX;
  }

  return userHash;
};
