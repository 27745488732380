const NOT_FOUND = -1;

export const isSafari = (): boolean => {
  return (
    !!navigator.vendor &&
    navigator.vendor.indexOf('Apple') > NOT_FOUND &&
    !!navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == NOT_FOUND &&
    navigator.userAgent.indexOf('FxiOS') == NOT_FOUND
  );
};
