import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import { storageKeyName } from 'utils/generic/storage-key-name';
import RidGenerator from 'utils/swarm/rid-generator';
import { NewCasinoGame } from 'interfaces/new-casino';
import { FavoriteCompetitionInfo } from 'interfaces/sportsbook-data-levels';
import { TTourDataSteps } from 'interfaces/tour';
import { authCommand } from 'services/authentication';
import { swarmCommand } from 'services/get-swarm-data';
import store from 'store';
import {
  setFavoriteCasinoGames,
  setFavoriteCasinoProviders,
  setFavoriteCompetitions,
  setFavoriteMarkets,
  setFavoriteSportsData,
  setPersonalisedDataLoaded,
  setPersonalizationData,
  setPersonalizedCompetitionResourceId,
  setPersonalizedGameResourceId,
  setPersonalizedMarketResourceId,
  setPersonalizedProviderResourceId,
  setPersonalizedSportGamesResourceId,
  setTourStepsInitialData
} from 'store/actions';

const SCOPE = 'spring_builder_x';
const SHOW_POPUP_RESOURCE = 'showPersonalizationPopup';
const CASINO_GAMES_RESOURCE = 'casinoFavoriteGames';
const CASINO_PROVIDERS_RESOURCE = 'casinoFavoriteProviders';
const SPORT_COMPETITIONS_RESOURCE = 'sportFavoriteCompetition';
const SPORT_MARKET_GROUP_RESOURCE = 'favoriteMarkets';
const SPORT_GAMES_RESOURCE = 'sportSBFavoriteGames';
type TSportIds = {
  [key: number]: {};
};
export type TPersonalizedSportGames = {
  sportsbook: {
    live: TSportIds;
    prematch: TSportIds;
  };
  esport: {
    live: TSportIds;
    prematch: TSportIds;
  };
};
type TPreparedSportGroup = {
  count: number;
  data: {};
  ids: number[];
};
export type TPersonalizedSportGamesPrepared = {
  sportsbook: {
    live: TPreparedSportGroup;
    prematch: TPreparedSportGroup;
  };

  esport: {
    live: TPreparedSportGroup;
    prematch: TPreparedSportGroup;
  };
} | null;

const TOUR_STEPS = 'tourSteps';

const tourStepsData = {
  sportsbook: { 0: {} },
  casino: { 0: {} }
};

type TPersonalizationResponse = {
  details: {
    data: {
      sportFavoriteCompetition: {
        configs: { [key: number]: FavoriteCompetitionInfo };
        _id: string;
      };
      casinoFavoriteGames: {
        configs: { [key: number]: NewCasinoGame };
        _id: string;
      };
      casinoFavoriteProviders: {
        configs: { [key: number]: {} };
        _id: string;
      };
      favoriteMarkets: {
        configs: { [key: number]: {} };
        _id: string;
      };
      sportSBFavoriteGames: {
        configs: TPersonalizedSportGames;
        _id: string;
      };
      tourSteps: {
        configs: TTourDataSteps;
      };
    };
  };
};

export type TPersonalizationPreparedData = {
  games: NewCasinoGame[];
  competitions: FavoriteCompetitionInfo[];
  providers: string[];
  markets: string[];
  sportGames: TPersonalizedSportGamesPrepared | null;
  tourStepsInitialData: {
    [key: string]: TTourDataSteps;
  };
};

export const FavoritePersonalization = () => {
  const storeHasUserSeenPopup = (value: boolean) => {
    const command = {
      command: CommandNames.SET_USER_DATA_PERSONALIZATION,
      params: {
        scope: SCOPE,
        resource: SHOW_POPUP_RESOURCE,
        configs: { 0: value }
      },
      rid: RidGenerator.gForCommand()
    };

    authCommand(command, () => {
      storeTourSteps(tourStepsData);
      store.dispatch(setTourStepsInitialData(tourStepsData));
    });
  };

  const getShowPopup = (callback: (res: any) => void) => {
    swarmCommand(
      CommandNames.GET_USER_DATA_PERSONALIZATION,
      { scope: SCOPE, resource: [SHOW_POPUP_RESOURCE, TOUR_STEPS] },
      (res: TPersonalizationResponse) => {
        callback(res);

        if (!res?.details?.data?.tourSteps) {
          storeTourSteps(tourStepsData);
          store.dispatch(setTourStepsInitialData(tourStepsData));
        }
      }
    );
  };

  const storeCasinoGames = (games: { [key: number]: NewCasinoGame }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: CASINO_GAMES_RESOURCE,
        configs: games
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedGameResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeCasinoProviders = (providers: { [key: string]: {} }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: CASINO_PROVIDERS_RESOURCE,
        configs: providers
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedProviderResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeMarketGroups = (markets: { [key: string]: {} }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_MARKET_GROUP_RESOURCE,
        configs: markets
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedMarketResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeSportGames = (sportGames: TPersonalizedSportGames) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_GAMES_RESOURCE,
        configs: sportGames
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedSportGamesResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeSportCompetitions = (competitions: {
    [key: number]: FavoriteCompetitionInfo;
  }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_COMPETITIONS_RESOURCE,
        configs: competitions
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedCompetitionResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeTourSteps = (configs: TTourDataSteps) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: TOUR_STEPS,
        configs
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setTourStepsInitialData(response?.details?.data?.configs || {})
          );
      }
    );
  };

  const prepareFavoritesInitialData = (
    callback: (preparedData: TPersonalizationPreparedData) => void
  ) => {
    swarmCommand(
      CommandNames.GET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: [
          CASINO_GAMES_RESOURCE,
          CASINO_PROVIDERS_RESOURCE,
          SPORT_COMPETITIONS_RESOURCE,
          SPORT_MARKET_GROUP_RESOURCE,
          SPORT_GAMES_RESOURCE,
          TOUR_STEPS
        ]
      },
      (res: TPersonalizationResponse) => {
        if (res === null) {
          callback({
            competitions: [],
            games: [],
            providers: [],
            markets: [],
            sportGames: null,
            tourStepsInitialData: {}
          });
        }

        if (res?.details?.data) {
          const data = res.details.data;
          const preparedData: TPersonalizationPreparedData = {
            competitions: [],
            games: [],
            providers: [],
            markets: [],
            sportGames: null,
            tourStepsInitialData: {}
          };

          if (data?.sportFavoriteCompetition?.configs) {
            for (const [key] of Object.entries(
              data.sportFavoriteCompetition.configs
            )) {
              preparedData.competitions.push(
                data.sportFavoriteCompetition.configs[+key]
              );
            }

            store.dispatch(
              setPersonalizedCompetitionResourceId(
                data.sportFavoriteCompetition._id
              )
            );
          }

          if (data?.casinoFavoriteGames?.configs) {
            for (const [key] of Object.entries(
              data.casinoFavoriteGames.configs
            )) {
              preparedData.games.push(data.casinoFavoriteGames.configs[+key]);
            }

            store.dispatch(
              setPersonalizedGameResourceId(data.casinoFavoriteGames._id)
            );
          }

          if (data?.casinoFavoriteProviders?.configs) {
            for (const [key] of Object.entries(
              data.casinoFavoriteProviders.configs
            )) {
              preparedData.providers.push(key);
            }

            store.dispatch(
              setPersonalizedProviderResourceId(
                data.casinoFavoriteProviders._id
              )
            );
          }

          if (data?.favoriteMarkets?.configs) {
            preparedData.markets = Object.keys(data.favoriteMarkets.configs);

            store.dispatch(
              setPersonalizedMarketResourceId(data.favoriteMarkets._id)
            );
          }

          const unsignedFavSportGame: {
            groupKey: 'sportsbook' | 'esport';
            gameType: 'prematch' | 'live';
            id: number;
          } = JSON.parse(
            LocalStorage.getItem(
              storageKeyName('account', 'UNSIGNED_FAV_SPORT_GAME')
            )
          );

          LocalStorage.removeItem(
            storageKeyName('account', 'UNSIGNED_FAV_SPORT_GAME')
          );

          if (data?.sportSBFavoriteGames?.configs || unsignedFavSportGame) {
            const initaialState = {
              count: 0,
              data: {},
              ids: []
            };

            preparedData.sportGames = {
              sportsbook: {
                live: structuredClone(initaialState),
                prematch: structuredClone(initaialState)
              },
              esport: {
                live: structuredClone(initaialState),
                prematch: structuredClone(initaialState)
              }
            };

            if (data?.sportSBFavoriteGames?.configs) {
              preparedData.sportGames.sportsbook.live.ids = Object.keys(
                data.sportSBFavoriteGames.configs.sportsbook.live
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.sportsbook.live
                  ).map(id => +id)
                : [];

              preparedData.sportGames.sportsbook.prematch.ids = Object.keys(
                data.sportSBFavoriteGames.configs.sportsbook.prematch
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.sportsbook.prematch
                  ).map(id => +id)
                : [];

              preparedData.sportGames.esport.live.ids = Object.keys(
                data.sportSBFavoriteGames.configs.esport.live
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.esport.live
                  ).map(id => +id)
                : [];

              preparedData.sportGames.esport.prematch.ids = Object.keys(
                data.sportSBFavoriteGames.configs.esport.prematch
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.esport.prematch
                  ).map(id => +id)
                : [];
            }

            if (unsignedFavSportGame) {
              if (
                !preparedData.sportGames[unsignedFavSportGame.groupKey][
                  unsignedFavSportGame.gameType
                ].ids.includes(+unsignedFavSportGame.id)
              ) {
                preparedData.sportGames[unsignedFavSportGame.groupKey][
                  unsignedFavSportGame.gameType
                ].ids.push(+unsignedFavSportGame.id);
              }
            }

            data?.sportSBFavoriteGames?._id &&
              store.dispatch(
                setPersonalizedSportGamesResourceId(
                  data.sportSBFavoriteGames._id
                )
              );
          }

          if (!data?.tourSteps?.configs) {
            data.tourSteps = {
              configs: tourStepsData
            };
          }

          preparedData.tourStepsInitialData = data.tourSteps.configs;

          callback(preparedData);
        } else {
          store.dispatch(setPersonalisedDataLoaded(true));
        }
      }
    );
  };

  const clearResource = (id: string) => {
    swarmCommand(
      CommandNames.DELETE_USER_RESOURCES_PERSONALIZATION,
      {
        _id: id
      },
      () => {}
    );
  };

  const clearDataFromStorage = () => {
    store.dispatch(
      setPersonalizationData({
        casinoGames: [],
        competitions: [],
        casinoProviders: [],
        categories: []
      })
    );
    store.dispatch(setFavoriteCompetitions([]));
    store.dispatch(setFavoriteCasinoGames([]));
    store.dispatch(setFavoriteCasinoProviders([]));
    store.dispatch(setFavoriteMarkets([]));
    store.dispatch(
      setFavoriteSportsData({
        esport: {
          live: { ids: [], data: {}, count: 0 },
          prematch: { ids: [], data: {}, count: 0 }
        },
        sportsbook: {
          live: { ids: [], data: {}, count: 0 },
          prematch: { ids: [], data: {}, count: 0 }
        }
      })
    );
  };

  return {
    getShowPopup: getShowPopup,
    storeHasUserSeenPopup: storeHasUserSeenPopup,
    storeCasinoProviders: storeCasinoProviders,
    storeSportCompetitions: storeSportCompetitions,
    storeMarketGroups: storeMarketGroups,
    storeSportGames: storeSportGames,
    storeCasinoGames: storeCasinoGames,
    storeTourSteps: storeTourSteps,
    prepareFavoritesInitialData: prepareFavoritesInitialData,
    clearResource: clearResource,
    clearDataFromStorage: clearDataFromStorage
  };
};
