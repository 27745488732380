import { ComponentProps, Suspense } from 'react';
import { lazy } from 'utils/generic/lazy';

const { BetslipRootProvider } = lazy(
  () =>
    import(
      /* webpackChunkName: "betslip-root-provider"*/ 'newelements/_Betslip/providers'
    )
);

const { BetslipComponent } = lazy(
  () =>
    import(
      /* webpackChunkName: "betslip-element"*/ 'newelements/_Betslip/component'
    )
);

export const Betslip = (props: ComponentProps<typeof BetslipComponent>) => {
  return (
    <Suspense>
      <BetslipRootProvider>
        <BetslipComponent {...props} />
      </BetslipRootProvider>
    </Suspense>
  );
};
