import { getCookie, setCookie } from './cookies';
import { CookieExpiration } from '../constants/dateInfo/dateInfo';

type LoginCredentialsType = {
  rememberMe: boolean;
  userGroups: string;
  jwe_token?: string;
};

export const setCookieLoginCredentials = (
  cName: string,
  cValue: LoginCredentialsType,
  exDays = CookieExpiration.DAYS_15
): void => {
  setCookie(
    cName,
    JSON.stringify({
      rememberMe: cValue.rememberMe,
      jwe_token: cValue.jwe_token,
      userGroups: '1'
    }),
    exDays
  );
};

export const getCookieLoginCredentials = (): {
  rememberMe: boolean;
  jwe_token?: string;
} => {
  const cValue = getCookie('loginCredentials');

  if (typeof cValue !== 'string') {
    return {
      rememberMe:
        cValue.rememberMe !== false && cValue.rememberMe !== undefined,
      jwe_token: cValue.jwe_token
    };
  }

  return {
    rememberMe: false
  };
};
