import { ActionType } from 'interfaces/generic';
import { ResultsSport } from 'interfaces/results';
import { ResultsDataActionTypes } from 'store/action-types/results-data';

export const setDate = (date: { from: number; to: number }): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_DATE,
    payload: date
  };
};

export const setCompetition = (id: number | undefined): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_COMPETITION,
    payload: id
  };
};

export const setTeam = (id: number | undefined): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_TEAM,
    payload: id
  };
};

export const setGameStatus = (status: 'live' | 'finished'): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_STATUS,
    payload: status
  };
};

export const setSport = (id: number): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_SPORT,
    payload: id
  };
};

export const setData = (data: ResultsSport[] | undefined): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_DATA,
    payload: data
  };
};

export const setTrigger = (): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_TRIGGER
  };
};

export const setCurrentStatus = (): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_CURRENT_STATUS
  };
};

export const setDataLoading = (loading: boolean): ActionType => {
  return {
    type: ResultsDataActionTypes.SET_DATA_LOADING,
    payload: loading
  };
};

export const resetFilters = (): ActionType => {
  return {
    type: ResultsDataActionTypes.RESET_FILTERS
  };
};
