// prettier-ignore

export const BetslipActionTypes = {
  SET_ACTIVE_CONFLICTING_FEATURE: 'SET_ACTIVE_CONFLICTING_FEATURE',
  UPDATE_EVENT_AND_GAME_IDS: 'UPDATE_EVENT_AND_GAME_IDS',
  CLOSE_EASY_BET: 'CLOSE_EASY_BET',
  UPDATE_QUICK_BET_STAKE: 'UPDATE_QUICK_BET_STAKE',
  TOGGLE_QUICK_BET_POPUP: 'TOGGLE_QUICK_BET_POPUP',
  TOGGLE_QUICK_BET: 'TOGGLE_QUICK_BET',
  BETTED_TRIGGER: 'BETTED_TRIGGER',
  UPDATE_OPEN_BETS: 'UPDATE_OPEN_BETS',
  UPDATE_BET_TICKETS: 'UPDATE_BET_TICKETS',
  UPDATE_ODD_FORMAT: 'UPDATE_ODD_FORMAT',
  LADDER_LOADED: 'LADDER_LOADED',
  SET_TYPE: 'SET_TYPE',
  SET_ALL_LS_BETS: 'SET_ALL_LS_BETS',
  SET_STAKES: 'SET_STAKES',
  SET_ODDS_COUNTER_OFFERS: 'SET_ODDS_COUNTER_OFFERS',
  SET_IS_BET_IN_PROGRESS: 'SET_IS_BET_IN_PROGRESS',
  SET_SYSTEM: 'SET_SYSTEM',
  SET_SELECTED_STAKE: 'SET_SELECTED_STAKE',
  SET_SELECTED_ODD_COUNTER_OFFER: 'SET_SELECTED_ODD_COUNTER_OFFER',
  SET_COUNTER_OFFER_SELECTED_TYPE: 'SET_COUNTER_OFFER_SELECTED_TYPE',
  SET_MUST_OPEN: 'SET_MUST_OPEN',
  SET_MUST_CLOSE: 'SET_MUST_CLOSE',
  SET_RS_PREDEFINED_STAKES: 'SET_RS_PREDEFINED_STAKES',
  UPDATE_BETSLIP_TYPE: 'UPDATE_BETSLIP_TYPE',
  UPDATE_BETSLIP_UK_TYPE: 'UPDATE_BETSLIP_UK_TYPE',
  SET_SELECTED_BET_TYPE: 'SET_SELECTED_BET_TYPE',
  SET_TOTAL_ODD_RETURN: 'SET_TOTAL_ODD_RETURN',
  SET_TOTAL_BET_STAKE: 'SET_TOTAL_BET_STAKE',
  TOGGLE_SUPER_BET: 'TOGGLE_SUPER_BET',
  RESET_SUPER_BETS: 'RESET_SUPER_BETS',
  SET_SENT_COUNTER_OFFERS_COUNT: 'SET_SENT_COUNTER_OFFERS_COUNT',
  SET_COUNTER_OFFERS_UNDER_REVIEW_MODAL: 'SET_COUNTER_OFFERS_UNDER_REVIEW_MODAL',
  SET_SENT_SUPER_BET_COUNT: 'SET_SENT_SUPER_BET_COUNT',
  SET_EDIT_BET_ACTIVE: 'SET_EDIT_BET_ACTIVE',
  SET_CASHOUT_FROM: 'SET_CASHOUT_FROM',
  SET_CONFLICTING_GAME_IDS: 'SET_CONFLICTING_GAME_IDS',
  SET_TEMPORARY_EVENT_HOLDER: 'SET_TEMPORARY_EVENT_HOLDER',
  SET_ACTIVE_TAB_PAGE: 'SET_ACTIVE_TAB_PAGE',
  FREEZE_TAB_CHANGE: 'FREEZE_TAB_CHANGE',
  SET_SPORT_BONUS_RULES: 'SET_SPORT_BONUS_RULES',
  SET_USE_BALANCE_BONUS: 'SET_USE_BALANCE_BONUS',
  SET_IS_BOOKING_MODE: 'SET_IS_BOOKING_MODE',
  SET_ACTIVE_MARKET_ODD: 'SET_ACTIVE_MARKET_ODD',
  SET_BETSLIP_OPEN: 'SET_BETSLIP_OPEN',
  TOGGLE_TICKETS_CHANGED: 'TOGGLE_TICKETS_CHANGED',
  SET_SINGLE_RETURN: 'SET_SINGLE_RETURN',
  SET_IS_CALCULATING: 'SET_IS_CALCULATING',
  SET_CALCULATION_LOADING: 'SET_CALCULATION_LOADING',
  SET_SUBSCRIBED_EVENTS: 'SET_SUBSCRIBED_EVENTS',
  REMOVE_SUBSCRIBED_EVENT: 'REMOVE_SUBSCRIBED_EVENT',
  MERGE_SUBSCRIBED_EVENTS: 'MERGE_SUBSCRIBED_EVENTS',
  SET_OPEN_BETS_COUNT: 'SET_OPEN_BETS_COUNT',
  SET_OPEN_BETS_COUNT_AFTER_CASHOUT: 'SET_OPEN_BETS_COUNT_AFTER_CASHOUT',
  SET_OPEN_BETS_DATA: 'SET_OPEN_BETS_DATA',
  SET_OPEN_BETS_LOADING: 'SET_OPEN_BETS_LOADING',
  SET_TABS: 'SET_TABS',
  SET_CASHOUT_TICKET: 'SET_CASHOUT_TICKET',
  SET_BOOK_BET_ID: 'SET_BOOK_BET_ID',
  SET_TAXES_DATA: 'SET_TAXES_DATA',
  SET_BASIC_EW: 'SET_BASIC_EW',

  SET_MULTIPLE_PROFIT_BOOST_ITEMS: 'SET_MULTIPLE_PROFIT_BOOST_ITEMS',
  REMOVE_PROFIT_BOOST_ITEM: 'REMOVE_PROFIT_BOOST_ITEM',
  ADD_SINGLE_PROFIT_BOOST_ITEM: 'ADD_SINGLE_PROFIT_BOOST_ITEM',
  SET_SELECTED_PROFIT_BOOST_ID: 'SET_SELECTED_PROFIT_BOOST_ID',
  CLEAN_PROFIT_BOOST_STATE: 'CLEAN_PROFIT_BOOST_STATE',
  SET_SELECTED_PROFIT_BOOST_EVENT_ID: 'SET_SELECTED_PROFIT_BOOST_EVENT_ID',

  SET_PROFIT_BOOST: 'SET_PROFIT_BOOST',
  SET_PROFIT_BOOST_ADVANCED: 'SET_PROFIT_BOOST_ADVANCED',
  ADD_PROFIT_BOOST_ADVANCED_SINGLE_ITEM:
    'ADD_PROFIT_BOOST_ADVANCED_SINGLE_ITEM',
  ADD_PROFIT_BOOST_ADVANCED_ITEM: 'ADD_PROFIT_BOOST_ADVANCED_ITEM',
  SET_FREE_BETS_DATA_FIELDS: 'SET_FREE_BETS_DATA_FIELDS',
  UPDATE_FREE_BET_PARTIAL: 'UPDATE_FREE_BET_PARTIAL',
  SET_FREE_BET_SELECTED_EVENT_ID: 'SET_FREE_BET_SELECTED_EVENT_ID',
  SET_SELECTED_FREE_BET_AMOUNT_ID: 'SET_SELECTED_FREE_BET_AMOUNT_ID',
  SET_IS_FREE_BET: 'SET_IS_FREE_BET',
  SET_ADVANCED_FREE_BET_DATA: 'SET_ADVANCED_FREE_BET_DATA',
  SET_FREE_BET_SELECTED_AMOUNT_ID: 'SET_FREE_BET_SELECTED_AMOUNT_ID',
  RESET_FREE_BETS_DATA_FIELDS: 'RESET_FREE_BETS_DATA_FIELDS',
  REMOVE_FREE_BET_ITEM: 'REMOVE_FREE_BET_ITEM',
  UPDATE_FREE_BET_SINGLE_EVENTS: 'UPDATE_FREE_BET_SINGLE_EVENTS',
  UPDATE_QUICK_BET_IN_PROCESS_COUNT: 'UPDATE_QUICK_BET_IN_PROCESS_COUNT',
  SET_TOTAL_STAKE: 'SET_TOTAL_STAKE',
  SET_TOTAL_SINGLE_WIN: 'SET_TOTAL_SINGLE_WIN',
  SET_CURRENT_STAKE: 'SET_CURRENT_STAKE',
  SET_MAX_STAKE_LOADING: 'SET_MAX_STAKE_LOADING',
  SET_SINGLE_QUICK_BETSLIP_OPEN: 'SET_SINGLE_QUICK_BETSLIP_OPEN',
  SET_EVENTS_PREV_ODDS: 'SET_EVENTS_PREV_ODDS',
  SET_ADVANCED_SELECTED_TAB: 'SET_ADVANCED_SELECTED_TAB',
  SET_IS_ACCUMULATOR_BONUS_CALCULATING: 'SET_IS_ACCUMULATOR_BONUS_CALCULATING',
  SET_COUNTER_OFFERS: 'SET_COUNTER_OFFERS',
  RESET_COUNTER_OFFERS: 'RESET_COUNTER_OFFERS',
  SET_ONLY_WITH_BONUS_BALANCE: 'SET_ONLY_WITH_BONUS_BALANCE',
  SET_IS_FRENCH_FREE_BET_ENABLED: 'SET_IS_FRENCH_FREE_BET_ENABLED',
  SET_BETS_TOTAL_ODDS: 'SET_BETS_TOTAL_ODDS',
  SET_SELECTED_SYSTEM: 'SET_SELECTED_SYSTEM',
  SET_SYSTEM_POSSIBLE_COMBINATIONS: 'SET_SYSTEM_POSSIBLE_COMBINATIONS',
  SET_SYSTEM_MIN_COMBINATIONS_COUNT: 'SET_SYSTEM_MIN_COMBINATIONS_COUNT',
  SET_COMBINATION_OF_SELECTED_SYSTEM: 'SET_COMBINATION_OF_SELECTED_SYSTEM'
};

export const betslipBetsSliceActions = {
  SET_ALL_LS_BETS: 'SET_ALL_LS_BETS',
  UPDATE_BET_TICKETS: 'UPDATE_BET_TICKETS',
  SET_IS_BET_IN_PROGRESS: 'SET_IS_BET_IN_PROGRESS',
  TOGGLE_TICKETS_CHANGED: 'TOGGLE_TICKETS_CHANGED',
  UPDATE_EVENT_AND_GAME_IDS: 'UPDATE_EVENT_AND_GAME_IDS',
  RESET_ALL_EACH_WAY: 'RESET_ALL_EACH_WAY',
  TOGGLE_SINGLE_EACH_WAY: 'TOGGLE_SINGLE_EACH_WAY',
  BETTED_TRIGGER: 'BETTED_TRIGGER',
  SET_SELECTED_BET_TYPE: 'SET_SELECTED_BET_TYPE',
  UPDATE_BETSLIP_UK_TYPE: 'UPDATE_BETSLIP_UK_TYPE',
  SET_CONFLICTING_GAME_IDS: 'SET_CONFLICTING_GAME_IDS',
  SET_SUBSCRIBED_EVENTS: 'SET_SUBSCRIBED_EVENTS',
  REMOVE_SUBSCRIBED_EVENT: 'REMOVE_SUBSCRIBED_EVENT',
  MERGE_SUBSCRIBED_EVENTS: 'MERGE_SUBSCRIBED_EVENTS'
};

export const betslipCalculationsSliceActions = {
  SET_CALCULATION_RESULTS: 'SET_CALCULATION_RESULTS',
  REMOVE_GENERAL_CALCULATION: 'REMOVE_GENERAL_CALCULATION',
  REMOVE_SINGLE_EVENT_CALCULATION: 'REMOVE_SINGLE_EVENT_CALCULATION'
};
export const basicBetslipCalculationsSliceActions = {
  SET_GENERAL_STAKE: 'SET_GENERAL_STAKE',
  UPDATE_SINGLE_EVENT_FIELD: 'UPDATE_SINGLE_EVENT_FIELD',
  SET_SINGLE_EVENT_PROPERTIES: 'SET_SINGLE_EVENT_PROPERTIES'
};
