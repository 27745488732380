import RidGenerator from 'utils/swarm/rid-generator';
import {
  CommandObj,
  WebsocketEnhanced
} from 'interfaces/spring-websocket-interfaces';
import { connectingWS } from 'hooks/useSpringConnect';

export const swarmCommand = (
  command: string,
  params: object,
  callback: Function | null,
  callbackUpdate?: Function | null,
  callbackError?: Function | null
): void => {
  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(
      {
        command,
        params,
        rid: RidGenerator.gForCommand()
      },
      '',
      callback,
      callbackUpdate,
      callbackError
    );
  });
};

export const sendCommand = async (
  commandObj: CommandObj, //  command object must be of type any for generic purpose
  callback: Function | null,
  callbackUpdate?: Function | null,
  callbackError?: Function | null
): Promise<void> => {
  connectingWS.then((socket: WebsocketEnhanced) =>
    socket.sendCommand(commandObj, '', callback, callbackUpdate, callbackError)
  );
};

export const unsubscribeCommand = (rid: string): void => {
  connectingWS.then((socket: WebsocketEnhanced) => socket.unsubscribe(rid));
};
