import { AnyAction } from 'redux';
import { BetTicketMin } from 'interfaces/bet-data';
import { betslipBetsSliceActions } from 'store/action-types';

export const updateEventAndGameIds = (
  payload: Array<{
    gameId: number;
    eventId: number;
  }>
): AnyAction => {
  return {
    type: betslipBetsSliceActions.UPDATE_EVENT_AND_GAME_IDS,
    payload
  };
};

export const updateBetTickets = (payload: Array<BetTicketMin>): AnyAction => {
  return {
    type: betslipBetsSliceActions.UPDATE_BET_TICKETS,
    payload
  };
};

export const setAllLsBets = (payload: Array<any>): AnyAction => {
  return {
    type: betslipBetsSliceActions.SET_ALL_LS_BETS,
    payload
  };
};

export const setIsBetInProgress = (payload: boolean): AnyAction => {
  return {
    type: betslipBetsSliceActions.SET_IS_BET_IN_PROGRESS,
    payload
  };
};

export const toggleTicketsChanged = (): AnyAction => {
  return {
    type: betslipBetsSliceActions.TOGGLE_TICKETS_CHANGED
  };
};

export const resetAllEachWay = (): AnyAction => {
  return {
    type: betslipBetsSliceActions.RESET_ALL_EACH_WAY
  };
};

export const toggleSingleEachWay = (id: string | number): AnyAction => {
  return {
    payload: id,
    type: betslipBetsSliceActions.TOGGLE_SINGLE_EACH_WAY
  };
};
