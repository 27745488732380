import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { addRemoveFromArray } from 'utils/collection-manipulation/add-remove-from-array';
import { deepMerge } from 'utils/collection-manipulation/deep-merge';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { ActionType } from 'interfaces/generic';
import {
  EAddRemoveAllReducerTypes,
  ERacingResultsTimeFilterTabs,
  ERacingTabsTimeFilters,
  ESetUpcomingDataReducerTypes,
  TRacingResultsRegion,
  TRacingResultsSingleGameGame
} from 'interfaces/racing';
import {
  EachWayPartnerTerms,
  RacingGame,
  RacingRegion,
  TSwarmRacingGame
} from 'interfaces/sportsbook-data-levels';
import { RacingDataActionTypes } from 'store/action-types';

const dateFilter = LocalStorage.getItem(
  storageKeyName('sportsbook', 'RACING_DATE_AND_SPORT_FILTER_ACTIVE_TAB')
);

const resultsDateFilter = LocalStorage.getItem(
  storageKeyName('sportsbook', 'RACING_RESULTS_DATE_FILTER_ACTIVE_TAB')
);

type State = {
  upcomingData: Record<number, RacingGame>;
  competitionData: Record<string, RacingRegion>;
  gameData: {};
  gameJsonSentIds: number[];
  gameJsonExistIds: number[];
  storedGameIds: number[];
  eachWayTerms: Record<string, EachWayPartnerTerms>;
  dateAndSportFilterActiveTab: string;
  isCompetitionsLoading: boolean;
  isGamesLoading: boolean;
  isUpcomingRacesLoading: boolean;
  singleGameFromActiveTab: string;
  checkSingleGameStartTs: boolean;
  checkCompetition: boolean;
  endedGameId: number | null;
  resultsTimeFilterActiveTab: ERacingResultsTimeFilterTabs;
  resultsCompetitionData: Record<
    ERacingResultsTimeFilterTabs,
    TRacingResultsRegion[]
  >;
  isResultsCompetitionDataLoading: boolean;
  resultsSingleGameData: Record<number, TRacingResultsSingleGameGame>;
  isResultsGameDataLoading: boolean;
  upcomingJsonExistIds: number[];
  upcomingJsonSentIds: number[];
  resultsGameJsonExistIds: number[];
};

const initialData: State = {
  upcomingData: {},
  competitionData: {},
  gameData: {},
  gameJsonSentIds: [],
  gameJsonExistIds: [],
  storedGameIds: [],
  eachWayTerms: {},
  dateAndSportFilterActiveTab:
    dateFilter !== 'null' ? dateFilter : ERacingTabsTimeFilters.TODAY,
  isCompetitionsLoading: true,
  isGamesLoading: true,
  isUpcomingRacesLoading: true,
  singleGameFromActiveTab: '',
  checkSingleGameStartTs: true,
  checkCompetition: true,
  endedGameId: null,
  resultsTimeFilterActiveTab: (resultsDateFilter !== 'null'
    ? resultsDateFilter
    : ERacingResultsTimeFilterTabs.YESTERDAY) as ERacingResultsTimeFilterTabs,
  resultsCompetitionData: {
    [ERacingResultsTimeFilterTabs.YESTERDAY]: [],
    [ERacingResultsTimeFilterTabs.TODAY]: []
  },
  isResultsCompetitionDataLoading: true,
  resultsSingleGameData: {},
  isResultsGameDataLoading: true,
  upcomingJsonExistIds: [],
  upcomingJsonSentIds: [],
  resultsGameJsonExistIds: []
};

export const raceData = (state = initialData, action: ActionType): State => {
  switch (action.type) {
    case RacingDataActionTypes.SET_UPCOMING_DATA: {
      const currentData = { ...state.upcomingData };
      let updatedData: Record<number, RacingGame> = {};

      if (action.payload.type === ESetUpcomingDataReducerTypes.ADD_ALL) {
        updatedData = action.payload.swarmData;
      } else if (
        action.payload.type === ESetUpcomingDataReducerTypes.UPDATE_ALL
      ) {
        for (const key in action.payload.swarmData) {
          if (currentData[+key]) {
            updatedData[+key] = deepMerge(
              currentData[+key],
              action.payload.swarmData[+key],
              { shouldMergeInFirst: false, applyEmptyArraysAndObjects: true }
            );
          } else {
            currentData[+key] = action.payload.swarmData[key];
          }
        }
      } else if (
        action.payload.type === ESetUpcomingDataReducerTypes.UPDATE_SINGLE_GAME
      ) {
        updatedData = currentData;

        const mergedGame = deepMerge(
          updatedData[+Object.keys(action.payload.swarmData)[0]],
          Object.values(action.payload.swarmData)[0],
          { shouldMergeInFirst: false, applyEmptyArraysAndObjects: true }
        );

        updatedData[mergedGame.id] = mergedGame;
      }

      return {
        ...state,
        upcomingData: updatedData
      };
    }

    case RacingDataActionTypes.SET_UPCOMING_JSON_EXIST_IDS: {
      const ids = [...state.upcomingJsonExistIds];

      const idsNewArray = addRemoveFromArray(
        action.payload.type,
        ids,
        +action.payload.id
      ) as number[];

      return {
        ...state,
        upcomingJsonExistIds: idsNewArray
      };
    }

    case RacingDataActionTypes.SET_UPCOMING_JSON_SENT_IDS: {
      const ids = [...state.upcomingJsonSentIds];

      const idsNewArray = addRemoveFromArray(
        action.payload.type,
        ids,
        +action.payload.id
      ) as number[];

      return {
        ...state,
        upcomingJsonSentIds: idsNewArray
      };
    }

    case RacingDataActionTypes.SET_RACING_COMPETITION_DATA: {
      let data = {};

      if (action.payload.type !== EAddRemoveAllReducerTypes.REMOVE_ALL) {
        data = action.payload.data;
      }

      return {
        ...state,
        competitionData: { ...data }
      };
    }

    case RacingDataActionTypes.SET_GAME: {
      const swarmData: TSwarmRacingGame | null = action.payload.swarmData;

      const gameData =
        swarmData === null || !swarmData
          ? {}
          : deepMerge(state.gameData, swarmData.game, {
              shouldMergeInFirst: false,
              applyEmptyArraysAndObjects: true
            });

      return {
        ...state,
        gameData
      };
    }

    case RacingDataActionTypes.SET_GAME_JSON_SENT_IDS: {
      const ids = [...state.gameJsonExistIds];

      const idsNewArray = addRemoveFromArray(
        action.payload.type,
        ids,
        +action.payload.id
      ) as number[];

      return {
        ...state,
        gameJsonSentIds: idsNewArray
      };
    }

    case RacingDataActionTypes.SET_GAME_JSON_EXIST_IDS: {
      const ids = [...state.gameJsonExistIds];

      const idsNewArray = addRemoveFromArray(
        action.payload.type,
        ids,
        +action.payload.id
      ) as number[];

      return {
        ...state,
        gameJsonExistIds: idsNewArray
      };
    }

    case RacingDataActionTypes.SET_STORED_GAME_IDS: {
      const storedGameIds =
        action.payload.type === EAddRemoveAllReducerTypes.REMOVE_ALL
          ? []
          : new Set([...state.storedGameIds, action.payload.storedGameId]);

      return {
        ...state,
        storedGameIds: [...storedGameIds]
      };
    }

    case RacingDataActionTypes.SET_EACH_WAY_PARTNER_TERMS: {
      return {
        ...state,
        eachWayTerms: {
          ...state.eachWayTerms,
          [action.payload.marketId]: action.payload.manualEachWayData
        }
      };
    }

    case RacingDataActionTypes.SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB: {
      return {
        ...state,
        dateAndSportFilterActiveTab: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_COMPETITIONS_LOADING: {
      return {
        ...state,
        isCompetitionsLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_GAMES_LOADING: {
      return {
        ...state,
        isGamesLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_UPCOMING_RACES_LOADING: {
      return {
        ...state,
        isUpcomingRacesLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_SINGLE_GAME_FROM_ACTIVE_TAB: {
      return {
        ...state,
        singleGameFromActiveTab: action.payload
      };
    }

    case RacingDataActionTypes.SET_CHECK_SINGLE_GAME_START_TS: {
      return {
        ...state,
        checkSingleGameStartTs: action.payload
      };
    }

    case RacingDataActionTypes.SET_CHECK_COMPETITION: {
      return {
        ...state,
        checkCompetition: action.payload
      };
    }

    case RacingDataActionTypes.SET_ENDED_GAME_ID: {
      return {
        ...state,
        endedGameId: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_TIME_FILTER_ACTIVE_TAB: {
      return {
        ...state,
        resultsTimeFilterActiveTab: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_COMPETITION_DATA: {
      let data: Record<ERacingResultsTimeFilterTabs, TRacingResultsRegion[]> =
        state.resultsCompetitionData;

      if (action.payload.type !== EAddRemoveAllReducerTypes.REMOVE_ALL) {
        data[action.payload.timeFilter as ERacingResultsTimeFilterTabs] =
          action.payload.resultsCompetitionData;
      } else {
        data = {
          [ERacingResultsTimeFilterTabs.YESTERDAY]: [],
          [ERacingResultsTimeFilterTabs.TODAY]: []
        };
      }

      return {
        ...state,
        resultsCompetitionData: { ...data }
      };
    }

    case RacingDataActionTypes.SET_IS_RESULTS_COMPETITION_DATA_LOADING: {
      return {
        ...state,
        isResultsCompetitionDataLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_SINGLE_GAME_DATA: {
      let data: Record<number, TRacingResultsSingleGameGame> = {};

      if (action.payload.type === EAddRemoveAllReducerTypes.ADD) {
        const newData = { ...state.resultsSingleGameData };
        newData[action.payload.data.id] = action.payload.data;
        data = newData;
      }

      return {
        ...state,
        resultsSingleGameData: data
      };
    }

    case RacingDataActionTypes.SET_IS_RESULTS_GAME_DATA_LOADING: {
      return {
        ...state,
        isResultsGameDataLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_GAME_JSON_EXIST_IDS: {
      const ids = [...state.resultsGameJsonExistIds];

      const idsNewArray = addRemoveFromArray(
        action.payload.type,
        ids,
        +action.payload.id
      ) as number[];

      return {
        ...state,
        resultsGameJsonExistIds: idsNewArray
      };
    }

    default: {
      return state;
    }
  }
};
