import { AnyAction } from 'redux';
import { FreeBetAmounts } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipFreeBetSliceInitial } from 'store/reducers/betslip/freeBet';

// FreeBet

export const setFreeBetField = (
  payload: Partial<IBetslipFreeBetSliceInitial['freeBets']>
): ActionType => {
  return {
    type: BetslipActionTypes.SET_FREE_BETS_DATA_FIELDS,
    payload
  };
};

export const setFreeBetSelectedEventId = (
  payload: string | number | null = null
): ActionType => {
  return {
    type: BetslipActionTypes.SET_FREE_BET_SELECTED_EVENT_ID,
    payload
  };
};

export const setFreeBetSelectedAmountId = (
  payload: number | null
): ActionType => {
  return {
    type: BetslipActionTypes.SET_SELECTED_FREE_BET_AMOUNT_ID,
    payload
  };
};

export const setAdvancedFreeBetsData = (
  payload: FreeBetAmounts
): ActionType => {
  return {
    type: BetslipActionTypes.SET_ADVANCED_FREE_BET_DATA,
    payload
  };
};

export const resetFreeBetData = (keepCache = false): ActionType => {
  return {
    type: BetslipActionTypes.RESET_FREE_BETS_DATA_FIELDS,
    payload: keepCache
  };
};

export const removeFreeBetItem = (freeBetId: number): ActionType => {
  return {
    type: BetslipActionTypes.REMOVE_FREE_BET_ITEM,
    payload: freeBetId
  };
};

export const updateSingleEvents = (
  payload: IBetslipFreeBetSliceInitial['freeBets']['singleEventFreeBets']
): AnyAction => {
  return {
    type: BetslipActionTypes.UPDATE_FREE_BET_SINGLE_EVENTS,
    payload
  };
};

export const setIsFrenchFreeBetEnabled = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_IS_FRENCH_FREE_BET_ENABLED,
    payload
  };
};

// SET_FREE_BET_SELECTED_EVENT_ID;
// SET_FREE_BET_SELECTED_AMOUNT_ID;
// SET_IS_FREE_BET;
