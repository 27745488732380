import { AnyAction } from 'redux';
import { betslipCalculationsSliceActions } from 'store/action-types';
import { IBetCalculationResultsWithAllFields } from 'store/reducers/betslip/calculations';

export const setCalculationResult = (payload: {
  result: IBetCalculationResultsWithAllFields;
  id: string | number;
}): AnyAction => {
  return {
    type: betslipCalculationsSliceActions.SET_CALCULATION_RESULTS,
    payload
  };
};

export const removeSingleEventCalculation = (
  payload: string | number
): AnyAction => {
  return {
    type: betslipCalculationsSliceActions.REMOVE_SINGLE_EVENT_CALCULATION,
    payload
  };
};

export const removeGeneralCalculation = (): AnyAction => {
  return {
    type: betslipCalculationsSliceActions.REMOVE_GENERAL_CALCULATION
  };
};
