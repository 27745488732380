import {
  Competition,
  Game,
  Region,
  Sport,
  TFavoriteSportsGroupedData,
  TFavoritesState
} from 'interfaces/sportsbook-data-levels';

export function removeMatchByID<T extends Record<string, Sport>>(
  obj: T,
  id: number
): T {
  const updatedObj: Record<string, Sport<Game>> = {};

  Object.values(obj || {}).forEach(sport => {
    const sportNew = structuredClone(sport);
    delete sportNew.region;

    Object.values(sport.region || {}).forEach(region => {
      const regionNew = structuredClone(region);
      delete regionNew.competition;

      Object.values(region.competition || {}).forEach(competition => {
        const competitionNew = structuredClone(competition);
        delete competitionNew.game;

        Object.values(competition.game || {}).forEach(game => {
          if (game && Number(game.id) !== Number(id)) {
            if (updatedObj[sport.id]) {
              const regionData = updatedObj[sport.id]?.region;
              const competitionData = regionData?.[region.id]?.competition;

              if (regionData?.[region.id]) {
                if (competitionData?.[competition.id]) {
                  updatedObj[sport.id] = {
                    ...(updatedObj[sport.id] || {}),
                    region: {
                      ...(regionData || {}),
                      [region.id]: {
                        ...(regionData?.[region.id] || {}),
                        competition: {
                          ...(competitionData || {}),
                          [competition.id]: {
                            ...(competitionData?.[competition.id] || {}),
                            game: {
                              ...(competitionData?.[competition.id]?.game ||
                                {}),
                              [game.id]: game
                            }
                          }
                        }
                      }
                    }
                  };
                } else {
                  updatedObj[sport.id] = {
                    ...(updatedObj[sport.id] || {}),
                    region: {
                      ...(regionData || {}),
                      [region.id]: {
                        ...(regionData?.[region.id] || {}),
                        competition: {
                          ...(competitionData || {}),
                          [competition.id]: {
                            ...competitionNew,
                            game: {
                              ...(competitionData?.[competition.id] || {}),
                              [game.id]: game
                            }
                          }
                        }
                      }
                    }
                  };
                }
              } else {
                updatedObj[sport.id].region = {
                  ...(updatedObj[sport.id].region || {}),
                  [region.id]: {
                    ...regionNew,
                    competition: {
                      [competition.id]: {
                        ...competitionNew,
                        game: {
                          [game.id]: game
                        }
                      }
                    }
                  }
                };
              }
            } else {
              updatedObj[sport.id] = {
                ...sportNew,
                region: {
                  [region.id]: {
                    ...regionNew,
                    competition: {
                      [competition.id]: {
                        ...competitionNew,
                        game: {
                          [game.id]: game
                        }
                      }
                    }
                  }
                }
              };
            }
          }
        });
      });
    });
  });

  return updatedObj as T;
}

const arrayFromObject = <T extends Record<string, any>>(object?: T) =>
  Object.values(object || {});

const applyPropertyIfNotExist = <T extends Record<string, any>>(
  object: T,
  property: keyof T,
  value: T[keyof T]
) => {
  if (!(property in object)) {
    object[property] = value;
  }

  return object;
};

const customDataModifier = <T>(
  objects: Record<string, T>,
  modifier: (item: T) => T
): Record<string, T> => {
  const result = {} as Record<string, T>;

  Object.entries(objects).forEach(([key, object]) => {
    result[key] = modifier(object);
  });

  return result;
};

export const applyMarketsCountToGame = (
  sports: Record<string, Sport>,
  marketsCount: TFavoritesState['marketsCount']
) => {
  return customDataModifier(sports, sport => ({
    ...sport,
    region: customDataModifier(sport.region || {}, region => ({
      ...region,
      competition: customDataModifier(
        region.competition || {},
        competition => ({
          ...competition,
          game: customDataModifier(competition.game || {}, game => ({
            ...game,
            markets_count: marketsCount[game.id]?.market || 0
          }))
        })
      )
    }))
  }));
};

export const modifyFavoritesResponse = (
  sports: Record<string, Sport>,
  updateOnlyIds?: boolean
): Pick<TFavoritesState, 'esport' | 'sportsbook'> => {
  const response: Pick<TFavoritesState, 'esport' | 'sportsbook'> = {
    esport: {
      live: { count: 0, data: {}, ids: [] },
      prematch: { count: 0, data: {}, ids: [] }
    },
    sportsbook: {
      live: { count: 0, data: {}, ids: [] },
      prematch: { count: 0, data: {}, ids: [] }
    }
  };

  arrayFromObject(sports).forEach(sport => {
    const groupKey: keyof typeof response =
      sport.type === 0 ? 'esport' : 'sportsbook';

    arrayFromObject(sport.region).forEach(region => {
      arrayFromObject(region.competition).forEach(competition => {
        arrayFromObject(competition.game).forEach(game => {
          const gameType: keyof TFavoriteSportsGroupedData =
            game.type === 1 ? 'live' : 'prematch';

          if (!updateOnlyIds) {
            const data = response[groupKey][gameType].data;

            applyPropertyIfNotExist(data, sport.id, { ...sport, region: {} });

            const _region = data[sport.id].region as NonNullable<
              Sport['region']
            >;

            applyPropertyIfNotExist(_region, region.id, {
              ...region,
              competition: {}
            });

            const _competition = _region[region.id].competition as NonNullable<
              Region['competition']
            >;

            applyPropertyIfNotExist(_competition, competition.id, {
              ...competition,
              game: {}
            });

            const _game = _competition[competition.id].game as NonNullable<
              Competition['game']
            >;

            applyPropertyIfNotExist(_game, game.id, game);
          }

          response[groupKey][gameType].ids.push(game.id);

          response[groupKey][gameType].count++;
        });
      });
    });
  });

  return response;
};
