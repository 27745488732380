import { add, multiply } from 'utils/generic/calculator';
import { EBetslipBetType } from 'interfaces/betslip';
import { PartnerConfig } from 'interfaces/partner-config';
import type { TCalculateAndDispatchUpdatesParams } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/calculateAndDispatchUpdates';
import { calcModifiedStake } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/utils/calcModifiedStake';
import type { TCalculationParams } from 'newelements/_Betslip/utils/calculator';
import { GENERAL_FIELD_KEY } from 'newelements/_Betslip/utils/constants/shared';
import { roundOdd } from 'newelements/_Betslip/utils/roundings/round-amount';
import store from 'store';
import type { IBetslipRootStateInitialData } from 'store/reducers/betslip';

export const getCalculationsParams = (
  stake: number,
  params: TCalculateAndDispatchUpdatesParams
): TCalculationParams | null => {
  const betslipStore: IBetslipRootStateInitialData = store.getState().betSlip;
  const { max_odd_for_multiple_bet }: PartnerConfig =
    store.getState().socket.partnerConfigs || {};

  if (params.id === GENERAL_FIELD_KEY) {
    const isEachWay =
      params.isEachWay ?? betslipStore.calculations.general?.isEachWay ?? false;

    const isChainType = betslipStore.type === EBetslipBetType.CHAIN;

    const calculationArgs = betslipStore.allLsBets.reduce<TCalculationParams>(
      (parameters, event, index) => {
        const isLastItem = index === betslipStore.allLsBets.length - 1;

        const coef = event.sp
          ? isChainType && !isLastItem
            ? 0
            : 1
          : event.coeficient;

        const eventCoef =
          isChainType && !isLastItem ? Number(coef) - 1 : Number(coef);

        return {
          ...parameters,
          odd: isChainType
            ? add(eventCoef ?? 0, parameters.odd)
            : multiply(parameters.odd, eventCoef ?? 1),
          sportIds: [...parameters.sportIds, event.sport_id as number]
        };
      },
      {
        isEachWay,
        eventId: params.id,
        odd: isChainType ? 0 : 1,
        sportIds: [],
        stake: calcModifiedStake(stake, isEachWay)
      }
    );

    calculationArgs.odd =
      max_odd_for_multiple_bet && calculationArgs.odd > max_odd_for_multiple_bet
        ? max_odd_for_multiple_bet
        : +roundOdd(calculationArgs.odd);

    return calculationArgs;
  }

  const _event = betslipStore.allLsBets.find(
    ({ eventId }) => eventId === params.id
  );

  if (_event) {
    // @Todo need make sportId field required on betslip event object
    const sportIds = [_event.sport_id] as number[];
    const odd = params.oddOffer || _event.sp ? 1 : _event.coeficient;

    return {
      isEachWay: !!_event.each_way,
      eventId: params.id,
      stake: calcModifiedStake(stake, !!_event.each_way),
      sportIds: sportIds,
      odd: Number(odd) ?? ''
    };
  }

  return null;
};
