// prettier-ignore

export const CasinoDataActionTypes = {
  SET_CASINO_OPTIONS: 'SET_CASINO_OPTIONS',
  RESET_CASINO_OPTIONS: 'RESET_CASINO_OPTIONS',
  TOGGLE_ACTIVE_CATEGORY: 'TOGGLE_ACTIVE_CATEGORY',
  RESET_ACTIVE_CATEGORIES: 'RESET_ACTIVE_CATEGORIES',
  TOGGLE_ACTIVE_PROVIDER: 'TOGGLE_ACTIVE_PROVIDER',
  RESET_ACTIVE_PROVIDERS: 'RESET_ACTIVE_PROVIDERS',
  SET_ACTIVE_OPTIONS: 'SET_ACTIVE_OPTIONS',
  SET_CASHED_TOURNAMENTS_DATA: 'SET_CASHED_TOURNAMENTS_DATA',
  SET_CASHED_TOURNAMENTS_OPTIONS: 'SET_CASHED_TOURNAMENTS_OPTIONS',
  SET_CASINO_GAME_TOURNAMENTS: 'SET_CASINO_GAME_TOURNAMENTS',
  SET_CASINO_JACKPOT: 'SET_CASINO_JACKPOT',
  SET_CASINO_GAME_JACKPOT: 'SET_CASINO_GAME_JACKPOT',
  RESET_CASINO_JACKPOT: 'RESET_CASINO_JACKPOT',
  SET_SPORTSBOOK_JACKPOT: 'SET_SPORTSBOOK_JACKPOT',
  RESET_SPORTSBOOK_JACKPOT: 'RESET_SPORTSBOOK_JACKPOT',
  RESET_CASINO_GAME_JACKPOT: 'RESET_CASINO_GAME_JACKPOT',
  SET_SELECTED_CASINO_GAME: 'SET_SELECTED_CASINO_GAME',
  SET_JOINED_TOURNAMENTS: 'SET_JOINED_TOURNAMENTS',
  SET_SELECTED_CASINO_CATEGORY_ID: 'SET_SELECTED_CASINO_CATEGORY_ID',
  SET_IS_LOADED: 'SET_IS_LOADED',
  SET_TOURNAMENT_ACTIVE_PRODUCT_TYPE_ID: 'SET_TOURNAMENT_ACTIVE_PRODUCT_TYPE_ID'
};
