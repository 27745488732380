import { BetSlipEventObj } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';

export const setTotalOddReturn = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_TOTAL_ODD_RETURN,
    payload
  };
};

export const setBetsTotalOdds = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_BETS_TOTAL_ODDS,
    payload
  };
};

export const setEventsPrevOdds = (
  payload: Record<number, number | null | undefined>
): ActionType => {
  return {
    type: BetslipActionTypes.SET_EVENTS_PREV_ODDS,
    payload
  };
};

export const updateOddFormat = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.UPDATE_ODD_FORMAT,
    payload
  };
};

export const setActiveMarketOdd = (payload: BetSlipEventObj): ActionType => {
  return {
    type: BetslipActionTypes.SET_ACTIVE_MARKET_ODD,
    payload
  };
};
