import { AnyAction } from 'redux';
import { BetSlipEventObj } from 'interfaces/bet-data';
import { SportBonus } from 'interfaces/betslip';
import { GENERAL_FIELD_KEY } from 'newelements/_Betslip/utils/constants/shared';
import { betslipCalculationsSliceActions } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

// Tax types end

export interface ICalculationInitialValues {
  stake?: string;
  finalStake?: number;
  odd?: number;
  isEachWay?: boolean;
  oddOffer?: string | null;
  superBet?: boolean;
}

export interface IFinalCalculatedFields {
  stake: number;
  // final stake is multiplied value for system bet, eachWay,
  finalStake: number;
  // SuperBet
  superBet?: boolean;
  // CounterOffer
  oddOffer?: number;
  isEachWay?: boolean;
  possibleWin: number;
  possibleWinWithoutStake: number;
  possibleWinWithoutRangeTax?: number;
  possibleWinWithoutProfitBoost?: number;
  possibleWinWithoutStakeAndWithoutRangeTax?: number;
}

// @Todo need to move into separate files after finalization
// Tax types start ---
export interface IBasicTaxInfo {
  // this field need to detect is bet taxable based on tax_sport_ids from partnerConfigs
  isTaxable: boolean;

  taxAmount?: number;

  basicTaxedStake?: number;
  basicTaxedStakeTaxAmount?: number;

  basicTaxedPossibleWin?: number;
  basicTaxedPossibleWinTaxAmount?: number;

  basicTaxedPossibleWinWithoutStake?: number;
  basicTaxedPossibleWinWithoutStakeTaxAmount?: number;
}

export interface IRangeTaxInfo {
  taxAmount?: number;
  taxPercent?: number;

  rangeTaxedPossibleWin?: number;
  rangeTaxedPossibleWinTaxAmount?: number;

  rangeTaxedPossibleWinWithoutStake?: number;
  rangeTaxedPossibleWinWithoutStakeTaxAmount?: number;
}

export interface IBasicTaxesCalculationResult {
  taxes: IBasicTaxInfo;
  result: Omit<IFinalCalculatedFields, 'finalStake'>;
}

export interface IRangeTaxesCalculationResult {
  taxes: IRangeTaxInfo;
  result: Omit<IFinalCalculatedFields, 'finalStake'>;
}
// Tax types end ---

// Profit Boost types start ----

export interface ICalculatedProfitBoostInfo {
  boostAmount: number;
  possibleWinWithoutProfitBoostAmount: number;
}

export interface IProfitBoostCalculationResult {
  profitBoost: ICalculatedProfitBoostInfo;
  result: Omit<IFinalCalculatedFields, 'finalStake'>;
}

// Profit Boost types end ----

// Bonuses types start
export interface IGetAvailableBonusResult {
  currentAcceptedBonus: SportBonus | null;
  acceptedBonusBets: BetSlipEventObj[];
  nextPossibleBonusText: string | null;
  bonusAmount: number;
}
// Bonuses types end

// Calculation results types start
export interface ICalculationResult {
  basicTaxes: IBasicTaxInfo;
  rangeTaxes: IRangeTaxInfo;
  bonusInfo: IGetAvailableBonusResult;
  boostInfo: ICalculatedProfitBoostInfo;
  finalResult: Omit<IFinalCalculatedFields, 'finalStake'>;
}

export interface IBetCalculationResultsWithAllFields
  extends ICalculationResult,
    ICalculationInitialValues {}

export interface IBetslipSingleEvent {
  stake: string;
  isEachWay?: boolean;
  profitBoost?: number;
}

export type TBetslipCalculations = {
  general: IBetCalculationResultsWithAllFields | null;
  singleEventsCalculations: Record<
    string | number,
    IBetCalculationResultsWithAllFields
  >;
};

export interface IBetslipCalculationsSliceInitialData {
  calculations: TBetslipCalculations;
}

export const betslipCalculationsSliceInitialData: IBetslipCalculationsSliceInitialData =
  {
    calculations: {
      general: null,
      singleEventsCalculations: {}
    }
  };

export const betslipCalculationsSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: AnyAction
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case betslipCalculationsSliceActions.SET_CALCULATION_RESULTS: {
      if (action.payload.id === GENERAL_FIELD_KEY) {
        return {
          ...state,
          calculations: {
            ...state.calculations,
            general: action.payload.result
          }
        };
      }

      return {
        ...state,
        calculations: {
          ...state.calculations,
          singleEventsCalculations: {
            ...state.calculations.singleEventsCalculations,
            [action.payload.id]: action.payload.result
          }
        }
      };
    }

    case betslipCalculationsSliceActions.REMOVE_SINGLE_EVENT_CALCULATION: {
      const singleEventsCalculationsClone = structuredClone(
        state.calculations.singleEventsCalculations
      );

      delete singleEventsCalculationsClone[action.payload];

      return {
        ...state,
        calculations: {
          ...state.calculations,
          singleEventsCalculations: singleEventsCalculationsClone
        }
      };
    }

    case betslipCalculationsSliceActions.REMOVE_GENERAL_CALCULATION: {
      const calculationsClone = structuredClone(state.calculations);

      delete calculationsClone[GENERAL_FIELD_KEY];

      return {
        ...state,
        calculations: calculationsClone
      };
    }
  }

  return null;
};
