/* eslint-disable */
export const enableBatching = (reducer: any /* reducer must be of type any for generic purpose */) => {
  return function batchingReducer(state: any /*  state must be of type any for generic purpose  */, action: any /*  action must be of type any for generic purpose  */) {
    switch (action.type) {
      case 'BATCH_ACTIONS':
        return action.actions.reduce(batchingReducer, state);
      default:
        return reducer(state, action);
    }
  };
};
/* eslint-enable */
