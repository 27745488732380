import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { deepMerge } from 'utils/collection-manipulation/deep-merge';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { InitialUserData, ProfileData } from 'interfaces/authentication';
import { ActionType } from 'interfaces/generic';
import { UserDataActionTypes } from 'store/action-types';

const initialUserData: InitialUserData = {
  isLoggedIn: false,
  user: {
    pending: !!JSON.parse(
      LocalStorage.getItem(storageKeyName('account', 'AUTH_DATA'))
    ),
    sport_bonus: undefined
  } as ProfileData,
  loginLimit: 0,
  bonuses: null,
  inboxMessages: [],
  unreadMessagesCount: 0,
  pendingSuperBets: null,
  verificationModal: false,
  fastRegData: null,
  isWithdrawalAllowed: null,
  casinoBonusDetails: {} as InitialUserData['casinoBonusDetails'],
  showAutoidentVerificationModal: undefined,
  showCancelRegisterPopup: false,
  userOptIns: null,
  shouldGetUserOptIns: false,
  sendAsPromoCode: false,
  hasFromError: false,
  gpsTrackingParams: {},
  userDepositLimits: null,
  rememberMe: true,
  payments: null,
  clientInfo: null,
  sumSubModalOpen: false,
  paymentDepositPromo: null,
  withdrawableBalance: null,
  regIsFinished: false,
  displayCryptoInFiat: JSON.parse(
    LocalStorage.getItem(storageKeyName('account', 'DISPLAY_CRYPTO_IN_FIAT'))
  ),
  selectedCurrencyForDisplay: LocalStorage.getItem(
    storageKeyName('account', 'SELECTED_CURRENCY_FOR_DISPLAY')
  ),
  cashbackNotifications: [],
  personalisedDataLoaded: false
};

export const userData = (
  state = initialUserData,
  action: ActionType
): InitialUserData => {
  switch (action.type) {
    case UserDataActionTypes.SET_USER_DATA:
      return {
        ...state,
        user: action.payload
      };

    case UserDataActionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        user: deepMerge(state.user, action.payload, {
          shouldMergeInFirst: false,
          applyEmptyArraysAndObjects: false
        })
      };

    case UserDataActionTypes.REMOVE_USER_DATA:
      return {
        ...state,
        user: {} as ProfileData,
        loginLimit: 0,
        isLoggedIn: false,
        bonuses: null,
        unreadMessagesCount: 0
      };

    case UserDataActionTypes.SET_USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
        bonuses: null
      };

    case UserDataActionTypes.SET_USER_LOGIN_LIMIT:
      return {
        ...state,
        loginLimit: parseInt(action.payload) || 0
      };

    case UserDataActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        inboxMessages: state.inboxMessages?.filter(
          (message: { id: number }) => message.id !== action.payload
        )
      };

    case UserDataActionTypes.SET_INBOX_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: state.unreadMessagesCount + action.payload
      };

    case UserDataActionTypes.SET_PENDING_SUPER_BETS:
      return {
        ...state,
        pendingSuperBets: {
          ...state.pendingSuperBets,
          ...action.payload
        }
      };

    case UserDataActionTypes.REMOVE_PENDING_SUPER_BET: {
      const superBetsCopy = { ...state.pendingSuperBets };
      delete superBetsCopy[action.payload];

      return {
        ...state,
        pendingSuperBets: { ...superBetsCopy }
      };
    }

    case UserDataActionTypes.SET_USER_BONUSES:
      return {
        ...state,
        bonuses: {
          ...state.bonuses,
          ...action.payload
        }
      };
    case UserDataActionTypes.SET_VERIFICATION_MODAL:
      return {
        ...state,
        verificationModal: action.payload
      };
    case UserDataActionTypes.SET_FAST_REG_DATA:
      return {
        ...state,
        fastRegData: action.payload
      };
    case UserDataActionTypes.SET_WITHDRAWAL_CAPABILITY:
      return {
        ...state,
        isWithdrawalAllowed: action.payload
      };
    case UserDataActionTypes.SET_CASINO_BONUS_DETAILS:
      return {
        ...state,
        casinoBonusDetails: action.payload
      };
    case UserDataActionTypes.SET_SHOW_AUTOIDENT_VERIFICATION_MODAL:
      return {
        ...state,
        showAutoidentVerificationModal: action.payload
      };
    case UserDataActionTypes.SET_SHOW_CANCEL_REGISTER_POPUP:
      return {
        ...state,
        showCancelRegisterPopup: action.payload
      };
    case UserDataActionTypes.SET_USER_OPT_INS:
      return {
        ...state,
        userOptIns: action.payload
      };
    case UserDataActionTypes.GPS_TRACKING:
      return {
        ...state,
        gpsTrackingParams: action.payload
      };
    case UserDataActionTypes.UPDATE_GPS_TRACKING:
      return {
        ...state,
        gpsTrackingParams: deepMerge(state.gpsTrackingParams, action.payload, {
          shouldMergeInFirst: false,
          applyEmptyArraysAndObjects: false
        })
      };
    case UserDataActionTypes.RESET_GPS_TRACKING:
      return {
        ...state,
        gpsTrackingParams: {}
      };
    case UserDataActionTypes.SHOULD_GET_USER_OPTINS:
      return {
        ...state,
        shouldGetUserOptIns: action.payload
      };
    case UserDataActionTypes.SEND_AS_PROMO_CODE:
      return {
        ...state,
        sendAsPromoCode: action.payload
      };
    case UserDataActionTypes.HAS_FORM_ERROR:
      return {
        ...state,
        hasFromError: action.payload
      };
    case UserDataActionTypes.USER_DEPOSIT_LIMITS:
      return {
        ...state,
        userDepositLimits: action.payload
      };
    case UserDataActionTypes.SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.payload
      };
    case UserDataActionTypes.SET_PAYMENTS:
      return {
        ...state,
        payments: action.payload
      };
    case UserDataActionTypes.SET_CLIENT_INFO:
      return {
        ...state,
        clientInfo: action.payload
      };
    case UserDataActionTypes.STAKE_BALANCE:
      return {
        ...state,
        stakeBalance: action.payload
      };
    case UserDataActionTypes.SUM_SUB_MODAL_OPEN:
      return {
        ...state,
        sumSubModalOpen: action.payload
      };
    case UserDataActionTypes.SET_PAYMENT_DEPOSIT_PROMO:
      return {
        ...state,
        paymentDepositPromo: action.payload
      };
    case UserDataActionTypes.WITHDRAWABLE_BALANCE:
      return {
        ...state,
        withdrawableBalance: action.payload
      };
    case UserDataActionTypes.SET_REG_IS_FINISHED:
      return {
        ...state,
        regIsFinished: action.payload
      };

    case UserDataActionTypes.SET_DISPLAY_CRYPTO_IN_FIAT:
      return {
        ...state,
        displayCryptoInFiat: action.payload
      };

    case UserDataActionTypes.SET_SELECTED_CURRENCY_FOR_DISPLAY:
      return {
        ...state,
        selectedCurrencyForDisplay: action.payload
      };

    case UserDataActionTypes.RESET_SELECTED_CURRENCY_FOR_DISPLAY:
      return {
        ...state,
        selectedCurrencyForDisplay: null
      };

    case UserDataActionTypes.CASHBACK_NOTIFICATIONS:
      return {
        ...state,
        cashbackNotifications: action.payload
      };

    case UserDataActionTypes.SET_PERSONALISED_DATA_LOADED:
      return {
        ...state,
        personalisedDataLoaded: action.payload
      };

    default: {
      return state;
    }
  }
};
