import { Locale } from 'antd/lib/locale-provider';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { LOCALE_LANG_EXCEPTIONS } from 'utils/constants/languages/language-format';

export const configureDayjs = () => {
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(dayOfYear);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(localizedFormat);
};

export const LocaleCalendar: {
  datePickerLocale?: Locale;
} = {};

export const importDayJsLang = () => {
  const lang = document.documentElement.lang.slice(0, 2);
  import(`dayjs/locale/${lang}.js`).then(() => {
    dayjs.locale(lang);
  });

  const currentLanguage = window.currentLanguageObject?.language?.split('-');
  let formatedLang;

  if (currentLanguage) {
    formatedLang =
      LOCALE_LANG_EXCEPTIONS[currentLanguage[0]] ||
      `${currentLanguage[0]}_${currentLanguage[
        currentLanguage.length === 2 ? 1 : 0
      ].toUpperCase()}`;
  } else {
    formatedLang = 'en_US';
  }

  import(`antd/lib/locale/${formatedLang}.js`).then(datePickerLocale => {
    LocaleCalendar.datePickerLocale = datePickerLocale.default;
  });
};
