import { CSSProperties, FC, ReactElement } from 'react';
import cc from 'classcat';
import type { SkeletonElementProps } from '..';
import './index.less';

export interface SkeletonAvatarProps
  extends Omit<SkeletonElementProps, 'shape'> {
  shape?: 'circle' | 'square';
  active?: boolean;
  applyElementStyle?: boolean;
  wrapperStyle?: CSSProperties;
}

export const AVATAR_DEFAULT_SIZE = 32;
export const AVATAR_GLOBAL_SIZE = 40;

export const GET_AVATAR_SKELETON_PROPS = (
  avatar: SkeletonAvatarProps | boolean
): SkeletonAvatarProps => {
  return typeof avatar !== 'boolean'
    ? {
        shape: avatar?.shape || 'circle',
        size: avatar?.size || AVATAR_GLOBAL_SIZE,
        style: avatar?.style || {},
        className: avatar?.className || ''
      }
    : {
        shape: 'circle',
        size: AVATAR_GLOBAL_SIZE
      };
};

export const SkeletonAvatar: FC<SkeletonAvatarProps> = ({
  className,
  shape = 'circle',
  style,
  wrapperStyle,
  size = AVATAR_DEFAULT_SIZE,
  active,
  applyElementStyle = true
}): ReactElement => {
  return (
    <div
      className={cc([
        'customSkeleton',
        {
          'customSkeleton-element': applyElementStyle,
          'customSkeleton-active': active
        },
        className
      ])}
      style={wrapperStyle}
    >
      <span
        className={cc([`customSkeleton-avatar customSkeleton-avatar-${shape}`])}
        style={{
          ...(size ? { width: size, height: size, lineHeight: size } : {}),
          ...(style || {})
        }}
      />
    </div>
  );
};
