import { CSSProperties, ReactElement, ReactNode } from 'react';
import cc from 'classcat';
import { createArray } from 'utils/helpers/create-fake-array';
import {
  GET_AVATAR_SKELETON_PROPS,
  SkeletonAvatar,
  SkeletonAvatarProps
} from './Avatar';
import { SkeletonButton } from './Button';
import { SkeletonImage } from './Image';
import { SkeletonInput } from './Input';
import './index.less';

declare type widthUnit = number | string;

const PARAGRAPH_AVATAR_ROWS = 2;
const PARAGRAPH_DEFAULT_ROWS = 3;

export interface SkeletonElementProps {
  className?: string;
  style?: CSSProperties;
  size?: 'large' | 'small' | 'default' | number;
  shape?: 'circle' | 'square' | 'round';
  active?: boolean;
}

export interface SkeletonProps {
  active?: boolean;
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  avatar?: SkeletonAvatarProps | boolean;
  title?: SkeletonTitleProps | boolean;
  paragraph?: SkeletonParagraphProps | boolean;
  round?: boolean;
}

interface SkeletonTitleProps {
  className?: string;
  style?: CSSProperties;
  width?: number | string;
}

interface SkeletonParagraphProps {
  className?: string;
  style?: CSSProperties;
  width?: widthUnit | Array<widthUnit>;
  rows?: number;
}

export const Skeleton = ({
  active,
  className,
  style,
  avatar,
  paragraph,
  round,
  title
}: SkeletonProps): ReactElement => {
  const GET_TITLE_SKELETON_PROPS =
    typeof title !== 'boolean'
      ? {
          style: { width: title?.width, ...title?.style },
          className: title?.className || ''
        }
      : {};

  const PARAGRAPH_ROWS = avatar
    ? PARAGRAPH_AVATAR_ROWS
    : PARAGRAPH_DEFAULT_ROWS;

  const HAS_PHARAGRAPH =
    typeof paragraph === 'boolean' || !paragraph
      ? PARAGRAPH_ROWS
      : paragraph?.rows ?? PARAGRAPH_ROWS;

  return (
    <div
      className={cc([
        'customSkeleton',
        {
          customSkeleton__withAvatar: avatar,
          'customSkeleton-active': active,
          'customSkeleton-round': round
        },
        className
      ])}
      style={style}
    >
      {avatar && (
        <div className="customSkeleton__header">
          <SkeletonAvatar {...GET_AVATAR_SKELETON_PROPS(avatar)} />
        </div>
      )}

      <div className="customSkeleton-content">
        <h3
          className={cc([
            `customSkeleton-title ${GET_TITLE_SKELETON_PROPS.className}`,
            { 'customSkeleton-title-avatar': avatar }
          ])}
          style={GET_TITLE_SKELETON_PROPS.style}
        />

        {paragraph !== false && (
          <ul
            className="customSkeleton-paragraph"
            style={{
              ...(typeof paragraph !== 'boolean' && paragraph
                ? paragraph.style
                : {})
            }}
          >
            {createArray(HAS_PHARAGRAPH).map(key => (
              <li key={key}></li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

/**
 * Skeleton namespace components
 */
Skeleton.Image = SkeletonImage;
Skeleton.Input = SkeletonInput;
Skeleton.Avatar = SkeletonAvatar;
Skeleton.Button = SkeletonButton;
