export const timeoutDuration = {
  DURATION_70: 70,
  DURATION_100: 100,
  DURATION_200: 200,
  DURATION_300: 300,
  DURATION_500: 500,
  DURATION_700: 700,
  DURATION_800: 800,
  DURATION_1000: 1000,
  DURATION_2000: 2000,
  DURATION_2500: 2500,
  DURATION_30000: 30000
};

export const SECONDS_PER_HOUR = 3600;
export const SECONDS_PER_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;
export const HOUR_PER_DAY = 24;

export const SECONDS_IN_DAY = 86400;

export const COMPARATIVE_NUMBER_IN_HOURS_10 = 10;
export const COMPARATIVE_NUMBER_IN_HOURS_12 = 12;
export const COMPARATIVE_NUMBER_IN_HOURS_24 = 24;
export const COMPARATIVE_NUMBER_IN_HOURS_59 = 59;
export const ERROR_CODE_TIMEOUT_1800 = 1800;
export const DAYS_TO_SUBTRACT = 6;

export const TimesToAdd = {
  AFTER_1_DAYS: 1,
  AFTER_2_DAYS: 2,
  AFTER_3_DAYS: 3,
  AFTER_30_DAYS: 30,
  AFTER_10_DAYS: 10,
  BEFORE_1_DAY: -1,
  BEFORE_2_DAYS: -2,
  BEFORE_1_MONTH: -1
};

export const durationHours = {
  THREE_HOURS: 3,
  SIX_HOURS: 6,
  TWELVE_HOURS: 12,
  TOMORROW: 24
};

export const setTimeOutGreatestValue = 2147483647;
