import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import {
  Competition,
  IRacingEvent,
  RacingGame,
  RacingRegion,
  StreamInfoType
} from 'interfaces/sportsbook-data-levels';
import { Market } from './sportsbook-data-levels';

const RACING_RESULTS_NOT_RUNNER_CUSTOM_NUMBER = 10001;
const RACING_RESULTS_NOT_FINISHED_CUSTOM_NUMBER = 99;

export enum ERacingSportSideBarTabs {
  All = 'all',
  HORSE_RACING = '1',
  GREYHOUNDS_RACING = '2'
}

export type TManualTerm = {
  End: string;
  IsMinCountNotMet: boolean;
  ManualEachWayK: number;
  ManualEachWayPlace: number;
  MinCount: number;
  PartnerId: number;
  Start: string;
};

export type TRacingMarket = {
  [key: string]: Pick<Market, 'extra_info'>;
};

export type TDateAndSportFilterTab = {
  key: string;
  title: string;
  timeFilter: string | null;
};

export type TCompetitionDataItemType = {
  id: number;
  alias: string;
  sportAlias: string;
  sportId: string;
  name: string;
  order: number;
  competition: {
    [key: string]: {
      order: number;
      id: number;
      name: string;
      bog: boolean;
      game: {
        [key: string]: {
          id: number;
          start_ts: number;
          team1_name: string;
          market: TRacingMarket;
        };
      };
    };
  };
};

export type TSportGameCompetitionCheck = {
  string: {
    id: number;
    competition?: {
      string: {
        id: number;
        game?: { string: { start_ts: number } };
      };
    };
  };
};

export enum EForecastTricastMarkets {
  FORECAST = 'forecast',
  TRICAST = 'tricast'
}

export enum EForcastTricastOddTypes {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  ANY = 'any'
}

export enum ERacingTabsTimeFilters {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  DAY_AFTER_TOMORROW = 'day-after-tomorrow',
  TWO_DAYS_AFTER_TOMORROW = 'two-days-after-tomorrow'
}

export enum ENextOff {
  NEXT_OFF = 'next-off'
}

export type TDayFilter =
  | {
      '@gt': number;
      '@lt': number;
    }
  | {};

export type TOddItemsTypes = {
  oddItems: { item: EForcastTricastOddTypes; title: string }[];
  changeSelectedEvents: Function;
  eventId: number;
  selectedEvents: {
    first?: number;
    second?: number;
    third?: number;
    any: Array<number>;
  };
};

export interface IHorseJson {
  distanceText?: string | null;
  title?: string;
  prizes?: {
    amount: number;
    position: number;
  }[];
  going?: string;
  name: string;
  selections: {
    lastRunDays: number;
    jockeyInfo?: {
      id?: number;
      name?: string;
      coloursFilename?: string;
      coloursDescription?: string;
      allowancePounds?: number;
    };
    trainerInfo?: {
      id?: number;
      name?: string;
    };
    age?: number;
    weightPounds?: number;
    weightStones?: number;
    runnerNum?: number;
    drawn?: number;
    raceHistoryStat?: { type?: string }[];
    formFigures?: string;

    /*this keys exist but not used*/
    bred?: string;
    colour?: string;
    dam?: string;
    damSire?: string;
    id?: number;
    name?: string;
    startingPrice?: number;
    runnerId?: string | number;
    weightUnits?: string | null;
    silkFile?: string | number | null;
  }[];

  /*this keys exist but not used*/
  id?: number;
  country?: string;
  venue?: string;
  resultTimestamp?: string;
  courseType?: string;
  date?: string;
  distance?: string;
  categoryCode?: string;
  eachWayPlaces?: number;
  handicap?: string;
  lastUpdated?: number;
  num?: number;
  numOfRunners?: number;
  resulted?: boolean;
  current?: boolean;
  status?: string;
  surfaceType?: string;
  time?: string;
  BackgroundUrl?: string;
}

export type TEventsData = { marketName: string; events: IRacingEvent[] };

export type TWinViewTypes = {
  eventsData: TEventsData[];
  gameData: RacingGame;
  addToBetSlip: (eventId: string | number | undefined, sp?: boolean) => void;
};

export type TRacingItemContextType = {
  region: RacingRegion | null;
  currentCompetition: Competition | null;
  pushUrl: (key: string, value: any) => void;
  loading: boolean;
  srcEnvVariable: string;
  isFutureRacing?: boolean;
  isSpecials?: boolean;
  loadMore: () => void;
  gamesForShow: RacingGame[];
  isMoreDataAvailable: boolean;
};

export type TSingleGameViewProps = {
  gameData: RacingGame;
  eventsData: TEventsData[];
  currentGameTime: string;
  streamInfo: StreamInfoType;
  showHideStream: () => void;
  showStream: boolean;
  singleGameActiveMarket: string;
  selectSingleGameActiveMarket: Dispatch<SetStateAction<string | undefined>>;
  singleGameMarketTabs: {
    key: string;
    title: string;
  }[];
  addToBetSlip: (eventId?: string | number, sp?: boolean) => void;
  sportId: string | number;
  isLoaded: boolean;
};

export type TForeCastTypes = {
  eventsData: TEventsData[];
  singleGameActiveMarket: string;
  currentGameTime: string;
  currentCompetitionName?: string;
};

export enum ERacingPageTypes {
  UPCOMING = 'upcoming',
  RESULTS = 'results'
}

export enum ERacingResultsTimeFilterTabs {
  TODAY = 'today',
  YESTERDAY = 'yesterday'
}

export type TResultsGameData = {
  CompetitionId: number;
  CompetitionName: string;
  CompetitionOrder: number;
  Id: number;
  RegionAlias: string;
  RegionId: number;
  RegionName: string;
  RegionOrder: number;
  SportAlias: string;
  SportId: number;
  SportName: string;
  StartTime: number;
};
export type TRacingResultsGameListSwarmData = {
  result: number;
  result_text: string | null;
  details: TResultsGameData[];
};

export type TRacingResultsGame = { id: number; start_ts: number };
export type TRacingResultsCompetition = {
  id: number;
  order: number;
  game: Record<number, TRacingResultsGame>;
  name: string;
};

export type TRacingResultsRegion = {
  id: number;
  order: number;
  competition: Record<number, TRacingResultsCompetition>;
  name: string;
  alias: string;
  sportId: string;
  sportAlias: string;
};

export type TResultsSingleGameEventData = {
  Id: number;
  Name: string;
  Order: number;
  Outcome: number;
  Position: number;
  SPPrice: number | null;
};

export type TResultsSingleGameData = {
  MarketExtraInfo: string;
  MarketId: number;
  MarketName: string;
  MarketTypeId: number;
  MatchId: number;
  Selections: TResultsSingleGameEventData[];
  StartTime: number;
};

export type TResultsSingleGameSwarmData = {
  result: number;
  result_text: string | null;
  details: TResultsSingleGameData;
};

export type TDividend = { betType: number; dividend: string };

export type TResultsSingleGameMarketExtraInfo = {
  Created: string;
  Dividents: Array<{
    BetType: number;
    Divident: number;
    Horses: Array<{ ExtraId: string; Name: string }>;
  }>;
  EachWayK: number;
  EachWayPlace: number;
  EarlyPrices: boolean;
  IsPartnerTermsEnabled: null | boolean;
  ManualTerms: null | string | boolean;
};

export type TRacingResultsSingleGameEvent = {
  id: number;
  name: string;
  order: number;
  position: number;
  outcome: number;
  price: number | null;
  type: string;
  original_order: number;
  jockeyInfo?: {
    coloursFilename: string;
    name: string;
    allowancePounds: number;
  };
  lastRunDays?: number;
  horse?: boolean;
  raceHistoryStat?: Array<{
    type: string;
  }>;
  runnerNum?: number;
  formFigures?: string;
  trainerInfo?: {
    name: string;
  };
  drawn?: number;
};

export type TRacingResultsSingleGameMarket = {
  id: number;
  name: string;
  type_id: number;
  extra_info: TResultsSingleGameMarketExtraInfo;
  event: Record<string, TRacingResultsSingleGameEvent>;
  type: string;
};

export type TRacingResultsSingleGameGame = {
  id: number;
  start_ts: number;
  market: { [key: number]: TRacingResultsSingleGameMarket };
  distanceText?: string;
  title?: string;
  prizes?: {
    amount: number;
    position: number;
  };
  single_title?: string;
  single_name?: string;
  winnerMarketId: number;
};

export type TRacingResultsItemContextType = {
  region: TRacingResultsRegion | null;
  currentCompetition: TRacingResultsCompetition | null;
  pushUrl: (key: string, value: string | number) => void;
  loadMore: () => void;
  gamesForShow: TRacingResultsSingleGameGame[];
  isMoreDataAvailable: boolean;
  srcEnvVariable: string;
  goBack: (e?: SyntheticEvent) => void;
  currentCompetitionOrderedGameIds: string;
  gamesCount: number;
  setGamesCount: Dispatch<SetStateAction<number>>;
};

export enum ERacingResultPositionTypes {
  NOT_FINISHED = RACING_RESULTS_NOT_FINISHED_CUSTOM_NUMBER,
  NON_RUNNER = RACING_RESULTS_NOT_RUNNER_CUSTOM_NUMBER
}

export type TResultsSingleGameViewProps = {
  gameData: TRacingResultsSingleGameGame;
  events: TRacingResultsSingleGameEvent[];
  currentGameTime: string;
  betInformation: TDividend[];
  showSkeleton: boolean;
};

export type TResultsCompetitionProps = {
  competition: {
    order: number;
    id: number;
    name: string;
    game?: {
      [key: string]: {
        id: number;
        start_ts: number;
      };
    };
  };
};

export enum ESetUpcomingDataReducerTypes {
  ADD_ALL = 'addAll',
  REMOVE_ALL = 'removeAll',
  UPDATE_ALL = 'updateAll',
  UPDATE_SINGLE_GAME = 'updateSingleGame'
}
export enum EGameJsonReducerTypes {
  ADD = 'add',
  REMOVE = 'remove',
  REMOVE_ALL = 'removeAll'
}
export enum EAddRemoveAllReducerTypes {
  ADD = 'add',
  REMOVE_ALL = 'removeAll'
}

export type TRacingPageTab = {
  label: string;
  alias: string;
  sport: number;
  icon: string;
};
