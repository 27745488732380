export const HIERARCHY_ARRAY = [
  'sport',
  'region',
  'competition',
  'game',
  'market',
  'event'
];

export const NOT_REAL_SPORTS = [
  'topLeague',
  'topMatches',
  'boostedOdds',
  'todayEvents',
  'multiples',
  'outright',
  'AllESports',
  'all',
  'favourites',
  'team',
  'favorite',
  'myBets',
  'betBuilder',
  'coupon',
  'olympicCompetition'
];

// Categories that have sport after them in route e.g. boostedOdds/Soccer...
export const CATEGORIES_WITH_SPORT = [
  'topLeague',
  'boostedOdds',
  'outright',
  'todayEvents',
  'topMatches',
  'team',
  'multiples',
  'betBuilder',
  'coupon',
  'olympicCompetition'
];

export const SPORT_BAR_LIST = [
  'topLeague',
  'outright',
  'todayEvents',
  'boostedOdds',
  'multiples',
  'betBuilder'
];

export const ALL_SPORTS_TYPES = Object.values({
  0: 0, // E-Sport
  2: 2, // General
  5: 5 // UFC, MMA
});

export const SPORTS_TYPES = {
  RACING: 4,
  VIRTUAL_RACING: 1
};

export const INITIAL_MARKETS_LIST = [
  'todayEvents',
  'topLeagues',
  'topMatches',
  'betBuilder',
  'outright',
  'boostedOdds',
  'multiples'
];
