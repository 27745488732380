export const addRemoveFromArray = (
  type: 'add' | 'remove' | 'removeAll' | 'removeAllSameElements',
  idsArr: any[],
  element: any = 'absent'
): any[] => {
  let array = idsArr;

  if (type === 'add') {
    if (element !== 'absent') {
      array.push(element);
    }

    return array;
  } else if (type === 'remove') {
    const index = array.indexOf(element);

    if (index !== -1) {
      array.splice(index, 1);
    }
  } else if (type === 'removeAllSameElements') {
    const index = array.indexOf(element);

    if (index !== -1) {
      array.splice(index, 1);
      array = addRemoveFromArray('removeAllSameElements', array, element);
    }
  } else {
    array = [];
  }

  return array;
};
