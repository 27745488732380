export const FavoriteActionTypes = {
  CLEAR_FAVORITE: 'CLEAR_FAVORITE',
  UPDATE_FAV_DATA: 'UPDATE_FAV_DATA',
  TOGGLE_FAVORITE: 'TOGGLE_FAVORITE',
  SET_MARKETS_COUNTS: 'SET_MARKETS_COUNTS',
  CLEAR_FAVORITE_MATCHES: 'CLEAR_FAVORITE_MATCHES',
  SET_FAVORITE_COMPETITION: 'SET_FAVORITE_COMPETITION',
  SET_FAVORITE_SPORTS_DATA: 'SET_FAVORITE_SPORTS_DATA',
  SET_FAVORITE_IDS_LOADING: 'SET_FAVORITE_IDS_LOADING',
  SET_FAVORITE_DATA_LOADING: 'SET_FAVORITE_DATA_LOADING',
  SET_COMPETITIONS_FAV_DATA: 'SET_COMPETITIONS_FAV_DATA',
  SET_CASINO_FAV_DATA: 'SET_CASINO_FAV_DATA',
  SET_PROVIDERS_FAV_DATA: 'SET_PROVIDERS_FAV_DATA',
  SET_MARKETS_FAV_DATA: 'SET_MARKETS_FAV_DATA',
  SET_FAVORITES_MARKETS_COUNT: 'SET_FAVORITES_MARKETS_COUNT',
  SET_PERSONALIZATION_DATA: 'SET_PERSONALIZATION_DATA',
  SET_PERSONALIZED_GAME_RESOURCE_ID: 'SET_PERSONALIZED_GAME_RESOURCE_ID',
  SET_PERSONALIZED_PROVIDER_RESOURCE_ID:
    'SET_PERSONALIZED_PROVIDER_RESOURCE_ID',
  SET_PERSONALIZED_COMPETITION_RESOURCE_ID:
    'SET_PERSONALIZED_COMPETITION_RESOURCE_ID',
  SET_PERSONALIZED_MARKET_RESOURCE_ID: 'SET_PERSONALIZED_MARKET_RESOURCE_ID',
  SET_PERSONALIZED_SPORT_GAMES_RESOURCE_ID:
    'SET_PERSONALIZED_SPORT_GAMES_RESOURCE_ID'
};
