import { message } from 'antd';
import { isMobile } from 'utils/is-mobile';

type Context = HTMLElement | undefined | null;
type Content = string | undefined | null;

let previousContext: Context = null;
let previousContent: Content = null;

export const configureMessage = (
  context: Context = null,
  duration = 2,
  content: Content = ''
): void => {
  if (
    !content?.length ||
    context !== previousContext ||
    content !== previousContent
  ) {
    message.destroy();
    previousContext = context;
    previousContent = content;
  } else {
    return;
  }

  if (!isMobile() && context) {
    message.config({
      duration: duration,
      getContainer: () => context,
      prefixCls: 'custom-container v3-message-desktop v3-message'
    });
  } else {
    message.config({
      duration: duration,
      getContainer: () => document.body,
      prefixCls: isMobile() ? 'v3-message' : 'v3-message-desktop v3-message'
    });
  }
};
