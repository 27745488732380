import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import {
  BOTTOM_NAVIGATION_ELEMENT_DEFAULT_HEIGHT,
  QUICK_BET_ELEMENT_DEFAULT_HEIGHT
} from 'utils/constants/app/BottomNavigationDefaultData';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { BETSLIP_AREA_ID } from 'newelements/_Betslip/utils/constants/shared';

export type THolder = {
  offsetTop: number;
  clientHeight: number;
};

export const generateAnimation = (
  holder: THolder,
  isAbsolute?: boolean,
  isQuickBetEnabled?: boolean
): string => {
  const betslipElem = document.getElementById(BETSLIP_AREA_ID);
  const bottomNav = document.getElementById('bottom-navigation');

  let betslipHeight = 0;

  if (SpringConfigs.BOTTOM_NAVIGATION) {
    betslipHeight +=
      bottomNav?.clientHeight || BOTTOM_NAVIGATION_ELEMENT_DEFAULT_HEIGHT;
  }

  if (isQuickBetEnabled) {
    betslipHeight +=
      betslipElem?.clientHeight || QUICK_BET_ELEMENT_DEFAULT_HEIGHT;
  }

  const minHeight = 88;

  const normTo = holder.offsetTop;
  const minFrom = holder.clientHeight + normTo;

  const minTo = betslipHeight;
  const normFrom = window.scrollY - (minHeight + minTo);

  const vpMinimizing = `{
    from { bottom: calc(${isAbsolute ? '50%' : '100%'} - ${minFrom}px); }
    to   { bottom: ${minTo}px; }
  }`;

  const vpNormalizing = `{
    from { top: calc(${isAbsolute ? '50%' : '100%'} + ${normFrom}px); }
    to   { top: 0; }
  }`;

  return `
    @keyframes vp-minimizing ${vpMinimizing}
    @keyframes vp-normalizing ${vpNormalizing}
    @-webkit-keyframes vp-minimizing ${vpMinimizing}
    @-webkit-keyframes vp-normalizing ${vpNormalizing}
  `;
};

export const IS_IOS_DEVICE = /(iPhone|iPad)/.test(navigator.userAgent);

export const saveGameUrlOnLocalStorage = (): void => {
  LocalStorage.setItem(
    storageKeyName('sportsbook', 'GAME_SINGLE_VIEW_URL'),
    window.getPathname()
  );
};
