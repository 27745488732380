const setItem = (name: string, data: string): boolean => {
  if (window.sessionStorageAvailable) {
    window.sessionStorage.setItem(name, data);

    return true;
  } else {
    return false;
  }
};

const getItem = (name: string): string => {
  return window.sessionStorageAvailable
    ? window.sessionStorage.getItem(name) || 'null'
    : 'null';
};

const removeItem = (name: string): boolean => {
  if (window.sessionStorageAvailable) {
    window.sessionStorage.removeItem(name);

    return true;
  } else {
    return false;
  }
};

export default {
  setItem,
  getItem,
  removeItem
};
