import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';

export const setSelectedStake = (payload: string): ActionType => {
  return {
    type: BetslipActionTypes.SET_SELECTED_STAKE,
    payload
  };
};

export const setRSPredefinedStakes = (payload: object): ActionType => {
  return {
    type: BetslipActionTypes.SET_RS_PREDEFINED_STAKES,
    payload
  };
};

export const setTotalBetStake = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_TOTAL_BET_STAKE,
    payload
  };
};

export const setMaxStakeLoading = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_MAX_STAKE_LOADING,
    payload
  };
};

export const setCurrentStake = (payload: string | number): ActionType => {
  return {
    type: BetslipActionTypes.SET_CURRENT_STAKE,
    payload
  };
};

export const setTotalStake = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.SET_TOTAL_STAKE,
    payload
  };
};

export const setStakes = (payload: Object): ActionType => {
  return {
    type: BetslipActionTypes.SET_STAKES,
    payload
  };
};
