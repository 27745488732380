import { BET_TYPES_CONF } from 'utils/constants/betslip/bet-types';
import { BetSlipEventObj, BetTicketMin, UkBets } from 'interfaces/bet-data';
import { BetslipEvents } from 'interfaces/betslip';
import { ActionType } from 'interfaces/generic';
import { betslipBetsSliceActions } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

// @Todo need to create action to reset EachWay field for all bets

export interface IBetslipBetsSliceInitialData {
  allLsBets: Array<BetSlipEventObj>;
  // @Todo create type
  subscriptions: Array<{
    gameId: number;
    eventId: number;
    baseRid?: string;
  }>;
  bettedTrigger?: boolean;
  ticketsChanged: boolean;
  isBetInProgress: boolean;
  betTypesConf: Array<UkBets>;
  selectedBetType: string | null;
  subscribedEvents: BetslipEvents;
  conflictingGameIds: Set<number>;
  updatedBetTickets: Array<BetTicketMin>;
}

export const betslipBetsSliceInitialData: IBetslipBetsSliceInitialData = {
  allLsBets: [],
  subscriptions: [],
  // systgemCombi
  bettedTrigger: false,
  subscribedEvents: {},
  ticketsChanged: false,
  selectedBetType: null,
  isBetInProgress: false,
  betTypesConf: BET_TYPES_CONF,
  conflictingGameIds: new Set(),
  updatedBetTickets: [] as Array<BetTicketMin>
};

export const betslipBetsSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: ActionType
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case betslipBetsSliceActions.UPDATE_EVENT_AND_GAME_IDS:
      return {
        ...state,
        subscriptions: action.payload
      };

    case betslipBetsSliceActions.BETTED_TRIGGER:
      return {
        ...state,
        bettedTrigger: !state.bettedTrigger
      };

    case betslipBetsSliceActions.UPDATE_BET_TICKETS:
      return {
        ...state,
        updatedBetTickets: action.payload
      };

    case betslipBetsSliceActions.SET_ALL_LS_BETS:
      return {
        ...state,
        allLsBets: action.payload
      };

    case betslipBetsSliceActions.SET_IS_BET_IN_PROGRESS:
      return {
        ...state,
        isBetInProgress: action.payload
      };

    case betslipBetsSliceActions.SET_SELECTED_BET_TYPE:
      return {
        ...state,
        selectedBetType: action.payload
      };

    case betslipBetsSliceActions.UPDATE_BETSLIP_UK_TYPE:
      return {
        ...state,
        betTypesConf: action.payload
      };

    case betslipBetsSliceActions.SET_CONFLICTING_GAME_IDS:
      return {
        ...state,
        conflictingGameIds: action.payload
      };

    case betslipBetsSliceActions.TOGGLE_TICKETS_CHANGED:
      return {
        ...state,
        ticketsChanged: !state.ticketsChanged
      };

    case betslipBetsSliceActions.SET_SUBSCRIBED_EVENTS:
      return {
        ...state,
        subscribedEvents: action.payload
      };

    case betslipBetsSliceActions.REMOVE_SUBSCRIBED_EVENT: {
      const stateClone = structuredClone(state.subscribedEvents);
      delete stateClone[action.payload];

      return {
        ...state,
        subscribedEvents: stateClone
      };
    }

    case betslipBetsSliceActions.MERGE_SUBSCRIBED_EVENTS:
      return {
        ...state,
        subscribedEvents: {
          ...state.subscribedEvents,
          ...action.payload
        }
      };

    case betslipBetsSliceActions.TOGGLE_SINGLE_EACH_WAY:
      return {
        ...state,
        allLsBets: state.allLsBets.map(bet =>
          bet.eventId === action.payload
            ? {
                ...bet,
                each_way: !bet.each_way
              }
            : bet
        )
      };

    case betslipBetsSliceActions.RESET_ALL_EACH_WAY:
      return {
        ...state,
        allLsBets: state.allLsBets.map(bet => ({
          ...bet,
          ...(bet.each_way ? { each_way: false } : {})
        }))
      };
  }

  return null;
};
