import { ActionType } from 'interfaces/generic';
import { MetaDataActionTypes } from 'store/action-types/meta-data';

export type MetaData = {
  sport?: {
    name: string;
  };
  competition?: {
    name: string;
  };
  game?: {
    team1_name: string;
    team2_name: string;
    start_ts: number;
  };
};

export const setSportMetaData = (data?: { name: string }): ActionType => {
  return {
    type: MetaDataActionTypes.SET_SPORT_DATA,
    payload: data
  };
};

export const setCompetitionMetaData = (data?: { name: string }): ActionType => {
  return {
    type: MetaDataActionTypes.SET_COMPETITION_DATA,
    payload: data
  };
};

export const setGameMetaData = (data?: {
  team1_name: string;
  team2_name: string;
  start_ts: number;
}): ActionType => {
  return {
    type: MetaDataActionTypes.SET_GAME_DATA,
    payload: data
  };
};

export const setAllMetaData = (data?: MetaData): ActionType => {
  return {
    type: MetaDataActionTypes.SET_ALL_DATA,
    payload: data
  };
};
