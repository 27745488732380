import { HIERARCHY_ARRAY } from 'utils/constants/sportsbook/sportsbook-data-hierarchy';
import { pluralLevel } from 'utils/string-manipulation/plural';
import {
  LevelsNormalizedData,
  TLevelsNormalizedDataWithObjects
} from 'interfaces/sportsbook-data-levels';

type TSerializeSwarmData = {
  (data: any): LevelsNormalizedData;
  (data: any, returnAsObject: boolean): TLevelsNormalizedDataWithObjects;
};

type TDataKeyType =
  | keyof LevelsNormalizedData
  | keyof TLevelsNormalizedDataWithObjects;

export const serializeSwarmData: TSerializeSwarmData = (
  data: any,
  returnAsObject = false
) => {
  const resultObject: LevelsNormalizedData | TLevelsNormalizedDataWithObjects =
    returnAsObject
      ? {
          sports: {},
          regions: {},
          competitions: {},
          games: {},
          markets: {},
          events: {}
        }
      : {
          sports: [],
          regions: [],
          competitions: [],
          games: [],
          markets: [],
          events: []
        };

  if (!data) {
    return resultObject;
  }

  const keys = Object.keys(data);

  if (keys.length === 0 && data.constructor === Object) {
    return data;
  }

  const currentLevel = keys[0];
  const currentLevelIndex = HIERARCHY_ARRAY.indexOf(currentLevel);
  const customHierarchyArray = HIERARCHY_ARRAY.slice(currentLevelIndex);

  const retrieveNextLevel = (obj: any, index: number): string | undefined => {
    const nextLevel = customHierarchyArray[index + 1];

    if (nextLevel === undefined) {
      return undefined;
    }

    if (Object.prototype.hasOwnProperty.call(obj, nextLevel)) {
      return nextLevel;
    } else {
      return retrieveNextLevel(obj, index + 1);
    }
  };

  const walk = (
    resultObj: LevelsNormalizedData | TLevelsNormalizedDataWithObjects,
    obj: any, // object must be of type any for generic purpose
    curLevel: string,
    index: number
  ) => {
    if (curLevel === '' || index === customHierarchyArray.length) {
      return;
    } else {
      const objCopy = JSON.parse(JSON.stringify(obj));
      Object.keys(objCopy[curLevel]).forEach(key => {
        const levelObj = objCopy[curLevel][key];
        const nextLevel = retrieveNextLevel(levelObj, index);
        const notLastLevel =
          index < customHierarchyArray.length - 1 &&
          nextLevel !== undefined &&
          Object.prototype.hasOwnProperty.call(levelObj, nextLevel);

        levelObj.levelName = obj[curLevel][key].levelName =
          levelObj.levelName || curLevel;
        levelObj.id = obj[curLevel][key].id = levelObj.id || +key;

        if (index > 0) {
          const parentIdName = `${obj.levelName}_id`;
          levelObj[parentIdName] = levelObj[parentIdName] || obj.id;
        }

        if (notLastLevel) {
          levelObj[`${nextLevel}Ids`] = Object.keys(levelObj[nextLevel]).map(
            id => +id
          );
          delete levelObj[nextLevel];
        }

        if (returnAsObject) {
          resultObj[pluralLevel(curLevel) as TDataKeyType][levelObj.id] =
            levelObj;
        } else {
          (resultObj[pluralLevel(curLevel) as TDataKeyType] as any[]).push(
            levelObj
          );
        }

        walk(
          resultObj,
          obj[curLevel][key],
          notLastLevel ? nextLevel : '',
          index + 1
        );
      });
    }
  };

  walk(resultObject, data, currentLevel, 0);

  return resultObject;
};
