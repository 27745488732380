import { BetTicket } from 'interfaces/bet-data';
import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

export interface IBetslipOpenBetsSliceInitialData {
  openBetsData: BetTicket[];
  openBetsCount: number | null;
  openBetsLoading: null | boolean;
  updateOpenBetsTrigger?: boolean;
  openBetsCountAfterCashout: null | number;
}

export const betslipOpenBetsSliceInitialData: IBetslipOpenBetsSliceInitialData =
  {
    openBetsData: [],
    openBetsCount: null,
    openBetsLoading: null,
    updateOpenBetsTrigger: false,
    openBetsCountAfterCashout: null
  };

export const betslipOpenBetsSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: ActionType
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.UPDATE_OPEN_BETS:
      return {
        ...state,
        updateOpenBetsTrigger: !state.updateOpenBetsTrigger
      };

    case BetslipActionTypes.SET_OPEN_BETS_COUNT:
      return {
        ...state,
        openBetsCount: action.payload
      };

    case BetslipActionTypes.SET_OPEN_BETS_COUNT_AFTER_CASHOUT:
      return {
        ...state,
        openBetsCountAfterCashout: action.payload
      };

    case BetslipActionTypes.SET_OPEN_BETS_DATA:
      return {
        ...state,
        openBetsData: action.payload
      };

    case BetslipActionTypes.SET_OPEN_BETS_LOADING:
      return {
        ...state,
        openBetsLoading: action.payload
      };
  }

  return null;
};
