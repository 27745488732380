import { bankersRounding } from 'utils/betslip/bankersRounding';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { subtract } from 'utils/generic/calculator';
import {
  ETaxAmountRangesType,
  ETaxTypes,
  TTaxesState
} from 'interfaces/betslip/taxes';
import { calPossibleWinByBetType } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/utils/calcPossibleWinByBetType';
import type { TCalculationParams } from 'newelements/_Betslip/utils/calculator/index';
import {
  calcPercentOf,
  createPercentCalculator
} from 'newelements/_Betslip/utils/calculator/shared';
import Store from 'store';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';
import {
  IBasicTaxesCalculationResult,
  IBasicTaxInfo,
  IFinalCalculatedFields,
  IRangeTaxesCalculationResult,
  IRangeTaxInfo
} from 'store/reducers/betslip/calculations';

export type TCalculateTaxes = (
  params: TCalculationParams
) => IBasicTaxesCalculationResult;

export const calculateBasicTaxes: TCalculateTaxes = ({
  odd,
  stake,
  sportIds,
  eventId,
  isEachWay
}) => {
  const betslipStore: IBetslipRootStateInitialData = Store.getState().betSlip;
  const taxes: TTaxesState = betslipStore.taxes;

  const possibleWin = bankersRounding(
    calPossibleWinByBetType(stake, odd, isEachWay, eventId)
  );

  const possibleWinWithoutStake = bankersRounding(subtract(possibleWin, stake));
  const calcTempData: Omit<IFinalCalculatedFields, 'finalStake'> = {
    stake,
    possibleWin,
    possibleWinWithoutStake,
    possibleWinWithoutRangeTax: possibleWin,
    possibleWinWithoutStakeAndWithoutRangeTax: possibleWinWithoutStake
  };

  const isFreeBetEnabled =
    betslipStore.freeBets.selectedFreeBetEventId === eventId;

  const partnerConfig = Store.getState().socket.partnerConfigs;

  const basicTaxesCalculationResult: IBasicTaxInfo = { isTaxable: true };

  const taxableSportIds = taxes.basic?.taxableSportIds || [];

  if (SpringConfigs.CALCULATE_BETS_WITHOUT_TAXES && taxableSportIds.length) {
    basicTaxesCalculationResult.isTaxable = sportIds.every(sportId =>
      taxableSportIds.some(taxableSportId => sportId !== taxableSportId)
    );
  }

  // @Todo maybe need to wrap roundAmount function here to

  // @Todo need to understand we can detect here witch sport has tax
  if (basicTaxesCalculationResult.isTaxable && taxes.basic?.percent) {
    const calculateBasicTaxFromAmount = createPercentCalculator(
      taxes.basic.percent
    );

    // basic tax logic (from partner configs tax_type and tax_percent)
    switch (taxes.basic.type) {
      case ETaxTypes.STAKE_TAX:
        if (!isFreeBetEnabled || partnerConfig?.is_bonus_bet_taxed) {
          basicTaxesCalculationResult.basicTaxedStakeTaxAmount =
            bankersRounding(calculateBasicTaxFromAmount(stake));

          basicTaxesCalculationResult.basicTaxedStake = bankersRounding(
            subtract(
              stake,
              basicTaxesCalculationResult.basicTaxedStakeTaxAmount
            )
          );

          calcTempData.stake = basicTaxesCalculationResult.basicTaxedStake;
          calcTempData.possibleWin = bankersRounding(
            calPossibleWinByBetType(
              +calcTempData.stake,
              odd,
              isEachWay,
              eventId
            )
          );
          calcTempData.possibleWinWithoutStake = bankersRounding(
            subtract(
              calcTempData.possibleWin,
              basicTaxesCalculationResult.basicTaxedStakeTaxAmount
            )
          );
        }

        break;
      case ETaxTypes.POSSIBLE_WIN_TAX:
        basicTaxesCalculationResult.basicTaxedPossibleWinTaxAmount =
          bankersRounding(
            calculateBasicTaxFromAmount(calcTempData.possibleWin)
          );
        basicTaxesCalculationResult.basicTaxedPossibleWin = bankersRounding(
          subtract(
            calcTempData.possibleWin,
            basicTaxesCalculationResult.basicTaxedPossibleWinTaxAmount
          )
        );

        calcTempData.possibleWin =
          basicTaxesCalculationResult.basicTaxedPossibleWin;
        calcTempData.possibleWinWithoutStake = bankersRounding(
          subtract(calcTempData.possibleWin, calcTempData.stake)
        );
        break;
      case ETaxTypes.POSSIBLE_WIN_TAX_WITHOUT_STAKE:
        basicTaxesCalculationResult.basicTaxedPossibleWinWithoutStakeTaxAmount =
          bankersRounding(
            calculateBasicTaxFromAmount(calcTempData.possibleWinWithoutStake)
          );

        basicTaxesCalculationResult.basicTaxedPossibleWinWithoutStake =
          bankersRounding(
            subtract(
              calcTempData.possibleWinWithoutStake,
              basicTaxesCalculationResult.basicTaxedPossibleWinWithoutStakeTaxAmount
            )
          );
        calcTempData.possibleWinWithoutStake =
          basicTaxesCalculationResult.basicTaxedPossibleWinWithoutStake;
        break;
    }
  }

  calcTempData.possibleWinWithoutRangeTax =
    basicTaxesCalculationResult.basicTaxedPossibleWin;
  calcTempData.possibleWinWithoutStakeAndWithoutRangeTax =
    basicTaxesCalculationResult.basicTaxedPossibleWinWithoutStake;

  basicTaxesCalculationResult.taxAmount =
    basicTaxesCalculationResult.basicTaxedStakeTaxAmount ??
    basicTaxesCalculationResult.basicTaxedPossibleWinTaxAmount ??
    basicTaxesCalculationResult.basicTaxedPossibleWinWithoutStakeTaxAmount;

  return {
    taxes: basicTaxesCalculationResult,
    result: calcTempData
  };
};

export type TCalculateRangeTaxes = (params: {
  stake: number;
  possibleWin: number;
}) => IRangeTaxesCalculationResult;

export const calculateRangeTaxes: TCalculateRangeTaxes = ({
  stake,
  possibleWin
}) => {
  const possibleWinWithoutStake = bankersRounding(subtract(possibleWin, stake));
  const calcTempData: Omit<IFinalCalculatedFields, 'finalStake'> = {
    stake,
    possibleWin,
    possibleWinWithoutStake,
    possibleWinWithoutRangeTax: possibleWin,
    possibleWinWithoutStakeAndWithoutRangeTax: possibleWinWithoutStake
  };

  const taxes: TTaxesState = Store.getState().betSlip?.taxes;

  const rangeTaxesCalculationResult: IRangeTaxInfo = {};

  const possibleWinTaxInfo =
    taxes.ranges?.[ETaxAmountRangesType.RANGE_TAX_POSSIBLE_WIN];

  const totalReturnWithoutStakeTaxInfo =
    taxes.ranges?.[ETaxAmountRangesType.RANGE_TAX_POSSIBLE_WIN_WITHOUT_STAKE];

  const isPossibleWinShouldRangeTaxed =
    possibleWinTaxInfo &&
    calcTempData.possibleWin >= possibleWinTaxInfo.from &&
    calcTempData.possibleWin <= possibleWinTaxInfo.to;

  if (isPossibleWinShouldRangeTaxed) {
    rangeTaxesCalculationResult.rangeTaxedPossibleWinTaxAmount =
      bankersRounding(
        calcPercentOf(calcTempData.possibleWin, possibleWinTaxInfo.percent)
      );

    rangeTaxesCalculationResult.rangeTaxedPossibleWin = bankersRounding(
      subtract(
        calcTempData.possibleWin,
        rangeTaxesCalculationResult.rangeTaxedPossibleWinTaxAmount
      )
    );

    calcTempData.possibleWin =
      rangeTaxesCalculationResult.rangeTaxedPossibleWin;
  }

  if (
    totalReturnWithoutStakeTaxInfo &&
    calcTempData.possibleWin >= totalReturnWithoutStakeTaxInfo.from &&
    calcTempData.possibleWin <= totalReturnWithoutStakeTaxInfo.to
  ) {
    rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStakeTaxAmount =
      bankersRounding(
        calcPercentOf(
          calcTempData.possibleWinWithoutStake,
          totalReturnWithoutStakeTaxInfo.percent
        )
      );

    rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStake =
      bankersRounding(
        subtract(
          calcTempData.possibleWinWithoutStake,
          rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStakeTaxAmount
        )
      );

    calcTempData.possibleWinWithoutStake =
      rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStake;
  } else if (
    isPossibleWinShouldRangeTaxed &&
    rangeTaxesCalculationResult?.rangeTaxedPossibleWin
  ) {
    rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStake =
      bankersRounding(
        subtract(rangeTaxesCalculationResult?.rangeTaxedPossibleWin, stake)
      );
    calcTempData.possibleWinWithoutStake =
      rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStake;
  }

  rangeTaxesCalculationResult.taxPercent =
    possibleWinTaxInfo?.percent ?? totalReturnWithoutStakeTaxInfo?.percent ?? 0;

  rangeTaxesCalculationResult.taxAmount =
    rangeTaxesCalculationResult.rangeTaxedPossibleWinTaxAmount ??
    rangeTaxesCalculationResult.rangeTaxedPossibleWinWithoutStakeTaxAmount ??
    0;

  return {
    taxes: rangeTaxesCalculationResult,
    result: calcTempData
  };
};
