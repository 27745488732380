export const JURISDICTIONS: Record<string, string> = {
  1: 'uk.ts',
  2: 'it.ts',
  3: 'po.ts',
  4: 'fr.ts',
  5: 'mga.ts',
  6: 'by.ts',
  7: 'usa.ts',
  8: 'ca.ts',
  9: 'nl.ts',
  10: 'gh.ts',
  11: 'se.ts',
  12: 'de.ts',
  13: 'cy.ts',
  14: 'ua.ts',
  15: 'dk.ts',
  16: 'za.ts'
};
