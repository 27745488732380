import { ActionType } from 'interfaces/generic';
import { TJackpotMeta } from 'interfaces/jackpot';
import { JackpotActionTypes } from 'store/action-types/jackpot';

type TInitialState = {
  currentJackpotId: Record<string, number | null>;
  jackpotMetaData: Record<string, Record<string, TJackpotMeta[]>>;
};

const initialState: TInitialState = {
  currentJackpotId: {},
  jackpotMetaData: {}
};

export const jackpot = (
  state = initialState,
  action: ActionType
): TInitialState => {
  switch (action.type) {
    case JackpotActionTypes.SET_CURRENT_JACKPOT_ID:
      return {
        ...state,
        currentJackpotId: {
          ...state.currentJackpotId,
          ...action.payload
        }
      };
    case JackpotActionTypes.SET_JACKPOT_META_DATA:
      return {
        ...state,
        jackpotMetaData: {
          ...state.jackpotMetaData,
          ...{
            [action.payload.moduleId]: {
              ...state.jackpotMetaData[action.payload.moduleId],
              [action.payload.jackpotId]: action.payload.jackpotData
            }
          }
        }
      };

    default: {
      return state;
    }
  }
};
