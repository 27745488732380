import { message } from 'antd';
import CSS from 'csstype';
import i18n from 'i18next';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { configureMessage } from 'configure/configure-message';

export const showToastError = (
  content: string,
  context: HTMLElement | undefined | null = null,
  key: string | null = null,
  callback?: () => void,
  style?: CSS.Properties
): void => {
  configureMessage(context, SpringConfigs.ERROR_MSG_DURATION, key || content);
  message.error({
    content: (
      <span
        dangerouslySetInnerHTML={{
          __html: content || i18n.t('swarm.sorryWrong')
        }}
      />
    ),
    key: key || content,
    onClick: callback,
    style: style
  });
};
